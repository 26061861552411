export const PARCEL_STATUS = {
  PENDING: "pending",
  APPROVED: "approved",
  DENIED: "denied",
  REMOVED: "removed",
};
export const PARCEL_STATUS_REF = {
  [PARCEL_STATUS.PENDING]: "Хүлээгдэж буй ",
  [PARCEL_STATUS.APPROVED]: "Баталгаажсан",
  [PARCEL_STATUS.DENIED]: "Татгалзсан",
  [PARCEL_STATUS.REMOVED]: "Устгагдсан",
};
export const PARCEL_STATUS_LIST = [
  {
    id: PARCEL_STATUS.PENDING,
    name: "Хүлээгдэж буй",
  },
  {
    id: PARCEL_STATUS.APPROVED,
    name: "Баталгаажсан",
  },
  {
    id: PARCEL_STATUS.DENIED,
    name: "Татгалзсан",
  },
  {
    id: PARCEL_STATUS.REMOVED,
    name: "Устгагдсан",
  },
];