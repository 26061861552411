import { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Select,
  Space,
  Form,
  Modal,
  Popconfirm,
  message,
  Checkbox,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import usePlantType from "../../hooks/usePlantType";
import NetworkApi from "../../network/networkApi";
import usePlant from "../../hooks/usePlant";
import usePlantSort from "../../hooks/usePlantSort";
import useUnit from "../../hooks/useUnit";
interface EditState {
  visible: boolean;
  edit?: any;
}
const PlantSort = () => {
  const [filterForm] = Form.useForm();
  const [content, loadContent, changeFilter] = usePlantSort();
  const [plantTypeList] = usePlantType();
  const [plantList, , changePlantFilter] = usePlant();
  const [edit, changeEdit] = useState<EditState>({
    visible: false,
  });
  const setEdit = (visible?: boolean, edit?: any) => {
    changeEdit({
      visible: visible == undefined ? false : visible,
      edit,
    });
  };
  const changePlantType = (value: string) => {
    changePlantFilter((prevValues: any) => {
      return { ...prevValues, page: 1, type: value };
    });
  };
  const onSubmit = ({
    typeId,
    plantId,
  }: {
    typeId: string;
    plantId: string;
  }) => {
    changeFilter({
      typeId,
      plantId,
    });
  };
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost("/api/sort/update", {
      ...data,
      status: "D",
    });
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Устгахад алдаа гарлаа.");
    }
  }, []);

  const elementInfo = [
    // {
    //   title: "Таримлын төрөл",
    //   render: (item: any) => item.type.name,
    // },
    {
      title: "Таримал",
      dataIndex: "plant",
      key: "plant",
      render: (item: any) => item.name,
    },
    {
      title: "Таримлын сорт",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Код",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Олон улсын код",
      dataIndex: "internationalCode",
      key: "internationalCode",
    },
    {
      title: "Хэмжих нэгж",
      dataIndex: "unit",
      render: (unit: any) => unit.name,
    },
    {
      title: "Гарцын доод хэмжээ /га-аас/",
      dataIndex: "yieldMin",
    },
    {
      title: "Гарцын дээд хэмжээ /га-аас/",
      dataIndex: "yieldMax",
    },
    {
      title: "Урамшуулал тооцогдох эсэх",
      dataIndex: "isIncentive",
      render: (value: boolean) =>
        value ? (
          <span style={{ fontWeight: "bold" }}>Тийм</span>
        ) : (
          <span>Үгүй</span>
        ),
    },
    {
      title: "Бүтээгдэхүүний код",
      dataIndex: "productCode",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Button
            onClick={() => setEdit(true, item)}
            type={"primary"}
            size={"small"}
            ghost
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна уу"
            onConfirm={() => deleteItem(item)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button
              onClick={() => {}}
              type={"primary"}
              size={"small"}
              danger
              ghost
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Таримлын сорт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col flex="auto" style={{ marginTop: 15, marginBottom: 15 }}>
            <Form layout="inline" form={filterForm} onFinish={onSubmit}>
              <Form.Item name="typeId">
                <Select
                  allowClear
                  placeholder="Таримлын төрөл"
                  style={{ width: 200 }}
                  onChange={(v: string) => {
                    changePlantType(v);
                    filterForm.setFieldsValue({ plantId: undefined });
                    filterForm.submit();
                  }}
                >
                  {plantTypeList.result.map((type) => {
                    return (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="plantId">
                <Select
                  allowClear
                  placeholder="Таримал"
                  style={{ width: 200 }}
                  onChange={() => {
                    filterForm.submit();
                  }}
                >
                  {plantList.result.map((type) => {
                    return (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col flex="none" style={{ marginTop: 15, marginBottom: 15 }}>
            <Row justify={"end"}>
              <Button
                onClick={() => setEdit(true)}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Таримлын сорт нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={elementInfo}
              dataSource={content.result}
              onChange={handleParam}
              rowKey={"id"}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <PlantSortForm
        visible={edit.visible}
        edit={edit.edit}
        onHide={() => setEdit(false)}
        onSuccess={() => {
          setEdit(false);
          loadContent();
        }}
      />
    </>
  );
};
interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}
const PlantSortForm = ({ edit, visible, onHide, onSuccess }: Props) => {
  const [plantType] = usePlantType();
  const [plant, , changePlantFilter] = usePlant();
  const [unit] = useUnit();
  const [plantSortForm] = Form.useForm();
  const [isIncentive, setIsIncentive] = useState(false);
  useEffect(() => {
    if (visible) {
      if (edit) {
        console.log(edit);
        plantSortForm.setFieldsValue({
          ...edit,
          plantTypeId: edit.plant.typeId,
        });
        setIsIncentive(edit.isIncentive);
        changePlantFilter({ type: edit.plant.typeId });
      } else {
        plantSortForm.resetFields();
      }
    }
  }, [visible, edit]);
  const onFinish = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      let response = await NetworkApi.requestPost("/api/sort/update", data);
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [edit]
  );
  return (
    <Modal
      width={900}
      centered
      visible={visible}
      title={edit ? "Сорт засах" : "Сорт нэмэх"}
      onCancel={onHide}
      footer={null}
    >
      <Form form={plantSortForm} labelCol={{ span: 6 }} onFinish={onFinish}>
        <Form.Item
          name="plantTypeId"
          label="Таримлын төрөл"
          rules={[{ required: true, message: "Таримлын төрөл сонгоно уу" }]}
        >
          <Select
            onChange={(v) => {
              changePlantFilter({ type: v });
              plantSortForm.setFieldsValue({ plantId: undefined });
            }}
            placeholder="Таримлын төрөл"
          >
            {plantType.result.map((p) => {
              return (
                <Select.Option key={p.id} value={p.id}>
                  {p.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="plantId"
          label="Таримал"
          rules={[{ required: true, message: "Таримал сонгоно уу" }]}
        >
          <Select placeholder="Таримал">
            {plant.result.map((p) => {
              return (
                <Select.Option key={p.id} value={p.id}>
                  {p.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Нэр"
          rules={[{ required: true, message: "Нэр оруулна уу" }]}
        >
          <Input placeholder="Нэр" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Код"
          rules={[{ required: true, message: "Код оруулна уу" }]}
        >
          <Input placeholder="Код" />
        </Form.Item>
        <Form.Item name="internationalCode" label="ОУ код">
          <Input />
        </Form.Item>
        <Form.Item name="unitId" label="Хэмжих нэгж">
          <Select>
            <Select.Option value={""}>Хэмжих нэгж</Select.Option>
            {unit.result.map((p) => {
              return (
                <Select.Option key={p.id} value={p.id}>
                  {p.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="yieldMin" label="Гарцын доод хэмжээ">
          <Input placeholder="Гарцын доод хэмжээ /га-аас/" />
        </Form.Item>
        <Form.Item name="yieldMax" label="Гарцын дээд хэмжээ">
          <Input placeholder="Гарцын дээд хэмжээ /га-аас/" />
        </Form.Item>
        <Form.Item
          name="isIncentive"
          label="Урамшуулал тооцогдох эсэх"
          valuePropName="checked"
        >
          <Checkbox onChange={(e) => setIsIncentive(e.target.checked)} />
        </Form.Item>
        {isIncentive && (
          <Form.Item
            name="productCode"
            label="Ибаримт код"
            rules={[{ required: true, message: "Ибаримт код оруулна уу" }]}
          >
            <Input placeholder="Код" />
          </Form.Item>
        )}
        <Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Хадгалах
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default PlantSort;
