import { Button, Form, Input, InputNumber, message, Modal, notification, Radio, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import NetworkApi from '../../network/networkApi';
import AddressSelect from '../lib/AddressSelect';

interface Props {
    visible: boolean
    hide: any
}

const farmerRegistration = {
    type: 'farmer',
    rd: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    aid: '',
    name: '',
    password: '',
    confirmPassword: ''
};
const orgRegistration = {
    type: 'organization',
    rd: '',
    userRd: '',
    organizationName: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    aid: '',
    name: '',
    password: '',
    confirmPassword: ''
}
const Register = ({ visible, hide }: Props) => {
    const [farmer, changeFarmer] = useState(farmerRegistration);
    const [org, orgChange] = useState(orgRegistration);
    const [isFarmer, changeTab] = useState(false);
    const submitRegister = useCallback(async (dt: any) => {
        setBtnDisable(true);
        let url = isFarmer ? '/api/farmer/register' : '/api/organization/register'
        let data = isFarmer ? farmer : org
        data = {
            ...dt,
            ...data
        }
        let result = await NetworkApi.requestPost(url, data);
        if (result.resultCode == 200) {
            window.location.reload()
        } else {
            setBtnDisable(false);
            message.error(result.message || 'system error')
        }
    }, [farmer, org, isFarmer]);
    const [organization, setOrganization] = useState(true)
    const [orgLoading,setLoading] = useState(false)
    const [organizationRd, setOrganizationRd] = useState('')
    const [btnDisable, setBtnDisable] = useState(false)

    const checkOrganization = useCallback(async () => {        
        if (organizationRd) {
            setLoading(true)
            let response = await NetworkApi.requestGet(`/api/organization/check/${organizationRd}`)
            if (response.resultCode == 200) {
                console.log(response.result.name)
                orgChange(o => {
                    return {
                        ...o,
                        rd: organizationRd,
                        organizationName: response.result.name
                    }
                })
                setOrganization(false)
            } else {
                notification.error({ message: response.message || 'Регистрийн дугаар оруулна уу' })
            }
            setLoading(false)
        } else {
            notification.error({ message: 'Регистрийн дугаар оруулна уу' })
        }
    }, [organizationRd])
    return (
        <Modal width={794} centered visible={visible}
            onCancel={() => hide()} title={'Бүртгэл'} footer={null}
        >
            <Form
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 10 }}
                initialValues={{
                    userType: 'org'
                }}
                onFinish={submitRegister}
            >
                <Form.Item name={'userType'} key={'1'}>
                    <Radio.Group onChange={(e) => changeTab(e.target.value == "farmer" ? true : false)}>
                        <Radio value={"org"} key={'aaa'}>Хуулийн этгээд</Radio>
                        <Radio value={"farmer"} key={'hhh'}>Хувь хүн / Тариаланч</Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    !isFarmer ? (
                        <>
                            {organization ? (
                                <>
                                    <Form.Item
                                        key={'3'}
                                        label={'Хуулийн этгээдийн Регистр'}
                                        rules={[{ required: true, message: 'Хуулийн этгээдийн регистр оруулна уу!' }]}
                                    >
                                        <Input value={organizationRd}
                                            onChange={t => setOrganizationRd(t.target.value)} />
                                    </Form.Item>
                                    <Button key="submit" loading={orgLoading} type="primary" onClick={() => {
                                        checkOrganization()
                                    }}>Үргэлжлүүлэх</Button>
                                </>
                            ) : (
                                <>  <Form.Item
                                    key={'2'}
                                    label={'Хуулийн этгээдийн регистрийн дугаар'}
                                    rules={[
                                        { required: true, message: 'Хуулийн этгээдийн регистр оруулна уу.' },
                                        {
                                            pattern: new RegExp('^(?=[0-9]{7}$)'), message: 'Хуулийн этгээдийн регистр 7 оронтой' +
                                                ' тоо байна'
                                        }
                                    ]}
                                >
                                    <Input value={org.rd} disabled />
                                </Form.Item>
                                    <Form.Item
                                        key={'3'}
                                        label={'Хуулийн этгээдийн нэр'}
                                    >
                                        <Input disabled value={org.organizationName} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgPreRd'}
                                        key={'4'}
                                        label={'Захирлын регистрийн дугаар'}
                                        rules={[
                                            { required: false, message: 'Хуулийн этгээдийн нэрээ оруулна уу!' },
                                            { pattern: new RegExp('^[а-яүөА-ЯҮӨЁ]{2}[0-9]{8}$'), message: 'АА00000000  регистрийн дугаар байна' }
                                        ]}
                                    >
                                        <Input value={org.userRd}
                                            onChange={t => orgChange({ ...org, userRd: t.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgPreSurname'}
                                        key={'5'}
                                        label={'Захирлын эцэг/эхийн нэр'}
                                        rules={[
                                            { required: false, message: 'Эцэг эхийн нэр оруулна уу!' },
                                            { pattern: new RegExp('^[а-яүөА-ЯҮӨЁ \-]{1,56}$'), message: 'Крилл үсгээр оруулна уу' }
                                        ]}
                                    >
                                        <Input value={org.lastName}
                                            onChange={t => orgChange({ ...org, lastName: t.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgPreName'}
                                        key={'6'}
                                        label={'Захирлын нэр'}
                                        rules={[{ required: false, message: 'Нэр оруулна уу!' },]}
                                    >
                                        <Input value={org.firstName}
                                            onChange={t => orgChange({ ...org, firstName: t.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgPhone'}
                                        key={'7'}
                                        label={'Утас'}
                                        rules={[
                                            { pattern: new RegExp('^[0-9]{6,15}$'), message: 'Тоо оруулна уу' },
                                        ]}
                                    >
                                        <Input value={org.phone}
                                            onChange={t => orgChange({ ...org, phone: t.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgEmail'}
                                        key={'8'}
                                        label={'И-мэйл хаяг'}
                                        rules={[
                                            { type: 'email', message: 'Мэйл хаяг буруу байна!' },
                                        ]}
                                    >
                                        <Input value={org.email}
                                            onChange={t => orgChange({ ...org, email: t.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgFirstStage'}
                                        key={'9'}
                                        label={'Анхан шатны үйлдвэрлэл эрхлэгчийн дугаар'}
                                        rules={[
                                            { pattern: new RegExp('^[0-9]{6,15}$'), message: 'Тоо оруулна уу' },
                                            { message: 'Анхан шатны үйлдвэрлэл эрхлэгчийн дугаар оруулна уу!' }
                                        ]}
                                    >
                                        <Input value={org.aid}
                                            onChange={t => orgChange({ ...org, aid: t.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgUserName'}
                                        key={'10'}
                                        label={'Системд нэвтрэх нэр'}
                                        rules={[
                                            {
                                                pattern: new RegExp('^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$'),
                                                message: 'Хэрэглэгчийн нэрийг latin үсэг болон тоо орсон 6-16 тэмдэгт байна.'
                                            },
                                            { required: true, message: 'Хэрэглэгчийн нэрийг оруулна уу' }
                                        ]}
                                    >
                                        <Input value={org.name}
                                            onChange={t => orgChange({ ...org, name: t.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgPassword'}
                                        key={'11'}
                                        label={'Нууц үг'}
                                    >
                                        <Input.Password value={org.password}
                                            onChange={t => orgChange({ ...org, password: t.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        name={'orgConfirmPassword'}
                                        key={'12'}
                                        label={'Нууц үгээ давтан оруулна уу!'}
                                    >
                                        <Input.Password
                                            value={org.confirmPassword}
                                            onChange={t => orgChange({ ...org, confirmPassword: t.target.value })} />
                                    </Form.Item>
                                    <AddressSelect />
                                    <Form.Item>
                                        <Button onClick={() => setOrganization(true)} >Буцах</Button>
                                        <Button key="submit" type="primary" htmlType={"submit"} disabled={btnDisable}>Бүртгүүлэх</Button>
                                    </Form.Item>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Form.Item
                                name={'userRd'}
                                key={'13'}
                                label={'Регистрийн дугаар'}
                                rules={[
                                    { required: true, message: 'Хуулийн этгээдийн нэрээ оруулна уу!' },
                                    { pattern: new RegExp('^[а-яүөА-ЯҮӨ]{2}[0-9]{8}$'), message: 'АА00000000  регистрийн дугаар байна' }
                                ]}
                            >
                                <Input value={farmer.rd}
                                    onChange={(t) => changeFarmer({ ...farmer, rd: t.target.value })} />
                            </Form.Item>
                            <Form.Item
                                name={'userSurname'}
                                key={'14'}
                                label={'Эцэг/эхийн нэр'}
                                rules={[
                                    { required: true, message: 'Эцэг эхийн нэр оруулна уу!' },
                                    { pattern: new RegExp('^[а-яүөА-ЯҮӨёЁ \-]{1,56}$'), message: 'Крилл үсгээр оруулна уу' }
                                ]}
                            >
                                <Input value={farmer.lastName}
                                    onChange={(t) => changeFarmer({ ...farmer, lastName: t.target.value })} />
                            </Form.Item>
                            <Form.Item
                                name={'userName'}
                                key={'15'}
                                label={'Нэр'}
                                rules={[
                                    { required: true, message: 'Эцэг эхийн нэр оруулна уу!' },
                                    { pattern: new RegExp('^[а-яүөА-ЯҮӨёЁ \-]{1,56}$'), message: 'Крилл үсгээр оруулна уу' }
                                ]}
                            >
                                <Input value={farmer.firstName}
                                    onChange={(t) => changeFarmer({ ...farmer, firstName: t.target.value })} />
                            </Form.Item>
                            <Form.Item
                                name={'userPhone'}
                                key={'16'}
                                label={'Утас'}
                                rules={[
                                    { pattern: new RegExp('^[0-9]{6,15}$'), message: 'Тоо оруулна уу', required: true },
                                ]}
                            >
                                <Input value={farmer.phone}
                                    onChange={(t) => changeFarmer({ ...farmer, phone: t.target.value })} />
                            </Form.Item>
                            <Form.Item
                                name={'userEmail'}
                                key={'17'}
                                label={'И-мэйл хаяг'}
                                rules={[
                                    { type: 'email', message: 'мэйл хаяг буруу байна' }]}
                            >
                                <Input value={farmer.email}
                                    onChange={(t) => changeFarmer({ ...farmer, email: t.target.value })} />
                            </Form.Item>
                            <Form.Item
                                name={'userFarmerCode'}
                                key={'18'}
                                label={'Тариаланчийн код'}
                                rules={[
                                    { pattern: new RegExp('^[0-9]{6,15}$'), message: 'Тоо оруулна уу' },
                                ]}
                            >
                                <Input value={farmer.aid}
                                    onChange={(t) => changeFarmer({ ...farmer, aid: t.target.value })} />
                            </Form.Item>
                            <Form.Item
                                name={'userLoginName'}
                                key={'19'}
                                label={'Системд нэвтрэх нэр'}
                                rules={[
                                    {
                                        pattern: new RegExp('^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$'),
                                        message: 'Хэрэглэгчийн нэрийг latin үсэг болон тоо орсон 6-16 тэмдэгт байна.'
                                    },
                                    { required: true, message: 'Хэрэглэгчийн нэрийг оруулна уу' }
                                ]}
                            >
                                <Input value={farmer.name}
                                    onChange={(t) => changeFarmer({ ...farmer, name: t.target.value })} />
                            </Form.Item>
                            <Form.Item
                                name={'userPassword'}
                                key={'20'}
                                label={'Нууц үг'}
                                rules={[
                                    { required: true, message: 'Нууц үг' }
                                ]}
                            >
                                <Input.Password
                                    value={farmer.password}
                                    onChange={(t) => changeFarmer({ ...farmer, password: t.target.value })} />
                            </Form.Item>
                            <Form.Item
                                name={'userConfirmPassword'}
                                key={'21'}
                                rules={[
                                    { required: true, message: 'Нууц үгээ дахин оруулна уу' }
                                ]}
                                label={'Нууц үгээ дахин оруулна уу!'}
                            >
                                <Input.Password
                                    value={farmer.confirmPassword}
                                    onChange={(t) => changeFarmer({ ...farmer, confirmPassword: t.target.value })} />
                            </Form.Item>
                            <AddressSelect />
                            <Form.Item>
                                <Button key="submit" type="primary" htmlType={"submit"} disabled={btnDisable}>Бүртгүүлэх</Button>
                            </Form.Item>
                        </>
                    )
                }
            </Form>
        </Modal>
    )
};
export default Register
