import { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Layout,
  Row,
  Form,
  Table,
  DatePicker,
  Select,
  Space,
  message,
  Tag,
  Popconfirm,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  FileOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import "moment/locale/mn";
import locale from "antd/es/date-picker/locale/mn_MN";

import BreadMe from "../lib/Breadme";
import AddressSelect from "../lib/AddressSelect";

import NetworkApi from "../../network/networkApi";

import usePlantType from "../../hooks/usePlantType";
import usePlant from "../../hooks/usePlant";
import { PLAN_STATUS, PLAN_STATUS_TEXT } from "../../enums/plan.enum";
import UserContext from "../../providers/UserContext";
import { USER_ROLE } from "../../enums/user.enum";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { usePlanApprove } from "../../hooks/usePlanApprove";
import { usePlanDecline } from "../../hooks/usePlanDecline";
import {
  formatArea,
  formatNumberWithComma,
  formatPercent,
} from "../../helpers/number.helper";

interface FilterType {
  year: string;
  aimagId: string;
  sumId: string;
  plantTypeId: string;
  plantId: string;
  page: number;
}

interface TableColumn {
  title: string;
  dataIndex: string;
  render: any;
}

interface PlanType {
  id: string;
  year: string;
  createdUser: {
    firstName: string;
    lastName: string;
    userId: number;
  };
  plantTypeName: string;
  plantName: string;
  ownerCount: number;
  planArea: number;
  cultivatedOwnerCount: number;
  cultivatedArea: number;
  address: {
    aimagName: string;
    sumName: string;
  };
  status: keyof typeof PLAN_STATUS_TEXT;
}

const PlanList = () => {
  const history = useHistory();
  const defaultYear = moment().format("YYYY");

  const { user } = useContext(UserContext);
  const [plantType] = usePlantType();
  const [plant, , changePlantFilter] = usePlant();
  const [loadingAP, approvePlan] = usePlanApprove();
  const [loadingAD, declinePlan] = usePlanDecline();

  const [filter, changeFilter] = useState<FilterType>({
    year: defaultYear,
    aimagId: [
      USER_ROLE.PROVINCE_AGRONOMIST,
      USER_ROLE.VILLAGE_AGRONOMIST,
    ].includes(user.role.cd)
      ? user.aimagId
      : "",
    sumId: [USER_ROLE.VILLAGE_AGRONOMIST].includes(user.role.cd)
      ? user.sumId
      : "",
    plantTypeId: "",
    plantId: "",
    page: 1,
  });

  const [content, changeContent] = useState({
    loading: false,
    result: [],
    total: 0,
    page: 1,
  });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet("/api/plan/list", filter);
    if (response.resultCode === 200) {
      changeContent({
        loading: false,
        result: response.result?.rows || [],
        total: response.result?.count || 0,
        page: response.result?.page || 1,
      });
    } else {
      message.error(response.message || "system error");
    }
  }, [filter]);

  useEffect(() => {
    loadContent();
  }, [filter, loadContent]);

  const onSubmit = ({
    year,
    aimagId,
    sumId,
    plantTypeId,
    plantId,
  }: {
    year: moment.Moment;
    aimagId: string;
    sumId: string;
    plantTypeId: string;
    plantId: string;
  }) => {
    changeFilter({
      year: year.format("YYYY"),
      aimagId,
      sumId,
      plantTypeId,
      plantId,
      page: 1,
    });
  };

  const deleteItem = useCallback(
    async (data) => {
      let response = await NetworkApi.requestDelete(
        `/api/plan/delete/${data.id}`
      );
      if (response.resultCode === 200) {
        message.success("Амжилттай устгагдлаа");
        loadContent();
      } else {
        message.error(response.message || "Устгахад алдаа гарлаа.");
      }
    },
    [loadContent]
  );

  const columns: TableColumn[] = [
    {
      title: "№",
      dataIndex: "id",
      render: (value: string) => value,
    },
    {
      title: "Он",
      dataIndex: "year",
      render: (value: string) => value,
    },
    {
      title: "Төлөвлөгөө үүсгэсэн",
      dataIndex: "createdUser",
      render: (value: { lastName: string; firstName: string }) =>
        `${value.lastName} ${value.firstName}`,
    },
    {
      title: "Таримлын төрөл",
      dataIndex: "plantTypeName",
      render: (value: string) => value,
    },
    {
      title: "Таримал",
      dataIndex: "plantName",
      render: (value: string) => value,
    },
    {
      title: "Тариалах тариаланчид",
      dataIndex: "ownerCount",
      render: (value: number) => formatNumberWithComma(value),
    },
    {
      title: "Тариалах талбай /га/",
      dataIndex: "planArea",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Тариалсан тариаланчаар",
      dataIndex: "cultivatedOwnerCount",
      render: (value: number, row: PlanType) =>
        `${formatNumberWithComma(value)} (${formatPercent(
          value > 0 ? (value / row.cultivatedOwnerCount) * 100 : 0
        )})`,
    },
    {
      title: "Тариалсан талбай /га/",
      dataIndex: "cultivatedArea",
      render: (value: number, row: PlanType) =>
        `${formatArea(value)} (${formatPercent(
          value > 0 ? (value / row.planArea) * 100 : 0
        )})`,
    },
    {
      title: "Хаяг",
      dataIndex: "address",
      render: (address: { aimagName: string; sumName: string }) =>
        `${address.aimagName}/${address.sumName}`,
    },
    {
      title: "Баталгаажсан төлөв",
      dataIndex: "status",
      render: (value: keyof typeof PLAN_STATUS_TEXT) => {
        switch (value) {
          case PLAN_STATUS.APPROVED:
            return <Tag color={"#87d068"}>{`${PLAN_STATUS_TEXT[value]}`}</Tag>;
          case PLAN_STATUS.DECLINED:
            return <Tag color={"#f50"}>{`${PLAN_STATUS_TEXT[value]}`}</Tag>;
          default:
            return <Tag color={"#108ee9"}>{`${PLAN_STATUS_TEXT[value]}`}</Tag>;
        }
      },
    },
    {
      title: "Үйлдэл",
      dataIndex: "id",
      render: (id: string, row: PlanType) => (
        <Space>
          <Button
            type="default"
            size="small"
            icon={<FileOutlined />}
            onClick={() => history.push(`/plan/detail/${id}`)}
          >
            Дэлгэрэнгүй
          </Button>
          {row.status === PLAN_STATUS.PENDING &&
            user.role.cd === USER_ROLE.PROVINCE_AGRONOMIST && (
              <>
                <Button
                  type="primary"
                  size="small"
                  loading={loadingAP}
                  onClick={() => approvePlan(id, loadContent)}
                >
                  Баталгаажуулах
                </Button>
                <Button
                  type="primary"
                  size="small"
                  danger
                  loading={loadingAD}
                  onClick={() => declinePlan(id, loadContent)}
                >
                  Цуцлах
                </Button>
              </>
            )}
          {(row.status === PLAN_STATUS.PENDING ||
            row.status === PLAN_STATUS.DECLINED) &&
            user.role.cd === USER_ROLE.VILLAGE_AGRONOMIST &&
            row.createdUser.userId === user.id && (
              <Button
                type="primary"
                size="small"
                icon={<EditOutlined />}
                onClick={() => history.push(`/plan/edit/${id}`)}
              >
                Засах
              </Button>
            )}
          {row.status === PLAN_STATUS.PENDING &&
            user.role.cd === USER_ROLE.VILLAGE_AGRONOMIST &&
            row.createdUser.userId === user.id && (
              <Popconfirm
                title="Устгахдаа итгэлтэй байна уу"
                onConfirm={() => deleteItem(row)}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button
                  onClick={() => {}}
                  type={"primary"}
                  size={"small"}
                  danger
                  ghost
                  icon={<DeleteOutlined />}
                >
                  Устгах
                </Button>
              </Popconfirm>
            )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadMe title={["Тариалалтын төлөвлөгөө", "Жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Form
            layout="inline"
            initialValues={{ year: moment() }}
            onFinish={onSubmit}
          >
            <Form.Item name="year">
              <DatePicker picker="year" locale={locale} allowClear={false} />
            </Form.Item>
            <AddressSelect
              defaultAimag={
                [
                  USER_ROLE.PROVINCE_AGRONOMIST,
                  USER_ROLE.VILLAGE_AGRONOMIST,
                ].includes(user.role.cd)
                  ? user.aimagId
                  : null
              }
              defaultSum={
                [USER_ROLE.VILLAGE_AGRONOMIST].includes(user.role.cd)
                  ? user.sumId
                  : null
              }
              hideLabel
              ignoreBag
            />
            <Form.Item name="plantTypeId">
              <Select
                defaultValue=""
                onChange={(v) => changePlantFilter({ type: v })}
              >
                <Select.Option value="">Таримлын төрөл</Select.Option>
                {plantType.result.map((p: any) => (
                  <Select.Option value={p.id}>{p.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="plantId">
              <Select defaultValue="">
                <Select.Option value={""}>Таримлын нэр</Select.Option>
                {plant.result.map((p: any) => (
                  <Select.Option value={p.id}>{p.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                icon={<SearchOutlined />}
                type="primary"
              ></Button>
            </Form.Item>
          </Form>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              rowKey={"id"}
              loading={content.loading}
              columns={columns}
              dataSource={content.result}
              scroll={{ x: 1500 }}
              pagination={{
                showSizeChanger: false,
                current: content.page,
                defaultPageSize: 10,
                total: content.total,
                onChange: (page: number) => changeFilter({ ...filter, page }),
              }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

export default PlanList;
