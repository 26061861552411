import { Modal } from "antd";
import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";

type AvailableIncentivePlant = {
  name: string;
  productCode: string;
};

interface ContentData {
  result: AvailableIncentivePlant[];
  loading: boolean;
}
const useIncentiveAvailablePlants = (): [ContentData, () => Promise<void>] => {
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    loadContent();
  }, []);
  const loadContent = useCallback(async () => {
    changeContent({
      ...content,
      loading: true,
    });
    let response = await NetworkApi.requestGet("/api/sort/incentive");
    if (response.resultCode == 200)
      changeContent({
        loading: false,
        result: response.result || [],
      });
    else {
      changeContent({
        loading: false,
        result: [],
      });
      Modal.confirm({
        title: "Анхааруулга",
        content: response.message || "Алдаа гарлаа",
        okText: "Ойлголоо",
        okCancel: false,
      });
    }
  }, []);

  return [content, loadContent];
};
export default useIncentiveAvailablePlants;
