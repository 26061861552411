export const formatNumberWithComma = (value: number | string) =>
  `${parseFloat(`${value ?? 0}`).toFixed(2)}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
export const formatArea = (value: number | string) =>
  `${parseFloat(`${value ?? 0}`).toFixed(2)}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  ) + " га";
export const formatPercent = (value: number) =>
  `${parseFloat(`${value ?? 0}`).toFixed(2)}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  ) + "%";
export const formatNumberWithCommaAndPoint = (
  value: number | string | undefined
) =>
  `${parseFloat(`${value ?? 0}`).toFixed(2)}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );
