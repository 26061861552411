export const INCENTIVE_STATUS = {
  DRAFT: "draft",
  REQUESTED: "requested",
  RECEIVED: "received",
  REJECTED: "rejected",
  PAID: "paid",
  INVALID_ACCOUNT: "invalid_account",
  ACCOUNT_CORRECTED: "account_corrected",
  REVIEWING: "reviewing",
  CORRECTED: "corrected",
  DELETED: "deleted",
  RETURNED: "returned",
};
export const INCENTIVE_STATUS_REF = {
  [INCENTIVE_STATUS.DRAFT]: "Илгээгээгүй",
  [INCENTIVE_STATUS.REQUESTED]: "Хүсэлт илгээсэн",
  [INCENTIVE_STATUS.RECEIVED]: "Хүсэлт хүлээн авсан",
  [INCENTIVE_STATUS.REJECTED]: "Хүсэлт татгалзсан",
  [INCENTIVE_STATUS.PAID]: "Олгогдсон",
  [INCENTIVE_STATUS.INVALID_ACCOUNT]: "Дансны мэдээлэл зөрсөн",
  [INCENTIVE_STATUS.ACCOUNT_CORRECTED]: "Данс зассан",
  [INCENTIVE_STATUS.REVIEWING]: "Гүйлгээ хянагдаж байна",
  [INCENTIVE_STATUS.CORRECTED]: "Данс засварласан",
  [INCENTIVE_STATUS.DELETED]: "Устгасан",
  [INCENTIVE_STATUS.RETURNED]: "Хүсэлт буцаасан",
};
export const INCENTIVE_STATUS_LIST = [
  {
    id: INCENTIVE_STATUS.DRAFT,
    name: "Илгээгээгүй",
  },
  {
    id: INCENTIVE_STATUS.REQUESTED,
    name: "Хүсэлт илгээгдсэн",
  },
  {
    id: INCENTIVE_STATUS.RECEIVED,
    name: "Хүсэлт хүлээн авсан",
  },
  {
    id: INCENTIVE_STATUS.REJECTED,
    name: "Хүсэлт татгалзсан",
  },
  {
    id: INCENTIVE_STATUS.PAID,
    name: "Олгогдсон",
  },
  {
    id: INCENTIVE_STATUS.INVALID_ACCOUNT,
    name: "Дансны мэдээлэл зөрсөн",
  },
  {
    id: INCENTIVE_STATUS.CORRECTED,
    name: "Данс засварласан",
  },
  {
    id: INCENTIVE_STATUS.RETURNED,
    name: "Хүсэлт буцаасан",
  },
];

export const INCENTIVE_TYPE = {
  WHEAT: "wheat",
  VEGETABLE: "vegetable",
};
export const INCENTIVE_TYPE_REF = {
  [INCENTIVE_TYPE.WHEAT]: "Улаанбуудай",
  [INCENTIVE_TYPE.VEGETABLE]: "Хүнсний ногоо",
};
export const INCENTIVE_TYPE_LIST = [
  {
    id: INCENTIVE_TYPE.WHEAT,
    name: "Улаанбуудай",
  },
  {
    id: INCENTIVE_TYPE.VEGETABLE,
    name: "Хүнсний ногоо",
  },
];
