import { Table } from "antd";
import useFarmerChemicalWarehouse from "../../../hooks/useFarmerChemicalWarehouse";
import { useEffect } from "react";

const FarmerWarehouseChemical = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const [chemicalWarehouse, , changeFilter] = useFarmerChemicalWarehouse();

  useEffect(() => {
    if (organizationId?.length > 0) {
      changeFilter({
        ...chemicalWarehouse,
        organizationId,
        page: 1,
        pageSize: 10,
      });
    }
  }, [organizationId]);

  const houseInfo = [
    {
      title: "№",
      width: 60,
      ellipsis: true,
      render: (a: any, b: any, index: any) => index + 1,
    },
    {
      title: "Бодис, бордооны нэр",
      render: (itm: any) => itm.chemical?.technicalName,
    },
    {
      title: "Зориулалт",
      render: (itm: any) => itm.chemical?.purpose?.name,
    },
    {
      title: "Олон улсын бүртгэлийн дугаар /CAS/",
      render: (itm: any) => itm.chemical?.registrationNumber,
    },
    {
      title: "Огноо",
      dataIndex: "importDate",
    },
    {
      title: "Хаанаас",
      render: (value: any) => (value.seller ? `${value.seller?.name}` : ""),
    },
    {
      title: "Хэмжээ",
      dataIndex: "amount",
      render: (amount: any) => amount + " кг",
    },
    {
      title: "Үлдэгдэл",
      dataIndex: "remaining",
      render: (remaining: any) => remaining + " кг",
    },
  ];

  return (
    <>
      <Table
        scroll={{ x: true }}
        style={{ width: "100%" }}
        loading={chemicalWarehouse.loading}
        columns={houseInfo}
        dataSource={chemicalWarehouse.result}
      />
    </>
  );
};
export default FarmerWarehouseChemical;
