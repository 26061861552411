import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Space,
  Form,
  Modal,
  message,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";
import useServiceType from "../../hooks/useServiceType";

const FarmLandType = () => {
  const [addEdit, setAddEdit] = useState({
    visible: false,
    edit: null,
  });
  const [content, loadContent, changeContentFilter] = useServiceType(1);
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost("/api/service/type/update", {
      ...data,
      status: "D",
    });
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "system error");
    }
  }, []);
  const elementInfo = [
    {
      title: "Арчилгааны төрөл",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Button
            onClick={() => {
              setAddEdit({ visible: true, edit: item });
            }}
            type={"primary"}
            size={"small"}
            icon={<EditOutlined />}
            ghost
          />
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна уу"
            onConfirm={() => deleteItem(item)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button
              type={"primary"}
              size={"small"}
              danger
              ghost
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Талбайн түүхийн ангилал"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"}>
              <Button
                onClick={() => setAddEdit({ visible: true, edit: null })}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Ангилал нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              columns={elementInfo}
              dataSource={content.result}
              onChange={handleParam}
              rowKey={"id"}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <FarmlandServiceTypeAddEdit
        edit={addEdit.edit}
        visible={addEdit.visible}
        onHide={() => setAddEdit({ visible: false, edit: null })}
        onSuccess={() => {
          loadContent();
          setAddEdit({ visible: false, edit: null });
        }}
      />
    </>
  );
};

interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const FarmlandServiceTypeAddEdit = ({
  edit,
  visible,
  onHide,
  onSuccess,
}: Props) => {
  const formRef = useRef<any>();
  useEffect(() => {
    if (visible) {
      if (edit) {
        formRef.current?.setFieldsValue(edit);
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [edit, visible]);
  const saveContent = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      let response = await NetworkApi.requestPost(
        "/api/service/type/update",
        data
      );
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "system error");
      }
    },
    [edit]
  );
  return (
    <Modal
      visible={visible}
      title={
        edit
          ? "Талбайн арчилгааны төрөл засах"
          : "Талбайн арчилгааны төрөл нэмэх"
      }
      onCancel={onHide}
      footer={null}
    >
      <Form ref={formRef} labelCol={{ span: 6 }} onFinish={saveContent}>
        <Form.Item name="name" label="Төрөл">
          <Input placeholder={"Талбайн арчилгааны төрөл"} />
        </Form.Item>
        <Form.Item name="description" label="Тайлбар">
          <Input placeholder={"Тайлбар"} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default FarmLandType;
