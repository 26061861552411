import { useCallback, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Select,
  Space,
  Form,
  Modal,
  Popconfirm,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";
import useEqiupmentSubType from "../../hooks/useEquipmentSubType";
import useEqiupmentType from "../../hooks/useEquipmentType";

interface EditState {
  visible: boolean;
  edit?: any;
}
const EquipmentSubType = () => {
  const [content, loadContent, changeFilter] = useEqiupmentSubType();
  const [typeList] = useEqiupmentType();
  const [edit, changeEdit] = useState<EditState>({
    visible: false,
  });
  const setEdit = (visible?: boolean, edit?: any) => {
    changeEdit({
      visible: visible == undefined ? false : visible,
      edit,
    });
  };
  const filterChange = (value: any) => {
    changeFilter((prevValues: any) => {
      return { ...prevValues, page: 1, parentTypeId: value };
    });
  };
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost(
      "/api/equipment/subtype/update",
      {
        ...data,
        status: "D",
      }
    );
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Устгахад алдаа гарлаа.");
    }
  }, []);

  const elementInfo = [
    {
      title: "Төхөөрөмжийн төрөл",
      render: (item: any) => item.parentType.name,
    },
    {
      title: "Төхөөрөмжийн дэд төрөл",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Button
            onClick={() => setEdit(true, item)}
            type={"primary"}
            size={"small"}
            ghost
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна уу"
            onConfirm={() => deleteItem(item)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button
              onClick={() => {}}
              type={"primary"}
              size={"small"}
              danger
              ghost
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Төхөөрөмжийн дэд төрөл"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"space-between"}>
              <Space size={20}>
                <Select
                  allowClear
                  placeholder="Төхөөрөмжийн төрөл"
                  style={{ width: 200 }}
                  onChange={filterChange}
                >
                  {typeList.result.map((type) => {
                    return (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Space>
              <Button
                onClick={() => setEdit(true)}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Төхөөрөмжийн дэд төрөл нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={elementInfo}
              dataSource={content.result}
              onChange={handleParam}
              rowKey={"id"}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <EquipmentSubTypeAddEdit
        visible={edit.visible}
        edit={edit.edit}
        onHide={() => setEdit(false)}
        onSuccess={() => {
          setEdit(false);
          loadContent();
        }}
      />
    </>
  );
};
interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}
const EquipmentSubTypeAddEdit = ({
  edit,
  visible,
  onHide,
  onSuccess,
}: Props) => {
  const [typeList] = useEqiupmentType();
  const formRef = useRef<any>();
  useEffect(() => {
    if (visible) {
      if (edit) {
        formRef.current?.setFieldsValue(edit);
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [visible, edit]);
  const onFinish = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      console.log(data);
      let response = await NetworkApi.requestPost(
        "/api/equipment/subtype/update",
        data
      );
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [edit]
  );
  return (
    <Modal
      width={595}
      centered
      visible={visible}
      title={
        edit ? "Төхөөрөмжийн дэд төрөл засах" : "Төхөөрөмжийн дэд төрөл нэмэх"
      }
      onCancel={onHide}
      footer={null}
    >
      <Form ref={formRef} onFinish={onFinish} layout="vertical">
        <Form.Item name="parentTypeId" label="Төхөөрөмжийн төрөл">
          <Select placeholder="Төхөөрөмжийн төрөл сонгоно уу">
            {typeList.result.map((type) => {
              return (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="name" label="Төхөөрөмжийн дэд төрөл">
          <Input placeholder="Төхөөрөмжийн дэд төрөл оруулна уу" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default EquipmentSubType;
