import { useCallback, useState } from "react";
import NetworkApi from "../network/networkApi";

type SupportFarmlandListContent = {
  loading: boolean;
  area: number;
  result: any[];
};

const useSupportFarmlandList = (): [
  SupportFarmlandListContent,
  (type: string) => void
] => {
  const [content, changeContent] = useState<SupportFarmlandListContent>({
    loading: false,
    area: 0,
    result: [],
  });
  const loadContent = useCallback(async (type: string) => {
    let response = await NetworkApi.requestGet("/api/support/farmland/list", {
      type,
    });
    if (response.resultCode == 200)
      changeContent({
        loading: false,
        area: response.result?.area,
        result: response.result?.lands ?? [],
      });
  }, []);
  return [content, loadContent];
};

export default useSupportFarmlandList;
