export const SUPPORT_TYPE = {
  TECHNIC: "technic",
  FERTILIZER: "fertilizer",
  FOUNDATION: "foundation",
};
export const SUPPORT_TYPE_REF = {
  [SUPPORT_TYPE.TECHNIC]: "Техник",
  [SUPPORT_TYPE.FERTILIZER]: "Бордоо",
  [SUPPORT_TYPE.FOUNDATION]: "ХААК",
};
export const SUPPORT_TYPE_LIST = [
  {
    id: SUPPORT_TYPE.TECHNIC,
    name: SUPPORT_TYPE_REF[SUPPORT_TYPE.TECHNIC],
  },
  {
    id: SUPPORT_TYPE.FERTILIZER,
    name: SUPPORT_TYPE_REF[SUPPORT_TYPE.FERTILIZER],
  },
  {
    id: SUPPORT_TYPE.FOUNDATION,
    name: SUPPORT_TYPE_REF[SUPPORT_TYPE.FOUNDATION],
  },
];

export const SUPPORT_STATUS = {
  DRAFT: "draft",
  REQUESTED: "requested",
  REJECTED: "rejected",
  RECEIVED: "received",
  INVALID_ACCOUNT: "invalid_account",
  REVIEWING: "reviewing",
  PAID: "paid",
  RETURNED: "returned",
};
export const SUPPORT_STATUS_REF = {
  [SUPPORT_STATUS.DRAFT]: "Ноорог",
  [SUPPORT_STATUS.REQUESTED]: "Хүсэлт илгээгдсэн",
  [SUPPORT_STATUS.REJECTED]: "Хүсэлт татгалзсан",
  [SUPPORT_STATUS.RECEIVED]: "Хүсэлт хүлээн авсан",
  [SUPPORT_STATUS.INVALID_ACCOUNT]: "Дансны мэдээлэл зөрүүтэй",
  [SUPPORT_STATUS.REVIEWING]: "Хүсэлт хянагдаж байгаа",
  [SUPPORT_STATUS.PAID]: "Олгогдсон",
  [SUPPORT_STATUS.RETURNED]: "Хүсэлт буцаасан",
};
