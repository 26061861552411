import { useState, useEffect, useCallback, useContext } from "react";
import {
  Col,
  Form,
  Layout,
  Row,
  Divider,
  Space,
  Button,
  Select,
  DatePicker,
  message,
  Table,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

import "moment/locale/mn";
import locale from "antd/es/date-picker/locale/mn_MN";

import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";
import UserContext from "../../providers/UserContext";
import usePlantType from "../../hooks/usePlantType";
import usePlant from "../../hooks/usePlant";
import { EditablePlanFarmLandTable } from "./components/EditablePlanFarmLandTable";
import { PlanFarmLandType } from "./types/PlanFarmLandType";
import { RequestFarmLandType } from "./types/RequestFarmLandType";
import { AddPlanFarmLand } from "./components/AddPlanFramLand";

import { PLAN_STATUS, PLAN_STATUS_TEXT } from "../../enums/plan.enum";
import { formatArea } from "../../helpers/number.helper";
import PlanAreaInput from "./components/PlanAreaInput";

interface Params {
  id?: any;
}

interface PlanDetailType {
  id: number;
  plant: {
    id: number;
    name: string;
    type: {
      id: number;
      name: string;
    };
  };
  status: keyof typeof PLAN_STATUS_TEXT;
  year: string;
  user: {
    id: number;
    lastName: string;
    firstName: string;
  };
  farmlands: any[];
  createdAt: string;
}

const PlanEdit = () => {
  const history = useHistory();
  const { id } = useParams<Params>();

  const { user } = useContext(UserContext);
  const [plantType] = usePlantType();
  const [plant, , changePlantFilter] = usePlant();

  const [planForm] = Form.useForm();
  const [showAddFarmLand, setShowAddFarmLand] = useState(false);

  const [content, changeContent] = useState<{
    loading: boolean;
    result: PlanDetailType;
  }>({
    loading: false,
    result: {
      id: 0,
      plant: { id: 0, name: "", type: { id: 0, name: "" } },
      status: PLAN_STATUS.PENDING,
      year: "",
      user: {
        id: 0,
        lastName: "",
        firstName: "",
      },
      farmlands: [],
      createdAt: "",
    },
  });

  const [planFarmLands, setPlanFarmLands] = useState<{
    loading: boolean;
    data: PlanFarmLandType[];
  }>({ loading: false, data: [] });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(`/api/plan/detail/${id}`);
    if (response.resultCode === 200 && response.result?.user.id === user.id) {
      changePlantFilter({ type: response.result.plant.type.id });

      planForm.setFieldsValue({
        plantTypeId: response.result.plant.type.id,
        plantId: response.result.plant.id,
        year: moment(response.result.year),
      });

      changeContent({
        loading: false,
        result: response.result,
      });

      const farmlands = response.result.farmlands.map(
        (farmland: PlanFarmLandType) => ({
          ...farmland,
          plannedArea: (farmland.plannedArea || 0) - (farmland.planArea || 0),
        })
      );

      setPlanFarmLands({
        loading: false,
        data: farmlands,
      });
    } else {
      history.goBack();
    }
  }, [changePlantFilter, history, id, planForm, user.id]);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  const loadFarmLandList = useCallback(
    async (farmlandIds: string[]) => {
      let response = await NetworkApi.requestPost(
        "/api/plan/farmland/remained",
        { farmlands: farmlandIds, year: content.result.year }
      );

      if (response.resultCode === 200) {
        const farmlands = [...planFarmLands.data, ...response.result];
        for (let i = 0; i < farmlands.length; i++) {
          const fl: any | undefined = content.result.farmlands.find(
            (item) => item.id === farmlands[i].id
          );
          if (fl) {
            farmlands[i].plannedArea =
              (farmlands[i].plannedArea || 0) - (fl.planArea || 0);
          }
        }
        setPlanFarmLands({
          loading: false,
          data: farmlands,
        });
      } else {
        message.error(response.message || "system error");
      }
    },
    [content.result.year, planFarmLands]
  );

  const handleAddFarmLand = (farmlandIds: string[]) => {
    setPlanFarmLands({ ...planFarmLands, loading: true });
    setShowAddFarmLand(false);
    loadFarmLandList(farmlandIds);
  };

  const onChangePlanArea = (index: number, planArea: any) => {
    const list = [...planFarmLands.data];
    list[index].planArea = planArea;
    setPlanFarmLands({ ...planFarmLands, data: list });
  };

  const handleRemoveFarmLand = (id: number) => {
    const farmlands = planFarmLands.data.filter(
      (farmland) => farmland.id !== id
    );
    setPlanFarmLands({ ...planFarmLands, data: farmlands });
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      const updatedFarmlands: RequestFarmLandType[] = planFarmLands.data
        .filter((farmland) => farmland.planArea && farmland.planArea > 0)
        .map(({ id, planArea }) => ({
          id,
          planArea,
        }));

      const farmLandIds = updatedFarmlands.map((farmland) => farmland.id);
      const removedFarmlands: number[] = content.result.farmlands
        .filter((farmland) => !farmLandIds.includes(farmland.id))
        .map((farmland) => farmland.id);

      let response = await NetworkApi.requestPost("/api/plan/update", {
        id: content.result.id,
        year: data.year.format("YYYY"),
        plantId: data.plantId,
        updatedFarmlands,
        removedFarmlands,
      });

      if (response.resultCode === 200) {
        history.push(`/plan/detail/${id}`);
      } else {
        message.error(response.message || "system error");
      }
    },
    [
      content.result.farmlands,
      content.result.id,
      history,
      id,
      planFarmLands.data,
    ]
  );

  const planFarmLandColumns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Нэгж талбайн дугаар",
      dataIndex: "posNo",
    },
    {
      title: "Талбайн нийт хэмжээ",
      dataIndex: "area",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Эзэмшигчийн нэр",
      dataIndex: "ownerName",
    },
    {
      title: "Регистр",
      dataIndex: "ownerRd",
    },
    {
      title: "Эзэмшлийн хэлбэр",
      dataIndex: "type",
      render: (type: any) => (type == "private" ? "Эзэмшил" : "Өмчлөл"),
    },
    {
      title: "Ашиглаж буй хэмжээ",
      dataIndex: "usedArea",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Төлөвлөгдсөн талбай",
      dataIndex: "plannedArea",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Тариалах талбай",
      dataIndex: "planArea",
      render: (value: number, record: any, index: number) => (
        <PlanAreaInput
          area={value ?? 0}
          max={(record.area || 0) - (record.plannedArea || 0)}
          onChange={(v: number) => onChangePlanArea(index, v)}
        />
      ),
    },
    {
      title: "Үйлдэл",
      dataIndex: "id",
      render: (id: number) => (
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveFarmLand(id)}
        />
      ),
    },
  ];

  return (
    <>
      <BreadMe title={["Тариалалтын төлөвлөгөө", "Нэмэх"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Form
              form={planForm}
              labelCol={{ xs: 24, sm: 24, md: 24, lg: 10, xl: 10 }}
              wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 14, xl: 14 }}
              onFinish={handleSubmit}
            >
              <Row justify="space-around" gutter={16}>
                <Divider
                  orientation="left"
                  plain
                  style={{ margin: 0, lineHeight: 0 }}
                >
                  Тариалалтын төрлийн мэдээлэл
                </Divider>
              </Row>
              <Row
                justify="space-around"
                gutter={16}
                style={{
                  padding: 24,
                  border: "1px solid #f0f0f0",
                  borderTop: "none",
                }}
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="plantTypeId"
                    label="Таримлын төрөл"
                    rules={[
                      { required: true, message: "Таримлын төрөл сонгоно уу" },
                    ]}
                  >
                    <Select
                      defaultValue=""
                      onChange={(v) => changePlantFilter({ type: v })}
                    >
                      <Select.Option value="">Таримлын төрөл</Select.Option>
                      {plantType.result.map((p: any) => (
                        <Select.Option value={p.id}>{p.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="plantId"
                    label="Таримлын нэр"
                    rules={[{ required: true, message: "Таримал сонгоно уу" }]}
                  >
                    <Select defaultValue="">
                      <Select.Option value={""}>Таримлын нэр</Select.Option>
                      {plant.result.map((p: any) => (
                        <Select.Option value={p.id}>{p.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="year"
                    label="Он"
                    rules={[{ required: true, message: "Он сонгоно уу" }]}
                  >
                    <DatePicker picker="year" disabled locale={locale} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row gutter={16}>
              <Divider
                orientation="left"
                plain
                style={{ margin: 0, lineHeight: 0 }}
              >
                Төлөвлөгдсөн талбай, тариаланч
              </Divider>
            </Row>
            <Row
              gutter={16}
              style={{
                padding: 24,
                border: "1px solid #f0f0f0",
                borderTop: "none",
              }}
            >
              <Col span={24}>
                <Row
                  justify="end"
                  gutter={16}
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  <Button
                    type="primary"
                    onClick={() => setShowAddFarmLand(true)}
                  >
                    Нэмэх
                  </Button>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  scroll={{ x: true }}
                  columns={planFarmLandColumns}
                  dataSource={planFarmLands.data}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 15, marginBottom: 15 }}
          justify="end"
          align="middle"
        >
          <Space>
            <Button onClick={() => history.goBack()}>Цуцлах</Button>
            <Button type="primary" onClick={() => planForm.submit()}>
              Хадгалах
            </Button>
          </Space>
        </Row>
        {showAddFarmLand && (
          <AddPlanFarmLand
            visible={showAddFarmLand}
            onCancel={() => setShowAddFarmLand(false)}
            onAddFarmLand={handleAddFarmLand}
            addedFarmLands={planFarmLands.data.map((farmland) => farmland.id)}
          />
        )}
      </Layout.Content>
    </>
  );
};
export default PlanEdit;
