import { useCallback, useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Space,
  Form,
  Modal,
  message,
  Popconfirm,
  Switch,
  Select,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import useChemicalTypeList from "../../hooks/useChemicalTypeList";
import NetworkApi from "../../network/networkApi";
import useChemicalTypeFileList from "../../hooks/useChemicalTypeFileList";

const ElementType = () => {
  const [content, loadContent] = useChemicalTypeList(1);
  const [addEdit, setAddEdit] = useState({
    visible: false,
    edit: null,
  });
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost("/api/chemical/type/update", {
      ...data,
      files: (data?.files ?? []).map((item: any) => item.id),
      status: "D",
    });
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "system error");
    }
  }, []);
  const elementInfo = [
    {
      title: "Төрөл",
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Button
            onClick={() => {
              setAddEdit({
                visible: true,
                edit: item,
              });
            }}
            type={"primary"}
            size={"small"}
            ghost
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна уу"
            onConfirm={() => deleteItem(item)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button
              type={"primary"}
              size={"small"}
              danger
              ghost
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Бодис, бордооны төрөл"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"}>
              <Button
                onClick={() => setAddEdit({ visible: true, edit: null })}
                type={"primary"}
              >
                <PlusSquareOutlined />
                Бодис, бордооны төрөл нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={elementInfo}
              dataSource={content.result}
              onChange={handleParam}
              rowKey={"id "}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <ChemicalTypeAddEdit
        edit={addEdit.edit}
        visible={addEdit.visible}
        onHide={() => setAddEdit({ visible: false, edit: null })}
        onSuccess={() => {
          loadContent();
          setAddEdit({ visible: false, edit: null });
        }}
      />
    </>
  );
};
interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}
const ChemicalTypeAddEdit = ({ edit, visible, onHide, onSuccess }: Props) => {
  const formRef = useRef<any>();
  const [fileList] = useChemicalTypeFileList();
  useEffect(() => {
    if (visible) {
      if (edit) {
        formRef.current?.setFieldsValue({
          ...edit,
          files: (edit.files ?? []).map((item: any) => item.id),
        });
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [edit, visible]);
  const saveContent = useCallback(
    async (data: any) => {
      data.hasParcel = data?.hasParcel ?? false;
      data.supportable = data?.supportable ?? false;

      if (edit) {
        data = {
          ...edit,
          ...data,
        };
      }
      let response = await NetworkApi.requestPost(
        "/api/chemical/type/update",
        data
      );
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "system error");
      }
    },
    [edit]
  );
  return (
    <Modal
      visible={visible}
      title={
        edit ? "Бодис, бордооны төрөл засах" : "Бодис, бордооны төрөл нэмэх"
      }
      onCancel={onHide}
      footer={null}
    >
      <Form ref={formRef} layout="vertical" onFinish={saveContent}>
        <Form.Item name="name" label="Төрөл">
          <Input placeholder={"Бодис, бордооны төрлийн нэр"} />
        </Form.Item>
        <Form.Item name="description" label="Тайлбар">
          <Input placeholder={"Бодис, бордооны төрлийн тайлбар"} />
        </Form.Item>
        <Form.Item
          name="supportable"
          label="Дэмжлэгт ашиглах эсэх"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="hasParcel"
          label="Сав баглаа бүртгэх эсэх"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item name="files" label="Бүрдүүлэх материалууд">
          <Select mode="multiple">
            {fileList.result.map((p: any) => (
              <Select.Option value={p.id}>{p.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default ElementType;
