import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  message,
  Modal,
  Select,
} from "antd";
import React, { useCallback } from "react";
import usePlant from "../../hooks/usePlant";
import usePlantSort from "../../hooks/usePlantSort";
import usePlantType from "../../hooks/usePlantType";
import NetworkApi from "../../network/networkApi";
import AddressSelect from "../lib/AddressSelect";
interface Props {
  visible: boolean;
  onHide: any;
}
const WarehouseHarvestAdd = ({ visible, onHide }: Props) => {
  const [form] = Form.useForm();
  const [plantType] = usePlantType();
  const [plant, , changePlantFilter] = usePlant();
  const [plantSort, , changePlantSortFilter] = usePlantSort();
  const handleSubmit = useCallback(async (data: any) => {
    let response = await NetworkApi.requestPost(
      "/api/warehouse/plant/add",
      data
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай нэмэгдлээ");
      onHide();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);
  return (
    <Modal
      width={700}
      title="Бүтээгдэхүүн нэмэх"
      visible={visible}
      onCancel={onHide}
      footer={[]}
    >
      <Form form={form} labelCol={{ span: 8 }} onFinish={handleSubmit}>
        <Form.Item name="plantTypeId" label="Бүтээгдэхүүний төрөл">
          <Select
            placeholder="Бүтээгдэхүүний төрөл"
            onChange={(v) => {
              changePlantFilter({ type: v });
              form.setFieldsValue({
                plantId: undefined,
                sortId: undefined,
              });
            }}
          >
            {plantType.result.map((p: any) => (
              <Select.Option value={p.id}>{p.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="plantId" label="Бүтээгдэхүүний нэр">
          <Select
            placeholder="Бүтээгдэхүүний нэр"
            onChange={(v) => {
              changePlantSortFilter({ plantId: v });
              form.setFieldsValue({ sortId: undefined });
            }}
          >
            {plant.result.map((p: any) => (
              <Select.Option value={p.id}>{p.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="sortId" label="Таримлын сорт">
          <Select placeholder="Таримлын сорт сонгоно уу">
            {plantSort.result.map((p: any) => (
              <Select.Option value={p.id}>{p.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="amount" label="Хэмжээ">
          <InputNumber parser={(value) => value?.replace(",", ".") || ""} />
        </Form.Item>
        <Form.Item name="date" label="Огноо">
          <DatePicker />
        </Form.Item>
        <Form.Item name="type" label="Төрөл">
          <Select defaultValue="">
            <Select.Option value={""}>Төрөл</Select.Option>
            <Select.Option value={"import"}>Импорт</Select.Option>
            <Select.Option value={"natural"}>Байгалийн</Select.Option>
          </Select>
        </Form.Item>
        <legend style={{ width: "auto", paddingLeft: 10, paddingRight: 10 }}>
          Хаанаас:
        </legend>
        <AddressSelect />
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default WarehouseHarvestAdd;
