import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Layout,
  message,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import UserContext from "../../providers/UserContext";
import { formatNumberWithComma } from "../../helpers/number.helper";
import SupportDetail from "./SupportDetail";

const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const SuppportList = () => {
  const { user } = useContext(UserContext);

  const [content, changeContent] = useState({
    loading: true,
    result: [],
    filter: { rd: user.rd },
  });

  const [showDetail, setShowDetail] = useState({
    id: 0,
    visible: false,
  });

  useEffect(() => {
    loadContent();
  }, [content.filter]);

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });

    let response = await NetworkApi.requestGet("/api/support/list", {
      ...content.filter,
    });

    if (response.resultCode == 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          result: JSON.parse(response.result),
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);

  content.result.sort((a, b) => {
    if (a["status_date"] > b["status_date"]) {
      return -1;
    }
    if (a["status_date"] < b["status_date"]) {
      return 1;
    }
    return 0;
  });

  const supportInfo = [
    {
      title: "№",
      dataIndex: "id",
      render: (v: any, r: any, index: number) => `${index + 1}`,
    },
    {
      title: "Огноо",
      dataIndex: "date",
    },
    {
      title: "Хүсэлт гаргагч Регистр №",
      dataIndex: "register",
    },
    {
      title: "Хуулийн этгээдийн нэр",
      dataIndex: "name",
    },
    {
      title: "Талбайн дугаар",
      dataIndex: "farmlandPosNo",
    },
    {
      title: "Ангилал",
      dataIndex: "category",
    },
    {
      title: "Бүтээгдэхүүний төрөл",
      dataIndex: "sub_category",
    },
    {
      title: "Бүтээгдэхүүний нэр",
      dataIndex: "product",
    },
    {
      title: "Боломжит дээд хэмжээ",
      dataIndex: "max",
    },
    {
      title: "Хүсэлтийн нэмжээ",
      dataIndex: "amount",
      render: (value: number) => formatNumberWithComma(value),
    },
    {
      title: "Нэгж үнэ",
      dataIndex: "price",
      render: (value: number) => formatNumberWithComma(value),
    },
    {
      title: "Нийт үнэ",
      dataIndex: "totalPrice",
      render: (value: number) => formatNumberWithComma(value),
    },
    {
      title: "ҮА явуулах аймар сум",
      dataIndex: "aimag",
    },
    {
      title: "Төлөв",
      dataIndex: "status_id",
      render: (value: number) => (
        <>
          {
            {
              1: <Tag color="cyan">Илгээгдсэн</Tag>,
              2: <Tag color="slateblue">Хүлээн авсан</Tag>,
              3: <Tag color="mediumslateblue">Хүлээн авсан</Tag>,
              4: <Tag color="darkslateblue">Хянасан</Tag>,
              5: <Tag color="green">Баталсан</Tag>,
              6: <Tag color="red">Татгалзсан</Tag>,
              null: <Tag color="cyan">Илгээгдсэн</Tag>,
            }[value]
          }
        </>
      ),
    },
    {
      title: "Төлөв огноо",
      dataIndex: "status_date",
    },
    // {
    //     title: "Үйлдэл",
    //     width: 220,
    //     render: (itm: any) => (
    //         <Space size={10}>
    //             <Button
    //                 onClick={() => setShowDetail({id: itm.id, visible: true})}
    //                 icon={<FileTextOutlined/>}
    //                 title={"Засах"}
    //                 type={"primary"}
    //                 ghost
    //                 size={"small"}
    //             >
    //                 Дэлгэрэнгүй
    //             </Button>
    //         </Space>
    //     ),
    // },
  ];
  return (
    <>
      <BreadMe title={["Дэмжлэгийн сан", "Хүсэлтийн жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              rowKey={"id"}
              columns={supportInfo}
              dataSource={content.result}
            />
          </Col>
        </Row>
      </Layout.Content>
      {/*<SupportDetail*/}
      {/*    visible={showDetail.visible}*/}
      {/*    id={showDetail.id}*/}
      {/*    hide={() => {*/}
      {/*        loadContent();*/}
      {/*        setShowDetail({id: 0, visible: false});*/}
      {/*    }}*/}
      {/*/>*/}
    </>
  );
};

export default SuppportList;
