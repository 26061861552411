import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Button, Col, Input, message, Popconfirm, Row, Table } from "antd";
import useCertificateDetail from "../../hooks/useCertificateDetail";
import moment from "moment";
import {
  Encoder,
  QRByte,
  QRKanji,
  ErrorCorrectionLevel,
} from "@nuintun/qrcode";
import UserContext from "../../providers/UserContext";
import NetworkApi from "../../network/networkApi";
const { TextArea } = Input;
interface Props {
  id?: any;
  isSmall?: boolean;
}
const License = ({ id, isSmall }: Props) => {
  const [content, loadContent, changeId] = useCertificateDetail();
  useEffect(() => {
    changeId(id);
  }, [id]);
  const { user } = useContext(UserContext);
  const mm = useMemo(() => {
    const qrcode = new Encoder();
    qrcode.setEncodingHint(false);
    qrcode.setErrorCorrectionLevel(ErrorCorrectionLevel.H);
    qrcode.write(
      new QRByte(
        `${window.location.protocol}//${window.location.host}/certificate/` +
          content.result.id || ""
      )
    );
    qrcode.make();
    return qrcode.toDataURL(2, 0);
  }, [content]);

  return (
    <div style={{ height: "99%", overflow: "hidden" }}>
      {content.loading ? (
        <div></div>
      ) : (
        <>
          {content.resultCode == 200 ? (
            <>
              <div style={{ pageBreakBefore: "always" }}></div>
              {isSmall == true ? (
                <LicenseSmall content={content} />
              ) : (
                <fieldset
                  style={{ border: "1px solid gray", margin: 5, padding: 10 }}
                >
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={24}>
                      <Row>
                        <Col xs={24} sm={12} span={12}>
                          <img
                            src="https://mofa.gov.mn/_next/image?url=%2Fimages%2Flogo%2Flogo_mofa.svg&w=640&q=75"
                            alt="Хүнс Хөдөө Аж Ахуйн Хөнгөн Үйлдвэрийн Яам"
                            style={{ marginBottom: 15 }}
                          />
                        </Col>
                        <Col
                          xs={24}
                          sm={12}
                          span={12}
                          style={{ textAlign: "right" }}
                        >
                          Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн Сайдын 2022 оны
                          06 сарын 17-ний өдрийн А/196 дугаар тушаалын 1 дүгээр
                          хавсралт
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={24}>
                      <h2
                        style={{
                          textDecoration: "uppercase",
                          textAlign: "center",
                        }}
                      >
                        Ургамал, түүний гаралтай хүнсний түүхий эд,
                        бүтээгдэхүүний гарал, үүслийн гэрчилгээ
                      </h2>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={8} offset={8}>
                      <h3 style={{ textAlign: "center" }}>
                        №ГҮГ-{content.result?.id}
                      </h3>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={12} xs={24} sm={12}>
                      <Row>
                        <Col span={12}>Үүсгэсэн огноо:</Col>
                        <Col span={12}>
                          {moment(content.result.createdAt).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </Col>
                        <Col span={12}>Гэрчилгээ үүсгэсэн:</Col>
                        <Col span={12}>
                          {content.result.createdUser?.lastName || ""}{" "}
                          {content.result.createdUser?.firstName || ""}
                        </Col>
                        <Col span={12}>Баталсан огноо:</Col>
                        <Col span={12}>
                          {moment(content.result.acceptedAt).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </Col>
                        <Col span={12}>Гэрчилгээний төлөв:</Col>
                        <Col span={12}>
                          {content.result.status == "active"
                            ? "Зөвшөөрсөн"
                            : content.result.status == "reviewed"
                            ? "Хянасан"
                            : ""}
                        </Col>
                        <Col span={12}>Хүчинтэй хугацаа:</Col>
                        <Col span={12}>
                          {moment(content.result.startDate).format(
                            "YYYY/MM/DD"
                          )}
                          -{moment(content.result.endDate).format("YYYY/MM/DD")}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <Row>
                        <Col span={12}>НТДугаар:</Col>
                        <Col span={12}>{content.result.farmland?.posNo}</Col>
                        <Col span={12}>Гэрчилгээ баталсан:</Col>
                        <Col span={12}>
                          {content.result.acceptedUser?.lastName || ""}{" "}
                          {content.result.acceptedUser?.firstName || ""}{" "}
                        </Col>
                        <Col span={12}>Хаяг:</Col>
                        <Col span={12}>
                          {content.result.farmland?.aimag?.name},{" "}
                          {content.result.farmland?.sum?.name}
                        </Col>
                        <Col span={12}>Тариаланч:</Col>
                        <Col span={12}>{content.result.seller?.name}</Col>
                        <Col span={12}>Утасны дугаар:</Col>
                        <Col span={12}>
                          {content.result.seller?.user?.phone}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={24}>
                      <h4>Таримлын мэдээлэл</h4>
                    </Col>
                    <Col span={24}>
                      <Table
                        scroll={{ x: true }}
                        columns={columns}
                        pagination={false}
                        dataSource={
                          content.result.sizes ? content.result.sizes : []
                        }
                        size="small"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Col span={12} xs={24} sm={12}>
                      <Row>
                        <Col span={24}>
                          <h4>Хаашаа</h4>
                        </Col>
                        <Col span={12}>Нэр</Col>
                        <Col span={12}>{content.result.receiverName}</Col>
                        <Col span={12}>Утас</Col>
                        <Col span={12}>{content.result.receiverPhone}</Col>
                        <Col xs={12} sm={12} span={12}>
                          Хаяг:
                        </Col>
                        <Col xs={12} sm={12} span={12}>
                          {content.result.receiverAimag?.name}{" "}
                          {content.result.receiverSum?.name}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <Row>
                        <Col span={24}>
                          <h4>Хариуцагчийн мэдээлэл</h4>
                        </Col>
                        <Col xs={12} sm={12} span={12}>
                          Нэр
                        </Col>
                        <Col xs={12} sm={12} span={12}>
                          {content.result.transporterName}
                        </Col>
                        <Col xs={12} sm={12} span={12}>
                          Утас:
                        </Col>
                        <Col xs={12} sm={12} span={12}>
                          {content.result.transporterPhone}
                        </Col>
                        <Col xs={12} sm={12} span={12}>
                          Тээврийн хэрэгсэл:
                        </Col>
                        <Col xs={12} sm={12} span={12}>
                          {content.result.transporterId}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Col xs={24} sm={24} span={6}>
                      Түүхий эд бүтээгдэхүүн, таримлын үр, суулгацад хийсэн
                      мэдрэхүйн үзлэгийн дүн:
                    </Col>
                    <Col xs={24} sm={24} span={18}>
                      <div
                        style={{
                          border: "1px solid",
                          minHeight: 60,
                          padding: 5,
                        }}
                      >
                        {content.result.examinationDescription}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Col xs={12} sm={12} span={4}>
                      <h4>Тайлбар:</h4>
                    </Col>
                    <Col xs={12} sm={12} span={8}>
                      <p>{content.result.description}</p>
                    </Col>
                    {content.result.logs?.length > 0 ? (
                      <>
                        <Col xs={12} sm={12} span={6}>
                          <h4>Хянасан байцаагч:</h4>
                        </Col>
                        <Col xs={12} sm={12} span={6}>
                          {content.result.logs[content.result.logs.length - 1]
                            .user?.lastName || ""}{" "}
                          {content.result.logs[content.result.logs.length - 1]
                            .user?.firstName || ""}
                        </Col>
                      </>
                    ) : null}
                  </Row>
                  <Row style={{ marginBottom: 15 }}>
                    <Col xs={3} sm={3} span={3}>
                      <img
                        src={mm}
                        alt="Гарал үүслийн гэрчилгээ шалгах"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </Col>
                    <Col xs={21} sm={21} span={21} style={{ width: "100%" }}>
                      <p>
                        Санамж: Энэхүү гарал үүслийн гэрчилгээ нь ургамлын
                        гаралтай бүтээгдэхүүнийг тариалсан гарал үүсэл болон
                        тухайн таримлыг тарьсан талбайн арчилгаа, бодисын
                        хэрэглээг зохистой түвшинд хэрэглэсэн гэдгийг
                        баталгаажуулна. Лавлагаа мэдээллийг ХХААХҮЯ-ны
                        мэдээллийн нэгдсэн сан
                        <a href="http://www.plant.mofa.gov.mn" target="_blank">
                          {" "}
                          www.plant.mofa.gov.mn
                        </a>{" "}
                        сайтаас шууд авах боломжтой.
                      </p>
                    </Col>
                  </Row>
                </fieldset>
              )}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
const columns = [
  {
    title: "Бүтээгдэхүүний нэр",
    dataIndex: "warehouse",
    render: (warehouse: any) => `${warehouse.sort?.plant.name}`,
  },
  {
    title: "Сорт",
    dataIndex: "warehouse",
    render: (warehouse: any, item: any) => `${warehouse.sort?.name ?? ""}`,
  },
  {
    title: "ОУ-ын код",
    dataIndex: "warehouse",
    render: (warehouse: any) => `${warehouse.sort?.internationalCode ?? "-"}`,
  },
  {
    title: "Хэмжээ",
    dataIndex: "area",
    editable: true,
    render: (value: number, item: any) =>
      `${value ?? "0"} ${item.warehouse.sort?.unit?.name ?? ""}`,
  },
];

interface SmallProps {
  content: any;
}
const LicenseSmall = ({ content }: SmallProps) => {
  const mm = useMemo(() => {
    const qrcode = new Encoder();
    qrcode.setEncodingHint(false);
    qrcode.setErrorCorrectionLevel(ErrorCorrectionLevel.H);
    qrcode.write(
      new QRByte(
        `${window.location.protocol}//${window.location.host}/certificate/` +
          content.result.id || ""
      )
    );
    qrcode.make();
    return qrcode.toDataURL(5, 0);
  }, [content]);

  return (
    <fieldset style={{ border: "1px solid gray", margin: 5, padding: 5 }}>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <h5 style={{ textDecoration: "uppercase", textAlign: "center" }}>
            УРГАМЛЫН ГАРАЛ ҮҮСЛИЙН ГЭРЧИЛГЭЭ
          </h5>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={4} offset={10}>
          <h3 style={{ textAlign: "center" }}>№ГҮГ-{content.result?.id}</h3>
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        {/* <Col span={12}>Код:</Col>
                <Col span={12}>{content.result.code}</Col> */}
        <Col span={12}>Үүсгэсэн огноо:</Col>
        <Col span={12}>
          {moment(content.result.createdAt).format("YYYY-MM-DD HH:mm")}
        </Col>
        <Col span={12}>Гэрчилгээ үүсгэсэн:</Col>
        <Col span={12}>
          {content.result.createdUser?.lastName || ""}{" "}
          {content.result.createdUser?.firstName || ""}
        </Col>
        <Col span={12}>Баталсан огноо:</Col>
        <Col span={12}>
          {moment(content.result.acceptedAt).format("YYYY-MM-DD HH:mm")}
        </Col>
        <Col span={12}>Гэрчилгээ баталсан:</Col>
        <Col span={12}>
          {content.result.acceptedUser?.lastName || ""}{" "}
          {content.result.acceptedUser?.firstName || ""}
        </Col>
        <Col span={12}>Хүчинтэй хугацаа:</Col>
        <Col span={12}>
          {content.result.startDate} - {content.result.endDate}
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <h4>Таримлын мэдээлэл</h4>
        </Col>
        <Col span={24}>
          <Table
            style={{ fontSize: 12 }}
            columns={columns}
            pagination={false}
            dataSource={content.result.sizes ? content.result.sizes : []}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <h4>Хаанаас</h4>
        </Col>
        <Col span={6}>НТДугаар:</Col>
        <Col span={18}>{content.result.farmland?.id}</Col>
        <Col span={6}>Хаяг:</Col>
        <Col span={18}>
          {content.result.farmland?.aimag?.name}{" "}
          {content.result.farmland?.sum?.name}{" "}
          {content.result.farmland?.bag?.name}
        </Col>
        <Col span={12}>Талбайн эзэмшигч/ашиглагч:</Col>
        <Col span={12}>{content.result.seller?.name}</Col>
        <Col span={12}>Талбайн түрээслэгч эсэх:</Col>
        <Col span={12}>
          {content.result.farmlandUsage?.type == "rent" ? "Тийм" : "Үгүй"}
        </Col>
        <Col span={12}>Утасны дугаар:</Col>
        <Col span={12}>{content.result.seller?.user?.phone}</Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <h4>Хаашаа</h4>
        </Col>
        <Col span={12}>Нэр</Col>
        <Col span={12}>{content.result.receiverName}</Col>
        <Col span={12}>Утас</Col>
        <Col span={12}>{content.result.receiverPhone}</Col>
        <Col span={12}>Хаяг:</Col>
        <Col span={12}>
          {content.result.receiverAimag?.name}{" "}
          {content.result.receiverSum?.name}
        </Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <h4>Тээвэрлэгчийн мэдээлэл</h4>
        </Col>
        <Col span={12}>Нэр</Col>
        <Col span={12}>{content.result.transporterName}</Col>
        <Col span={12}>Утас:</Col>
        <Col span={12}>{content.result.transporterPhone}</Col>
        <Col span={12}>Тээврийн хэрэгсэл:</Col>
        <Col span={12}>{content.result.transporterId}</Col>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <Col span={12}>
          <h4>Тайлбар:</h4>
        </Col>
        <Col span={12}>
          <p>{content.result.description}</p>
        </Col>
        <Col span={12}>
          <h4>Техник үзлэгийн дүгнэлт:</h4>
        </Col>
        <Col span={12}>
          <p>{content.result.examinationDescription}</p>
        </Col>
        <Col span={12}>
          <h4>Хянасан байцаагч:</h4>
        </Col>
        <Col span={12}>
          {content.result.acceptedUser?.lastName || ""}{" "}
          {content.result.acceptedUser?.firstName || ""}
        </Col>
      </Row>
      <Row
        gutter={5}
        style={{ marginBottom: 5 }}
        justify="space-around"
        align="middle"
      >
        <Col span={12} xs={24} sm={12}>
          <Col span={24}>
            <img
              src={mm}
              alt="Гарал үүслийн гэрчилгээ шалгах"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Col>
          <Col span={24}>
            <p style={{ fontSize: 12, lineHeight: 1.2, textAlign: "justify" }}>
              Санамж: Энэхүү гарал үүслийн гэрчилгээ нь ургамлын гаралтай
              бүтээгдэхүүнийг тариалсан гарал үүсэл болон тухайн таримлыг
              тарьсан талбайн арчилгаа, бодисын хэрэглээг зохистой түвшинд
              хэрэглэсэн гэдгийг баталгаажуулна. Лавлагаа мэдээллийг ХХААХҮЯ-ны
              мэдээллийн нэгдсэн сан
              <a href="http://www.plant.mofa.gov.mn" target="_blank">
                www.plant.mofa.gov.mn
              </a>{" "}
              сайтаас шууд авах боломжтой.
            </p>
          </Col>
        </Col>
      </Row>
    </fieldset>
  );
};
export default License;
