import { Layout } from "antd";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from './Login'
import CertificateCheck from './CertificateCheck'
import CertificateUrl from "./CertificateUrl";
import ForgotPassword from "./ForgotPassword";
const LoginApp = () => {
    return (
        <BrowserRouter>
            <Layout style={{ minHeight: '100vh' }}>
                <Layout style={{ padding: '0 24px 24px' }}>
                    <Switch>
                        <Route exact path="/certificate" component={CertificateCheck} />
                        <Route exact path="/certificate/:id" component={CertificateUrl} />
                        <Route path="/forgot/password" component={ForgotPassword} />
                        <Route path="/" component={Login} />
                    </Switch>
                </Layout>
            </Layout>
        </BrowserRouter>
    );

}
export default LoginApp