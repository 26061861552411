import { Row, Timeline } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  HighlightOutlined,
  StopOutlined,
} from "@ant-design/icons";
import moment from "moment";

interface Props {
  activities: any[];
}

const FarmlandUsageActivities = ({ activities }: Props) => {
  const getActivityDate = (date: string) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const getActivityUser = (user: any) => {
    if (user.organizations?.length > 0) {
      return `${user.organizations[0].name} (${user.role.name})`;
    }
    return `${user.lastName} ${user.firstName} (${user.role.name})`;
  };

  const getActivity = (activity: any) => {
    switch (activity.type) {
      case "created":
        return (
          <Timeline.Item>
            <strong>{getActivityDate(activity.createdAt)}</strong>
            {`-д үүсгэсэн: ${getActivityUser(activity.user)}`}
          </Timeline.Item>
        );

      case "edited":
        return (
          <Timeline.Item dot={<HighlightOutlined />}>
            <strong>{getActivityDate(activity.createdAt)}</strong>
            {`-д зассан: ${getActivityUser(activity.user)}`}
          </Timeline.Item>
        );
      case "deleted":
        return (
          <Timeline.Item dot={<DeleteOutlined style={{ color: "red" }} />}>
            <strong>{getActivityDate(activity.createdAt)}</strong>
            {`-д устгасан: ${getActivityUser(activity.user)}`}
          </Timeline.Item>
        );
      case "approved":
        return (
          <Timeline.Item
            dot={<CheckCircleOutlined style={{ color: "green" }} />}
          >
            <strong>{getActivityDate(activity.createdAt)}</strong>
            {`-д зөвшөөрсөн: ${getActivityUser(activity.user)}`}
          </Timeline.Item>
        );
      case "declined":
        return (
          <Timeline.Item dot={<CloseCircleOutlined style={{ color: "red" }} />}>
            <strong>{getActivityDate(activity.createdAt)}</strong>
            {`-д татгалзсан: ${getActivityUser(activity.user)}`}
          </Timeline.Item>
        );
      case "cancelled":
        return (
          <Timeline.Item dot={<StopOutlined style={{ color: "magenta" }} />}>
            <strong>{getActivityDate(activity.createdAt)}</strong>
            {`-д цуцалсан: ${getActivityUser(activity.user)}`}
          </Timeline.Item>
        );
      case "expired":
        return (
          <Timeline.Item
            dot={<ClockCircleOutlined style={{ color: "purple" }} />}
          >
            <strong>{getActivityDate(activity.createdAt)}</strong>
            {`-д хугацаа дууссанг бүртгэсэн: ${getActivityUser(activity.user)}`}
          </Timeline.Item>
        );
      default:
        return (
          <Timeline.Item>
            <strong>{getActivityDate(activity.createdAt)}</strong>
            {`-д тодорхойгүй үйлдэл хийсэн: ${getActivityUser(activity.user)}`}
          </Timeline.Item>
        );
    }
  };
  return (
    <>
      <Row style={{ marginTop: 24, marginBottom: 24 }}>
        <span
          style={{
            color: "rgba(0, 0, 0, 0.85)",
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          Тариалангийн талбайн хүсэлтийн түүх
        </span>
      </Row>
      <Row justify="start">
        <Timeline mode="left">
          {activities.map((activity: any) => getActivity(activity))}
        </Timeline>
      </Row>
    </>
  );
};
export default FarmlandUsageActivities;
