import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { SearchOutlined, FileTextOutlined } from "@ant-design/icons";
import NetworkApi from "../../../network/networkApi";

const Option = Select.Option;

const FarmerLicenseList = ({ rd }: { rd: string }) => {
  const [form] = Form.useForm();
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page, rd]);

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/certificate/list", {
      ...content.filter,
      rd,
      page: content.page,
    });
    if (response.resultCode == 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content, rd]);

  const licenseInfo = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "ГҮГ дугаар",
      dataIndex: "id",
      render: (value: any) => `ГҮГ-${value}`,
    },
    {
      title: "Тариаланч",
      render: (value: any) =>
        `${value.sizes[0]?.warehouse?.owner?.name}-${value.sizes[0]?.warehouse?.owner?.rd}`,
    },
    {
      title: "Хаанаас",
      render: (value: any) =>
        `${value.sizes[0]?.warehouse?.aimag?.name}-${value.sizes[0]?.warehouse?.sum?.name}`,
    },
    {
      title: "Хүлээн авагчийн нэр",
      dataIndex: "receiverName",
    },
    {
      title: "Хүлээн авагчийн хаяг",
      render: (value: any) =>
        `${value.receiverAimag?.name}-${value.receiverSum?.name}`,
    },
    {
      title: "Хүчинтэй хугацаа",
      render: (value: any) => `${value.startDate}-${value.endDate}`,
    },
    {
      title: "Зориулалт",
      dataIndex: "purpose",
      render: (value: any) =>
        value == "sale" ? "Худалдан борлуулах" : "Үрэнд",
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: string) => (
        <>
          {
            {
              pending: <Tag color={"#ff9900"}>Хүлээгдэж буй</Tag>,
              draft: <Tag color={"#ff9900"}>Ноорог</Tag>,
              active: <Tag color={"#93c47d"}>Зөвшөөрсөн</Tag>,
              rejected: <Tag color={"#cc0000"}>Татгалзсан</Tag>,
              reviewed: <Tag color={"#93c47d"}>Хянасан</Tag>,
              detained: <Tag color={"#d04040"}>Саатуулсан</Tag>,
            }[value]
          }
        </>
      ),
    },
    {
      title: "Үйлдэл",
      render: (value: any) => (
        <Space size={10}>
          <Link to={"/origin/detail/" + value.id}>
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
            >
              Дэлгэрэнгүй
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
  const [aimagList, setAimagList] = useState<any[]>([]);
  const [fromSumList, setFromSumList] = useState<any[]>([]);
  const [toSumList, setToSumList] = useState<any[]>([]);
  const loadAimagList = useCallback(async () => {
    const response = await NetworkApi.requestGet(`/api/address/1`);
    setAimagList(response.result || []);
  }, []);
  useEffect(() => {
    loadAimagList();
  }, [loadAimagList]);
  const loadSumList = useCallback(async (parent, cb) => {
    if (parent) {
      const response = await NetworkApi.requestGet(`/api/address/${parent}`);
      cb(response.result || []);
    } else {
      cb([]);
    }
  }, []);
  return (
    <>
      <Row>
        <Col span={24}>
          <Form
            onFinish={(data) => {
              let v = data;
              if (data.acceptedAt) {
                v.acceptedAt = data.acceptedAt.format("YYYY/MM/DD");
              }
              if (data.date?.length > 0) {
                v.startDate = data.date[0].format("YYYY/MM/DD");
                v.endDate = data.date[1].format("YYYY/MM/DD");
              }
              changeContent({ ...content, filter: data, page: 1 });
            }}
            name="filter"
            form={form}
          >
            <Row gutter={24}>
              <Col span={7}>
                <Form.Item name="id">
                  <Input placeholder={"Гэрчилгээний дугаар"} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="status">
                  <Select allowClear placeholder="Төлөв">
                    <Option value="pending">Хүлээгдэж буй</Option>
                    <Option value="draft">Ноорог</Option>
                    <Option value="active">Зөвшөөрсөн</Option>
                    <Option value="rejected">Татгалзсан</Option>
                    <Option value="reviewed">Хянасан</Option>
                    <Option value="detained">Саатуулсан</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="purpose">
                  <Select allowClear placeholder="Зориулалт">
                    <Option value="sale">Худалдаа</Option>
                    <Option value="seed">Үрэнд</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="rd">
                  <Input disabled placeholder={rd} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="acceptedAt">
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Баталсан огноо"
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="date">
                  <DatePicker.RangePicker
                    style={{ width: "100%" }}
                    placeholder={["Хүчинтэй хугацаа эхлэх", "Дуусах"]}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="fromAimag">
                  <Select
                    allowClear
                    placeholder="Хаанаас аймаг"
                    onChange={(value) => {
                      form.setFieldsValue({ fromSum: undefined });
                      loadSumList(value, setFromSumList);
                    }}
                  >
                    {aimagList.map((aimag: any) => {
                      return (
                        <Select.Option key={aimag.id} value={aimag.id}>
                          {aimag.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="fromSum">
                  <Select allowClear placeholder="Хаанаас сум">
                    {fromSumList.map((aimag: any) => {
                      return (
                        <Select.Option key={aimag.id} value={aimag.id}>
                          {aimag.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="receiverAimagId">
                  <Select
                    allowClear
                    placeholder="Хаашаа аймаг"
                    onChange={(value) => {
                      form.setFieldsValue({ receiverSumId: undefined });
                      loadSumList(value, setToSumList);
                    }}
                  >
                    {aimagList.map((aimag: any) => {
                      return (
                        <Select.Option key={aimag.id} value={aimag.id}>
                          {aimag.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="receiverSumId">
                  <Select allowClear placeholder="Хаашаа сум">
                    {toSumList.map((aimag: any) => {
                      return (
                        <Select.Option key={aimag.id} value={aimag.id}>
                          {aimag.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item>
                  <Button type={"primary"} htmlType={"submit"}>
                    <SearchOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Descriptions title="" style={{ marginTop: 15 }}>
        <Descriptions.Item label="Нийт">{content.count}</Descriptions.Item>
      </Descriptions>
      <Row>
        <Col span={24}>
          <Table
            scroll={{ x: true }}
            style={{ width: "100%" }}
            pagination={{
              current: content.page,
              total: content.count,
            }}
            onChange={(p) => {
              changeContent({
                ...content,
                page: p.current || 1,
              });
            }}
            rowKey={"id"}
            loading={content.loading}
            columns={licenseInfo}
            dataSource={content.result}
          />
        </Col>
      </Row>
    </>
  );
};
export default FarmerLicenseList;
