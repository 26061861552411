import { useCallback, useEffect, useState } from "react";
import {
  Layout,
  Table,
  Space,
  Row,
  Col,
  Button,
  Descriptions,
  Form,
  Select,
  Input,
} from "antd";

import { FileTextOutlined, SearchOutlined } from "@ant-design/icons";
import NetworkApi from "../../../network/networkApi";
import BreadMe from "../../lib/Breadme";
import FarmlandUsageDetail from "./FarmlandUsageDetail";
import FarmlandUsageAdd from "./FarmlandUsageAdd";
import { Link } from "react-router-dom";
import AddressSelect from "../../lib/AddressSelect";

const FarmlandListV2 = () => {
  const [content, changeContent] = useState({
    loading: false,
    result: [],
    page: 1,
    count: 0,
  });
  const [filter, changeFilter] = useState({
    status: "all",
    posNo: "",
    identifier: "",
    page: 1,
  });
  const [addEdit, setAddEdit] = useState({
    visible: false,
    id: null,
  });
  const [showDetail, setShowDetail] = useState({
    id: 0,
    visible: false,
  });
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    const response = await NetworkApi.requestGet(
      "/api/v2/farmland/list",
      filter
    );
    changeContent({
      loading: false,
      count: response.result?.count,
      page: response.result.page,
      result: response.result?.rows,
    });
  }, [filter]);
  const columns = [
    {
      title: "НТДугаар",
      render: (value: any) => <>{value.posNo}</>,
    },
    {
      title: "Талбайн хэмжээ",
      dataIndex: "area",
      render: (value: number) => <>{value} га</>,
    },
    {
      title: "Байршил",
      render: (itm: any) => (
        <>
          {`${itm.aimag?.name ?? ""}-${itm.sum?.name ?? ""}-${
            itm.bag?.name ?? ""
          }`}
        </>
      ),
    },
    {
      title: "Төлөв",
      render: (status: any) => {
        if (status.xyrStatus === "created") {
          return <span>Систем</span>;
        } else if (status.xyrStatus === "approved") {
          return <span style={{ color: "green" }}>Баталгаажсан</span>;
        } else if (status.xyrStatus === "agro") {
          return <span style={{ color: "green" }}>Агро баталгаажуулсан</span>;
        } else if (status.xyrStatus === "rejected") {
          return <span style={{ color: "red" }}>Мэдээлэл зөрүүтэй</span>;
        }
      },
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      render: (type: any) => (type == "rent" ? "Түрээс" : "Эзэмшил"),
    },
    {
      title: "Үйлдэл",
      width: 220,
      render: (itm: any) => (
        <Space size={10}>
          <Link to={`/farmland/${itm.id}/usage/list`}>
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
            >
              Дэлгэрэнгүй
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadMe title={["Тариалангийн талбай", "Жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={[16, 16]} justify={"space-between"}>
          <Col span={24}>
            <Space size={40}>
              <Form
                layout="inline"
                onFinish={(values) =>
                  changeFilter({
                    ...values,
                    page: 1,
                  })
                }
              >
                <AddressSelect hideLabel={true} />
                <Form.Item name="xyrStatus">
                  <Select
                    style={{ width: 201 }}
                    placeholder="Баталгаажсан эсэх"
                    allowClear
                  >
                    <Select.Option value="approved">
                      Геодезиор баталгаажсан
                    </Select.Option>
                    <Select.Option value="agro">
                      Агро баталгаажуулсан
                    </Select.Option>
                    <Select.Option value="created">
                      Системээр нэмсэн
                    </Select.Option>
                    <Select.Option value="rejected">
                      Мэдээлэл зөрүүтэй
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="posNo">
                  <Input placeholder={"НТДугаар"} />
                </Form.Item>
                <Button htmlType="submit" type={"primary"}>
                  <SearchOutlined />
                </Button>
              </Form>
            </Space>
          </Col>
        </Row>
        <Descriptions title="" style={{ marginTop: 15 }}>
          <Descriptions.Item label="Нийт">{content.count}</Descriptions.Item>
        </Descriptions>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              loading={content.loading}
              columns={columns}
              pagination={{
                current: filter.page,
                total: content.count,
              }}
              onChange={(p) => {
                changeFilter({
                  ...filter,
                  page: p.current || 1,
                });
              }}
              dataSource={content.result}
              rowKey={"id"}
            />
          </Col>
        </Row>
      </Layout.Content>
      {showDetail.visible && (
        <FarmlandUsageAdd
          id={showDetail.id}
          onHide={() => {
            setShowDetail({ visible: false, id: 0 });
          }}
          visible={showDetail.visible}
          onSuccess={() => {
            setShowDetail({ visible: false, id: 0 });
            loadContent();
          }}
        />
      )}
      {addEdit.visible && (
        <FarmlandUsageDetail
          visible={addEdit.visible}
          id={addEdit.id}
          hide={() => {
            loadContent();
            setAddEdit({ visible: false, id: null });
          }}
        />
      )}
    </>
  );
};
export default FarmlandListV2;
