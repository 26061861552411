import React, {useCallback, useState} from "react";
import {Link, Redirect, useHistory} from 'react-router-dom'
import {
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Row,
    Tabs,
    Table,
    Typography
} from "antd"
import type { ColumnsType } from 'antd/es/table'
import Register from "./Register"
import NetworkApi from "../../network/networkApi"
import {
    UserOutlined,
    LockOutlined,
    FileDoneOutlined,
    FileOutlined,
    VideoCameraOutlined,
    QrcodeOutlined,
    FilePdfFilled,
    TwitterSquareFilled,
    FacebookFilled
} from '@ant-design/icons';
import {USER_ROLE} from "../../enums/user.enum";
import "./login.css";
import CertificateCheck from "./CertificateCheck";
import {render} from "react-dom";

const {Title} = Typography;

const {TabPane} = Tabs;

const tabPaneStyle = {
    height: '100px',
    margin: '40px 0px 40px 40px',
}

const contentStyle = {
    margin: '0px 0px 0px 40px',
}

const loginCardStyle = {
    margin: '40px'
}

const headerStyle = {
    borderTop: 'grey 1px',
    height: '100px',
    width: '100%'
}

const footerStyle = {
    borderTop: 'grey 1px',
    height: '100px',
    width: '100%'
}

interface DataType {
    key: React.Key;
    number: string;
    name: string;
    date: string;
    attach: string;
}

const dataSource: DataType[] = [
    {
        key: '1',
        number: '1',
        name: 'УРГАМАЛ, ТҮҮНИЙ  ГАРАЛТАЙ ХҮНСНИЙ ТҮҮХИЙ ЭД, БҮТЭЭГДЭХҮҮНИЙ УЛ МӨРИЙГ МӨРДӨН ТОГТООХ БҮРТГЭЛИЙН ЖУРАМ',
        date: '2022-06-27',
        attach: 'https://legalinfo.mn/mn/detail?lawId=16530810646881&showType=1',
    },
    {
        key: '2',
        number: '2',
        name: 'ТАРИАЛАНГИЙН ҮЙЛДВЭРЛЭЛ ЭРХЭЛЖ БАЙГАА ИРГЭН, ХУУЛИЙН ЭТГЭЭДИЙН ҮЙЛДВЭРЛЭСЭН БҮТЭЭГДЭХҮҮНД МӨНГӨН УРАМШУУЛАЛ ОЛГОХ, МӨНГӨН БУС ДЭМЖЛЭГ ҮЗҮҮЛЭХ ЖУРАМ',
        date: '2022-07-06',
        attach: 'https://legalinfo.mn/mn/detail?lawId=16530825212161&showType=1',
    },
    {
        key: '3',
        number: '3',
        name: 'СИСТЕМИЙН ТАНИЛЦУУЛГА( ВИДЕО)',
        date: '2022-05-31',
        attach: 'https://www.youtube.com/watch?v=oN7D4MSjDv0',
    }
];

const defaultColumns: ColumnsType<DataType> = [
    {
        title: '№',
        dataIndex: 'number',
        key: 'number',
        width: '5%',
        responsive: ['md'],
    },
    {
        title: 'Нэр',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Огноо',
        dataIndex: 'date',
        key: 'date',
        responsive: ['md'],
    },
    {
        title: 'Үзэх',
        dataIndex: 'attach',
        render: (attach: string) => <Link target="_blank" to={{pathname: attach}}>Үзэх</Link>
    },
]

const manualDataSource = [
    {
        number: '1',
        name: 'Систем ашиглах заавар',
        link: 'https://drive.google.com/drive/u/2/folders/1_zX7qnYbNO5vmG39RsqtwCSA3m895Qr-',
    },
    {
        number: '2',
        name: 'Бүртгүүлэх заавар',
        link: 'https://www.youtube.com/watch?v=ZGRx2nB56Lg',
    },
];

const manualColumns = [
    {
        title: '№',
        dataIndex: 'number',
        key: 'number',
        width: '5%',
    },
    {
        title: 'Нэр',
        dataIndex: 'name',
        key: 'name',
        width: '55%',
    },
    {
        title: 'Үзэх',
        dataIndex: 'link',
        render: (link: string) => <Link target="_blank" to={{pathname: link}}>Үзэх</Link>,
    },
]
const Login = () => {
    const history = useHistory()
    const [showRegister, changeShowRegister] = useState(false)
    const [login, changeLogin] = useState({
        name: '',
        password: ''
    });
    const handleClick = useCallback(async () => {
        let result = await NetworkApi.requestPost('/api/auth/login', login);
        if (result.resultCode == 200) {
            message.success("Системд амжилттай нэвтэрлээ...");
            window.localStorage.setItem("announcement", JSON.stringify(true));
            if (result.result?.user?.role?.cd === USER_ROLE.WAREHOUSE_SPECIALIST) {
                window.location.href = "/wm/warehouse";
            } else {
                window.location.href = '/'
            }
        } else {
            message.error(result.message || 'системийн алдаа');
        }
    }, [login]);
    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
    };
    return (
        <Layout>
            <Card style={headerStyle}>
                <Row>
                    <Col span={2}>
                    </Col>
                    <Col span={5}>
                        <img
                             src="https://mofa.gov.mn/_next/image?url=%2Fimages%2Flogo%2Flogo_mofa.svg&w=640&q=75"
                             alt="Хүнс Хөдөө Аж Ахуйн Хөнгөн Үйлдвэрийн Яам"
                        />
                    </Col>
                    <Col span={17}>
                        <Title level={2} className={"loginTitle"}>Тариалан үйлдвэрлэлийн нэгдсэн систем</Title>
                    </Col>
                </Row>
            </Card>
            <Layout style={{width: '100%'}}>
                <Row justify={'center'}>
                    <div className="cardContainer loginPage">
                        <Tabs type="card" defaultActiveKey={"default"} tabBarGutter={5} tabBarStyle={tabPaneStyle}>
                            <TabPane tab={<span><FileDoneOutlined
                                style={{color: '#2038a2', fontSize: '280%', float: 'left'}}/><span className={"loginTabSpan"}><p
                                style={{marginBottom: '0px'}}>ДҮРЭМ ЖУРАМ,</p> <p>ТУШААЛ ШИЙДВЭР</p></span></span>}
                                     key="default">
                                <Card style={contentStyle}>
                                    <Table dataSource={dataSource} columns={defaultColumns} pagination={false}
                                           scroll={{y: 240}}/>
                                </Card>
                            </TabPane>
                            <TabPane
                                tab={<span><QrcodeOutlined style={{color: '#2038a2', fontSize: '280%', float: 'left'}}/><span className={"loginTabSpan"}><p
                                    style={{marginBottom: '0px'}}>ГАРАЛ ҮҮСЛИЙН</p><p>ГЭРЧИЛГЭЭ ЛАВЛАХ</p></span></span>}
                                key="certificate">
                                <Card style={contentStyle}>
                                    <CertificateCheck/>
                                </Card>
                            </TabPane>
                            <TabPane
                                tab={<span><FilePdfFilled style={{color: '#2038a2', fontSize: '280%', float: 'left'}}/><span  className={"loginTabSpan"}>ГАРЫН АВЛАГА, ЗААВАР</span></span>}
                                key="manual">
                                <Card style={contentStyle}>
                                    <Table dataSource={manualDataSource} columns={manualColumns} pagination={false}
                                           scroll={{y: 240}}/>
                                </Card>
                            </TabPane>
                        </Tabs>
                    </div>
                    <div style={loginCardStyle}>
                        <Form
                            name="login_form"
                            className="login-form"
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="name"
                                rules={[{required: true, message: 'Хэрэглэгчийн нэрээ оруулна уу!'}]}
                            >
                                <Input size={'large'} value={login.name}
                                       onChange={t => changeLogin({...login, name: t.target.value})}
                                       placeholder={'Нэвтрэх нэр'}
                                       suffix={<UserOutlined/>}/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{required: true, message: 'Нууц үгээ оруулна уу!'}]}
                            >
                                <Input size={'large'} type="password" value={login.password}
                                       onChange={t => changeLogin({...login, password: t.target.value})}
                                       placeholder={'Нууц үг'} suffix={<LockOutlined/>}/>
                            </Form.Item>
                            <Form.Item>
                                <Row justify={'space-between'} align={'middle'}>
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Checkbox>Намайг сана</Checkbox>
                                    </Form.Item>
                                    <Button type={"primary"} onClick={handleClick}
                                            htmlType={"submit"}>Нэвтрэх</Button>
                                </Row>
                            </Form.Item>
                            <Form.Item>
                                <Row justify={'space-between'}>
                                    <Button type={'link'} onClick={() => history.push('/forgot/password')}>Нууц үгээ
                                        мартсан</Button>
                                    <Button onClick={() => changeShowRegister(true)} type={'link'}>Тариаланч
                                        бүртгүүлэх</Button>
                                </Row>
                            </Form.Item>
                        </Form>
                        <p>
                            Вэб холбоос
                        </p>
                        <Card>
                            <Row justify={'center'}>
                                <Link target="_blank" to={{pathname: 'https://mofa.gov.mn'}}>Хүнс, хөдөө аж ахуй, хөнгөн
                                    үйлдвэрийн яам</Link>
                            </Row>
                            <Divider/>
                            <Row justify={'center'}>
                                <Link target="_blank" to={{pathname: 'http://khaads.mn'}}>Хөдөө аж ахуйн дэмжлэгийн
                                    сан</Link>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </Layout>
            <Register visible={showRegister} hide={() => changeShowRegister(false)}/>
            <Card style={footerStyle}>
                <Row>
                    <Col span={6}>
                        <img style={{float: 'left'}}
                             src="https://mofa.gov.mn/_next/image?url=%2Fimages%2Flogo%2Flogo_mofa.svg&w=640&q=75"
                             alt="Хүнс Хөдөө Аж Ахуйн Хөнгөн Үйлдвэрийн Яам"
                        />
                    </Col>
                    <Col span={12}>
                        <p style={{paddingTop: '10px'}} className="copyRightText">
                            Зохиогчийн эрх ©2022 Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн яам
                        </p>
                    </Col>
                    <Col span={6}>
                        <span style={{float: 'left', fontSize: '20px'}}>
                            <TwitterSquareFilled style={{color: '#00acee'}}/>
                            <Link target="_blank" to={{pathname: 'https://www.facebook.com/mofagovmn'}}>
                                <FacebookFilled style={{color: '#4267B2', marginLeft: '10px'}}/>
                            </Link>
                        </span>
                    </Col>
                </Row>
            </Card>
        </Layout>
    )
}
export default Login
