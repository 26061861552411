import React, {useEffect, useState} from 'react'

import {Row, Col, Input, Button, Modal, Table, Card, Layout} from 'antd'
import License from '../lib/License';
import { useParams } from 'react-router-dom';

const { TextArea } = Input;
interface LocationOrams{
    id:any
}
const CertificateUrl = () => {
    const [visible, setVisible] = useState(false);
    const {id} = useParams<LocationOrams>()
    const showModal = () => {
        setVisible(true);
    };
    console.log(window.location.hostname)
    useEffect(()=>{
        if(id){
            setVisible(true)
        }
    },[id])
    return (
        <Layout title="Ургамал, түүний  гаралтай хүнсний  түүхий эд,  бүтээгдэхүүний  гарал, үүсэл">
            <Row align="middle" style={{ minHeight: '100vh'}}>
                <Col span={6} push={9}>
                    <Card>
                        <img
                            src="https://mofa.gov.mn/exp/images/uploads/logo_mofa.svg"
                            alt="Хүнс Хөдөө Аж Ахуйн Хөнгөн Үйлдвэрийн Яам"
                            style={{marginBottom:15}}
                        />
                        <p style={{textAlign:'center'}}>
                            <h1>ГҮГ шалгах</h1>
                        </p>
                        
                        <div style={{textAlign:'center'}}>
                            <Button onClick={showModal}>Шалгах</Button><br/>
                            <a href={'/'} style={{textDecoration: 'underline', color: '#085394'}}>Нэвтрэх хуудас руу буцах</a>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Modal width={1240} centered visible={visible} onCancel={() => setVisible(true)}
                footer={[
                    <Button key="submit" type="default" onClick={() => setVisible(true)}>
                        Хаах
                       </Button>,
                ]}
            >
                <License isSmall={false} id={visible?id:''} key={"PrintLicense"} />
            </Modal>
            
        </Layout>
    )
};
export default CertificateUrl
