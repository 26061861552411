import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  Layout,
  Row,
  Table,
  PageHeader,
  Descriptions,
  Divider,
  Typography,
  Modal,
  message,
  Image,
  Popconfirm,
} from "antd";
import {
  HistoryOutlined,
  PrinterOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import moment from "moment";
import License from "../lib/License";
import BreadMe from "../lib/Breadme";
import useCertificateDetail from "../../hooks/useCertificateDetail";
import useFarmlandChemicalList from "../../hooks/useFarmlandChemicalList";
import useFarmlandServiceList from "../../hooks/useFarmlandServiceList";
import ReactToPrint from "react-to-print";
import UserContext from "../../providers/UserContext";
import config from "../../config";

const { Text } = Typography;

interface Params {
  id?: any;
}

const LicenseDetail = () => {
  let { id } = useParams<Params>();
  const [content, loadContent, changeId] = useCertificateDetail();
  useEffect(() => {
    changeId(id);
  }, [id]);
  const printRef = useRef<any>();
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const [visiblePrint, printVisible] = useState(false);
  const [smallPrint, setSmallPrint] = useState(false);
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (
      user.role?.cd == "ADMIN" ||
      user?.role?.cd == "VILLAGE_AGRONOMIST"
      // || user.role?.cd == "PROVINCE_AGRONOMIST"
    ) {
      return true;
    }
    return false;
  }, [user]);
  const files = useMemo(() => {
    if (content.result.files) {
      try {
        let c = JSON.parse(content.result.files);
        if (c && c != undefined) {
          return c;
        }
      } catch (e) {
        console.log(e);
      }
      return [];
    }
  }, [content]);
  const confirmLicense = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      `/api/certificate/confirm/${content.result.id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай баталгаажлаа");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);
  const rejectLicense = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      `/api/certificate/reject/${content.result.id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай цуцлагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);
  const columns = [
    {
      title: "Бүтээгдэхүүний нэр",
      dataIndex: "warehouse",
      render: (warehouse: any) => `${warehouse.sort?.plant.name}`,
    },
    {
      title: "Сорт",
      dataIndex: "warehouse",
      render: (warehouse: any, item: any) => `${warehouse.sort?.name ?? ""}`,
    },
    {
      title: "ОУ-ын код",
      dataIndex: "warehouse",
      render: (warehouse: any) => `${warehouse.sort?.internationalCode ?? "-"}`,
    },
    {
      title: "Хэмжээ",
      dataIndex: "area",
      editable: true,
      render: (value: number, item: any) =>
        `${value ?? "0"} ${item.warehouse.sort?.unit?.name ?? ""}`,
    },
  ];

  const title: any = {
    pending: "Хүлээгдэж буй",
    draft: "Ноорог",
    active: `Баталсан: ${content.result.acceptedUser?.firstName || ""} ${
      content.result.acceptedUser?.lastName || ""
    }`,
    rejected: "Татгалзсан",
    reviewed: "Хянасан",
    detained: "Саатуулсан",
  };

  const titleStyle: any = {
    pending: "#ff9900",
    draft: "#ff9900",
    active: "#93c47d",
    rejected: "#cc0000",
  };

  const submitAccept = useCallback(
    async (status: any) => {
      let response = await NetworkApi.requestPost("/api/certificate/check", {
        certificateId: content.result.id,
        status,
        description: "..",
      });
      if (response.resultCode == 200) {
        loadContent();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [content]
  );

  return (
    <>
      <BreadMe title={["Гэрчилгээ", "Гэрчилгээний дэлгэрэнгүй"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title={content.result ? title[content.result.status] : ""}
          extra={[]}
          style={{
            backgroundColor: content.result
              ? titleStyle[content.result.status]
              : "#fff",
            marginBottom: 15,
          }}
        />
        <Row justify={"space-between"} style={{ marginBottom: 15 }} gutter={20}>
          <Col span={4}>
            <Button onClick={showModal}>
              <HistoryOutlined /> Талбайн түүх
            </Button>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            {content.result.status == "pending" && canEdit && (
              <>
                <Button type="primary" onClick={() => confirmLicense()}>
                  <CheckCircleOutlined /> Батлах
                </Button>
                <Button
                  style={{ marginLeft: 5 }}
                  type="primary"
                  danger
                  onClick={() => rejectLicense()}
                >
                  <CloseCircleOutlined /> Цуцлах
                </Button>
              </>
            )}
            {(content.result.status == "active" ||
              content.result.status == "reviewed") && (
              <Button
                type="primary"
                onClick={() => {
                  printVisible(true);
                  setSmallPrint(false);
                }}
              >
                <PrinterOutlined /> Хэвлэх
              </Button>
            )}
            {(content.result.status == "active" ||
              content.result.status == "reviewed") && (
              <Button
                style={{ marginLeft: 5 }}
                type="primary"
                onClick={() => {
                  printVisible(true);
                  setSmallPrint(true);
                }}
              >
                <PrinterOutlined /> Жижиг printer
              </Button>
            )}
          </Col>
        </Row>
        <Row gutter={20}>
          <Divider orientation="left" plain>
            Гэрчилгээний мэдээлэл
          </Divider>
          <Descriptions>
            <Descriptions.Item label="Үүсгэсэн" span={3}>
              {content.result.createdUser?.firstName}{" "}
              {content.result.createdUser?.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Үүсгэсэн огноо">
              {moment(content.result.createdAt).format("YYYY-MM-DD HH:mm")}
            </Descriptions.Item>
            <Descriptions.Item label="Баталсан огноо">
              {content.result.acceptedAt
                ? moment(content.result.acceptedAt).format("YYYY-MM-DD")
                : ""}
            </Descriptions.Item>
            <Descriptions.Item label="Хаанаас">
              {content.result.seller?.name || ""}-{content.result.seller?.rd} (
              {content.result.farmland?.aimag?.name || ""},{" "}
              {content.result.farmland?.sum?.name || ""})
            </Descriptions.Item>
            <Descriptions.Item label="Хаашаа">
              {content.result.receiverName || ""} (
              {content.result.receiverAimag?.name || ""},{" "}
              {content.result.receiverSum?.name})
            </Descriptions.Item>
            <Descriptions.Item label="Төлөв">
              <Text mark>
                {content.result.status == "draft"
                  ? "Ноорог"
                  : content.result.status == "active"
                  ? "Зөвшөөрсөн"
                  : content.result.status == "reviewed"
                  ? "Хянасан"
                  : content.result.status == "detained"
                  ? "Саатуулсан"
                  : ""}
                {user?.role?.cd == "PROVINCE_INSPECTOR" ||
                user?.role?.cd == "PROVINCE_AGRONOMIST" ||
                "ADMIN" == user?.role.cd ? (
                  <Popconfirm
                    title={"Гарал үүсийн гэрчилгээг баталгаажуулах уу"}
                    onConfirm={() => submitAccept("accept")}
                    onCancel={() => submitAccept("decline")}
                    okText="Хянах"
                    cancelText="Саатуулах"
                  >
                    <Button type="primary">Хяналт</Button>
                  </Popconfirm>
                ) : null}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="НТД:">
              {content.result.farmland?.posNo}({content.result.farmland?.area}
              га)
            </Descriptions.Item>
            <Descriptions.Item label="Зориулалт">
              {content.result.purpose == "sale" ? "Борлуулах" : "Үрэнд"}
            </Descriptions.Item>
            <Descriptions.Item label="Хүчинтэй хугацаа">
              {content.result.startDate}-{content.result.endDate}
            </Descriptions.Item>
            <Descriptions.Item label="Хариуцагчийн нэр">
              {content.result.transporterName}
            </Descriptions.Item>
            <Descriptions.Item label="Дүнгийн хуудас">
              {(files || []).map((file: any, index: number) => {
                return (
                  <a href={`/tmp/${file.tmp}`} target="_blank">
                    {file.type == "application/pdf" ? (
                      <>
                        <FilePdfOutlined />
                        {file.name}{" "}
                      </>
                    ) : (
                      <Image
                        width={40}
                        height={40}
                        src={`${config.get("apiUrl")}/tmp/${file.tmp}`}
                      />
                    )}
                  </a>
                );
              })}
            </Descriptions.Item>
            <Descriptions.Item label="Тээврийн хэрэгсэл">
              {content.result.transporterId}
            </Descriptions.Item>
            <Descriptions.Item label="Хариуцагчийн утас">
              {content.result.transporterPhone}
            </Descriptions.Item>
            <Descriptions.Item label="Тайлбар">
              {content.result.description}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row gutter={20}>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={content.result.sizes}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <UsageHistory
        visible={visible}
        onHide={() => setVisible(false)}
        usageId={content.usageId}
      />
      <Modal
        width={1240}
        centered
        visible={visiblePrint}
        onCancel={() => printVisible(false)}
        footer={[
          <ReactToPrint
            pageStyle={smallPrint ? pageStylePortrait : pageStyleA4}
            trigger={() => {
              // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
              // to the root node of the returned component as it will be overwritten.
              return (
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => console.log("print")}
                >
                  <PrinterOutlined /> Хэвлэх
                </Button>
              );
            }}
            content={() => printRef.current}
          />,
          <Button
            key="submit"
            type="default"
            onClick={() => printVisible(false)}
          >
            Хаах
          </Button>,
        ]}
      >
        <div ref={printRef}>
          <License
            isSmall={smallPrint}
            id={visiblePrint ? content.result.id : null}
            key={"PrintLicense"}
          />
        </div>
      </Modal>
    </>
  );
};

const pageStylePortrait = `
  @page {
    size: 80mm 400mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
const pageStyleA4 = `
  @page {
    size: A4;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
interface HistoryProps {
  usageId: any;
  visible: boolean;
  onHide: any;
}
const UsageHistory = ({ visible, onHide, usageId }: HistoryProps) => {
  const [chemicalList, loadChemical, chemicalFilter] =
    useFarmlandChemicalList();
  const [serviceList, loadServiceList, serviceFilter] =
    useFarmlandServiceList();
  useEffect(() => {
    if (usageId) {
      chemicalFilter({
        usageId,
      });
      serviceFilter({
        usageId,
      });
    }
  }, [usageId]);
  return (
    <Modal
      onCancel={onHide}
      width={800}
      centered
      visible={visible}
      footer={[
        <Button key="submit" type="default" onClick={onHide}>
          Хаах
        </Button>,
      ]}
    >
      <Row justify="space-around" align="middle">
        <Col span={24}>
          <h2>Талбайн түүх</h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            title={() => "Бодис зарцуулалт"}
            pagination={false}
            columns={elementColumn}
            dataSource={chemicalList.result}
            scroll={{ x: true }}
          />
        </Col>
        <Col span={24}>
          <Table
            title={() => "Талбайн арчилгаа"}
            pagination={false}
            columns={chemColumn}
            dataSource={serviceList.result}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    </Modal>
  );
};
export default LicenseDetail;
const elementColumn = [
  {
    title: "№",
    render: (a: any, b: any, c: any) => c + 1,
  },
  {
    title: "Бодис, бордооны төрөл",
    render: (a: any) => a.chemical?.type?.name,
  },
  {
    title: "Бодис, бордооны нэр",
    render: (a: any) => a.chemical?.name,
  },
  {
    title: "Зориулалт",
    render: (a: any) => a.chemical?.description,
  },
  {
    title: "Хэрэглэсэн огноо",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Тун/га",
    dataIndex: "dosage",
    key: "dosage",
  },
  {
    title: "Бүртгэсэн ажилтан",
    render: (a: any) => a.user?.firstName,
  },
  {
    title: "Албан тушаал",
    render: (a: any) => a.user?.role?.name,
  },
];

const chemColumn = [
  {
    title: "№",
    render: (a: any, b: any, c: any) => c + 1,
  },
  {
    title: "Төрөл",
    render: (a: any) => a.service?.type?.name,
  },
  {
    title: "Нэр",
    render: (a: any) => a.service?.name,
  },
  {
    title: "1га-ийн тун/хэмжээ",
    dataIndex: "dosage",
    key: "dosage",
  },
  {
    title: "Огноо",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Бүртгэсэн ажилтан",
    render: (a: any) => a.user?.firstName,
  },
  {
    title: "Албан тушаал",
    render: (a: any) => a.user?.role?.name,
  },
];
