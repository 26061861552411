import { useCallback, useEffect, useState } from "react";
import { Col, Row, message } from "antd";
import NetworkApi from "../network/networkApi";
import Dashboard from "./Dashboard";

import { Column, Pie, Bar } from "@ant-design/plots";

const chartOuterStyle = {
  margin: 10,
  paddingTop: 10,
  paddingBottom: 10,
  background: "#fff",
};
const chartTitleStyle = {
  textAlign: "center" as "center",
  fontSize: 14,
  fontWeight: 600,
  paddingTop: 10,
};

interface pieChartDataRow {
  label: string;
  value: number;
}

interface CustomerSummaryContent {
  loading: boolean;
  result: {
    total: {
      farmerCount: number;
      organizationCount: number;
    };
    aimag: [];
  };
  customerPieChartData: pieChartDataRow[];
  customerBarChartData: any[];
}

const useCustomers = (): [CustomerSummaryContent, () => void] => {
  const [content, changeContent] = useState<CustomerSummaryContent>({
    loading: false,
    result: {
      total: {
        farmerCount: 0,
        organizationCount: 0,
      },
      aimag: [],
    },
    customerPieChartData: [],
    customerBarChartData: [],
  });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet("/api/dashboard/users");
    if (response.resultCode === 200) {
      const customerPieChartData = [
        {
          label: "Хуулийн этгээд",
          value: response.result.total.organizationCount,
        },
        {
          label: "Тариаланч",
          value: response.result.total.farmerCount,
        },
      ];

      let customerBarChartData: any[] = [];
      for (const item of response.result.aimag) {
        const name = item.aimagName.split(" ")[0];
        customerBarChartData.push({
          name,
          type: "Хуулийн этгээд",
          value: item.organizationCount,
        });
        customerBarChartData.push({
          name,
          type: "Тариаланч",
          value: item.farmerCount,
        });
      }
      changeContent({
        loading: false,
        result: response.result || {},
        customerPieChartData,
        customerBarChartData,
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  return [content, loadContent];
};

interface FarmLandSummaryContent {
  loading: boolean;
  result: {
    total: {
      stateCount: number;
      privateCount: number;
    };
    aimag: any[];
  };
  farmlandPieChartData: pieChartDataRow[];
  farmlandBarChartData: any[];
}

const useFarmlands = (): [FarmLandSummaryContent, () => void] => {
  const [content, changeContent] = useState<FarmLandSummaryContent>({
    loading: false,
    result: {
      total: {
        stateCount: 0,
        privateCount: 0,
      },
      aimag: [],
    },
    farmlandPieChartData: [],
    farmlandBarChartData: [],
  });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet("/api/dashboard/farmlands");
    if (response.resultCode === 200) {
      const farmlandPieChartData = [
        {
          label: "Эзэмшлийн",
          value: response.result.total.privateCount,
        },
        {
          label: "Түрээсийн",
          value: response.result.total.stateCount,
        },
      ];

      let farmlandBarChartData: any[] = [];
      for (const item of response.result.aimag) {
        const name = item.aimagName.split(" ")[0];
        farmlandBarChartData.push({
          name,
          type: "Нийт талбай",
          value: item.totalArea,
        });
        farmlandBarChartData.push({
          name,
          type: "Ашиглагдаж буй",
          value: item.usedArea,
        });
      }
      changeContent({
        loading: false,
        result: response.result || {},
        farmlandPieChartData,
        farmlandBarChartData,
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  return [content, loadContent];
};

const DashboardFarmLand = () => {
  const [customers] = useCustomers();
  const [farmlands] = useFarmlands();

  return (
    <>
      <Dashboard />
      <Row style={{ padding: 20, paddingTop: 0 }}>
        <Col span={12}>
          <div style={chartOuterStyle}>
            <div style={chartTitleStyle}>
              <span>Нийт тариаланчид</span>
            </div>
            <div
              style={{
                width: "100%",
                height: 280,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Pie
                appendPadding={10}
                angleField="value"
                colorField="label"
                radius={0.9}
                label={{
                  type: "outer",
                  content: "{percentage}",
                }}
                interactions={[
                  {
                    type: "element-active",
                  },
                ]}
                data={customers.customerPieChartData || []}
              />
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div style={chartOuterStyle}>
            <div style={chartTitleStyle}>
              <span>Талбайн эзэмшлийн төрлөөр</span>
            </div>
            <div
              style={{
                width: "100%",
                height: 280,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Pie
                appendPadding={10}
                angleField="value"
                colorField="label"
                radius={0.9}
                label={{
                  type: "outer",
                  content: "{percentage}",
                }}
                interactions={[
                  {
                    type: "element-active",
                  },
                ]}
                data={farmlands.farmlandPieChartData || []}
              />
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div style={chartOuterStyle}>
            <div style={chartTitleStyle}>
              <span>Нийт талбайн ашиглалт</span>
            </div>

            <div
              style={{
                width: "100%",
                height: 610,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Bar
                data={farmlands.farmlandBarChartData}
                isGroup
                xField="value"
                yField="name"
                seriesField="type"
              />
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div style={chartOuterStyle}>
            <div style={chartTitleStyle}>
              <span>Нийт тариаланч</span>
            </div>

            <div
              style={{
                width: "100%",
                height: 400,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Column
                data={customers.customerBarChartData}
                isGroup
                xField="name"
                yField="value"
                seriesField="type"
                xAxis={{
                  label: {
                    rotate: -45,
                    offset: 40,
                  },
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DashboardFarmLand;
