import React, { useState } from "react";
import BreadMe from "../lib/Breadme";
import {
  Button,
  Col,
  Input,
  Layout,
  Modal,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import {
  PlusSquareOutlined,
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

const Permission = () => {
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setTimeout(() => {
      setVisible(false);
    }, 500);
  };
  const permissionData = [
    {
      key: "1",
      name: "admin",
      role: "administrator",
      permission: ["Бүгд"],
    },
    {
      key: "2",
      name: "Сумын агрономич",
      role: "administrator",
      permission: [
        "Тариаланч харах",
        "Тариаланч засах",
        "Тариаланч бүртгэх",
        "Тариаланч устгах",
        "Тариалангийн талбай бүртгэх",
        "Тариалангийн талбай харах",
      ],
    },
    {
      key: "2",
      name: "Тариаланч",
      role: "farmer",
      permission: [
        "Тариаланч харах",
        "Тариаланч засах",
        "Тариалангийн талбай бүртгэх",
        "Тариалангийн талбай" + " харах",
      ],
    },
  ];
  const permissionInfo = [
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Хандах эрх",
      dataIndex: "permission",
      key: "permission",
      render: (list: string[]) => (
        <Row>
          {list.map((item) => (
            <Tag color={"green"}>{item}</Tag>
          ))}
        </Row>
      ),
    },
    {
      title: "Үйлдэл",
      dataIndex: "id",
      key: "id",
      render: (value: number) => (
        <Space size={10}>
          <Button type={"primary"} color={"blue"} icon={<EditOutlined />}>
            Засах
          </Button>
          <Button
            type={"primary"}
            color={"red"}
            icon={<DeleteOutlined />}
            danger
          >
            Устгах
          </Button>
        </Space>
      ),
    },
  ];
  const handleParam = (e: any) => {
    console.log(e);
  };
  return (
    <>
      <BreadMe title={["Системийн утгууд", "Хэмжих нэгж"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row justify={"space-between"} style={{ marginBottom: 15 }}>
          <Space size={20}>
            <Input placeholder={"Хайх"} />
            <Button type={"primary"}>
              <SearchOutlined />
            </Button>
          </Space>
          <Button onClick={showModal} type={"primary"}>
            <PlusSquareOutlined /> Эрх бүртгэх
          </Button>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              columns={permissionInfo}
              dataSource={permissionData}
              onChange={handleParam}
            />
          </Col>
        </Row>
      </Layout.Content>
      <Modal
        width={595}
        centered
        visible={visible}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Хадгалах
          </Button>,
          <Button key="submit" type="default" onClick={handleOk}>
            Хаах
          </Button>,
        ]}
      >
        <Row justify="space-around" align="middle">
          <Col span={24}>
            <h2>Хэмжих нэгж нэмэх</h2>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Permission;
