import { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Layout,
  List,
  Row,
  Select,
  message,
} from "antd";
import {
  PlusOutlined,
  AntDesignOutlined,
  PhoneOutlined,
  EditOutlined,
  HomeOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import AddressItemSelect from "../lib/AddressItemSelect";
import useRoleList from "../../hooks/useRoleList";

const { Option } = Select;

const UserList = () => {
  const [roles] = useRoleList(["CUSTOMER", "s"]);
  const [userData, changeUserData] = useState({
    loading: true,
    result: [],
    total: 0,
  });
  const [address, changeAddress] = useState({
    aimag: "",
    sum: "",
    bag: "",
  });
  const [filter, changeFilter] = useState<any>({});
  const [editId, changeEditId] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet("/api/admin/user/list", filter);
    console.log(response);
    if (response.resultCode === 200) {
      changeUserData({
        loading: false,
        result: response.result.rows || [],
        total: response.result.count,
      });
    }
  }, [filter]);

  useEffect(() => {
    loadContent();
  }, [filter]);

  return (
    <>
      <BreadMe title={["Хэрэглэгчийн бүртгэл"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row justify={"space-between"}>
          <Col span={6}>
            <Form onFinish={(data: any) => changeFilter(data)}>
              <Form.Item name="name">
                <Input placeholder={"Хэрэглэгчийн нэр"} />
              </Form.Item>
              <AddressItemSelect
                subtitle={"- Аймаг -"}
                value={address.aimag}
                parent={1}
                title=""
                name="aimagId"
                onChange={(aimag) => {
                  changeAddress({
                    aimag,
                    sum: "",
                    bag: "",
                  });
                }}
              />
              <AddressItemSelect
                subtitle={"- Сум -"}
                value={address.sum}
                parent={address.aimag}
                title=""
                name="sumId"
                onChange={(sum) => {
                  changeAddress({
                    ...address,
                    sum,
                    bag: "",
                  });
                }}
              />
              <AddressItemSelect
                subtitle={"- Баг -"}
                value={address.bag}
                parent={address.sum}
                title=""
                name="bagId"
                onChange={(bag) => {
                  changeAddress({
                    ...address,
                    bag,
                  });
                }}
              />
              <Form.Item name="role">
                <Select allowClear placeholder="Албан тушаал">
                  {roles.result.map((r) => (
                    <Select.Option key={r.id} value={r.id}>
                      {r.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="status">
                <Select allowClear placeholder="Төлөв">
                  <Select.Option value="A">Ажиллаж байгаа</Select.Option>
                  <Select.Option value="D">Ажлаас гарсан</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Шүүх
                </Button>
              </Form.Item>
              <Form.Item>
                <h3>Хэрэглэгч бүртгэх</h3>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    changeEditId(undefined);
                    showModal();
                  }}
                >
                  <PlusOutlined />
                  Хэрэглэгч нэмэх
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={14}>
            <h3>Нийт {userData.total} хэрэглэгчид</h3>
            <List
              itemLayout="vertical"
              size="large"
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 3,
              }}
              dataSource={userData.result}
              renderItem={(item: any) => (
                <List.Item
                  key={item.id}
                  style={{
                    border: "3px solid #ddd",
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <Row justify="space-between" align="bottom">
                    <Col span={6}>
                      <Avatar
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 80,
                          xl: 120,
                          xxl: 160,
                        }}
                        icon={<AntDesignOutlined />}
                      />
                    </Col>
                    <Col span={9}>
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                      >
                        {item.lastName} {item.firstName}
                      </p>
                      <p>
                        <PhoneOutlined style={{ paddingRight: 10 }} />
                        {item.phone}
                      </p>
                      <p>
                        <MailOutlined style={{ paddingRight: 10 }} />
                        {item.email}
                      </p>
                      <p>
                        <UserOutlined style={{ paddingRight: 10 }} />
                        {item.role.name}
                      </p>
                    </Col>
                    <Col span={9}>
                      <Row align="top" justify={"end"}>
                        <Button
                          type="primary"
                          onClick={() => {
                            changeEditId(item.id);
                            showModal();
                          }}
                        >
                          <EditOutlined />
                        </Button>
                      </Row>
                      <p>
                        <HomeOutlined style={{ paddingRight: 10 }} />
                        {item.address ? item.address : "-"}
                      </p>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
            ,
          </Col>
        </Row>
      </Layout.Content>
      {visible && (
        <UserAddEdit
          id={editId}
          visible={visible}
          onSuccess={() => {
            loadContent();
            changeEditId(undefined);
            setVisible(false);
          }}
          hide={() => {
            changeEditId(undefined);
            setVisible(false);
          }}
        />
      )}
    </>
  );
};
export default UserList;

interface UserAddEditProps {
  visible: boolean;
  hide: () => void;
  onSuccess: () => void;
  id?: number;
}

const RoleAddress = [
  {
    value: "uls",
    label: "Улс",
  },
  {
    value: "aimag",
    label: "Аймаг",
  },
  {
    value: "sum",
    label: "Сум",
  },
];

const UserAddEdit = ({ visible, hide, onSuccess, id }: UserAddEditProps) => {
  const [address, changeAddress] = useState({ aimag: "", sum: "", bag: "" });
  const [roleList] = useRoleList(["CUSTOMER", "s"]);
  const [roleAddressOpts, setRoleAddressOpts] = useState(RoleAddress);

  const [form] = Form.useForm();
  useEffect(() => {
    if (visible && id) {
      loadEdit(id);
    } else if (visible) {
      form.resetFields();
    }
  }, [visible, id]);
  const loadEdit = useCallback(
    async (id: any) => {
      let response = await NetworkApi.requestGet("/api/admin/user/get", { id });
      if (response.resultCode == 200) {
        form.setFieldsValue(response.result);
        changeAddress({
          aimag: response.result.aimagId,
          sum: response.result.sumId,
          bag: response.result.bagId,
        });
        return;
      }
    },
    [form]
  );
  const handleOk = useCallback(
    async (data: any) => {
      let response = await NetworkApi.requestPost("/api/admin/user/create", {
        ...data,
        id,
      });
      if (response.resultCode != 200) {
        message.error(response.message || "System error");
        return;
      }
      onSuccess();
    },
    [onSuccess, id]
  );
  return (
    <Modal
      width={800}
      centered
      visible={visible}
      title={"Хэрэглэгч бүртгэх"}
      onCancel={hide}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleOk}>
        <Row>
          <Col span={6}>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 80, xl: 120, xxl: 160 }}
              icon={<AntDesignOutlined />}
            />
          </Col>
          <Col span={18}>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item name="lastName" label={"Овог"}>
                  <Input placeholder={"Овог"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="firstName" label={"Нэр"}>
                  <Input placeholder={"Нэр"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="rd" label={"Регистр"}>
                  <Input placeholder={"XX00000000"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="phone" label={"Утас"}>
                  <Input placeholder={"Утасны дугаар"} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item name="email" label={"И-мэйл"}>
              <Input placeholder={"и-мэйл хаяг"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="roleId" label={"Эрх"}>
              <Select
                defaultValue={1}
                style={{ width: "100%" }}
                onChange={(value: number) => {
                  if ([1, 6, 8, 9].includes(value)) {
                    setRoleAddressOpts([RoleAddress[0]]);
                    form.setFieldsValue({
                      roleAddress: "uls",
                    });
                  }
                  if ([3, 7].includes(value)) {
                    setRoleAddressOpts([RoleAddress[1]]);
                    form.setFieldsValue({
                      roleAddress: "aimag",
                    });
                  }
                  if ([2, 4].includes(value)) {
                    setRoleAddressOpts([RoleAddress[2]]);
                    form.setFieldsValue({
                      roleAddress: "sum",
                    });
                  }
                }}
              >
                {roleList.result.map((role: any) => {
                  return <Option value={role.id}>{role.name}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="status" label={"Төлөв"}>
              <Select defaultValue="A" style={{ width: "100%" }}>
                <Select.Option value="A">Ажиллаж байгаа</Select.Option>
                <Select.Option value="D">Ажлаас гарсан</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="roleAddress" label={"Эрхийн хүрээ"}>
              <Select
                defaultValue="uls"
                style={{ width: "100%" }}
                options={roleAddressOpts}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <AddressItemSelect
              value={address.aimag}
              parent={1}
              title="Хамаарах / Аймаг"
              name="aimagId"
              onChange={(aimag) => changeAddress({ aimag, sum: "", bag: "" })}
            />
          </Col>
          <Col span={8}>
            <AddressItemSelect
              value={address.sum}
              parent={address.aimag}
              title="Хамаарах / Сум"
              name="sumId"
              onChange={(sum) => changeAddress({ ...address, sum, bag: "" })}
            />
          </Col>
          <Col span={8}>
            <Form.Item name="name" label={"Нэвтрэх нэр"}>
              <Input placeholder={"нэвтрэх нэр"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="password" label={"Нууц үг"}>
              <Input.Password placeholder={"нууц үг"} type={"password"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="confirmPassword" label={"Нууц үг давтах"}>
              <Input.Password
                placeholder={"нууц үг давтах"}
                type={"password"}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Button htmlType="submit" type="primary">
              Хадгалах
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
