import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Upload,
} from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as querystring from "querystring";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import {
  PARCEL_STATUS_REF,
  PARCEL_STATUS,
  PARCEL_STATUS_LIST,
} from "../../enums/parcel.enum";
import {
  CheckOutlined,
  CloseOutlined,
  InboxOutlined,
  PlusSquareOutlined,
  CloudDownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import config from "../../config";
import UserContext from "../../providers/UserContext";
import { USER_ROLE } from "../../enums/user.enum";

const SellerParcelList = () => {
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (user.role?.cd == USER_ROLE.CUSTOMER) {
      return true;
    }
    return false;
  }, [user]);

  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/parcel/remove/list", {
      ...content.filter,
      page: content.page,
    });
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content.filter, content.page]);

  const [parcelTransferModal, setParcelTransferModal] = useState<{
    visible: boolean;
  }>({
    visible: false,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const approve = async (id: string) => {
    const response = await NetworkApi.requestPost(`/api/parcel/request/${id}`, {
      status: PARCEL_STATUS.APPROVED,
      receiveDate: moment().format("YYYY-MM-DD"),
    });
    if (response.resultCode === 200) {
      loadContent();
      message.success("Хүсэлт амжилттай биелэгдлээ");
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  };

  const deny = async (id: string) => {
    const response = await NetworkApi.requestPost(`/api/parcel/request/${id}`, {
      status: PARCEL_STATUS.DENIED,
      receiveDate: moment().format("YYYY-MM-DD"),
    });
    if (response.resultCode === 200) {
      loadContent();
      message.success("Хүсэлт амжилттай биелэгдлээ");
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  };

  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);

  const columns = [
    {
      title: "Хүлээн авсан",
      children: [
        {
          title: "№",
          dataIndex: "id",
        },
        {
          title: "Хүлээн авсан огноо",
          dataIndex: "receiveDate",
        },
        {
          title: "Бодис, бордоо",
          dataIndex: "sale",
          render: (value: any) => `${value.chemical?.technicalName}`,
        },
        {
          title: "Савны тоо",
          dataIndex: "amount",
        },
        {
          title: "Хаанаас",
          dataIndex: "sale",
          render: (value: any) => `${value.buyerRd}`,
        },
        {
          title: "Хүлээн авагч",
          dataIndex: "sale",
          render: (value: any) => `${value?.seller.rd}-${value?.seller.name}`,
        },
        {
          title: "Баримт",
          dataIndex: "receiveFiles",
          render: (value: string) => {
            if (value?.length > 0) {
              const files = JSON.parse(value);
              return (
                <Space size={5}>
                  {files.map((item: string) => (
                    <Button
                      target="blank"
                      href={`${config.get("apiUrl")}/tmp/${item}`}
                      type="link"
                    >
                      <CloudDownloadOutlined />
                    </Button>
                  ))}
                </Space>
              );
            }
            return "-";
          },
        },
        {
          title: "Төлөв",
          dataIndex: "status",
          render: (value: string) => `${PARCEL_STATUS_REF[value]}`,
        },
        {
          title: "Хүлээн авах",
          dataIndex: "id",
          render: (id: string, row: any) => (
            <>
              {canEdit && row.status === PARCEL_STATUS.PENDING && (
                <Space>
                  <Popconfirm
                    title="Бодис, бордооны савыг хүлээн авахад итгэлтэй байна уу? Буцаах боломжгүй болохыг анхаарна уу"
                    onConfirm={() => approve(id)}
                    okText="Тийм"
                    cancelText="Үгүй"
                  >
                    <Button
                      type={"primary"}
                      size={"small"}
                      ghost
                      icon={<CheckOutlined />}
                    ></Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Бодис, бордооны савыг хүлээн авахаас татгалзахад итгэлтэй байна уу? Буцаах боломжгүй болохыг анхаарна уу"
                    onConfirm={() => deny(id)}
                    okText="Тийм"
                    cancelText="Үгүй"
                  >
                    <Button
                      type={"primary"}
                      size={"small"}
                      danger
                      ghost
                      icon={<CloseOutlined />}
                    ></Button>
                  </Popconfirm>
                </Space>
              )}
            </>
          ),
        },
      ],
    },
    {
      title: "Устгалд шилжүүлсэн",
      children: [
        {
          title: "Огноо",
          dataIndex: "removeDate",
        },
        {
          title: "Хүлээн авсан",
          dataIndex: "removeOrgRd",
          render: (value: string, row: any) =>
            `${row.removeOrgRd || ""} - ${row.removeOrgName || ""}`,
        },
        {
          title: "Баримт",
          dataIndex: "removeFiles",
          render: (value: string) => {
            if (value?.length > 0) {
              const files = JSON.parse(value);
              return (
                <Space size={5}>
                  {files.map((item: string) => (
                    <Button
                      target="blank"
                      href={`${config.get("apiUrl")}/tmp/${item}`}
                      type="link"
                    >
                      <CloudDownloadOutlined />
                    </Button>
                  ))}
                </Space>
              );
            }
            return "-";
          },
        },
      ],
    },
  ];
  return (
    <>
      <BreadMe title={["Сав устгал", "Жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Form
          style={{ width: "100%" }}
          onFinish={(data) => {
            let v = data;
            if (data.receiveDate) {
              v.receiveDate = data.receiveDate.format("YYYY/MM/DD");
            }
            if (data.removeDate) {
              v.removeDate = data.removeDate.format("YYYY/MM/DD");
            }
            changeContent({ ...content, filter: data, page: 1 });
          }}
          name="filter"
        >
          <Row>
            <Col span={24}>
              <Row gutter={12}>
                {user && [USER_ROLE.SPECIALIST].includes(user.role?.cd) && (
                  <Col span={4}>
                    <Form.Item name="receiver">
                      <Input placeholder={"Хүлээн авагчийн нэр, РД"} />
                    </Form.Item>
                  </Col>
                )}
                <Col span={4}>
                  <Form.Item name="receiveDate">
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Хүлээн авсан огноо"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="buyer">
                    <Input placeholder={"Шилжүүлэгчийн нэр, РД"} />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item name="status">
                    <Select allowClear placeholder="Төлөв">
                      {PARCEL_STATUS_LIST.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="remover">
                    <Input placeholder={"Устгасан АА нэр, РД"} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="removeDate">
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Устгасан огноо"
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Form.Item>
                    <Button type={"primary"} htmlType={"submit"}>
                      <SearchOutlined />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Row justify="end" style={{ marginTop: 15, marginBottom: 15 }}>
          <Button
            href={`${config.get(
              "apiUrl"
            )}/api/parcel/remove/list/download?${querystring.stringify(
              content.filter
            )}`}
            type="primary"
            target="blank"
          >
            Татах
          </Button>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: 15, marginBottom: 15 }}>
            <Row justify={"end"}>
              {canEdit && (
                <Button
                  disabled={selectedRows.length === 0}
                  onClick={() => setParcelTransferModal({ visible: true })}
                  type={"primary"}
                  icon={<PlusSquareOutlined />}
                >
                  Сав баглаа устгах
                </Button>
              )}
            </Row>
          </Col>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              rowSelection={
                canEdit
                  ? {
                      type: "checkbox",
                      selectedRowKeys,
                      onChange: (srks: React.Key[], srs: any[]) => {
                        setSelectedRowKeys(srks);
                        setSelectedRows(srs);
                      },
                      getCheckboxProps: (record: any) => ({
                        disabled: record.status !== PARCEL_STATUS.APPROVED,
                      }),
                    }
                  : undefined
              }
              bordered
              pagination={{
                current: content.page,
                total: content.count,
              }}
              onChange={(p) => {
                changeContent({
                  ...content,
                  page: p.current || 1,
                });
              }}
              rowKey={"id"}
              columns={columns}
              dataSource={content.result}
            />
            <ParcelTransferModal
              visible={parcelTransferModal.visible}
              selectedRows={selectedRows}
              onHide={() => {
                setParcelTransferModal({ visible: false });
              }}
              onSuccess={() => {
                setParcelTransferModal({ visible: false });
                loadContent();
              }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

const uploadFile = (file: any) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await NetworkApi.requestUploadFile(file.originFileObj);

      if (response.resultCode === 200) {
        resolve(response.result.name);
      } else {
        throw Error(`Файл хуулхад алдаа гарлаа.`);
      }
    } catch (e) {
      reject(e);
    }
  });

const uploadFiles = (files: any[]) =>
  new Promise((resolve, reject) => {
    const promises = files.map((file) => uploadFile(file));
    Promise.all(promises)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error));
  });

const parcelRemove = (values: any): Promise<boolean> =>
  new Promise(async (resolve) => {
    try {
      const response = await NetworkApi.requestPost(
        `/api/parcel/remove/${values.id}`,
        values
      );
      if (response.resultCode == 200) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (e) {
      resolve(false);
    }
  });

const parcelRemoves = (parcels: any[]): Promise<boolean[]> => {
  const promises = parcels.map((parcel) => parcelRemove(parcel));
  return Promise.all(promises);
};

const ParcelTransferModal = ({
  visible,
  selectedRows,
  onHide,
  onSuccess,
}: {
  visible: boolean;
  selectedRows: any[];
  onHide: any;
  onSuccess: any;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [localSelectedRows, setLocalSelectedRows] = useState<any[]>([]);
  useEffect(() => {
    setLocalSelectedRows(selectedRows);
  }, [selectedRows]);
  const onFinish = async (values: any) => {
    setLoading(true);
    const images = await uploadFiles(values.images);
    const responses = await parcelRemoves(
      localSelectedRows.map((row) => ({
        id: row.id,
        removeDate: moment(values.removeData).format("YYYY-MM-DD"),
        images,
        removeOrgRd: values.removeOrgRd,
        removeOrgName: values.removeOrgName,
      }))
    );
    const isAllSuccess = responses.every((item: boolean) => item === true);
    if (isAllSuccess) {
      message.success("Амжилттай устгалд шилжүүлэгдлээ");
      form.resetFields();
      onSuccess();
      setLoading(false);
      return;
    }

    const isSomeSuccess = responses.some((item: boolean) => item === true);
    if (isSomeSuccess) {
      setLocalSelectedRows(
        localSelectedRows.filter((item, index) => !responses[index])
      );
      message.error("Устгалд шилжүүлэх үед зарим саван дээр гарлаа");
      setLoading(false);
      return;
    }

    message.error("Устгалд шилжүүлэх үед алдаа гарлаа");
    setLoading(false);
  };
  const columns = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "Хүлээн авсан огноо",
      dataIndex: "receiveDate",
    },
    {
      title: "Бодис, бордоо",
      dataIndex: "sale",
      render: (value: any) => `${value.chemical?.technicalName}`,
    },
    {
      title: "Савны тоо",
      dataIndex: "amount",
    },
    {
      title: "Хаанаас",
      dataIndex: "sale",
      render: (value: any) => `${value.buyerRd}`,
    },
    {
      title: "Хүлээн авагч",
      dataIndex: "sale",
      render: (value: any) => `${value?.seller.rd}-${value?.seller.name}`,
    },
    {
      title: "Баримт",
      dataIndex: "receiveFiles",
      render: (value: string) => {
        if (value?.length > 0) {
          const files = JSON.parse(value);
          return (
            <Space size={5}>
              {files.map((item: string) => (
                <Button
                  target="blank"
                  href={`${config.get("apiUrl")}/tmp/${item}`}
                  type="link"
                >
                  <CloudDownloadOutlined />
                </Button>
              ))}
            </Space>
          );
        }
        return "-";
      },
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: string) => `${PARCEL_STATUS_REF[value]}`,
    },
  ];
  return (
    <Modal
      width={800}
      visible={visible}
      onCancel={() => {
        form.resetFields();
        onHide();
      }}
      title="Устгалд шилжүүлэх"
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="removeOrgRd"
          label="Устгалд хүлээн авсан хуулийн этгээдийн РД"
          rules={[{ required: true, message: "РД оруулна уу" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="removeOrgName"
          label="Устгалд хүлээн авсан хуулийн этгээдийн нэр"
          rules={[{ required: true, message: "Нэр оруулна уу" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={localSelectedRows}
            pagination={false}
          />
        </Form.Item>
        <Form.Item
          name="removeDate"
          label="Хүлээлгэн өгсөн огноо"
          rules={[{ required: true, message: "Хүлээлгэн өгсөн огноо уу" }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="images"
          valuePropName="fileList"
          getValueFromEvent={(e: any) => {
            return e.fileList;
          }}
          rules={[{ required: true, message: "Зураг оруулна уу" }]}
        >
          <Upload.Dragger
            name="file"
            multiple
            accept="image/*"
            customRequest={(value: any) => {
              setTimeout(() => {
                value.onSuccess({ status: "success" });
              }, 600);
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Хүлээн авсан баримт хавсаргах:</p>
          </Upload.Dragger>
        </Form.Item>
        <Divider />
        <Button loading={loading} type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};

export default SellerParcelList;
