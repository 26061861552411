import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Layout,
  Table,
  Space,
  Select,
  Row,
  Col,
  Button,
  Input,
  Tag,
  Form,
  Popconfirm,
  message,
} from "antd";

import {
  PlusSquareOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
  StopOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../../network/networkApi";
import BreadMe from "../../lib/Breadme";
import FarmlandUsageDetail from "./FarmlandUsageDetail";
import UserContext from "../../../providers/UserContext";
import FarmlandUsageAdd from "./FarmlandUsageAdd";
import AddressSelect from "../../lib/AddressSelect";
import { USER_ROLE } from "../../../enums/user.enum";

const FarmlandUsageList = () => {
  const { user } = useContext(UserContext);
  const [content, changeContent] = useState({
    loading: false,
    page: 1,
    pageSize: 10,
    result: [],
    count: 0,
  });
  const [filter, changeFilter] = useState<any>({
    status: "",
    posNo: "",
    identifier: "",
  });
  const [addEdit, setAddEdit] = useState({
    visible: false,
    id: null,
  });
  const [showDetail, setShowDetail] = useState({
    id: 0,
    visible: false,
  });
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet(
      "/api/farmland/usage/list",
      filter
    );
    changeContent({
      loading: false,
      page: response.result.page,
      pageSize: response.result.pageSize,
      count: response.result?.count,
      result: response.result?.rows,
    });
  }, [filter]);
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestGet(
      `/api/farmland/usage/delete/${data.id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  const cancelItem = useCallback(async (data) => {
    let response = await NetworkApi.requestGet(
      `/api/v2/farmland/usage/cancel/${data.id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай цуцлагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  const expireItem = useCallback(async (data) => {
    let response = await NetworkApi.requestGet(
      `/api/v2/farmland/usage/expire/${data.id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай бүртгэгдлээ");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const columns = [
    {
      title: "№",
      width: 60,
      ellipsis: true,
      render: (a: any, b: any, index: any) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "НТДугаар",
      render: (value: any) => <>{value.farmland?.posNo}</>,
    },
    {
      title: "Талбайн хэмжээ",
      dataIndex: "area",
      render: (value: number) => <>{value} га</>,
    },
    {
      title: "Байршил",
      render: (itm: any) => (
        <>
          {itm.farmland?.sum?.name} - {itm.farmland?.bag?.name}
        </>
      ),
    },
    {
      title: "Ашиглаж эхэлсэн огноо",
      dataIndex: "startDate",
    },
    {
      title: "Ашиглаж дуусах огноо",
      dataIndex: "endDate",
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      render: (type: any) => (type == "rent" ? "Түрээс" : "Эзэмшил"),
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: string) => TagCreat(value),
    },
    {
      title: "Үйлдэл",
      width: 220,
      render: (itm: any) => (
        <Space size={10}>
          {itm.status == "active" && user.role.cd == "CUSTOMER" ? (
            <>
              <Button
                onClick={() => setAddEdit({ visible: true, id: itm.id })}
                icon={<FileTextOutlined />}
                title={"Засах"}
                type={"primary"}
                ghost
                size={"small"}
              >
                Дэлгэрэнгүй
              </Button>
            </>
          ) : (
            <>
              {user.role.cd == "ADMIN" ? (
                <Button
                  onClick={() => setShowDetail({ visible: true, id: itm.id })}
                  icon={<EditOutlined />}
                  title={"Засах"}
                  type={"primary"}
                  ghost
                  size={"small"}
                >
                  Засах
                </Button>
              ) : null}
              {user.role.cd == "ADMIN" ? (
                <Popconfirm
                  title="Устгахдаа итгэйлтэй байна уу"
                  onConfirm={() => deleteItem(itm)}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    onClick={() => {}}
                    type={"primary"}
                    size={"small"}
                    danger
                    ghost
                    icon={<DeleteOutlined />}
                  >
                    Устгах
                  </Button>
                </Popconfirm>
              ) : null}
              {!["active", "cancelled", "expired"].includes(itm.status) &&
              user.role.cd == "CUSTOMER" ? (
                <Button
                  onClick={() => setShowDetail({ visible: true, id: itm.id })}
                  icon={<EditOutlined />}
                  title={"Засах"}
                  type={"primary"}
                  ghost
                  size={"small"}
                >
                  Засах
                </Button>
              ) : null}
              {!["active", "cancelled", "expired"].includes(itm.status) &&
              user.role.cd == "CUSTOMER" ? (
                <Popconfirm
                  title="Устгахдаа итгэлтэй байна уу?"
                  onConfirm={() => deleteItem(itm)}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    onClick={() => {}}
                    type={"primary"}
                    size={"small"}
                    danger
                    ghost
                    icon={<DeleteOutlined />}
                  >
                    Устгах
                  </Button>
                </Popconfirm>
              ) : null}
              <Button
                onClick={() => setAddEdit({ visible: true, id: itm.id })}
                icon={<FileTextOutlined />}
                title={"Засах"}
                type={"primary"}
                ghost
                size={"small"}
              >
                Дэлгэрэнгүй
              </Button>
              {["active"].includes(itm.status) &&
              [
                USER_ROLE.ADMIN,
                USER_ROLE.PROVINCE_AGRONOMIST,
                USER_ROLE.VILLAGE_AGRONOMIST,
              ].includes(user.role.cd) ? (
                <Popconfirm
                  title="Цуцлахдаа итгэлтэй байна уу?"
                  onConfirm={() => cancelItem(itm)}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    style={{
                      color: "#c41d7f",
                      background: "#fff0f6",
                      borderColor: "#ffadd2",
                    }}
                    onClick={() => {}}
                    type={"primary"}
                    size={"small"}
                    ghost
                    icon={<StopOutlined />}
                  >
                    Цуцлах
                  </Button>
                </Popconfirm>
              ) : null}
              {["active"].includes(itm.status) &&
              [
                USER_ROLE.ADMIN,
                USER_ROLE.PROVINCE_AGRONOMIST,
                USER_ROLE.VILLAGE_AGRONOMIST,
              ].includes(user.role.cd) ? (
                <Popconfirm
                  title="Хугацаа дууссаныг бүртгэхдээ итгэлтэй байна уу?"
                  onConfirm={() => expireItem(itm)}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    style={{
                      color: "#531dab",
                      background: "#f9f0ff",
                      borderColor: "#d3adf7",
                    }}
                    onClick={() => {}}
                    type={"primary"}
                    size={"small"}
                    ghost
                    icon={<IssuesCloseOutlined />}
                  >
                    Хугацаа дууссан
                  </Button>
                </Popconfirm>
              ) : null}
            </>
          )}
        </Space>
      ),
    },
  ];

  const TagCreat = (cs: string) => {
    switch (cs) {
      case "created":
        return <Tag color={"processing"}>Үүсгэсэн</Tag>;
      case "pending":
        return <Tag color={"default"}>Хүлээгдэж буй</Tag>;
      case "rejected":
        return <Tag color={"error"}>Татгалзсан</Tag>;
      case "active":
        return <Tag color={"success"}>Зөвшөөрсөн</Tag>;
      case "deleted":
        return <Tag color={"warning"}>Устгасан</Tag>;
      case "cancelled":
        return <Tag color={"magenta"}>Цуцалсан</Tag>;
      case "expired":
        return <Tag color={"purple"}>Хугацаа дууссан</Tag>;
      default:
        return <Tag color={"default"}>Хүлээгдэж буй</Tag>;
    }
  };

  const { defaultAimag, defaultSum } = useMemo(() => {
    let defaultAimag = "";
    let defaultSum = "";
    if (
      user.role?.cd == "PROVINCE_AGRONOMIST" ||
      user.role?.cd == "VILLAGE_AGRONOMIST"
    ) {
      defaultAimag = user.aimagId;
    }
    if (user.role?.cd == "VILLAGE_AGRONOMIST") {
      defaultSum = user.sumId;
    }
    console.log(defaultAimag);
    return { defaultAimag, defaultSum };
  }, [user]);

  return (
    <>
      <BreadMe
        title={["Зөвшөөрөл", "Тариалангийн талбайн зөвшөөрлийн хүсэлт"]}
      />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Space size={40}>
              <Form layout="inline" onFinish={changeFilter}>
                <Form.Item name="status">
                  <Select defaultValue={""} style={{ minWidth: 160 }}>
                    <Select.Option value="">Бүгд</Select.Option>
                    <Select.Option value="created">
                      <Tag color={"processing"}>Үүсгэсэн</Tag>
                    </Select.Option>
                    <Select.Option value="pending">
                      <Tag color={"default"}>Хүлээгдэж буй</Tag>
                    </Select.Option>
                    <Select.Option value="rejected">
                      <Tag color={"error"}>Татгалзсан</Tag>
                    </Select.Option>
                    <Select.Option value="active">
                      <Tag color={"success"}>Зөвшөөрсөн</Tag>
                    </Select.Option>
                    <Select.Option value="deleted">
                      <Tag color={"warning"}>Устгасан</Tag>
                    </Select.Option>
                    <Select.Option value="cancelled">
                      <Tag color={"magenta"}>Цуцалсан</Tag>
                    </Select.Option>
                    <Select.Option value="expired">
                      <Tag color={"purple"}>Хугацаа дууссан</Tag>
                    </Select.Option>
                  </Select>
                </Form.Item>
                <AddressSelect
                  defaultAimag={defaultAimag}
                  defaultSum={defaultSum}
                  hideLabel
                  ignoreBag
                />
                <Form.Item name="posNo">
                  <Input placeholder={"НТДугаар"} />
                </Form.Item>
                <Form.Item name="identifier">
                  <Input placeholder={"Регистр"} />
                </Form.Item>
                <Form.Item name="type">
                  <Select defaultValue={""} style={{ minWidth: 120 }}>
                    <Select.Option value="">Бүгд</Select.Option>
                    <Select.Option value="owner">
                      <Tag color={"default"}>Эзэмшлийн</Tag>
                    </Select.Option>
                    <Select.Option value="rent">
                      <Tag color={"default"}>Түрээсийн</Tag>
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Button type={"primary"} htmlType="submit">
                  <SearchOutlined />
                </Button>
              </Form>
            </Space>
          </Col>
          <Col>
            {user.role.cd == "CUSTOMER" && (
              <Button
                onClick={() => setShowDetail({ visible: true, id: 0 })}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Талбайн хүсэлт илгээх
              </Button>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              style={{ width: "100%" }}
              columns={columns}
              dataSource={content.result}
              rowKey={"id"}
              onChange={(p) => {
                changeFilter({
                  ...filter,
                  page: p.current || 1,
                  pageSize: p.pageSize || 10,
                });
              }}
              pagination={{
                current: content.page,
                pageSize: content.pageSize,
                total: content.count,
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize);
                },
              }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <FarmlandUsageAdd
        id={showDetail.id}
        onHide={() => {
          setShowDetail({ visible: false, id: 0 });
        }}
        visible={showDetail.visible}
        onSuccess={() => {
          setShowDetail({ visible: false, id: 0 });
          loadContent();
        }}
      />
      <FarmlandUsageDetail
        visible={addEdit.visible}
        id={addEdit.id}
        hide={() => {
          loadContent();
          setAddEdit({ visible: false, id: null });
        }}
      />
    </>
  );
};
export default FarmlandUsageList;
