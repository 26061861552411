import React, { useCallback, useContext, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";
import useIncentiveAvailableItems from "../../hooks/useIncentiveAvailableItems";
import UserContext from "../../providers/UserContext";
import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";
import { useHistory, useParams } from "react-router-dom";
import moment, { Moment } from "moment";
import { isEmpty, omit } from "lodash";
import { INCENTIVE_STATUS } from "../../enums/incentive.enum";
import { AmountInput } from "./atoms/AmountInput";
import { formatNumberWithCommaAndPoint } from "../../helpers/number.helper";

const IncentiveEdit = () => {
  const history = useHistory();
  const { id } = useParams<{ id: any }>();
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const [submit, changeSubmit] = useState<{
    loading: boolean;
  }>({
    loading: false,
  });
  const [incentive, changeIncentive] = useState<any>({});
  const [items, , changeItemsFilter] = useIncentiveAvailableItems();
  const [localItems, changeLocalItems] = useState<any[]>([]);

  // ebarimt
  const [loadingEB, changeLoadingEB] = useState<boolean>(false);
  const [itemsEB, changeItemsEB] = useState<any[]>([]);

  // bank list
  const [loadingBank, changeLoadingBank] = useState<boolean>(false);
  const [bankList, changeBankList] = useState<any[]>([]);

  const [totalEB, changeTotalEB] = useState<number>(0);
  const [totalOC, changeTotalOC] = useState<number>(0);
  const [totalAmount, changeTotalAmount] = useState<number>(0);
  const [totalWheat, changeTotalWheat] = useState<number>(0);
  const [totalVegetable, changeTotalVegetable] = useState<number>(0);

  const [selectedDate, changeSelectedDate] = useState<Moment | null>(null);
  const [selectedBank, changeSelectedBank] = useState<any | undefined>();
  const [accountNumber, changeAccountNumber] = useState<string | undefined>();
  const [showRequestMessage, changeShowRequestMessage] =
    useState<boolean>(false);

  const [loadingCamName, changeLoadingCamName] = useState<boolean>(false);
  const [camName, changeCamName] = useState<any | undefined>();
  const [camStatus, changeCamStatus] = useState<boolean>(false);

  const loadBankList = useCallback(async () => {
    changeLoadingBank(true);
    const response = await NetworkApi.requestGet("/api/bank/list");
    if (response.resultCode === 200) {
      changeBankList(response.result ?? []);
    } else {
      changeBankList([]);
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
    changeLoadingBank(false);
  }, []);

  const loadIncentive = useCallback(async () => {
    const response = await NetworkApi.requestGet(`/api/incentive/detail/${id}`);
    if (response.resultCode === 200) {
      form.setFieldsValue({
        targetDate: moment(response.result.targetDate),
      });
      changeSelectedDate(moment(response.result.targetDate));
      changeIncentive(response.result);
      changeSelectedBank({
        code: response.result.bankId,
        name: response.result.bankName,
        bic: response.result.bankBic,
      });
      changeAccountNumber(response.result.accountNumber);
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [id]);

  useEffect(() => {
    if (user && id) {
      loadIncentive();
    }
  }, [id]);

  useEffect(() => {
    if (user.confirm !== "yes") {
      Modal.warning({
        title: "Анхааруулга",
        content:
          "Таны тариаланчийн мэдээлэл баталгаажаагүй байна. Та хариуцсан сумын ХААТ-ын мэргэжилтэнд хандаж баталгаажуулалт хийлгэнэ үү",
        okText: "За",
        onOk: () => {
          history.goBack();
        },
        onCancel: () => {
          history.goBack();
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (user.confirm === "yes") {
      loadBankList();
    }
  }, [user]);

  useEffect(() => {
    const list = [];
    for (const item of items.result) {
      const itemEB = itemsEB.find(
        (itm) => itm.productCode === item.product_code
      );
      let ebarimt_amount = 0;
      let ebarimt_count = 0;
      let prdBarCode = "";
      let prdProductName = "";
      if (itemEB) {
        ebarimt_amount = itemEB.totalAmount;
        ebarimt_count = itemEB.totalCount;
        prdBarCode = itemEB.prdBarCode;
        prdProductName = itemEB.prdProductName;
      }
      const editItem = incentive.incentiveItems.find(
        (itm: any) => itm.productCode === item.product_code
      );
      let amount = Math.min(
        (item.certificate_amount ?? 0) - (item.incentive_amount ?? 0),
        ebarimt_count
      );
      if (editItem) {
        amount = editItem.amount;
      }
      list.push({
        ...item,
        ebarimt_amount,
        ebarimt_count,
        amount,
        prdProductName,
        prdBarCode,
        ebarimt: itemEB?.ebarimt || [],
      });
    }
    changeLocalItems(list);
  }, [items, itemsEB]);

  useEffect(() => {
    if (localItems.length > 0) {
      let totaloc = 0;
      let totalam = 0;
      let totalw = 0;
      let totalv = 0;
      for (const itm of localItems) {
        totaloc += +itm.certificate_amount ?? 0 - +itm.incentive_amount ?? 0;
        totalam += +itm.amount ?? 0;
        if (itm.product_code === "0306007") {
          totalw += +itm.amount ?? 0;
        } else {
          totalv += +itm.amount ?? 0;
        }
      }
      changeTotalOC(totaloc);
      changeTotalAmount(totalam);
      changeTotalWheat(totalw);
      changeTotalVegetable(totalv);
    } else {
      changeTotalOC(0);
      changeTotalAmount(0);
      changeTotalWheat(0);
      changeTotalVegetable(0);
    }
  }, [localItems]);

  useEffect(() => {
    if (itemsEB.length > 0) {
      let total = 0;
      for (const itm of itemsEB) {
        total += +itm.totalCount ?? 0;
      }
      changeTotalEB(total);
    } else {
      changeTotalEB(0);
    }
  }, [itemsEB]);

  useEffect(() => {
    if (
      user &&
      totalAmount &&
      totalEB > 0 &&
      selectedBank &&
      accountNumber &&
      camStatus
    ) {
      changeShowRequestMessage(true);
    } else {
      changeShowRequestMessage(false);
    }
  }, [
    user,
    totalEB,
    totalAmount,
    camStatus,
    selectedBank,
    accountNumber,
    totalVegetable,
    totalWheat,
  ]);

  const onChangeAmount = (index: number, amount: any) => {
    const list = [...localItems];
    list[index].amount = amount;
    changeLocalItems(list);
  };

  const itemsColumns = [
    {
      title: "№",
      dataIndex: "farmland_id",
      render: (v: any, r: any, index: number) => `${index + 1}`,
    },
    {
      title: "Таримал",
      dataIndex: "plant_name",
    },
    {
      title: "ГҮГ авсан тоо хэмжээ",
      dataIndex: "certificate_amount",
      render: (value: any) => `${formatNumberWithCommaAndPoint(value)} кг`,
    },
    {
      title: "Урамшуулалд тооцуулсан тоо хэмжээ",
      dataIndex: "incentive_amount",
      render: (value: any) => `${formatNumberWithCommaAndPoint(value)} кг`,
    },
    {
      title: "ГҮГ-ний боломжит үлдэгдэл",
      render: (item: any) =>
        `${formatNumberWithCommaAndPoint(
          (item.certificate_amount ?? 0) - (item.incentive_amount ?? 0)
        )} кг`,
    },
    {
      title: "Ибаримт бичигдсэн тоо хэмжээ",
      dataIndex: "ebarimt_count",
      render: (ebarimt_count: number) =>
        `${formatNumberWithCommaAndPoint(ebarimt_count)} кг`,
    },
    {
      title: "Урамшуулалд хамрагдах ГҮГ дүн оруулах ",
      dataIndex: "amount",
      render: (value: any, record: any, index: number) => (
        <AmountInput
          amount={value}
          max={Math.min(
            record.certificate_amount - record.incentive_amount,
            record.ebarimt_count
          )}
          onChange={(v: number) => onChangeAmount(index, v)}
        />
      ),
    },
  ];

  const ebarimColumns = [
    {
      title: "№",
      dataIndex: "id",
      render: (v: any, r: any, index: number) => `${index + 1}`,
    },
    {
      title: "Урамшуулалд хамрагдах бүтээгдэхүүний код",
      dataIndex: "productCode",
    },
    {
      title: "Ибаримтаар баталгаажсан бүтээгдэхүүн",
      dataIndex: "prdProductName",
    },
    {
      title: "Тоо хэмжээ /кг/",
      dataIndex: "totalCount",
    },
  ];

  const loadItemsFromEB = useCallback(
    async (values: any) => {
      changeLoadingEB(true);
      const response = await NetworkApi.requestPost(
        `/api/incentive/ebarimt/${id}`,
        values
      );
      if (response.resultCode === 200) {
        changeItemsEB(response.result ?? []);
      } else {
        Modal.confirm({
          title: "Анхааруулга",
          content: "Ибаримтын системээс хариу ирсэнгүй",
          okText: "Ойлголоо",
          okCancel: false,
        });
        changeItemsEB([]);
      }
      changeLoadingEB(false);
    },
    [id]
  );

  const onCheckEB = () => {
    if (!selectedDate) {
      return;
    }
    loadItemsFromEB({
      rd: user.rd,
      year: selectedDate.format("YYYY"),
      month: selectedDate.format("MM"),
    });
  };

  useEffect(() => {
    if (selectedDate && id) {
      changeItemsFilter({
        year: selectedDate?.format("YYYY"),
        month: selectedDate?.format("MM"),
        incentiveId: id,
      });
      onCheckEB();
    } else {
      changeItemsEB([]);
      changeLocalItems([]);
    }
  }, [selectedDate, id]);

  const sendIncentive = useCallback(
    async (data: any) => {
      if (!submit.loading) {
        changeSubmit({
          loading: true,
        });
        data["id"] = id;
        data.targetDate = data.targetDate.startOf("M").format("YYYY-MM-DD");
        data.bankName = selectedBank.name;
        data.bankId = selectedBank.code;
        data.bankBic = selectedBank.bic;
        data.accountNumber = accountNumber;
        data.camName = camName;
        const incentiveItems = localItems
          .filter((item: any) => +item.amount > 0)
          .map((item) => ({
            ...omit(item, ["certificate_amount"]),
            certificateAmount: item.certificate_amount,
            ebTotalAmount: item.ebarimt_amount,
            ebTotalCount: item.ebarimt_count,
          }));
        data["incentiveItems"] = incentiveItems;
        let result = await NetworkApi.requestPost(
          "/api/incentive/update",
          data
        );
        if (result.resultCode == 200) history.push("/incentive");
        else {
          message.error(result.message || "Алдаа гарлаа, дахин оролдоно уу!");
        }
      }
      changeSubmit({
        loading: false,
      });
    },
    [id, submit, selectedBank, accountNumber, localItems, camName]
  );

  const checkCamName = useCallback(async () => {
    changeLoadingCamName(true);
    changeCamStatus(false);
    let result = await NetworkApi.requestPost("/api/camname/check", {
      bankBic: selectedBank.bic,
      accountNumber: accountNumber,
      accountName: user.lastName + " " + user.firstName,
    });
    if (
      result.resultCode == 200 &&
      result.result.response !== "CAM NOT REGISTERED"
    ) {
      changeCamName(result.result.camname);
      changeCamStatus(true);
    } else {
      Modal.confirm({
        title: "Анхааруулга",
        content: "Буруу байна, дахин мэдээллээ шалгаад оруулна уу!",
        okText: "Ойлголоо",
        okCancel: false,
      });
    }
    changeLoadingCamName(false);
  }, [selectedBank, accountNumber, user]);

  const onFinish = (data: any) => {
    if (submit.loading || loadingEB || items.loading) {
      message.error("Мэдээлэл татагдаж байна. Түр хүлээнэ үү");
      return;
    }

    if (!camStatus) {
      Modal.warning({
        title: "Анхааруулга",
        content: "Банкны мэдээлэл шалгана уу!",
        okText: "За",
      });
    }

    Modal.confirm({
      title: "Анхааруулга",
      content:
        "Сонгосон сард “Хүнсний ногоо” болон “Улаанбуудай”-н төрлөөр тус бүр нэг хүсэлтийг хүлээж авах тул та тухайн сарын хүсэлтээ нягтлаад нэг удаа илгээнэ үү. Хүсэлт илгээгдсэнээр дахин засах боломжгүй болно. ",
      onOk() {
        sendIncentive(data);
      },
      okText: "Ойлголоо",
      cancelText: "Цуцлах",
    });
  };

  return (
    <>
      <BreadMe title={["Урамшууллын хүсэлт", "Урамшууллын хүсэлт илгээх"]} />

      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form
              wrapperCol={{ span: 14 }}
              labelCol={{ span: 8 }}
              onFinish={(data) => onFinish(data)}
              name="basic"
              form={form}
            >
              <Form.Item
                label="Урамшуулалд хамрагдах сар"
                name="targetDate"
                rules={[
                  {
                    required: true,
                    message: "Урамшуулалд хамрагдах сар сонгоно уу",
                  },
                ]}
                tooltip="Урамшуулалд хамрагдах сарын хүсэлтийг дараа сарын 10-ны дотор ирүүлээгүй тариалан эрхлэгчийн мөнгөн урамшууллыг нөхөн олгохгүй болохыг анхаарна уу. Журам харах: линк"
              >
                <DatePicker
                  picker="month"
                  disabled
                  onChange={(value) => {
                    changeSelectedDate(value);
                  }}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 14, offset: 8 }}>
                <Row justify="start">
                  <Button
                    loading={loadingEB}
                    icon={<SearchOutlined />}
                    type="primary"
                    style={{ borderRadius: 10 }}
                    onClick={() => onCheckEB()}
                  >
                    Ибаримт лавлах
                  </Button>
                </Row>
              </Form.Item>
              <Row style={{ marginTop: 15, marginBottom: 15 }}>
                <Col span={24}>
                  <Table
                    rowKey="id"
                    loading={loadingEB}
                    columns={ebarimColumns}
                    dataSource={itemsEB ?? []}
                    pagination={false}
                    scroll={{ x: true }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 15, marginBottom: 15 }}>
                <Col span={24}>
                  <Table
                    loading={items.loading}
                    className="plant-table"
                    rowKey="id"
                    columns={itemsColumns}
                    dataSource={localItems ?? []}
                    pagination={false}
                    scroll={{ x: true }}
                  />
                </Col>
              </Row>
              <Form.Item
                label="Банк сонгох"
                name="bankId"
                rules={[
                  {
                    required: true,
                    message: "Банк сонгоно уу",
                  },
                ]}
              >
                <Select
                  loading={loadingBank}
                  placeholder="Банк сонгоно уу"
                  onChange={(v) => {
                    if (v) {
                      const bank = bankList.find(
                        (item) => `${item.code}` === `${v}`
                      );
                      changeSelectedBank(bank);
                    } else {
                      changeSelectedBank(undefined);
                    }
                  }}
                >
                  {bankList.map((t: any) => (
                    <Select.Option key={`bank-${t.code}`} value={t.code}>
                      {t.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Хүсэлт гаргагчийн нэр дээрх дансны дугаар"
                name="accountNumber"
                rules={[
                  {
                    required: true,
                    message:
                      "Хүсэлт гаргагчийн нэр дээрх дансны дугаар оруулна уу",
                  },
                  {
                    validator: (_rule, value) => {
                      if (isEmpty(value)) Promise.resolve();
                      if (!/^[0-9]*$/.test(value))
                        return Promise.reject(
                          new Error("Зөвхөн тоо оруулна уу")
                        );
                      if (!selectedBank) return Promise.resolve();
                      if (9 > value.length)
                        return Promise.reject(
                          new Error(`Дансны дугаарын урт багадаа 9 байх ёстой`)
                        );
                      if (12 < value.length)
                        return Promise.reject(
                          new Error(`Дансны дугаарын урт ихдээ 12 байх ёстой`)
                        );
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Хүсэлт гаргагчийн нэр дээрх дансны дугаар оруулна уу"
                  onChange={(e) => {
                    changeAccountNumber(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 14, offset: 8 }}>
                <Row justify="start">
                  <Button
                    loading={loadingCamName}
                    type="primary"
                    style={{ borderRadius: 10 }}
                    disabled={!selectedBank || !accountNumber}
                    onClick={() => checkCamName()}
                  >
                    Шалгах
                  </Button>
                </Row>
              </Form.Item>
              <Divider />
              <Form.Item label="Хүсэлт гаргагч" style={{ marginBottom: 10 }}>
                {`${user?.lastName} овогтой ${user?.firstName} (${user?.rd})`}
              </Form.Item>
              <Form.Item
                label="Хүлээн авах дансны мэдээлэл"
                style={{ marginBottom: 10 }}
              >
                {`${selectedBank?.name ?? ""} - ${accountNumber ?? ""}`}
              </Form.Item>
              <Form.Item
                label="Урамшуулалд хамрагдах сар"
                style={{ marginBottom: 10 }}
              >
                {selectedDate ? `${selectedDate.format("M")} сар` : ""}
              </Form.Item>
              <Form.Item label="Гарал үүслийн дүн" style={{ marginBottom: 10 }}>
                {`${formatNumberWithCommaAndPoint(
                  totalAmount > 0 ? totalAmount / 1000 : totalAmount
                )} тн`}
              </Form.Item>
              <Form.Item label="Ибаримтын дүн" style={{ marginBottom: 10 }}>
                {`${formatNumberWithCommaAndPoint(
                  totalEB > 0 ? totalEB / 1000 : totalEB
                )} тн`}
              </Form.Item>
              <Form.Item
                label="Урамшуулалд хамрагдах нийт хэмжээ"
                style={{ marginBottom: 10 }}
              >
                {`${
                  totalEB < totalAmount
                    ? formatNumberWithCommaAndPoint(
                        totalEB > 0 ? totalEB / 1000 : totalAmount
                      )
                    : formatNumberWithCommaAndPoint(
                        totalAmount > 0 ? totalAmount / 1000 : totalAmount
                      )
                } тн`}
              </Form.Item>
              <Form.Item label="Хүсэлт" style={{ marginBottom: 10 }}>
                {showRequestMessage && (
                  <div style={{ textAlign: "justify" }}>
                    Иргэн/ Хуулийн этгээд{" "}
                    <span style={{ color: "cornflowerblue" }}>{``}</span> нь
                    Хүнс, хөдөө аж ахуй, хөнгөн үйлдвэрийн сайд, Сангийн сайдын
                    2022 оны 07 дугаар сарын 06-ны өдрийн А/218/А/146 дугаар
                    хамтарсан тушаалын хавсралт “ТАРИАЛАНГИЙН ҮЙЛДВЭРЛЭЛ ЭРХЭЛЖ
                    БАЙГАА ИРГЭН, ХУУЛИЙН ЭТГЭЭДИЙН ҮЙЛДВЭРЛЭСЭН БҮТЭЭГДЭХҮҮНД
                    МӨНГӨН УРАМШУУЛАЛ ОЛГОХ, МӨНГӨН БУС ДЭМЖЛЭГ ҮЗҮҮЛЭХ
                    ЖУРАМ”-ын дагуу хүнсний ногоо:{" "}
                    {formatNumberWithCommaAndPoint(totalVegetable / 1000)} тн,
                    улаанбуудайн:{" "}
                    {formatNumberWithCommaAndPoint(totalWheat / 1000)} тн{" "}
                    <span style={{ color: "cornflowerblue" }}>
                      нийт {formatNumberWithCommaAndPoint(totalAmount / 1000)}{" "}
                      тн
                    </span>{" "}
                    бүтээгдэхүүнд урамшуулалд хамрагдах хүсэлтэй байгаа тул{" "}
                    <span style={{ color: "cornflowerblue" }}>
                      {selectedBank?.name}ны {accountNumber}
                    </span>{" "}
                    тоот дансанд мөнгөн урамшуулал олгож өгнө үү.
                  </div>
                )}
              </Form.Item>
              <Form.Item
                noStyle
                initialValue={INCENTIVE_STATUS.REQUESTED}
                name="status"
              >
                <Input hidden />
              </Form.Item>
              <Row justify="center" gutter={16}>
                <Col>
                  <Button
                    type="default"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Цуцлах
                  </Button>
                </Col>
                <Col>
                  <Button
                    loading={submit.loading}
                    type="primary"
                    htmlType="submit"
                  >
                    Хүсэлт илгээх
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export default IncentiveEdit;
