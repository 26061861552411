import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Layout,
    message,
    Modal,
    PageHeader,
    Row,
    Select,
    Switch,
    Upload
} from "antd"
import React, {useCallback, useContext, useEffect, useState} from "react"
import useFarmlandDetail from "../../hooks/useFarmlandDetail"
import NetworkApi from "../../network/networkApi"
import UserContext from "../../providers/UserContext"
import moment from 'moment'
import config from "../../config";
import {
    PlusOutlined
} from "@ant-design/icons";
import AddressSelect from "../lib/AddressSelect";

const Option = Select.Option;
interface Props{
    id:number
    visible:boolean
    onHide:any
}
const FarmlandEdit = ({visible,id,onHide}:Props) => {
    const [form] = Form.useForm();
    const { user } = useContext(UserContext)
    const [hasId,setHasId] = useState(true)
    const [xyrStatus,setXyrStatus] = useState("")
    const [farmlandData, setFarmlandData] = useState<any>({})
    const [farmlandContent,loadFarmlandContent,changeId] = useFarmlandDetail()
    const [posNo, setPosNo] = useState<string | undefined>()
    const [regnum, setRegNum] = useState<string | undefined>()
    const [hasInitialDate, setHasInitialDate] = useState<boolean>(false);
    const [disabledSearchButton, setDisabledSearchButton] = useState(true)
    const [findingFarmland, setFindingFarmland] = useState(false)
    const handleSubmit = useCallback(async (data: any) => {
        data.initialDate = data.initialDate.format('YYYY-MM-DD');
        data.id = id
        if (data.images?.length > 0) {
            let images = data.images.map(
                (item: any) => item.response.result.name
            );
            data.files = images[0];
        }
        data = {
            ...data,
            aimagId: data.aimagId,
            sumId: data.sumId,
            bagId: data.bagId,
            xyrStatus: xyrStatus,
        }
        let result = await NetworkApi.requestPost('/api/farmland/update', data);
        if (result.resultCode == 200){
            message.success("Амжилттай засагдлаа")
            onHide()
        }
        else {
            message.error(result.message || 'system error')
        }
    }, [id, farmlandData, xyrStatus]);
    useEffect(() => {
        if (form && user.role.cd == 'VILLAGE_AGRONOMIST') {
            form.setFieldsValue({ aimagId: user.aimagId, sumId: user.sumId })
        }
    }, [user, form])
    useEffect(()=>{
        if(visible){
            changeId(id)            
        }else{
            changeId(null)
        }
    },[visible,id])
    const [currentState, changeState] = useState('');
    useEffect(()=>{
        if (id!= null && id!= undefined){
            if(!farmlandContent.loading&&farmlandContent.resultCode==200&&farmlandContent.farmland.id==id){
                changeState(farmlandContent.farmland.type)
                form.setFieldsValue({...farmlandContent.farmland,initialDate:moment(farmlandContent.farmland.initialDate)})
                setPosNo(farmlandContent.farmland.posNo)
                setXyrStatus(farmlandContent.farmland.xyrStatus)
            }
        }
    },[farmlandContent,id])

    const changeXyrStatus = ((status: string)=>{
        setXyrStatus(status);
    })

    const findFarmlandByPosNo = useCallback(async () => {
        setFindingFarmland(true);
        const response = await NetworkApi.requestPost("/api/farmland/find", {
            posNo,
            farmlandId: id,
            regnum: regnum,
        });
        if (response.resultCode === 200) {
            setFarmlandData(response.result);
            setHasInitialDate(response.result.initialDate ? true : false);
            form.setFieldsValue({
                area: response.result?.area,
                aimagId: response.result?.aimag?.id,
                sumId: response.result?.sum?.id,
                bagId: response.result?.bag?.id,
                ownerId: response.result?.regnum,
                position: response.result?.position,
                initialDate: response.result.initialDate
                    ? moment(response.result.initialDate)
                    : undefined,
                type: response.result?.type,
                useType: response.result?.useType,
            });
            setXyrStatus("approved")
        } else {
            message.error(response?.message || "Алдаа гарлаа, дахин оролдоно уу!");
        }
        setFindingFarmland(false);
    }, [posNo, regnum, form]);

    useEffect(() => {
        if (hasId && `${posNo ?? ""}`.length > 0 && `${regnum ?? ""}`.length > 0) {
            setDisabledSearchButton(false);
        } else {
            setDisabledSearchButton(true);
        }
    }, [hasId, posNo, regnum]);
    return (
        <Modal width={700} visible={visible} onCancel={onHide} footer={[]}>
            <PageHeader title="Хаяг" subTitle={`${farmlandContent.farmland?.aimag?.name}-${farmlandContent.farmland?.sum?.name}-${farmlandContent.farmland?.bag?.name}`}/>
            <Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
                <Row gutter={20}>
                    <Col span={24} >

                        <Form
                            onFinish={(data) =>
                                handleSubmit(data)
                            }
                            wrapperCol={{ span: 16 }}
                            labelCol={{ span: 8 }}
                            name="basic"
                            form={form}
                        >
                            <Form.Item
                                label="Талбайн хэмжээ/га"
                                name="area"

                                rules={[{ required: true, message: 'Талбайн хэмжээ оруулна уу!' }]}
                            >
                                <InputNumber style={{ width: '100%' }} parser={value => value?.replace(',', '.') ||''} />
                            </Form.Item>
                            
                            <Form.Item label=".">
                                <Switch checkedChildren="дугаартай" unCheckedChildren="дугааргүй" checked={hasId} onChange={e=>setHasId(e)} />
                            </Form.Item>
                            {
                                hasId && (
                                    <Form.Item
                                    label="Нэгж талбарын дугаар"
                                    name="posNo"
                                    rules={[{ required: true, message: 'Нэгж талбарын дугаар оруулна уу!' }]}
                                >
                                    <Input onChange={(e) => setPosNo(e.target.value)} />
                                </Form.Item>
                                )
                            }
                            {
                                hasId && xyrStatus!="approved" && posNo?.substring(0,2)!="PL" && (
                                    <>
                                        <Form.Item
                                            label="Эзэмшигчийн РД"
                                            name="regnum"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Эзэмшигчийн регистрийн дугаар оруулна уу!",
                                                },
                                            ]}
                                        >
                                            <Input onChange={(e) => setRegNum(e.target.value)} />
                                        </Form.Item>
                                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                            <Row justify="end">
                                                <Button
                                                    disabled={disabledSearchButton}
                                                    type="primary"
                                                    loading={findingFarmland}
                                                    onClick={findFarmlandByPosNo}
                                                >
                                                    Хайх
                                                </Button>
                                            </Row>
                                        </Form.Item>
                                    </>
                                )
                            }
                            <Form.Item
                                label="Талбайн байршил"
                                name="position"
                                rules={[{ message: 'Талбайн байршил сонгоно уу!' }]}
                            >
                                <Input />
                            </Form.Item>
                            <AddressSelect
                                defaultAimag={
                                    farmlandContent.farmland?.aimagId
                                }
                                defaultSum={
                                    farmlandContent.farmland?.sumId
                                }
                                defaultBag={farmlandContent.farmland?.bagId}
                                rule={true}
                                enableSelect={user.role.cd=="ADMIN"}
                            />
                            <Form.Item
                                label="Ашиглагдаж эхэлсэн огноо"
                                name="initialDate"
                                rules={[{ required: true, message: 'Огноо оруулна уу!' }]}
                            >
                                <DatePicker placeholder={'Огноо'} />
                            </Form.Item>
                            <Form.Item
                                label="Өмчийн хэлбэр"
                                name="type"
                                initialValue="state"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Select onChange={(s: string) => {
                                    changeState(s)
                                }}>
                                    <Option value="private">Хувийн</Option>
                                    <Option value="state">Төрийн</Option>
                                </Select>
                            </Form.Item>
                            {
                                currentState == "private" ? (
                                    <Form.Item name="ownerId" label="Эзэмшигчийн РД" >
                                        <Input disabled={xyrStatus=='approved'&&user?.role?.cd!='ADMIN'}/>
                                    </Form.Item>
                                ) : null
                            }
                            {
                                posNo?.substring(0,2)=='PL' && xyrStatus!="approved" && (
                                    <>
                                        <Form.Item
                                            label="Зураг"
                                            name="images"
                                            valuePropName="fileList"
                                            getValueFromEvent={(e: any) => {
                                                return e.fileList;
                                            }}
                                            rules={[{ required: true, message: "Зураг оруулна уу" }]}
                                        >
                                            <Upload
                                                name="file"
                                                listType="picture-card"
                                                accept="image/*"
                                                action={`${config.get("apiUrl")}/api/file/upoad`}
                                                maxCount={1}
                                            >
                                                <div>
                                                    <PlusOutlined />
                                                    <div style={{ marginTop: 8 }}>Upload</div>
                                                </div>
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item label="Баталгаажилт">
                                            <Switch checkedChildren="Агро" unCheckedChildren="Систем" checked={xyrStatus=='agro'} onChange={e=>changeXyrStatus(e?"agro":"created")} />
                                        </Form.Item>
                                    </>
                                )
                            }
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit">Хадгалах</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Layout.Content>
        </Modal>
    )
};
export default FarmlandEdit
