import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  PlusSquareOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Checkbox,
  Image,
  Form,
  Select,
  Input,
} from "antd";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  EQUIPMENT_OWN_TYPE,
  EQUIPMENT_RENT_STATUS_REF,
  EQUIPMENT_USAGE_LIST,
  EQUIPMENT_USAGE_REF,
} from "../../enums/equipment.enum";
import { USER_ROLE } from "../../enums/user.enum";
import NetworkApi from "../../network/networkApi";
import config from "../../config";
import UserContext from "../../providers/UserContext";
import BreadMe from "../lib/Breadme";
import EquipmentAddEdit from "./components/EquipmentAddEdit";
import useEquipmentType from "../../hooks/useEquipmentType";
import useEquipmentSubType from "../../hooks/useEquipmentSubType";
import AddressSelect from "../lib/AddressSelect";
import moment from "moment";
import useEquipmentCountry from "../../hooks/useEquipmentCountry";

interface EditState {
  visible: boolean;
  edit?: any;
}
interface RenterState {
  visible: boolean;
  equipment: any;
}

const EquipmentList = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      {user &&
        [
          USER_ROLE.ADMIN,
          USER_ROLE.VILLAGE_AGRONOMIST,
          USER_ROLE.PROVINCE_AGRONOMIST,
        ].includes(user?.role?.cd) && <EquipmentListAdmin />}
      {user && [USER_ROLE.CUSTOMER].includes(user?.role?.cd) && (
        <EquipmentListCustomer />
      )}
    </>
  );
};

const EquipmentListCustomer = () => {
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/user/equipment/list", {
      ...content.filter,
      page: content.page,
    });
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);
  const equipmentColumn = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "Зураг",
      dataIndex: "image",
      render: (image: string) => {
        if (!image && image.length === 0) {
          return;
        }
        const equipmentImage = JSON.parse(image);
        return (
          <Image
            width={48}
            src={`${config.get("apiUrl")}/tmp/${equipmentImage.name}`}
          />
        );
      },
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      render: (type: any, value: any) =>
        `${type?.name}, ${value.subType?.name}`,
    },
    {
      title: "Марк",
      dataIndex: "mark",
    },
    {
      title: "Ажиллаж эхэлсэн огноо",
      dataIndex: "usageStartDate",
    },
    {
      title: "Ажилласан жил",
      dataIndex: "usageStartDate",
      render: (value: string) => `${moment().diff(moment(value), "y")} жил`,
    },
    {
      title: "Ашиглалт",
      dataIndex: "usage",
      render: (usage: any) => EQUIPMENT_USAGE_REF[usage],
    },
    {
      title: "Түрээсийн эсэх",
      dataIndex: "relation",
      render: (value: any) => (
        <Checkbox
          defaultChecked={value === EQUIPMENT_OWN_TYPE.RENTER}
          checked={value === EQUIPMENT_OWN_TYPE.RENTER}
        >
          Тийм
        </Checkbox>
      ),
    },
    {
      title: "Үйлдвэрлэсэн улс",
      dataIndex: "country",
      render: (country: any) => country?.name,
    },
    {
      title: "Тоо хэмжээ",
      dataIndex: "quantity",
    },
    {
      title: "Үзүүлэлт хүчин чадал",
      dataIndex: "performance",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Link to={"/equipment/detail/" + item.id}>
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
            ></Button>
          </Link>
        </Space>
      ),
    },
  ];
  return (
    <>
      <BreadMe title={["Тоног төхөөрөмж", "Тоног төхөөрөмжийн жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col span={24}>
            <Table
              pagination={{
                current: content.page,
                total: content.count,
              }}
              onChange={(p) => {
                changeContent({
                  ...content,
                  page: p.current || 1,
                });
              }}
              rowKey={"id"}
              columns={equipmentColumn}
              dataSource={content.result}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

const EquipmentListAdmin = () => {
  const [filterForm] = Form.useForm();
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  const [typeList] = useEquipmentType(1);
  const [subTypeList, , changeSubTypeFilter] = useEquipmentSubType();
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);
  const [countryList] = useEquipmentCountry();
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (
      [USER_ROLE.ADMIN, USER_ROLE.VILLAGE_AGRONOMIST].includes(user?.role?.cd)
    ) {
      return true;
    }
    return false;
  }, [user]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/equipment/list", {
      ...content.filter,
      page: content.page,
    });
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content.filter, content.page]);
  const deleteEquipment = useCallback(async (item: any) => {
    let response = await NetworkApi.requestPost(
      `/api/equipment/delete/${item.id}`
    );
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Устгахад алдаа гарлаа.");
    }
  }, []);
  const [edit, changeEdit] = useState<EditState>({
    visible: false,
  });
  const [renter, changeRenter] = useState<RenterState>({
    visible: false,
    equipment: undefined,
  });
  const setEdit = (visible?: boolean, edit?: any) => {
    changeEdit({
      visible: visible == undefined ? false : visible,
      edit,
    });
  };
  const onSubmit = ({
    search,
    typeId,
    subTypeId,
    aimagId,
    sumId,
    usage,
    countryId,
  }: {
    search: string;
    typeId: string;
    subTypeId: string;
    aimagId: string;
    sumId: string;
    usage: string;
    countryId: string;
  }) => {
    changeContent((c) => {
      return {
        ...c,
        filter: {
          search,
          typeId,
          subTypeId,
          aimagId,
          sumId,
          usage,
          countryId,
        },
      };
    });
  };
  const equipmentColumn = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "Зураг",
      dataIndex: "image",
      render: (image: string) => {
        if (!image && image.length === 0) {
          return;
        }
        const equipmentImage = JSON.parse(image);
        return (
          <Image
            width={48}
            src={`${config.get("apiUrl")}/tmp/${equipmentImage.name}`}
          />
        );
      },
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      render: (type: any, value: any) => `${type.name}, ${value.subType?.name}`,
    },
    {
      title: "Марк",
      dataIndex: "mark",
    },
    {
      title: "Ажиллаж эхэлсэн огноо",
      dataIndex: "usageStartDate",
    },
    {
      title: "Ажилласан жил",
      dataIndex: "usageStartDate",
      render: (value: string) => `${moment().diff(moment(value), "y")} жил`,
    },
    {
      title: "Ашиглалт",
      dataIndex: "usage",
      render: (usage: any) => EQUIPMENT_USAGE_REF[usage],
    },
    {
      title: "Эзэмшигч",
      dataIndex: "owner",
      render: (owner: any) =>
        `${owner.lastName?.substring(0, 1)}.${owner.firstName} (${owner.rd})`,
    },
    {
      title: "Түрээслэгч",
      dataIndex: "totalRentedQuantity",
      render: (value: any, item: any) => (
        <Button
          type="link"
          onClick={() => {
            console.log("test");

            changeRenter({ visible: true, equipment: item });
          }}
        >
          {value || 0}
        </Button>
      ),
    },
    {
      title: "Үйлдвэрлэсэн улс",
      dataIndex: "country",
      render: (country: any) => country.name,
    },
    {
      title: "Тоо хэмжээ",
      dataIndex: "quantity",
    },
    {
      title: "Үзүүлэлт хүчин чадал",
      dataIndex: "performance",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Link to={"/equipment/detail/" + item.id}>
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
            ></Button>
          </Link>
          {/* {canEdit && (
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<EditOutlined />}
              onClick={() => setEdit(true, item)}
            />
          )} */}
          {canEdit && (
            <Popconfirm
              title="Устгахдаа итгэйлтэй байна уу"
              onConfirm={() => deleteEquipment(item)}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <Button
                onClick={() => {}}
                type={"primary"}
                size={"small"}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <BreadMe title={["Тоног төхөөрөмж", "Тоног төхөөрөмжийн жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col flex="auto" style={{ marginTop: 15, marginBottom: 15 }}>
            <Form layout="inline" form={filterForm} onFinish={onSubmit}>
              <Form.Item name="search">
                <Input placeholder="Овог нэр, Регистр" />
              </Form.Item>
              <Form.Item name="typeId">
                <Select
                  allowClear
                  placeholder="Төрөл сонгоно уу"
                  onChange={(v) => {
                    changeSubTypeFilter({ parentTypeId: v });
                    filterForm.setFieldsValue({ subTypeId: undefined });
                  }}
                >
                  {typeList.result.map((type) => {
                    return (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="subTypeId">
                <Select allowClear placeholder="Дэд төрөл сонгоно уу">
                  {subTypeList.result.map((type) => {
                    return (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <AddressSelect
                defaultAimag={
                  [
                    USER_ROLE.PROVINCE_AGRONOMIST,
                    USER_ROLE.VILLAGE_AGRONOMIST,
                  ].includes(user.role.cd)
                    ? user.aimagId
                    : null
                }
                defaultSum={
                  [USER_ROLE.VILLAGE_AGRONOMIST].includes(user.role.cd)
                    ? user.sumId
                    : null
                }
                hideLabel
                ignoreBag
              />
              <Form.Item name="usage">
                <Select
                  allowClear
                  placeholder="Ашиглалт сонгоно уу"
                  style={{ minWidth: 150 }}
                >
                  {EQUIPMENT_USAGE_LIST.map((type) => {
                    return (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="countryId">
                <Select allowClear placeholder="Улс сонгоно уу">
                  {countryList.result.map((country) => {
                    return (
                      <Select.Option key={country.id} value={country.id}>
                        {country.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  icon={<SearchOutlined />}
                  type="primary"
                ></Button>
              </Form.Item>
            </Form>
          </Col>
          <Col flex="none" style={{ marginTop: 15, marginBottom: 15 }}>
            <Row justify={"end"}>
              {canEdit && (
                <Button
                  onClick={() => setEdit(true)}
                  type={"primary"}
                  icon={<PlusSquareOutlined />}
                >
                  Тоног төхөөрөмж нэмэх
                </Button>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              pagination={{
                current: content.page,
                total: content.count,
              }}
              onChange={(p) => {
                changeContent({
                  ...content,
                  page: p.current || 1,
                });
              }}
              rowKey={"id"}
              columns={equipmentColumn}
              dataSource={content.result}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <EquipmentAddEdit
        visible={edit.visible}
        edit={edit.edit}
        onHide={() => setEdit(false)}
        onSuccess={() => {
          setEdit(false);
          loadContent();
        }}
      />
      {renter.visible && (
        <EquipmentRenters
          visible={renter.visible}
          onHide={() => changeRenter({ visible: false, equipment: undefined })}
          onSuccess={() => {
            changeRenter({ visible: false, equipment: undefined });
            loadContent();
          }}
          equipment={renter.equipment}
        />
      )}
    </>
  );
};
interface EquipmentRentersProps {
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
  equipment: any;
}

const EquipmentRenters = ({
  visible,
  onHide,
  onSuccess,
  equipment,
}: EquipmentRentersProps) => {
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet(
      `/api/equipment/renting/list/${equipment?.id}`,
      {
        ...content.filter,
        page: content.page,
      }
    );
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);

  const columns = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "Түрээслэгч",
      dataIndex: "renter",
      render: (value: any) =>
        `${value.lastName.substring(0, 1)}.${value.firstName}`,
    },
    {
      title: "Эхэлсэн огноо",
      dataIndex: "startDate",
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: any) => `${EQUIPMENT_RENT_STATUS_REF[value]}`,
    },
  ];
  return (
    <Modal
      visible={visible}
      title={equipment && `Тоног төхөөрөмж: #${equipment.id}`}
      onCancel={onHide}
      footer={null}
      width={1214}
    >
      <Table
        columns={columns}
        dataSource={content.result}
        scroll={{ x: true }}
      />
    </Modal>
  );
};
export default EquipmentList;
