import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DownloadOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import useUserList from "../../hooks/useUserrList";
import AddressSelect from "../lib/AddressSelect";
import FarmerEdit from "./FarmerEdit";
import UserContext from "../../providers/UserContext";
import config from "../../config";
import { Link } from "react-router-dom";
import * as querystring from "querystring";

const Farmers = () => {
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (user.role?.cd == "ADMIN" || user?.role?.cd == "VILLAGE_AGRONOMIST") {
      return true;
    }
    return false;
  }, [user]);
  const [content, loadContent, changeContentFilter, filter] = useUserList(1);
  const [filterType, setFilterType] = useState("address");
  const [farmerEdit, setFarmerEdit] = useState({
    visible: false,
    id: null,
  });

  useEffect(() => {
    changeContentFilter((c: any) => {
      return { ...c, filterType, page: 1 };
    });
  }, [filterType, changeContentFilter]);

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    changeContentFilter((c: any) => {
      return { ...c, page: pagination.current, filterType };
    });
  };

  const columnsByAddress = [
    {
      title: "№",
      dataIndex: "id",
      width: 50,
    },
    {
      title: "Нэр",
      render: (item: any) => {
        return item.organizations?.length > 0 ? item.organizations[0].name : "";
      },
    },
    {
      title: "Регистр",
      render: (item: any) => {
        return item.organizations?.length > 0 ? item.organizations[0].rd : "";
      },
    },
    {
      title: "Харьяалал",
      render: (item: any) => {
        return `${item.aimag?.name || ""} ${item.sum?.name || ""}`;
      },
    },
    {
      title: "Төрөл",
      render: (item: any) => {
        return item.organizations?.length > 0
          ? item.organizations[0]?.type == "farmer"
            ? "Иргэн"
            : "Хуулийн этгээд"
          : "";
      },
    },
    {
      title: "АШҮЭ код",
      render: (item: any) => {
        return item.organizations?.length > 0 ? item.organizations[0].aid : "";
      },
    },
    {
      title: "Утас",
      dataIndex: "phone",
    },
    {
      title: "Тоног төхөөрөмж",
      dataIndex: "equipment",
    },
    {
      title: "Баталгаажсан төлөв",
      dataIndex: "confirm",
      render: (value: number) => (
        <>
          {
            {
              no: <Tag color="red">Цуцалсан</Tag>,
              yes: <Tag color="green">Баталгаажсан</Tag>,
            }[value]
          }
        </>
      ),
    },
    {
      title: "Үйлдэл",
      render: (index: any) => (
        <Space size={10}>
          <Link to={"/farmers/" + index.id}>
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
            />
          </Link>
          {canEdit ? (
            <Button
              onClick={() => {
                console.log(index);
                setFarmerEdit({ visible: true, id: index.id });
              }}
              type={"primary"}
              size={"small"}
              ghost
            >
              <EditOutlined />
            </Button>
          ) : null}
        </Space>
      ),
      ellipsis: true,
    },
  ];

  const columnsByUsage = [
    {
      title: "№",
      dataIndex: "id",
      width: 50,
    },
    {
      title: "Нэр",
      render: (item: any) => {
        return item.name;
      },
    },
    {
      title: "Регистр",
      render: (item: any) => {
        return item.rd;
      },
    },
    {
      title: "Харьяалал",
      render: (item: any) => {
        return `${item.aimag_name || ""} ${item.sum_name || ""}`;
      },
    },
    {
      title: "Төрөл",
      render: (item: any) => {
        return item.type == "farmer" ? "Иргэн" : "Хуулийн этгээд";
      },
    },
    {
      title: "АШҮЭ код",
      render: (item: any) => {
        return item.aid;
      },
    },
    {
      title: "Утас",
      dataIndex: "phone",
    },
    {
      title: "Тоног төхөөрөмж",
      dataIndex: "equipment",
    },
    {
      title: "Баталгаажсан төлөв",
      dataIndex: "confirm",
      render: (value: number) => (
        <>
          {
            {
              no: <Tag color="red">Цуцалсан</Tag>,
              yes: <Tag color="green">Баталгаажсан</Tag>,
            }[value]
          }
        </>
      ),
    },
    {
      title: "Үйлдэл",
      render: (index: any) => (
        <Space size={10}>
          {canEdit ? (
            <Button
              onClick={() => {
                console.log(index);
                setFarmerEdit({ visible: true, id: index.user_id });
              }}
              type={"primary"}
              size={"small"}
              ghost
            >
              <EditOutlined />
            </Button>
          ) : null}
        </Space>
      ),
      ellipsis: true,
    },
  ];

  return (
    <>
      <BreadMe title={["Тариаланчид"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Form
          onFinish={(values) => {
            changeContentFilter({
              ...values,
              filterType,
            });
          }}
          name={"farmers-filter"}
          layout="inline"
        >
          <AddressSelect hideLabel={true} ignoreBag />
          <Form.Item name={"name"}>
            <Input placeholder={"Нэр, регистр"} />
          </Form.Item>
          <Form.Item name={"type"} initialValue="">
            <Select style={{ width: 150 }}>
              <Select.Option value="">Төрөл</Select.Option>
              <Select.Option value="farmer">Хувь хүн</Select.Option>
              <Select.Option value="organization">Хуулийн этгээд</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name={"confirm"} initialValue="">
            <Select>
              <Select.Option value="">Баталгаажсан төлөв</Select.Option>
              <Select.Option value="null">Баталгаажуулаагүй</Select.Option>
              <Select.Option value="yes">Баталгаажсан</Select.Option>
              <Select.Option value="no">Цуцалсан</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name={"search"}>
            <Space size={16}>
              <Button type={"primary"} htmlType="submit">
                <SearchOutlined />
              </Button>
              {user?.role.cd == "ADMIN" && (
                <Button
                  target="blank"
                  href={`${config.get(
                    "apiUrl"
                  )}/api/user/list/download?${querystring.stringify(filter)}`}
                  type={"primary"}
                >
                  <DownloadOutlined />
                  Татах
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
        <Descriptions title="Тариаланчид" style={{ marginTop: 15 }}>
          <Descriptions.Item label="Нийт">{content.count}</Descriptions.Item>
        </Descriptions>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="address"
              type="card"
              onChange={(filterType) => {
                setFilterType(filterType);
              }}
            >
              <Tabs.TabPane tab="Харьяалалын" key="address">
                <Table
                  scroll={{ x: true }}
                  loading={content.loading}
                  columns={columnsByAddress}
                  dataSource={content.users ?? []}
                  pagination={{
                    pageSize: 10,
                    current: content.page,
                    total: content.count,
                  }}
                  onChange={handleParam}
                  rowKey={"id"}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Ү.а явуулдаг" key="usage">
                <Table
                  scroll={{ x: true }}
                  loading={content.loading}
                  columns={columnsByUsage}
                  dataSource={content.orgs ?? []}
                  pagination={{
                    pageSize: 10,
                    current: content.page,
                    total: content.count,
                  }}
                  onChange={handleParam}
                  rowKey={"id"}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
        <FarmerEdit
          visible={farmerEdit.visible}
          id={farmerEdit.id}
          onHide={() => {
            loadContent();
            setFarmerEdit({
              visible: false,
              id: null,
            });
          }}
        />
      </Layout.Content>
    </>
  );
};
export default Farmers;
