import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  Row,
  Select,
  Table,
  Image,
  Popconfirm,
  Space,
} from "antd";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import {
  CloudDownloadOutlined,
  SearchOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import config from "../../../config";
import NetworkApi from "../../../network/networkApi";
import AddressSelect from "../../lib/AddressSelect";
import useFarmlandList from "../../../hooks/useFarmlandList";
import useFarmlandUsageDetail from "../../../hooks/useFarmlandUsageDetail";
import moment from "moment";
import UserContext from "../../../providers/UserContext";
const { RangePicker } = DatePicker;

interface Props {
  visible: boolean;
  onHide: () => void;
  id?: any;
  onSuccess: () => void;
}

const host = config.get("apiUrl");
const modalCol = [
  {
    title: "НТДугаар",
    render: (id: any) => `${id.posNo}`,
    ellipsis: true,
  },
  {
    title: "Талбайн хэмжээ",
    dataIndex: "area",
  },
  {
    title: "Байршил",
    render: (data: any) => {
      return `${data.aimag.name}-${data.sum.name}`;
    },
  },
  {
    title: "Ашиглаж эхэлсэн огноо",
    dataIndex: "initialDate",
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    render: (type: any) => (type == "private" ? "Хувийн" : "Төрийн"),
    key: "type",
  },
  {
    title: "Төлөв",
    dataIndex: "xyrStatus",
    render: (status: any) => {
      if (status === "created") {
        return <span>Систем</span>;
      }
      if (status === "approved") {
        return <span style={{ color: "green" }}>Геодиз</span>;
      }
      if (status === "rejected") {
        return <span style={{ color: "red" }}>Мэдээлэл зөрүүтэй</span>;
      }
    },
  },
];
const FarmlandUsageAdd = ({ visible, onHide, onSuccess, id }: Props) => {
  const [filterForm] = Form.useForm();
  const editFormRef = useRef<any>();
  const [farmlandList, loadList, changeListFilter, listFilter] =
    useFarmlandList();
  const [listPage, setListPage] = useState(1);
  const [detail, loadDetail, changeDetailId] = useFarmlandUsageDetail();
  const [fileList, changeFileList] = useState<any[]>([]);
  const [selectedFarmland, setSelectedFarmland] = useState<any>("");
  const { user } = useContext(UserContext);
  const [findModal, setFindModal] = useState<boolean>(false);
  useEffect(() => {
    if (visible) {
      console.log(id);
      if (id) {
        changeDetailId(id);
      } else {
        changeListFilter({});
        filterForm.resetFields();
        changeFileList([]);
      }
    }
  }, [visible, id]);
  useEffect(() => {
    changeListFilter({
      ...listFilter,
      page: listPage,
    });
  }, [listPage]);
  useEffect(() => {
    if (!detail.loading && id && detail.result.id) {
      console.log(detail.result);
      editFormRef.current?.setFieldsValue({
        area: detail.result.area,
        type: detail.result.type,
        startDate: moment(detail.result.startDate),
        endDate: moment(detail.result.endDate),
      });
      changeFileList(JSON.parse(detail.result.certificate));
    }
  }, [detail, id]);
  const handleSubmit = useCallback(
    async (data: any) => {
      if (selectedFarmland == "" && (id == undefined || id == null)) {
        message.error("Тариалангийн талбай сонгогдоогүй байна");
        return;
      }
      /*
        let fieldArea = farmlandList.result.filter((itm: any) => { if (itm.id === farmlandList.selectedId) return itm.area });
        if (data.area > fieldArea[0]['area']) {
            message.error("Сонгосон талбайн нийт хэмжээнээс их байна");
            return
        }*/
      // if (fileList.length == 0) {
      //     message.error("Эзэмших /Түрээслэх/ гэрээ оруулна уу")
      //     return
      // }
      if (data.endDate < data.startDate) {
        message.error("Эхлэх огноо дуусах огнооноос их байна");
        return;
      }

      data = {
        ...data,
        farmlandId: selectedFarmland,
        certificate: fileList,
        startDate: data.startDate.format("YYYY-MM-DD"),
        endDate: data.endDate.format("YYYY-MM-DD"),
      };
      if (id) {
        data = { ...data, id };
      }
      let result = await NetworkApi.requestPost(
        "/api/farmland/usage/request",
        data
      );
      if (result.resultCode == 200) {
        onSuccess();
      } else {
        message.error(result.message || " Системд алдаа гарлаа");
      }
      //onSuccess()
    },
    [farmlandList, onSuccess, fileList, id]
  );
  return (
    <Modal
      width={1240}
      centered
      visible={visible}
      onCancel={onHide}
      footer={null}
      title={"Талбайн хүсэлт"}
    >
      {id ? (
        <div></div>
      ) : (
        <>
          <Form
            form={filterForm}
            size="middle"
            layout="inline"
            onFinish={(data) => {
              changeListFilter(data);
            }}
          >
            <Row>
              <Col span={16}>
                <Row>
                  <AddressSelect />
                </Row>
              </Col>
              <Col span={4}>
                <Form.Item name={"posNo"}>
                  <Input placeholder={"НТДугаар"} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Space>
                    <Button htmlType={"submit"} type={"primary"}>
                      Хайх
                      <SearchOutlined />
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col span={20}></Col>
              <Col span={4}>
                <Form.Item>
                  <Button
                    type={"text"}
                    onClick={() => setFindModal(true)}
                    style={{
                      marginRight: "0px",
                      background: "#fa541c",
                      color: "#FFFFFF",
                    }}
                  >
                    Геодезээс лавлах
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row style={{ marginBottom: 15 }}>
            <Col span={24}>
              <Table
                scroll={{ x: true }}
                rowKey="id"
                rowSelection={{
                  columnTitle: "Сонгох",
                  selectedRowKeys: [selectedFarmland],
                  onChange: (keys) => {
                    setSelectedFarmland(keys.length > 0 ? keys[0] : "");
                  },
                  columnWidth: 80,
                  type: "radio",
                }}
                loading={farmlandList.loading}
                columns={modalCol}
                dataSource={farmlandList.result}
                pagination={{
                  current: listPage,
                  total: farmlandList?.total ?? 1,
                  showSizeChanger: false,
                  onChange: (next) => {
                    console.log("next: ", next);
                    setListPage(next);
                  },
                }}
              />
            </Col>
          </Row>
        </>
      )}

      <Form
        ref={editFormRef}
        name="usage"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={15} style={{ marginBottom: 15 }}>
          <Col span={6}>
            <Form.Item
              name="type"
              rules={[
                { required: true, message: "Зөвшөөрлийн төрөл оруулна уу." },
              ]}
              label="Зөвшөөрлийн төрөл"
            >
              <Select>
                <Select.Option value="owner">Эзэмшилийн</Select.Option>
                <Select.Option value="rent">Түрээсийн</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="area"
              rules={[
                { required: true, message: "Талбайн хэмжээ оруулна уу." },
              ]}
              label="Ашиглах газрын хэмжээ"
            >
              <InputNumber
                style={{ width: "100%" }}
                parser={(value) => value?.replace(",", ".") || ""}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="startDate"
              rules={[{ required: true, message: "Хугацаа оруулна уу." }]}
              label="Ашиглаж эхлэх хугацаа"
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="endDate"
              rules={[{ required: true, message: "Хугацаа оруулна уу." }]}
              label="Ашиглаж дуусах хугацаа"
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }} gutter={20}>
          <Col span={24}>
            {fileList.length > 0 ? (
              <List
                grid={{ gutter: 10, column: 8 }}
                dataSource={fileList}
                footer={
                  <Dragger
                    name="file"
                    showUploadList={false}
                    multiple={false}
                    action={`${host}/api/file/upoad`}
                    onChange={(info: any) => {
                      console.log(info);
                      const { status } = info.file;
                      if (status !== "uploading") {
                        console.log(info.file, info.fileList);
                      }
                      if (status === "done") {
                        changeFileList((files: any[]) => {
                          console.log(info);
                          return [
                            ...files,
                            {
                              name: info.file.name,
                              tmp: info.file.response.result.name,
                              type: info.file.response.result.mimetype,
                            },
                          ];
                        });
                        message.success(
                          `${info.file.name} файл амжилттай хууллаа.`
                        );
                      } else if (status === "error") {
                        message.error(
                          `${info.file.name} файл хуулхад алдаа гарлаа.`
                        );
                      }
                    }}
                  >
                    <Row>
                      <Col span={20}>
                        <p className="ant-upload-text">
                          Эзэмших /Түрээслэх/ гэрээ
                        </p>
                        <p className="ant-upload-hint">
                          Чирэх болон сонгож оруулна уу
                        </p>
                      </Col>
                      <Col span={4}>
                        <CloudDownloadOutlined />
                      </Col>
                    </Row>
                  </Dragger>
                }
                renderItem={(item) => {
                  return (
                    <List.Item>
                      {item.type == "application/pdf" ? (
                        <Card bodyStyle={{ padding: 5, textAlign: "center" }}>
                          <FilePdfOutlined style={{ fontSize: 50 }} />
                          <Popconfirm
                            title="Устгахдаа итгэйлтэй байна уу."
                            onConfirm={() => {
                              const index = fileList.indexOf(item);
                              const newFileList = fileList.slice();
                              newFileList.splice(index, 1);
                              changeFileList(newFileList);
                            }}
                            okText="Тийм"
                            cancelText="Үгүй"
                          >
                            <DeleteOutlined
                              style={{
                                position: "absolute",
                                padding: 5,
                                right: 0,
                                top: 0,
                                background: "white",
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </Popconfirm>
                        </Card>
                      ) : (
                        <Card bodyStyle={{ padding: 5 }}>
                          <Image
                            width="100%"
                            style={{ minWidth: 50, minHeight: 50 }}
                            height="100%"
                            src={`${host}/tmp/${item.tmp}`}
                          />
                          <Popconfirm
                            title="Устгахдаа итгэйлтэй байна уу."
                            onConfirm={() => {
                              const index = fileList.indexOf(item);
                              const newFileList = fileList.slice();
                              newFileList.splice(index, 1);
                              changeFileList(newFileList);
                            }}
                            okText="Тийм"
                            cancelText="Үгүй"
                          >
                            <DeleteOutlined
                              style={{
                                position: "absolute",
                                padding: 5,
                                right: 0,
                                top: 0,
                                background: "white",
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </Popconfirm>
                        </Card>
                      )}
                    </List.Item>
                  );
                }}
              />
            ) : (
              <Col span={24}>
                <Dragger
                  name="file"
                  showUploadList={false}
                  multiple={false}
                  action={`${host}/api/file/upoad`}
                  onChange={(info: any) => {
                    console.log(info);
                    const { status } = info.file;
                    if (status !== "uploading") {
                      console.log(info.file, info.fileList);
                    }
                    if (status === "done") {
                      changeFileList((files: any[]) => {
                        console.log(info);
                        return [
                          ...files,
                          {
                            name: info.file.name,
                            tmp: info.file.response.result.name,
                            type: info.file.response.result.mimetype,
                          },
                        ];
                      });
                      message.success(
                        `${info.file.name} файл амжилттай хууллаа.`
                      );
                    } else if (status === "error") {
                      message.error(
                        `${info.file.name} файл хуулхад алдаа гарлаа.`
                      );
                    }
                  }}
                >
                  <Row>
                    <Col span={20}>
                      <p className="ant-upload-text">
                        Эзэмших /Түрээслэх/ гэрээ
                      </p>
                      <p className="ant-upload-hint">
                        Чирэх болон сонгож оруулна уу
                      </p>
                    </Col>
                    <Col span={4}>
                      <CloudDownloadOutlined />
                    </Col>
                  </Row>
                </Dragger>
              </Col>
            )}
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col span={20}>
            <p>
              Тариалангийн талбайд эзэмшигчээр бүртгүүлэх бол Эзэмших гэрчилгээг
              оруулна уу.
              <br />
              Тухайн талбайд түрээслэгчээр бүртгүүлэх бол Түрээслэх гэрээ хэсэгт
              эзэмшигчтэй байгуулсан гэрээг оруулна уу.
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button htmlType="submit" block type="primary">
              {user.role.cd == "ADMIN" ? "Хүсэлт засах" : "Хүсэлт илгээх"}
            </Button>
          </Col>
        </Row>
      </Form>
      <FindFarmland
        visible={findModal}
        onCancel={() => setFindModal(false)}
        onSuccess={(posNo: string) => {
          setFindModal(false);
          filterForm.setFieldsValue({ posNo });
          filterForm.submit();
        }}
      />
    </Modal>
  );
};

const FindFarmland = ({
  visible,
  onCancel,
  onSuccess,
}: {
  visible: boolean;
  onCancel: any;
  onSuccess: any;
}) => {
  const { user } = useContext(UserContext);
  const [ownerType, setOwnerType] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const find = useCallback(
    async (values) => {
      setLoading(true);
      let response = await NetworkApi.requestPost(`/api/farmland/find/create`, {
        posNo: values.posNo,
        regnum: ownerType === "owner" ? user.rd : values?.regnum,
      });
      if (response.resultCode == 200) {
        onSuccess(values.posNo);
      } else {
        message.error(response.message ?? "system error");
      }
      setLoading(false);
    },
    [ownerType]
  );

  return (
    <Modal centered visible={visible} footer={false} onCancel={onCancel}>
      <Form layout="vertical" onFinish={find}>
        <Form.Item name="ownerType" label=" Газар Ашиглах төрөл">
          <Select onChange={(value: string) => setOwnerType(value)}>
            <Select.Option value="owner">Эзэмшилийн</Select.Option>
            <Select.Option value="rent">Түрээсийн</Select.Option>
          </Select>
        </Form.Item>
        {ownerType !== "owner" && (
          <Form.Item name="regnum" label="Газар эзэмшигчийн регистрийг оруулах">
            <Input />
          </Form.Item>
        )}
        <Form.Item name="posNo" label="Нэгж талбарын дугаар оруулах">
          <Input />
        </Form.Item>
        <Form.Item>
          <Row justify="center">
            <Button
              htmlType="submit"
              loading={loading}
              style={{ width: "100%" }}
              type="primary"
              icon={<SearchOutlined />}
            >
              Хүсэлт гаргах
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FarmlandUsageAdd;
