import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Select,
  Space,
  Checkbox,
  Form,
  Modal,
  Popconfirm,
  message,
} from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";
import useFarmlandServiceType from "../../hooks/useServiceType";
import useService from "../../hooks/useService";
import useServiceType from "../../hooks/useServiceType";

const { Option } = Select;
const { TextArea } = Input;
const FarmService = () => {
  const [content, loadContent, changeFilter] = useService();
  const [typeList] = useServiceType();
  const [addEdit, setAddEdit] = useState({
    visible: false,
    edit: null,
  });
  const showModal = (item: any) => {
    setAddEdit({ visible: true, edit: item });
  };
  const filterChange = (value: any) => {
    changeFilter((prevValues: any) => {
      return { ...prevValues, page: 1, type: value };
    });
  };
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost("/api/service/update", {
      ...data,
      status: "D",
    });
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "system error");
    }
  }, []);

  const elementInfo = [
    {
      title: "Талбайн арчилгааны төрөл",
      render: (t: any) => t.type?.name,
    },
    {
      title: "Нэр",
      dataIndex: "name",
    },
    {
      title: "нэгж/га",
      render: (t: any) => (t.unit == "enum" ? "Ерөнхий" : t.unit),
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Button
            onClick={() => showModal(item)}
            type={"primary"}
            size={"small"}
            ghost
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Талбайн арчилгааны төрөл устгахад итгэлтэй байна уу?"
            okText="Тийм"
            cancelText="Үгүй"
            onConfirm={() => deleteItem(item)}
          >
            <Button
              type={"primary"}
              size={"small"}
              danger
              ghost
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Талбайн арчилгаа"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"space-between"}>
              <Space size={20}>
                <Select
                  defaultValue={0}
                  style={{ width: 200 }}
                  onChange={filterChange}
                >
                  <Option value={0}>Арчилгааны төрөл</Option>
                  {typeList.result.map((type) => {
                    return (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    );
                  })}
                </Select>
              </Space>
              <Button
                onClick={() => showModal(null)}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Төрөл нэмэх
              </Button>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              columns={elementInfo}
              dataSource={content.result}
              onChange={handleParam}
              rowKey="id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <FarmServiceEdit
        edit={addEdit.edit}
        visible={addEdit.visible}
        onHide={() => setAddEdit({ visible: false, edit: null })}
        onSuccess={() => {
          setAddEdit({ visible: false, edit: null });
          loadContent();
        }}
      />
    </>
  );
};

interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const FarmServiceEdit = ({ edit, visible, onHide, onSuccess }: Props) => {
  const formRef = useRef<any>();
  const [serviceTypes] = useServiceType();
  useEffect(() => {
    if (visible) {
      if (edit) {
        formRef.current?.setFieldsValue(edit);
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [edit, visible]);
  const saveContent = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      let response = await NetworkApi.requestPost("/api/service/update", data);
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "system error");
      }
    },
    [edit]
  );
  return (
    <Modal
      visible={visible}
      width={595}
      centered
      title={edit ? "Талбайн арчилгаа засах" : "Талбайн арчилгаа нэмэх"}
      onCancel={onHide}
      footer={null}
    >
      <Form
        ref={formRef}
        labelCol={{ span: 8 }}
        onFinish={(data) => saveContent(data)}
      >
        <Form.Item name="typeId" initialValue="" label="Төрөл">
          <Select>
            <Select.Option value="">Төрөл</Select.Option>
            {serviceTypes.result.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                {t.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="name" label="Нэр">
          <Input />
        </Form.Item>
        <Form.Item label="Хэмжих нэгж">
          <Form.Item name="unit" noStyle>
            <Select>
              <Option value={"%"}>%</Option>
              <Option value={"enum"}>Ерөнхий</Option>
              <Option value={"г/кг"}>г/кг</Option>
              <Option value={"г/л"}>г/л</Option>
              <Option value={"л"}>л</Option>
              <Option value={"килограмм"}>кг</Option>
              <Option value={"см"}>см</Option>
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item name="description" label="Тайлбар">
          <TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            placeholder={"Тайлбар бичнэ үү!"}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};

export default FarmService;
