import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useRef } from "react";
import { EQUIPMENT_USAGE_LIST } from "../../../enums/equipment.enum";
import useEquipmentCountry from "../../../hooks/useEquipmentCountry";
import useEquipmentSubType from "../../../hooks/useEquipmentSubType";
import useEquipmentType from "../../../hooks/useEquipmentType";
import NetworkApi from "../../../network/networkApi";
import UserSelectWithSearch from "../../lib/UserSelectWithSearch";
import { mockUploadAction } from "../../../helpers/upload.helper";

interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const EquipmentAddEdit = ({ edit, visible, onHide, onSuccess }: Props) => {
  const formRef = useRef<any>();
  const [typeList] = useEquipmentType(1);
  const [subTypeList, , changeSubTypeFilter] = useEquipmentSubType();
  const [countryList] = useEquipmentCountry(1);
  useEffect(() => {
    if (visible) {
      if (edit) {
        formRef.current?.setFieldsValue({
          ...edit,
          usageStartDate: moment(edit.usageStartDate),
          manufacturedDate: moment(edit.manufacturedDate),
        });
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [edit, visible]);
  const saveContent = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      if (data.upload && data.upload.length > 0) {
        handleUploadFile(data);
      } else {
        const response = await NetworkApi.requestPost("/api/equipment", {
          ...data,
          image: JSON.parse(edit.image),
        });
        if (response.resultCode === 200) {
          onSuccess();
        } else {
          message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
        }
      }
    },
    [edit]
  );
  const handleUploadFile = async (data: any) => {
    const file: File = data.upload[0].originFileObj;
    let response = await NetworkApi.requestUploadFile(file);
    if (response.resultCode === 200) {
      message.success(`${response.result.name} файл амжилттай хууллаа.`);
      response = await NetworkApi.requestPost("/api/equipment", {
        ...data,
        image: response.result,
      });
      if (response.resultCode === 200) {
        onSuccess();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    } else {
      message.error(`Файл хуулхад алдаа гарлаа.`);
    }
  };
  const handleChangeImage = (e: any) => {
    return e.fileList;
  };

  return (
    <Modal
      visible={visible}
      title={edit ? "Төхөөрөмж засах" : "Төхөөрөмж нэмэх"}
      onCancel={onHide}
      footer={null}
      width={1214}
    >
      <Form
        ref={formRef}
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        colon={false}
        layout="horizontal"
        className="form-with-label-wrap"
        onFinish={saveContent}
      >
        <Row gutter={24}>
          <Col sm={24} md={12}>
            <Form.Item
              name="typeId"
              label="Төрөл : "
              rules={[{ required: true, message: "Төрөл сонгоно уу" }]}
            >
              <Select
                placeholder="Төрөл сонгоно уу"
                onChange={(v) => changeSubTypeFilter({ parentTypeId: v })}
              >
                {typeList.result.map((type) => {
                  return (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="subTypeId"
              label="Дэд төрөл : "
              rules={[{ required: true, message: "Дэд төрөл сонгоно уу" }]}
            >
              <Select placeholder="Дэд төрөл сонгоно уу">
                {subTypeList.result.map((type) => {
                  return (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="mark"
              label="Марк"
              rules={[{ required: true, message: "Марк сонгоно уу" }]}
            >
              <Input placeholder="Марк оруулна уу" />
            </Form.Item>
            <Form.Item
              name="usage"
              label="Ашиглалт : "
              rules={[{ required: true, message: "Ашиглалт сонгоно уу" }]}
            >
              <Select placeholder="Ашиглалт сонгоно уу">
                {EQUIPMENT_USAGE_LIST.map((type) => {
                  return (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="usageStartDate"
              label="Ажиллаж эхэлсэн он : "
              rules={[
                { required: true, message: "Ажиллаж эхэлсэн он оруулна уу" },
              ]}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="quantity"
              label="Тоо хэмжээ : "
              rules={[{ required: true, message: "Тоо хэмжээ оруулна уу" }]}
            >
              <Input placeholder="Тоо хэмжээ оруулна уу" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item
              name="countryId"
              label="Үйлдвэрлэсэн улс : "
              rules={[
                { required: true, message: "Үйлдвэрлэсэн улс оруулна уу" },
              ]}
            >
              <Select placeholder="Үйлдвэрлэсэн улс сонгоно уу">
                {countryList.result.map((type) => {
                  return (
                    <Select.Option key={type.id} value={type.id}>
                      {type.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="ownerId"
              label="Эзэмшигч : "
              rules={[{ required: true, message: "Эзэмшигч сонгоно уу" }]}
            >
              <UserSelectWithSearch
                only={true}
                placeholder="Эзэмшигч сонгоно уу"
              />
            </Form.Item>
            <Form.Item name="manufacturedDate" label="Үйлдвэрлэсэн огноо : ">
              <DatePicker picker="year" />
            </Form.Item>
            <Form.Item name="countryNumber" label="Улсын дугаар : ">
              <Input placeholder="Улсын дугаар оруулна уу" />
            </Form.Item>
            <Form.Item name="serialNumber" label="Сериал дугаар : ">
              <Input placeholder="Сериал дугаар оруулна уу" />
            </Form.Item>
            <Form.Item name="performance" label="Үзүүлэлт хүчин чадал : ">
              <Input placeholder="Үзүүлэлт хүчин чадал оруулна уу" />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col sm={24} md={12}>
            <Form.Item
              name="upload"
              label="Зураг"
              valuePropName="fileList"
              getValueFromEvent={handleChangeImage}
              rules={[{ required: true, message: "Зураг сонгоно уу" }]}
            >
              <Upload
                accept="image/*"
                name="picture"
                customRequest={mockUploadAction}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Файл сонгох</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item name="desciption" label="Тайлбар : ">
              <Input placeholder="Тайлбар оруулна уу" />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default EquipmentAddEdit;
