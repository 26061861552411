export const EQUIPMENT_USAGE = {
  NEW: "new",
  USED: "used",
  DOWN: "down",
};
export const EQUIPMENT_USAGE_REF = {
  [EQUIPMENT_USAGE.NEW]: "Шинэ",
  [EQUIPMENT_USAGE.USED]: "Хуучин",
  [EQUIPMENT_USAGE.DOWN]: "Ашиглалтаас гарсан",
};
export const EQUIPMENT_USAGE_LIST = [
  {
    id: EQUIPMENT_USAGE.NEW,
    name: "Шинэ",
  },
  {
    id: EQUIPMENT_USAGE.USED,
    name: "Хуучин",
  },
  {
    id: EQUIPMENT_USAGE.DOWN,
    name: "Ашиглалтаас гарсан",
  },
];

export const EQUIPMENT_RENT_STATUS = {
  RENTED: "rented",
  RETUNRED: "returned",
};
export const EQUIPMENT_RENT_STATUS_REF = {
  [EQUIPMENT_RENT_STATUS.RENTED]: "Түрээсэлж байгаа",
  [EQUIPMENT_RENT_STATUS.RETUNRED]: "Дууссан",
};
export const EQUIPMENT_RENT_STATUS_LIST = [
  {
    id: EQUIPMENT_RENT_STATUS.RENTED,
    name: "Түрээсэлж байгаа",
  },
  {
    id: EQUIPMENT_RENT_STATUS.RETUNRED,
    name: "Дууссан",
  },
];
export const EQUIPMENT_OWN_TYPE = {
  OWNER: "owner",
  RENTER: "renter",
};
export const EQUIPMENT_OWN_TYPE_REF = {
  [EQUIPMENT_OWN_TYPE.OWNER]: "Үгүй",
  [EQUIPMENT_OWN_TYPE.RENTER]: "Тийм",
};