import { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import BreadMe from "../lib/Breadme";
import UserContext from "../../providers/UserContext";
import moment from "moment";
import NetworkApi from "../../network/networkApi";
import { FileTextOutlined, SearchOutlined } from "@ant-design/icons";
import { USER_ROLE } from "../../enums/user.enum";

const LicenseStatus = (license: any) => {
  if (license.vAgro === "accepted" && license.pAgro === "pending") {
    return "Санал оруулсан";
  }
  if (
    license.vAgro === "accepted" &&
    license.pAgro === "accepted" &&
    license.status === "pending"
  ) {
    return "Зөвшөөрсөн";
  }
  if (
    license.vAgro === "accepted" &&
    license.pAgro === "accepted" &&
    license.status === "active"
  ) {
    return "Баталсан";
  }
  return "Тодорхойгүй";
};

const ChemicalFOrders = () => {
  const { user } = useContext(UserContext);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

  const [details, setDetails] = useState<{
    visible: boolean;
    ids: any[];
    requestId: any;
  }>({
    visible: false,
    ids: [],
    requestId: undefined,
  });
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    page: 1,
    pageSize: 10,
    count: 0,
  });
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  const [filter, changeFilter] = useState<any>({});

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet(
      "/api/chemical/license/appointment/list",
      { ...filter, supportable: true }
    );
    if (response.resultCode == 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          pageSize: response.result.pageSize,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content, filter]);

  useEffect(() => {
    loadContent();
  }, [filter]);
  const columns = [
    {
      title: "№",
      render: (a: any, b: any, index: any) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Байгууллагын нэр",
      dataIndex: "licenses",
      render: (licenses: any[]) =>
        licenses.length > 0 && licenses[0].organization.name,
    },
    {
      title: "РД",
      dataIndex: "licenses",
      render: (licenses: any[]) =>
        licenses.length > 0 && licenses[0].organization.rd,
    },
    {
      title: "Үүсгэсэн огноо",
      dataIndex: "createdAt",
      render: (value: string) => moment(value).format("YYYY-MM-DD"),
    },
    {
      title: "Бордооны тоо",
      dataIndex: "licenses",
      render: (licenses: any[]) => licenses.length,
    },
    {
      title: "Төлөв",
      dataIndex: "licenses",
      render: (licenses: any[]) =>
        licenses.length > 0 && LicenseStatus(licenses[0]),
    },
    {
      title: "Огноо илгээсэн",
      dataIndex: "createdAt",
      render: (item: string) => moment(item).format("YYYY/MM/DD"),
    },
    {
      title: "Үйлдэл",
      render: (itm: any) => (
        <Space size={10}>
          <Button
            onClick={() => {
              const ids = itm.licenses.map((item: any) => item.id);
              setDetails({
                visible: true,
                ids,
                requestId: itm.id,
              });
            }}
            type={"primary"}
            size={"small"}
            ghost
            icon={<FileTextOutlined />}
          >
            Дэлгэрэнгүй
          </Button>
        </Space>
      ),
    },
  ];

  const approveRequests = useCallback(async () => {
    let response = await NetworkApi.requestPost(
      "/api/chemical/fertilizer-license/success",
      { ids: selectedRowKeys }
    );
    if (response.resultCode == 200) {
      message.success("Хүсэлт амжилттай биелэгдлээ");
      loadContent();
      setSelectedRowKeys([]);
      setSelectedRows([]);
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [selectedRowKeys]);

  return (
    <>
      <BreadMe
        title={[
          "Зөвшөөрөл",
          "Ургамал хамгааллын бордооны жагсаалт, тоо хэмжээ",
        ]}
      />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={[16, 16]} justify={"space-between"}>
          <Col span={18}>
            <Space size={40}>
              <Form
                layout="inline"
                onFinish={(values: any) => {
                  if (values.createdDate) {
                    values.createdDate = values.createdDate.format("YYYY");
                  }
                  if (values.appointmentDate) {
                    values.appointmentDate =
                      values.appointmentDate.format("YYYY");
                  }
                  changeFilter(values);
                }}
              >
                <Form.Item name="licenseStatus">
                  <Select style={{ width: 201 }} placeholder="Төлөв" allowClear>
                    <Select.Option value="approved">Баталсан</Select.Option>
                    <Select.Option value="allowed">Зөвшөөрсөн</Select.Option>
                    <Select.Option value="uncertain">Тодорхойгүй</Select.Option>
                    <Select.Option value="suggested">
                      Санал оруулсан
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="organisationRd">
                  <Input placeholder={"Байгууллагын регистр"} />
                </Form.Item>
                <Form.Item name="createdDate">
                  <DatePicker picker="year" placeholder="Үүсгэсэн он" />
                </Form.Item>
                <Button htmlType="submit" type={"primary"}>
                  <SearchOutlined />
                </Button>
              </Form>
            </Space>
          </Col>
          <Col span={6}>
            <Row justify="end" align="middle">
              {[USER_ROLE.SPECIALIST, USER_ROLE.ADMIN].includes(
                user.role.cd
              ) && (
                <Button
                  type="primary"
                  disabled={selectedRows.length === 0}
                  onClick={() => approveRequests()}
                >
                  Зөвшөөрөх
                </Button>
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              rowSelection={
                [USER_ROLE.SPECIALIST, USER_ROLE.ADMIN].includes(user.role.cd)
                  ? {
                      selectedRowKeys,
                      type: "checkbox",
                      hideSelectAll: true,
                      getCheckboxProps: (record) => ({
                        disabled: record.licenses.some(
                          (item: any) => item.status === "active"
                        ),
                      }),
                      onChange: (rowKeys: React.Key[], rows: any[]) => {
                        setSelectedRowKeys(rowKeys);
                        setSelectedRows(rows);
                      },
                    }
                  : undefined
              }
              rowKey="id"
              scroll={{ x: true }}
              loading={content.loading}
              columns={columns}
              pagination={{
                current: content.page,
                pageSize: content.pageSize,
                total: content.count,
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize);
                },
              }}
              onChange={(p) => {
                changeFilter({
                  ...filter,
                  page: p.current || 1,
                  pageSize: p.pageSize || 10,
                });
              }}
              dataSource={content.result || []}
            />
          </Col>
        </Row>
        {details.visible && (
          <ChemicalLicenseDetails
            ids={details.ids}
            requestId={details.requestId}
            visible={details.visible}
            onHide={() => {
              setDetails({
                visible: false,
                ids: [],
                requestId: undefined,
              });
              loadContent();
            }}
          />
        )}
      </Layout.Content>
    </>
  );
};

interface DetailsState {
  loading: boolean;
  result?: any[];
  resultCode?: number;
}

interface DetailsProps {
  requestId: any;
  ids: any[];
  visible: boolean;
  onHide: any;
}

const ChemicalLicenseDetails = ({
  visible,
  onHide,
  requestId,
  ids,
}: DetailsProps) => {
  const [content, changeContent] = useState<DetailsState>({
    loading: true,
    result: [],
    resultCode: 200,
  });
  const [detail, setDetail] = useState<any>({});
  useEffect(() => {
    if (visible) {
      loadContent();
    }
  }, [visible]);
  const loadContent = useCallback(async () => {
    changeContent({
      loading: true,
    });
    let response = await NetworkApi.requestPost(
      `/api/chemical/license/details`,
      { ids }
    );

    if (response.result.rows.length > 0) {
      const license = response.result.rows[0];
      setDetail({
        licenseType: license.type,
        organizationId: license.organization.rd,
        organizationName: license.organization.name,
        organizationCEOName: license.organization.firstName,
        vAgro: license.vAgro,
        pAgro: license.pAgro,
        status: license.status,
      });
    }

    changeContent({
      loading: false,
      result: response.result.rows || [],
      resultCode: response.resultCode,
    });
  }, [ids]);
  const columns = [
    {
      title: "Бордооны нэр төрөл",
      dataIndex: "chemical",
      render: (value: any) =>
        value?.type
          ? value?.type?.name == "sale"
            ? "Борлуулах"
            : "Импортлох"
          : "-",
    },
    {
      title: "Бордооны нэр",
      dataIndex: "chemical",
      render: (value: any) => value?.technicalName ?? "-",
    },
    {
      title: "Үйлчлэх бордооны нэр",
      dataIndex: "chemical",
      render: (value: any) => value?.name ?? "-",
    },
    {
      title: "Олон улсын бүртгэлийн дугаар CAS",
      dataIndex: "chemical",
      render: (value: any) => value?.registrationNumber ?? "-",
    },
    {
      title: "Хүсэлтийн хэмжээ",
      render: (row: any) =>
        row?.requestedAmount
          ? `${row.requestedAmount} ${row.chemical.unit.name}`
          : "-",
    },
  ];

  return (
    <Modal
      width={900}
      centered
      visible={visible}
      onCancel={onHide}
      title={"Бордооны зөвшөөрлийн дэлгэрэнгүй"}
      footer={null}
    >
      <Row gutter={15}>
        <Col span={16}>Зөвшөөрлийн төрөл</Col>
        <Col span={8}>
          {detail?.licenseType == "sale" ? "Худалдан борлуулах" : "Импортлох"}
        </Col>
        <Col span={16}>Байгууллагын регистрийн дугаар</Col>
        <Col span={8}>{detail?.organizationId}</Col>
        <Col span={16}>Байгууллагын нэр</Col>
        <Col span={8}>{detail?.organizationName}</Col>
        <Col span={16}>Захирлын нэр</Col>
        <Col span={8}>{detail?.organizationCEOName}</Col>
        <Col span={16}>Утасны дугаар</Col>
        <Col span={8}></Col>
      </Row>
      <Row style={{ marginTop: 24, marginBottom: 15 }}>
        <Table
          scroll={{ x: true }}
          loading={content.loading}
          columns={columns}
          dataSource={content.result || []}
          pagination={false}
          summary={(pageData) => {
            let offerAmountTotal = 0;
            let requestedAmountTotal = 0;
            pageData.forEach(({ requestedAmount, offerAmount }) => {
              offerAmountTotal += offerAmount;
              requestedAmountTotal += requestedAmount;
            });
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  {offerAmountTotal}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                  {requestedAmountTotal}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Row>
    </Modal>
  );
};

export default ChemicalFOrders;
