import GoogleMapReact, {
    BootstrapURLKeys, MapOptions, NESWBounds,
    Size,
    fitBounds
} from "google-map-react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import moment from 'moment';
import {
    Button,
    Col,
    DatePicker,
    Descriptions,
    Form,
    Input,
    Layout,
    message,
    Popconfirm,
    Row,
    Select,
    Space,
    Table, Tag,
} from "antd"
import {
    VerticalAlignBottomOutlined,
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    FileTextOutlined
} from '@ant-design/icons';
import NetworkApi from "../../network/networkApi"
import BreadMe from "../lib/Breadme";
import AddressItemSelect from "../lib/AddressItemSelect";
import UserContext from "../../providers/UserContext";


const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const center = { lat: 47.91937048288034, lng: 106.91745115560471 };
const key: BootstrapURLKeys = { key: 'AIzaSyAMXSkvgnddqOuIuHThJ5lvF1f_j8IIwIo', libraries: "places" };
const client: BootstrapURLKeys = { client: 'turbold@digitalmedic.mn', v: '3.28' , language: 'en', libraries: "places", region: "PR", id: '123' };
const options: MapOptions = {
    zoomControl: true,
    gestureHandling: 'cooperative',
    styles: [
        {
            featureType: "administrative",
            elementType: "all",
            stylers: [ {saturation: "-100"} ]
        },
        {
            featureType: "administrative.neighborhood",
            stylers: [ {visibility: "off" } ]
        },
        {
            elementType: "labels.text.stroke",
            stylers: [ {color: "#242f3e"} ]
        },
        {
            stylers: [ {color: "#fcfffd"} ]
        }
    ],
};

const bounds: NESWBounds = {
    ne: {
      lat: 55,
      lng: 10,
    },
    sw: {
      lat: 45,
      lng: 20,
    }
};

const size: Size = {
    width: 1280,
    height: 640
};

fitBounds(bounds, size);

const MapPage =  () => {
    const { user } = useContext(UserContext)
    
    return (
        <>
            <BreadMe title={['', 'Газрын зураг']} />

            <Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
                <GoogleMapReact center={center} heatmapLibrary={true} zoom={3} bootstrapURLKeys={key} options={options} />
            </Layout.Content>
        </>
    )
};
export default MapPage
