import React, { useCallback, useEffect, useState } from 'react'
import NetworkApi from '../network/networkApi'
interface ContentData {
    result: any[],
    loading: boolean
}
const useChemicalPurposeList = (): [ContentData, () => void] => {    
    const [content, changeContent] = useState<ContentData>({
        loading: false,
        result: []
    })
    useEffect(() => {
        loadContent()
    }, [])
    const loadContent = useCallback(async () => {
        let response = await NetworkApi.requestGet('/api/chemical/purpose/list')
        changeContent({
            loading: false,
            result: response.result || []
        })
    }, [])

    return [content, loadContent]
}
export default useChemicalPurposeList