import React, { useCallback, useEffect, useState } from "react"
import { Form, Select } from "antd"
import NetworkApi from "../../network/networkApi"
interface Props {
    parent: any,
    title: string,
    disabled?: boolean
    onChange: (data: any) => void,
    value: any,
    name: string,
    subtitle?: string,
    hideLabel?: boolean,
    rule?: boolean
}
const AddressItemSelect = ({ value, parent, disabled, title, onChange: och, name, subtitle = '- Сонго -', hideLabel, rule = false }: Props) => {

    const [content, changeContent] = useState({
        loading: true,
        result: []
    })
    useEffect(() => {
        if (parent) {
            
            loadList()
        } else {
            changeContent({
                loading: false,
                result: []
            })
        }
    }, [parent])    
    const loadList = useCallback(async () => {
        changeContent({
            ...content,
            loading: true
        })
        let response = await NetworkApi.requestGet(`/api/address/${parent}`)
        changeContent({
            ...content,
            loading: false,
            result: response.result || []

        })
    }, [parent])
    return (
        <Form.Item
            label={hideLabel ? null : title}
            name={name}
            initialValue={value}
            rules={[{ required: rule, message: 'Хаяг сонгоно уу!' }]}
        >
            <Select virtual={false} disabled={disabled} style={{ width: '100%',minWidth:150 }} loading={content.loading} value={value} onChange={och}>
                <Select.Option value="">{subtitle}</Select.Option>
                {
                    content.result.map((rs: any) => {
                        return <Select.Option key={rs.id} value={rs.id}>{rs.name}</Select.Option>
                    })
                }
            </Select>
        </Form.Item >
    )
}
export default AddressItemSelect
