import { Col, Modal, Radio, RadioChangeEvent, Row } from "antd";
import { useContext, useState } from "react";
import UserContext from "../../../providers/UserContext";
import SupportProductForm1 from "./SupportProductForm1";
import SupportProductForm2 from "./SupportProductForm2";
import { SUPPORT_TYPE } from "../../../enums/support.enum";

interface Props {
  visible: boolean;
  id?: any;
  selectedSupportType: string;
  onHide: () => void;
  onSuccess: (item: SupportItemType) => void;
}

export type DealerProductType = {
  id: number;
  name: string;
  price: string;
  e_barimt_code: string;
  category: string;
  sub_category: string;
  require_certificate: boolean;
};

export type DealerType = {
  id: number;
  name: string;
  register: string;
  products: DealerProductType[];
};

export type EbarimtType = {
  totalAmount: number;
  totalCount: number;
  prdBarCode: string;
  prdProductName: string;
  prdUnitCode: string | null;
  status: string;
  aссName: string;
  pin: string;
  ddtd: string;
  createdDate: string;
};

export type SupportItemType = {
  type: 1 | 2;
  dealer: string;
  dealerRd: string;
  dealerId: number;
  productId: number;
  productCode: string;
  prdProductName: string;
  ebTotalAmount: number;
  ebTotalCount: number;
  contract?: any;
  custom?: any;
  landImage?: any[];
  name: string;
  certRequire: boolean;
  xyrStatus?: boolean;
  cabinNo?: string;
  ebarimt: EbarimtType[];
  price: number;
  totalPrice: number;
  totalCount: number;
  unit?: string;
};

export type EbarimtResultType = {
  dealer: string;
  dealerRd: string;
  productCode: string;
  prdProductName: string;
  probarCode: string;
  ebTotalAmount: number;
  ebTotalCount: number;
  ebarimt: EbarimtType[];
};

export default function SupportProductAdd({
  visible,
  id,
  selectedSupportType,
  onHide,
  onSuccess,
}: Props) {
  const { user } = useContext(UserContext);
  const [selectedProductType, changeSelectedProductType] = useState<number>(1);

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    changeSelectedProductType(Number(e.target.value));
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onHide}
        title="Бүтээгдэхүүн нэмэх"
        footer={null}
      >
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Radio.Group onChange={onChange} value={selectedProductType}>
              <Radio value={1}>Дилер байгууллагаас худалдан авсан</Radio>
              {selectedSupportType === SUPPORT_TYPE.FERTILIZER && (
                <Radio value={2}>
                  Бодис импортлогч өөрийн хэрэгцээнд хэрэглэсэн
                </Radio>
              )}
            </Radio.Group>
          </Col>
        </Row>
        {selectedProductType === 1 && (
          <SupportProductForm1
            id={id}
            selectedSupportType={selectedSupportType}
            onSuccess={onSuccess}
          />
        )}
        {selectedProductType === 2 && (
          <SupportProductForm2
            id={id}
            selectedSupportType={selectedSupportType}
            onSuccess={onSuccess}
          />
        )}
      </Modal>
    </>
  );
}
