import { useCallback, useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Space,
  Form,
  Modal,
  message,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";
import useEquipmentType from "../../hooks/useEquipmentType";

const EquipmentType = () => {
  const [content, loadContent, changeContentFilter] = useEquipmentType(1);
  const [addEdit, setAddEdit] = useState({
    visible: false,
    edit: null,
  });

  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost(
      "/api/equipment/type/update",
      {
        ...data,
        status: "D",
      }
    );
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  const elementInfo = [
    {
      title: "Төхөөрөмжийн төрөл",
      dataIndex: "name",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Button
            onClick={() => setAddEdit({ visible: true, edit: item })}
            type={"primary"}
            size={"small"}
            ghost
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна уу"
            onConfirm={() => deleteItem(item)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button
              onClick={() => {}}
              type={"primary"}
              size={"small"}
              danger
              ghost
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Төхөөрөмжийн төрөл"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"}>
              <Button
                onClick={() => setAddEdit({ visible: true, edit: null })}
                type={"primary"}
                icon={<PlusSquareOutlined />}
              >
                Төхөөрөмжийн төрөл нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={elementInfo}
              dataSource={content.result}
              onChange={handleParam}
              rowKey={"id"}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <EquipmentTypeAddEdit
        edit={addEdit.edit}
        visible={addEdit.visible}
        onHide={() => setAddEdit({ visible: false, edit: null })}
        onSuccess={() => {
          loadContent();
          setAddEdit({ visible: false, edit: null });
        }}
      />
    </>
  );
};

interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const EquipmentTypeAddEdit = ({ edit, visible, onHide, onSuccess }: Props) => {
  const formRef = useRef<any>();
  useEffect(() => {
    if (visible) {
      if (edit) {
        formRef.current?.setFieldsValue(edit);
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [edit, visible]);

  const saveContent = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      let response = await NetworkApi.requestPost(
        "/api/equipment/type/update",
        data
      );
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [edit]
  );

  return (
    <Modal
      visible={visible}
      title={edit ? "Tөхөөрөмжийн төрөл засах" : "Төхөөрөмжийн төрөл нэмэх"}
      onCancel={onHide}
      footer={null}
    >
      <Form ref={formRef} layout="vertical" onFinish={saveContent}>
        <Form.Item name="name" label="Төхөөрөмжийн төрөл">
          <Input placeholder="Төхөөрөмжийн төрөл оруулна уу" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default EquipmentType;
