import React, {useCallback, useContext, useState} from "react";
import {Avatar, Badge, Col, Divider, Layout, Menu, Row} from 'antd';

import { UserOutlined , BellOutlined, FileOutlined, VideoCameraOutlined} from '@ant-design/icons';
import UserContext from "../../providers/UserContext";
import NetworkApi from "../../network/networkApi";
import {Link} from "react-router-dom";

const { Header } = Layout;
const { SubMenu } = Menu;

const UserMenu =()=> {
    const [current, changeCurrent] = useState('key');
    const {user} = useContext(UserContext)
    const handleClick = (e:any) => {
        changeCurrent(e.key);
    };
    const logout = useCallback(async()=>{
        let response = await NetworkApi.requestGet('/api/auth/logout')
        window.location.href = '/'
    },[])
    return (
        <Header>
            <Row justify={"space-between"}>
                <Col span={12}>
                </Col>
                <Col span={12}>
                    <Row justify={'end'} gutter={15}>
                        <Col>
                            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal"  theme={"dark"}>
                                <Menu.Item key="notification">
                                    <Badge count={100} overflowCount={99}>
                                        <Avatar icon={<BellOutlined />} />
                                    </Badge>
                                </Menu.Item>
                                <SubMenu key="SubMenu" icon={<UserOutlined />} title={`${user?.lastName} ${user?.firstName}`}>
                                    <Menu.Item key="profile"><Link to={'/user/profile'}>Хувийн мэдээлэл</Link></Menu.Item>
                                    <Menu.Item key="profile"><Link target="_blank" to={{ pathname: 'https://drive.google.com/drive/u/2/folders/1_zX7qnYbNO5vmG39RsqtwCSA3m895Qr-'}}><FileOutlined/> Систем ашиглах заавар</Link></Menu.Item>
                                    <Menu.Item key="profile"><Link target="_blank" to={{ pathname: 'https://www.youtube.com/watch?v=ZGRx2nB56Lg'}}><VideoCameraOutlined/> Бүртгүүлэх заавар</Link></Menu.Item>
                                    <Menu.Item onClick={logout} key="logout">Гарах</Menu.Item>
                                </SubMenu>
                            </Menu>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Header>
    ) ;
};
export default UserMenu;
