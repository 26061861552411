import { Button, Form, Modal, Select, Upload } from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

import { mockUploadAction } from "../../../helpers/upload.helper";
import useSupportFarmlandList from "../../../hooks/useSupportFarmlandList";

interface Props {
  visible: boolean;
  selectedSupportType: string;
  supportLandIds: number[];
  onHide: () => void;
  onSuccess: (item: SupportLandType) => void;
}

export type SupportLandType = {
  landid: number;
  area: number;
  usagearea: number;
  posno: string;
  ownerType: string;
  file: any;
};

export default function SupportLandAdd({
  visible,
  selectedSupportType,
  supportLandIds,
  onHide,
  onSuccess,
}: Props) {
  const [farmLandList, loadFarmlandList] = useSupportFarmlandList();
  const [selectedFarmland, changeSelectedFarmland] = useState<
    any | undefined
  >();

  useEffect(() => {
    if (selectedSupportType) {
      loadFarmlandList(selectedSupportType);
    }
  }, [selectedSupportType]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onHide}
        title="Тариалангийн газар нэмэх"
        footer={null}
      >
        <Form
          name="product-add"
          layout="vertical"
          onFinish={(values) => {
            if (!selectedFarmland) return;

            onSuccess({
              landid: selectedFarmland.landid,
              area: selectedFarmland.area,
              usagearea: selectedFarmland.usagearea,
              posno: selectedFarmland.posno,
              ownerType: selectedFarmland.ownerType,
              file: values.file[0],
            });
          }}
        >
          <Form.Item
            label="Талбайн"
            name="farmlandId"
            rules={[{ required: true, message: "Талбайн сонгоно уу" }]}
          >
            <Select
              style={{ width: "100%", minWidth: 150 }}
              showSearch
              placeholder="Талбайн сонгоно уу"
              onChange={(landId) => {
                const farmland = farmLandList.result.find(
                  (item) => item.landid === landId
                );
                changeSelectedFarmland(farmland);
              }}
            >
              {farmLandList.result
                .filter((f: any) => !supportLandIds.includes(f.landid))
                .map((f: any) => {
                  return (
                    <Select.Option key={f.landid} value={f.landid}>
                      НТД-{f.posno}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          {selectedFarmland && (
            <Form.Item label="Ашиглаж буй газрын хэмжээ">
              {`${selectedFarmland.usagearea} га`}
            </Form.Item>
          )}
          <Form.Item
            name="file"
            label="Газрын агрохими, агрофизикийн лабораторийн шинжилгээний дүн (2019-2023)"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Файл хавсаргана уу",
              },
            ]}
          >
            <Upload
              accept=".pdf,image/*"
              name="upload"
              customRequest={mockUploadAction}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Файл сонгох</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Нэмэх
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
