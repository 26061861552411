import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Space,
  Form,
  Button,
  Table,
  DatePicker,
  message,
} from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import * as querystring from "querystring";

import "moment/locale/mn";
import locale from "antd/es/date-picker/locale/mn_MN";

import config from "../../config";
import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";
import AddressItemSelect from "../lib/AddressItemSelect";
import { formatArea, formatPercent } from "../../helpers/number.helper";
import UserContext from "../../providers/UserContext";
import { USER_ROLE } from "../../enums/user.enum";

const AimagSelect = (props: any) => {
  const [aimag, changeAimag] = useState(props.defaultAimag || "");

  useEffect(() => {
    changeAimag(props.defaultAimag);
  }, [props.defaultAimag]);

  return (
    <>
      <AddressItemSelect
        disabled={props.defaultAimag ? true : false}
        subtitle="Аймаг сонгох"
        name="aimagId"
        {...props}
        value={aimag}
        title={props.aimagTitle || "Аймаг, хот"}
        parent={1}
        onChange={(v: string) => {
          changeAimag(v);
        }}
      />
    </>
  );
};

const SumSelect = (props: any) => {
  const [sum, changeSum] = useState(props.defaultAimag || "");

  useEffect(() => {
    changeSum(props.defaultAimag);
  }, [props.defaultAimag]);

  return (
    <>
      <AddressItemSelect
        disabled={props.sum ? true : false}
        subtitle="Сум сонгох"
        name="sumId"
        {...props}
        value={sum}
        title={props.sumTitle || "Аймаг, хот"}
        parent={props.parent ?? 1}
        onChange={(v: string) => {
          changeSum(v);
        }}
      />
    </>
  );
};

interface Filter {
  aimagId: string;
  sumId: string;
  year: string;
}

interface ContentData {
  columnGroups: { key: string; name: string }[];
  result: any[];
  total: number;
  page: number;
  loading: boolean;
}

interface TableColumn {
  title: string;
  children: {
    title: string;
    dataIndex: string;
  }[];
}

interface TableGroup {
  key: string;
  name: string;
}

const ProcessReport = () => {
  const { user } = useContext(UserContext);

  const rowGroupName = useMemo(() => {
    if (
      [
        "PROVINCE_AGRONOMIST",
        "VILLAGE_AGRONOMIST",
        "PROVINCE_INSPECTOR",
      ].includes(user?.role.cd)
    ) {
      return "Сум";
    }

    return "Аймаг";
  }, [user]);
  const showVillageFilter = useMemo(
    () =>
      [USER_ROLE.PROVINCE_AGRONOMIST, USER_ROLE.PROVINCE_INSPECTOR].includes(
        user?.role.cd
      ),
    [user]
  );
  const showProvinceFilter = useMemo(
    () =>
      ![
        USER_ROLE.CUSTOMER,
        USER_ROLE.WAREHOUSE_SPECIALIST,
        USER_ROLE.VILLAGE_AGRONOMIST,
        USER_ROLE.PROVINCE_AGRONOMIST,
        USER_ROLE.PROVINCE_INSPECTOR,
      ].includes(user?.role.cd),
    [user]
  );

  const defaultYear = moment().format("YYYY");

  const [filter, changeFilter] = useState<Filter>({
    year: defaultYear,
    aimagId: "",
    sumId: "",
  });

  const [content, setContent] = useState<ContentData>({
    columnGroups: [],
    result: [],
    total: 0,
    page: 1,
    loading: false,
  });

  const loadContent = useCallback(async () => {
    setContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });

    let response = await NetworkApi.requestGet(
      "/api/cultivation/report",
      filter
    );

    if (response.resultCode === 200) {
      setContent({
        columnGroups: response.result?.columnGroups || [],
        result: response.result?.data || [],
        total: response.result?.total || 0,
        page: response.result?.page || 1,
        loading: false,
      });
    } else {
      message.error(response.message || "system error");
    }
  }, [filter]);

  const initColumns = () => {
    const columns: TableColumn[] = [];
    const aimagColumn = {
      title: rowGroupName,
      dataIndex: "group",
      children: [],
      fixed: "left",
    };

    columns.push(aimagColumn);

    if (content.result) {
      content.columnGroups.forEach((group: TableGroup) => {
        const column = {
          title: group.name,
          children: [
            {
              title: "Тариалах талбай (га)",
              dataIndex: `${group.key}_planned`,
              render: (value: number) => `${formatArea(value)}`,
            },
            {
              title: "Тариалсан талбай (га)",
              dataIndex: `${group.key}_executed`,
              render: (value: number) => `${formatArea(value)}`,
            },
            {
              title: "Хувь",
              dataIndex: `${group.key}_percent`,
              render: (value: number) => `${formatPercent(value)}`,
            },
          ],
        };
        columns.push(column);
      });
    }

    return columns;
  };

  useEffect(() => {
    loadContent();
  }, [filter, loadContent]);

  const handleSubmitSearch = ({
    year,
    aimagId,
    sumId,
  }: {
    year: moment.Moment;
    aimagId: string;
    sumId: string;
  }) => {
    changeFilter({
      year: year.format("YYYY"),
      aimagId,
      sumId,
    });
  };

  return (
    <>
      <BreadMe title={["Тайлан", "Тариалалтын мэдээ"]} />
      <Layout.Content
        style={{
          background: "#fff",
          padding: 24,
          margin: 0,
          minHeight: 280,
          width: "100%",
        }}
      >
        <Row>
          <Col flex="auto">
            <Space size={40}>
              <Form
                layout="inline"
                initialValues={{ year: moment() }}
                onFinish={handleSubmitSearch}
              >
                {showProvinceFilter && <AimagSelect name="aimagId" hideLabel />}
                {showVillageFilter && (
                  <SumSelect name="sumId" hideLabel parent={user?.aimagId} />
                )}
                <Form.Item name="year">
                  <DatePicker
                    picker="year"
                    locale={locale}
                    allowClear={false}
                  />
                </Form.Item>
                <Button htmlType="submit" type={"primary"}>
                  <SearchOutlined />
                </Button>
              </Form>
            </Space>
          </Col>
          <Col flex="none">
            <Row justify="end">
              <Button
                disabled={content.loading}
                target="blank"
                href={`${config.get(
                  "apiUrl"
                )}/api/cultivation/report/download?${querystring.stringify({
                  ...filter,
                })}`}
                type={"primary"}
              >
                <DownloadOutlined />
                Татах
              </Button>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              loading={content.loading}
              columns={initColumns()}
              dataSource={content.result}
              pagination={false}
              scroll={content.loading ? {} : { x: true }}
              bordered
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export default ProcessReport;
