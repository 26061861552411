import { Button, Checkbox, Form, Input, Select, Upload, message } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import NetworkApi from "../../../network/networkApi";
import UserContext from "../../../providers/UserContext";
import { UploadOutlined } from "@ant-design/icons";
import { mockUploadAction } from "../../../helpers/upload.helper";
import {
  DealerProductType,
  DealerType,
  SupportItemType,
} from "./SupportProductAdd";

interface Props {
  id?: any;
  selectedSupportType: string;
  onSuccess: (item: SupportItemType) => void;
}

export default function SupportProductForm2({
  id,
  selectedSupportType,
  onSuccess,
}: Props) {
  const { user } = useContext(UserContext);
  const [isHasChemicalLicense, setIsHasChemicalLicense] =
    useState<boolean>(false);

  const checkChemicalLicense = useCallback(async () => {
    const response = await NetworkApi.requestGet(
      "/api/support/chemical-license"
    );
    setIsHasChemicalLicense(response.result ?? false);
  }, []);

  useEffect(() => {
    checkChemicalLicense();
  }, []);

  const [dealerC, changeDealerC] = useState<{
    loading: boolean;
    result: DealerType[];
  }>({
    loading: true,
    result: [],
  });
  const [selectedDealer, changeSelectedDealer] = useState<
    DealerType | undefined
  >();

  const loadDealers = useCallback(async () => {
    changeDealerC({
      ...dealerC,
      loading: true,
    });
    const response = await NetworkApi.requestPost("/api/support/dealers", {
      type: selectedSupportType,
    });

    if (response.resultCode !== 200) {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      changeDealerC({
        ...dealerC,
        loading: false,
      });
    } else {
      changeDealerC({
        ...dealerC,
        loading: false,
        result: response.result.organisations,
      });
    }
  }, [selectedSupportType]);

  useEffect(() => {
    loadDealers();
  }, [selectedSupportType]);

  useEffect(() => {
    if (dealerC.result.length > 0 && user.organizations.length > 0) {
      const register = user.organizations[0].rd ?? "";
      const dealer = dealerC.result.find((item) => item.register === register);
      changeSelectedDealer(dealer);
    } else {
      changeSelectedDealer(undefined);
    }
  }, [dealerC]);

  const [selectedProduct, changeSelectedProduct] = useState<
    DealerProductType | undefined
  >();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  return (
    <Form
      name="product-add"
      layout="vertical"
      initialValues={{
        unit: "кг",
      }}
      onFinish={(values) => {
        console.log("values: ", values);
        if (!selectedDealer) return;
        if (!selectedProduct) return;

        const data: SupportItemType = {
          type: 2,
          dealer: selectedDealer.name,
          dealerRd: selectedDealer.register,
          dealerId: selectedDealer?.id,
          productId: selectedProduct?.id,
          productCode: selectedProduct.e_barimt_code,
          prdProductName: selectedProduct.name,
          ebTotalAmount: 0,
          ebTotalCount: 0,
          name: selectedProduct.name,
          certRequire: selectedProduct.require_certificate,
          custom: values.custom[0],
          landImage: values.landImage,
          ebarimt: [],
          price: +values.price,
          totalPrice: +values.price * +values.totalCount,
          totalCount: values.totalCount,
          unit: values.unit,
        };

        onSuccess(data);
      }}
    >
      <Form.Item label="Бодисын зөвшөөрөл" style={{ position: "relative" }}>
        <Checkbox
          style={{
            scale: "150%",
            top: 0,
            left: 5,
            position: "absolute",
          }}
          defaultChecked={isHasChemicalLicense}
          checked={isHasChemicalLicense}
        />
      </Form.Item>
      <Form.Item
        label="Бүтээгдэхүүн"
        name="product"
        rules={[
          {
            required: true,
            message: "Бүтээгдэхүүн сонгоно уу",
          },
        ]}
      >
        <Select
          loading={dealerC.loading}
          disabled={!selectedDealer}
          placeholder="Сонгоно уу"
          onChange={(prodId: number) => {
            console.log("onchange product id: ", prodId);
            if (prodId > 0) {
              const product = selectedDealer?.products.find(
                (item) => +item.id === +prodId
              );
              changeSelectedProduct(product);
            } else {
              changeSelectedProduct(undefined);
            }
          }}
        >
          {(selectedDealer?.products ?? []).map((item: any) => (
            <Select.Option value={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Хэрэглэсэн хэмжээ">
        <Input.Group compact>
          <Form.Item
            name="totalCount"
            noStyle
            rules={[
              {
                required: true,
                message: "Хэрэглэсэн хэмжээ оруулна уу",
              },
            ]}
          >
            <Input
              type="number"
              style={{ width: "75%" }}
              placeholder={"Хэрэглэсэн хэмжээ"}
            />
          </Form.Item>
          <Form.Item
            name="unit"
            noStyle
            rules={[
              {
                required: true,
                message: "Хэмжих нэгж оруулна уу",
              },
            ]}
          >
            <Select style={{ width: "25%" }}>
              <Select.Option value={"%"}>%</Select.Option>
              <Select.Option value={"г/кг"}>г/кг</Select.Option>
              <Select.Option value={"г/л"}>г/л</Select.Option>
              <Select.Option value={"л"}>л</Select.Option>
              <Select.Option value={"кг"}>кг</Select.Option>
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>
      <Form.Item
        name="price"
        label="Гаальд мэдүүлсэн нэгж үнэ"
        rules={[
          {
            required: true,
            message: "Нэгж үнэ оруулна уу",
          },
        ]}
      >
        <Input type="number" placeholder={"Гаальд мэдүүлсэн нэгж үнэ"} />
      </Form.Item>
      <Form.Item
        name="custom"
        label={"Гаалийн мэдүүлэг"}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: true,
            message: "Файл хавсаргана уу",
          },
        ]}
      >
        <Upload
          accept=".pdf,image/*"
          name="upload"
          customRequest={mockUploadAction}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Файл сонгох</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name="landImage"
        label="Бордоо хэрэглэсэн талбайн зураг, явцын дүнгийн зураг"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: true,
            message: "Файл хавсаргана уу",
          },
        ]}
      >
        <Upload
          accept=".pdf,image/*"
          name="upload"
          customRequest={mockUploadAction}
          maxCount={5}
        >
          <Button icon={<UploadOutlined />}>Файл сонгох</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Нэмэх
        </Button>
      </Form.Item>
    </Form>
  );
}
