import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Space,
  Switch,
  Table,
  Tag,
} from "antd";
import {
  PlusSquareOutlined,
  FileTextOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import moment from "moment";
import UserContext from "../../providers/UserContext";
import { useForm } from "antd/lib/form/Form";

interface AddEditModalData {
  visible: boolean;
  edit?: any;
}

const OrgLicense = () => {
  const [addEdit, setAddEdit] = useState<AddEditModalData>({
    visible: false,
    edit: undefined,
  });
  const { user } = useContext(UserContext);
  const [filter, changeFilter] = useState<{ organizationName: string }>({
    organizationName: "",
  });
  const [content, changeContent] = useState({
    loading: true,
    result: [],
  });

  const columns = [
    {
      title: "Хуулийн этгээд",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Зөвшөөрлийн төрөл",
      render: (record: any) => (
        <>
          {record.importLicense && <Tag color="#108ee9">Импорт</Tag>}
          {record.salesLicense && <Tag color="#108ee9">Худалдаа</Tag>}
        </>
      ),
    },
    {
      title: "Огноо",
      dataIndex: "startDate",
      key: "initial",
    },
    {
      title: "Дуусах огноо",
      dataIndex: "endDate",
      key: "expire",
    },
    {
      title: "Үйлдэл",
      render: (index: any) => (
        <Space size={10}>
          {user.role.cd == "ADMIN" && (
            <Button
              onClick={() => {
                setAddEdit({ visible: true, edit: index });
                console.log(index);
              }}
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
              title={"Дэлгэрэнгүй"}
            >
              Засах
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      "/api/organization/license/list",
      filter
    );
    if (response.resultCode == 200) {
      changeContent({
        loading: false,
        result: response.result.rows || [],
      });
    }
  }, [filter]);
  useEffect(() => {
    loadContent();
  }, [filter, loadContent]);

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe
        title={["Зөвшөөрөл", "Хуулийн этгээдийн зөвшөөрлийн жагсаалт"]}
      />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row justify={"space-between"}>
          <Form
            onFinish={(values) => {
              changeFilter(values);
            }}
          >
            <Space size={20}>
              <Form.Item name="organizationName">
                <Input placeholder={"Байгууллага"} />
              </Form.Item>
              <Form.Item>
                <Button type={"primary"} htmlType="submit">
                  <SearchOutlined />
                </Button>
              </Form.Item>
            </Space>
          </Form>
          {user.role.cd == "ADMIN" && (
            <Button
              onClick={() => setAddEdit({ visible: true })}
              icon={<PlusSquareOutlined />}
              type={"primary"}
            >
              Зөвшөөрөл нэмэх
            </Button>
          )}
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={content.result}
              onChange={handleParam}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
        <OrgLicenseAdd
          visible={addEdit.visible}
          edit={addEdit.edit}
          onHide={() => setAddEdit({ visible: false })}
          onSuccess={() => {
            setAddEdit({ visible: false });
            loadContent();
          }}
        />
      </Layout.Content>
    </>
  );
};

interface Props {
  visible: boolean;
  onHide: any;
  onSuccess: any;
  edit?: any;
}

const OrgLicenseAdd = ({ visible, onHide, onSuccess, edit }: Props) => {
  const [form] = useForm();
  const [isLoading, setLoading] = useState<boolean>(false);

  const onFinish = useCallback(
    async (data: any) => {
      if (isLoading) return;

      setLoading(true);
      if (edit) {
        data = {
          ...edit,
          ...data,
        };
      }
      if (data.importLicense == undefined) data.importLicense = false;
      if (data.salesLicense == undefined) data.salesLicense = false;
      if (data.startDate) data.startDate = data.startDate.format("YYYY-MM-DD");
      if (data.endDate) data.endDate = data.endDate.format("YYYY-MM-DD");
      let response = await NetworkApi.requestPost(
        "/api/organization/license/add",
        data
      );
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "system error");
      }
      setLoading(false);
    },
    [edit, isLoading]
  );
  useEffect(() => {
    if (visible) {
      if (edit) {
        edit.startDate = moment(edit.startDate);
        edit.endDate = moment(edit.endDate);
        form.setFieldsValue(edit);
      } else {
        form.resetFields();
      }
    }
  }, [visible, edit]);
  return (
    <Modal
      width={595}
      centered
      visible={visible}
      title={"Хуулийн этгээдийн хүсэлтийн дэлгэрэнгүй"}
      onCancel={onHide}
      footer={[
        <Button key="cancel" onClick={onHide}>
          Хаах
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={(e) => {
            e.preventDefault();
            form.submit();
          }}
        >
          Хадгалах
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item name="name" label="Хуулийн этгээдийн нэр">
          <Input />
        </Form.Item>
        <Form.Item name="rd" label="Хуулийн этгээдийн регистр">
          <Input />
        </Form.Item>
        <Form.Item name="startDate" label="Эхлэх огноо">
          <DatePicker />
        </Form.Item>
        <Form.Item name="endDate" label="Дуусах огноо">
          <DatePicker />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name="importLicense"
          label="Импортлох зөвшөөрөл"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name="salesLicense"
          label="Худалдан борлуулах болон импортлох"
        >
          <Switch />
        </Form.Item>
        <Form.Item name="description" label="Тайлбар">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default OrgLicense;
