import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Descriptions,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import AddressSelect from "../lib/AddressSelect";
import UserContext from "../../providers/UserContext";
const Option = Select.Option;
const moment = require("moment");
const columns = [
  {
    title: "№",
    render: (t: any, ind: any, index: any) => index + 1,
  },
  {
    title: "Тариаланч",
    dataIndex: "orgname",
  },
  {
    title: "Таримал",
    dataIndex: "sortname",
  },
  {
    title: "Аймаг",
    dataIndex: "aimagname",
    ellipsis: true,
  },
  {
    title: "Сум",
    dataIndex: "sumname",
    ellipsis: true,
  },
  {
    title: "НТДугаар",
    dataIndex: "posno",
    ellipsis: true,
  },
  {
    title: "Талбайн хэмжээ",
    render: (value: any) =>
      `${value?.harvestarea ? value.harvestarea : "N/A"} га`,
    ellipsis: true,
  },
  {
    title: "Тариалсан талбай",
    render: (value: any) => `${value?.cultivatearea} га`,
    ellipsis: true,
  },
  {
    title: "Хураасан талбай",
    render: (value: any) => `${value?.usagearea} га`,
    ellipsis: true,
  },
  {
    title: "Хураасан хэмжээ",
    render: (value: any) => `${value.harvest ? value.harvest : "N/A"} кг`,
    ellipsis: true,
  },
];
const AgroCultivation = () => {
  const { user } = useContext(UserContext);
  const { defaultAimag, defaultSum } = useMemo(() => {
    let defaultAimag = "";
    let defaultSum = "";
    if (
      user.role?.cd == "PROVINCE_AGRONOMIST" ||
      user.role?.cd == "VILLAGE_AGRONOMIST"
    ) {
      defaultAimag = user.aimagId;
    }
    if (user.role?.cd == "VILLAGE_AGRONOMIST") {
      defaultSum = user.sumId;
    }
    return { defaultAimag, defaultSum };
  }, [user]);
  const [form] = Form.useForm();
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    // count: 0,
    filter: { year: moment().year(), aimagId: defaultAimag, sumId: defaultSum },
    page: 1,
  });
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/harvest/report", {
      ...content.filter,
      page: content.page,
    });
    if (response.resultCode == 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          // count: response.result.count,
          result: response.result.data || [],
        };
      });
      console.log("result is: ", response.result);
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);
  return (
    <div>
      <BreadMe title={["Тариалалтын төлөвлөгөө", `Тайлан`]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Form
          layout={"inline"}
          style={{ width: "100%" }}
          onFinish={(data) => {
            let v = data;
            if (data.year) {
              v.year = data.year.format("YYYY");
            }
            changeContent({ ...content, filter: data, page: 1 });
          }}
          name="filter"
          form={form}
        >
          <Form.Item name="year">
            <DatePicker value={moment().year()} picker={"year"} />
          </Form.Item>
          <AddressSelect
            defaultAimag={defaultAimag}
            defaultSum={defaultSum}
            hideLabel={true}
            ignoreBag={true}
          />
          <Form.Item>
            <Button type={"primary"} htmlType={"submit"}>
              <SearchOutlined />
            </Button>
          </Form.Item>
        </Form>
        {/*<Descriptions title="" style={{ marginTop: 15 }}>*/}
        {/*    <Descriptions.Item label="Нийт">{content.count}</Descriptions.Item>*/}
        {/*</Descriptions>*/}
        <Row>
          <Table
            pagination={{
              current: content.page,
              // total: content.count,
            }}
            onChange={(p) => {
              changeContent({
                ...content,
                page: p.current || 1,
              });
            }}
            scroll={{ x: true }}
            rowKey={"id"}
            columns={columns}
            dataSource={content.result}
          />
        </Row>
      </Layout.Content>
    </div>
  );
};
export default AgroCultivation;
