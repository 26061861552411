import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Layout,
  Table,
  Space,
  Select,
  Row,
  Col,
  Button,
  Input,
  Modal,
  Upload,
  DatePicker,
  Image,
  Checkbox,
  Cascader,
  List,
  Card,
  Tag,
  Form,
  Popconfirm,
  message,
} from "antd";

import {
  PlusSquareOutlined,
  SearchOutlined,
  CheckSquareOutlined,
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../../network/networkApi";
import BreadMe from "../../lib/Breadme";
import FarmlandUsageDetail from "./FarmlandUsageDetail";
import UserContext from "../../../providers/UserContext";
import { Link, useParams } from "react-router-dom";
import FarmlandUsageAdd from "./FarmlandUsageAdd";
interface Params {
  id?: any;
}

const ActiveFarmlandUsageList = () => {
  let { id } = useParams<Params>();
  const { user } = useContext(UserContext);
  const [content, changeContent] = useState<{
    loading: boolean;
    farmland: any;
  }>({
    loading: false,
    farmland: {},
  });
  const [filter, changeFilter] = useState({
    id: id,
    posNo: "",
    identifier: "",
  });
  const [addEdit, setAddEdit] = useState({
    visible: false,
    id: null,
  });
  const [showDetail, setShowDetail] = useState({
    id: 0,
    visible: false,
  });
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    const response = await NetworkApi.requestGet(
      "/api/v2/farmland/detail",
      filter
    );
    changeContent({
      loading: false,
      farmland: response.result,
    });
  }, [filter]);
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestGet(
      `/api/farmland/usage/delete/${data.id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "system error");
    }
  }, []);
  const columns = [
    { title: "#", dataIndex: "id" },
    {
      title: "Ашиглаж эхэлсэн огноо",
      dataIndex: "startDate",
    },
    {
      title: "Ашиглаж дуусах огноо",
      dataIndex: "endDate",
    },
    {
      title: "Эзэмшигч",
      render: (value: any) => <>{value.organization?.rd}</>,
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      render: (type: any) => (type == "rent" ? "Түрээс" : "Эзэмшил"),
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: string) => TagCreat(value),
    },
    {
      title: "Үйлдэл",
      width: 220,
      render: (itm: any) => (
        <Space size={10}>
          {["active", "cancelled", "expired"].includes(itm.status) &&
          user.role.cd == "CUSTOMER" ? (
            <>
              <Link to={`/area/farm/${itm.id}`}>
                <Button type={"primary"} ghost size={"small"}>
                  Таримал
                </Button>
              </Link>
              <Link to={`/area/service/${itm.id}`}>
                <Button type={"primary"} ghost size={"small"}>
                  Талбайн арчилгаа
                </Button>
              </Link>
            </>
          ) : (
            <>
              {itm.status != "active" &&
              (user.role.cd == "ADMIN" || user.role.cd == "CUSTOMER") ? (
                <Button
                  onClick={() => setShowDetail({ visible: true, id: itm.id })}
                  icon={<EditOutlined />}
                  title={"Засах"}
                  type={"primary"}
                  ghost
                  size={"small"}
                >
                  Засах
                </Button>
              ) : null}
              {itm.status != "active" &&
              (user.role.cd == "ADMIN" || user.role.cd == "CUSTOMER") ? (
                <Popconfirm
                  title="Устгахдаа итгэйлтэй байна уу"
                  onConfirm={() => deleteItem(itm)}
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    onClick={() => {}}
                    type={"primary"}
                    size={"small"}
                    danger
                    ghost
                    icon={<DeleteOutlined />}
                  >
                    Устгах
                  </Button>
                </Popconfirm>
              ) : null}
              <Button
                onClick={() => setAddEdit({ visible: true, id: itm.id })}
                icon={<FileTextOutlined />}
                title={"Засах"}
                type={"primary"}
                ghost
                size={"small"}
              >
                Дэлгэрэнгүй
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  const TagCreat = (cs: string) => {
    switch (cs) {
      case "created":
        return <Tag color={"processing"}>Үүсгэсэн</Tag>;
      case "pending":
        return <Tag color={"default"}>Хүлээгдэж буй</Tag>;
      case "rejected":
        return <Tag color={"error"}>Татгалзсан</Tag>;
      case "active":
        return <Tag color={"success"}>Зөвшөөрсөн</Tag>;
      case "deleted":
        return <Tag color={"warning"}>Устгасан</Tag>;
      case "cancelled":
        return <Tag color={"magenta"}>Цуцалсан</Tag>;
      case "expired":
        return <Tag color={"purple"}>Хугацаа дууссан</Tag>;
      default:
        return <Tag color={"default"}>Хүлээгдэж буй</Tag>;
    }
  };

  return (
    <>
      <BreadMe
        title={[
          "Тариалангийн талбай",
          `T-${content.farmland?.posNo}`,
          "Зөвшөөрлүүд",
        ]}
      />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Хаяг
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Талбайн хэмжээ
          </Col>
          <Col
            span={4}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Эзэмшигчийн РД
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Ашиглаж эхэлсэн
          </Col>

          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Байршил
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {`${content.farmland?.aimag?.name ?? "-"}, ${
              content.farmland?.sum?.name ?? "-"
            }, ${content.farmland?.bag?.name ?? "-"}`}
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {`${content.farmland.area ?? "-"} га`}
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {content.farmland.ownerId}
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {content.farmland.initialDate}
          </Col>
          <Col
            span={4}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {`${content.farmland?.position ?? "-"}`}
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <h4>Зөвшөөрлүүд:</h4>
          </Col>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              columns={columns}
              dataSource={content.farmland.usage || []}
              rowKey={"id"}
            />
          </Col>
        </Row>
      </Layout.Content>
      <FarmlandUsageAdd
        id={showDetail.id}
        onHide={() => {
          setShowDetail({ visible: false, id: 0 });
        }}
        visible={showDetail.visible}
        onSuccess={() => {
          setShowDetail({ visible: false, id: 0 });
          loadContent();
        }}
      />

      <FarmlandUsageDetail
        visible={addEdit.visible}
        id={addEdit.id}
        hide={() => {
          loadContent();
          setAddEdit({ visible: false, id: null });
        }}
      />
    </>
  );
};
export default ActiveFarmlandUsageList;
