import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Carousel,
  Col,
  Descriptions,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import {
  SearchOutlined,
  FileTextOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import AddressSelect from "../lib/AddressSelect";
import UserContext from "../../providers/UserContext";
import * as querystring from "querystring";
import config from "../../config";
import { USER_ROLE } from "../../enums/user.enum";
import useCellarType from "../../hooks/useCellarType";
import { useHistory } from "react-router-dom";
import useWarehouseDetail from "../../hooks/useWarehouseDetail";
import { formatNumberWithComma } from "../../helpers/number.helper";

interface WarehouseModalProps {
  id: number;
  visible: boolean;
  onHide: () => void;
}

const WarehouseModal = ({ id, visible, onHide }: WarehouseModalProps) => {
  const [content, , changeId] = useWarehouseDetail();
  useEffect(() => {
    if (id) {
      changeId(id);
    }
  }, [id, changeId]);

  return (
    <Modal
      visible={visible}
      title={"Агуулахын дэлгэрэнгүй"}
      onCancel={onHide}
      footer={null}
      width={1214}
    >
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Carousel>
            {(content.result?.warehouse?.images ?? []).map((item: string) => (
              <div className="wd-image">
                <Image src={`${config.get("apiUrl")}/tmp/${item}`} />
              </div>
            ))}
          </Carousel>
        </Col>
        <Col span={16}>
          <Descriptions column={1}>
            <Descriptions.Item label="Эзэмшигч">
              {content.result?.warehouse?.owner ?? ""}
            </Descriptions.Item>
            {content.result?.warehouse?.rd && (
              <Descriptions.Item label="Регистр">
                {content.result.warehouse.rd}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Утас">
              {content.result?.warehouse?.phone ?? ""}
            </Descriptions.Item>
            <Descriptions.Item label="Хаяг">{`${
              content.result?.warehouse?.aimag?.name ?? ""
            }, ${content.result?.warehouse?.sum?.name ?? ""}, ${
              content.result?.warehouse?.bag?.name ?? ""
            }, ${content.result?.warehouse?.address ?? ""}`}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Table
            columns={[
              {
                title: "Зоорийн хийц",
                dataIndex: "cellarType",
                render: (value: any) => `${value.name}`,
              },
              {
                title: "Тоо",
                dataIndex: "quantity",
              },
              {
                title: "Багтаамж (тн)",
                dataIndex: "storage",
                render: (value: number | null) =>
                  formatNumberWithComma(value ?? 0),
              },
              {
                title: "Боломжит багтаамж (тн)",
                dataIndex: "availableStorage",
                render: (value: number | null) =>
                  formatNumberWithComma(value ?? 0),
              },
            ]}
            dataSource={content.result.cellars ?? []}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Descriptions>
            <Descriptions.Item label="Нэмэлт тэмдэглэл">
              {content.result?.warehouse?.note ?? ""}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Modal>
  );
};

const WareHouseList = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [cellarTypes] = useCellarType();
  const canEdit = useMemo(() => {
    if (
      user.role?.cd &&
      [USER_ROLE.ADMIN, USER_ROLE.WAREHOUSE_SPECIALIST].includes(user.role?.cd)
    ) {
      return true;
    }
    return false;
  }, [user]);
  const [content, changeContent] = useState({
    loading: false,
    page: 1,
    count: 0,
    filter: {},
    result: [],
  });

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/warehouse/list", {
      ...content.filter,
      page: content.page,
    });
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);

  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);

  const deleteItem = useCallback(async (item: any) => {
    let response = await NetworkApi.requestGet(`/api/warehouse/delete/${item}`);
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  const changeFilter = (filter: any) => {
    changeContent({ ...content, filter });
  };

  const [warehouseDetail, setWarehouseDetail] = useState<{
    visible: boolean;
    id: number;
  }>({
    visible: false,
    id: 0,
  });

  const columns = [
    {
      title: "№",
      width: 60,
      ellipsis: true,
      render: (a: any, e: any, index: any) => index + 1,
    },
    {
      title: "Аймаг",
      dataIndex: "aimag",
      ellipsis: true,
      render: (value: any) => `${value?.name}`,
    },
    {
      title: "Сум",
      dataIndex: "sum",
      ellipsis: true,
      render: (value: any) => `${value?.name}`,
    },
    {
      title: "Баг",
      dataIndex: "bag",
      ellipsis: true,
      render: (value: any) => `${value?.name}`,
    },
    {
      title: "Хуулийн этгээд иргэний нэр",
      dataIndex: "owner",
      ellipsis: true,
    },
    {
      title: "Хаяг",
      dataIndex: "address",
      ellipsis: true,
    },
    {
      title: "Утасны дугаар",
      dataIndex: "phone",
      ellipsis: true,
    },
    {
      title: "Зоорийн тоо",
      dataIndex: "cellarsQuantity",
      ellipsis: true,
      render: (value: number | null) => formatNumberWithComma(value ?? 0),
    },
    {
      title: "Багтаамж (тн)",
      dataIndex: "cellarsStorage",
      ellipsis: true,
      render: (value: number | null) => formatNumberWithComma(value ?? 0),
    },
    {
      title: "Боломжит багтаамж (тн)",
      dataIndex: "cellarsAvailableStorage",
      ellipsis: true,
      render: (value: number | null) => formatNumberWithComma(value ?? 0),
    },
    {
      title: "Дэлгэрэнгүй",
      render: (value: any) => (
        <Space size={10}>
          <Button
            type={"primary"}
            size={"small"}
            ghost
            icon={<FileTextOutlined />}
            onClick={() =>
              setWarehouseDetail({
                visible: true,
                id: value.id,
              })
            }
          />
          {canEdit && value?.createdById === user.id ? (
            <>
              <Button
                onClick={() => history.push("/wm/warehouse/edit/" + value.id)}
                type={"primary"}
                size={"small"}
                ghost
                icon={<EditOutlined />}
              ></Button>
              <Popconfirm
                placement="top"
                title={"Та устгахдаа итгэлтэй байна уу"}
                onConfirm={() => deleteItem(value.id)}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button
                  danger
                  type={"primary"}
                  size={"small"}
                  ghost
                  icon={<DeleteOutlined color="red" />}
                />
              </Popconfirm>
            </>
          ) : null}
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadMe title={["Агуулах", "Агуулах зоорийн жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="inline"
              onFinish={(filter: any) => {
                changeFilter(filter);
              }}
            >
              <Row gutter={[0, 16]} style={{ width: "100%" }}>
                <Col flex="none">
                  <Row gutter={[0, 16]}>
                    <AddressSelect hideLabel={true} />
                  </Row>
                </Col>
                <Col flex="none">
                  <Form.Item name="key">
                    <Input placeholder={"Нэр, РД-аар хайх"} />
                  </Form.Item>
                </Col>
                <Col flex="200px">
                  <Form.Item name="phone">
                    <Input placeholder={"Утасны дугаараар хайх"} />
                  </Form.Item>
                </Col>
                <Col flex="250px">
                  <Form.Item name="cellarType">
                    <Select placeholder="Зоорийн хийцийн төрөл">
                      {(cellarTypes.result ?? []).map((item: any) => (
                        <Select.Option
                          key={`ct-${item.id}`}
                          value={`${item.id}`}
                        >
                          {item.name ?? ""}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Button
                    htmlType="submit"
                    type={"primary"}
                    style={{ marginRight: 16 }}
                  >
                    <SearchOutlined />
                  </Button>
                </Col>
                {USER_ROLE.CUSTOMER !== user.role?.cd && (
                  <Col flex="none">
                    <Button
                      target="blank"
                      href={`${config.get(
                        "apiUrl"
                      )}/api/warehouse/list/download?${querystring.stringify(
                        content.filter
                      )}`}
                      type={"primary"}
                    >
                      <DownloadOutlined />
                      Татах
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
        <Descriptions title="" style={{ marginTop: 15 }}>
          <Descriptions.Item label="Нийт">{content.count}</Descriptions.Item>
        </Descriptions>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Row style={{ marginTop: 15, marginBottom: 15 }}>
            <Col span={24}>
              <Table
                className="plant-table"
                pagination={{
                  current: content.page,
                  total: content.count,
                }}
                onChange={(p) => {
                  changeContent({
                    ...content,
                    page: p.current || 1,
                  });
                }}
                loading={content.loading}
                columns={columns}
                dataSource={content.result}
                rowKey={"id"}
                scroll={{ x: true }}
              />
            </Col>
          </Row>
        </Row>
        {warehouseDetail.visible && (
          <WarehouseModal
            visible={warehouseDetail.visible}
            id={warehouseDetail.id}
            onHide={() =>
              setWarehouseDetail({
                visible: false,
                id: 0,
              })
            }
          />
        )}
      </Layout.Content>
    </>
  );
};

export default WareHouseList;
