import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Input, Row, Modal, Divider } from "antd";
import {
  ApartmentOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
  AuditOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import NetworkApi from "../network/networkApi";
interface ContentInterface {
  loading: boolean;
  result: any;
}
const Dashboard = () => {
  const [content, changeContent] = useState<ContentInterface>({
    loading: true,
    result: {},
  });
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet("/api/user/dashboard");
    if (response.resultCode == 200) {
      changeContent({
        loading: false,
        result: response.result,
      });
    }
  }, []);

  const showAnnouncements = useCallback(async () => {
    const isShow = window.localStorage.getItem("announcement");
    if (isShow) {
      Modal.warning({
        title: <div style={{ textAlign: "center" }}>Мэдэгдэл</div>,
        content: (
          <div style={{ width: "100%" }}>
            <p style={{ textAlign: "center" }}>
              2023 оны тариалалтын мэдээг "систем"-д оруулахыг 2023.09.15-ны
              өдрөөр тасалбар болгож байгаа тул хугацаандаа мэдээллээ оруулна
              уу.
            </p>
            <p style={{ textAlign: "center" }}>
              2023.09.15-ны өдрөөс хойш Тариалалтын мэдээг оруулах үйлдлийг хаах
              болохыг мэдэгдье.
            </p>
            <p style={{ textAlign: "center", fontWeight: 500 }}>
              ХХААХҮЯ - ГАЗАР ТАРИАЛАНГИЙН БОДЛОГЫН ХЭРЭГЖИЛТИЙГ ЗОХИЦУУЛАХ
              ГАЗАР
            </p>
            <div style={{ textAlign: "center" }}>
              <span>
                Холбоо барих: <a href="tel:51263408">51263408</a>
              </span>
            </div>
          </div>
        ),
        okText: "За",
        icon: null,
        onOk: () => window.localStorage.removeItem("announcement"),
      });
    }
  }, []);

  useEffect(() => {
    loadContent();
    // showAnnouncements();
  }, []);
  return (
    <Row gutter={15} style={{ padding: 20, paddingBottom: 0 }}>
      {content.result.landArea ? (
        <Col span={8}>
          <LineChart
            Icon={AppstoreOutlined}
            name="Тариалангийн талбай"
            countValue={`Тоо-${content.result.landCount}`}
            sizeValue={`Нийт хэмжээ-${(content.result.landArea || 0)
              .toFixed(1)
              .toLocaleString()}га`}
          />
        </Col>
      ) : null}
      <Col span={8}>
        <LineChart
          Icon={ApartmentOutlined}
          name="Ашиглагдаж буй"
          countValue={`Тоо-${content.result.activeUsage}`}
          sizeValue={`Нийт хэмжээ-${(content.result.activeUsageArea || 0)
            .toFixed(1)
            .toLocaleString()}га`}
        />
      </Col>
      <Col span={8}>
        <LineChart
          Icon={AppstoreAddOutlined}
          name="Тариалангийн талбайн хүсэлт"
          countValue={`Тоо-${content.result.pendingUsage}`}
          sizeValue={`Нийт хэмжээ-${(content.result.pendingUsageArea || 0)
            .toFixed(1)
            .toLocaleString()}га`}
        />
      </Col>
      {content.result.orgCount ? (
        <Col span={8}>
          <LineChart
            Icon={AuditOutlined}
            name="Бүртгэлтэй хуулийн этгээд"
            countValue={`${content.result.orgCount}`}
            sizeValue={null}
          />
        </Col>
      ) : null}
      {content.result.farmerCount ? (
        <Col span={8}>
          <LineChart
            Icon={IdcardOutlined}
            name="Бүртгэлтэй тариаланч"
            countValue={`${content.result.farmerCount}`}
            sizeValue={null}
          />
        </Col>
      ) : null}
    </Row>
  );
};
interface Props {
  Icon: any;
  name: string;
  countValue: any;
  sizeValue: any;
}
const LineChart = ({ Icon, name, countValue, sizeValue }: Props) => {
  return (
    <Card style={{ borderRadius: 15, marginBottom: 15 }}>
      <div className="" style={{ textAlign: "center" }}>
        <Icon style={{ fontSize: 50 }} />
        <Divider plain>{name}</Divider>
        <div style={{ textAlign: "center", fontSize: 20 }}>{countValue}</div>
        {sizeValue && (
          <div style={{ textAlign: "center", fontSize: 20 }}>{sizeValue}</div>
        )}
      </div>
    </Card>
  );
};

export default Dashboard;
