import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Layout,
  message,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { EditOutlined, FileTextOutlined } from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import UserContext from "../../providers/UserContext";

const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const PlannedPlantList = () => {
  const { user } = useContext(UserContext);

  const [content, changeContent] = useState({
    loading: true,
    result: [],
    filter: { rd: user.rd },
  });

  useEffect(() => {
    loadContent();
  }, [content.filter]);

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });

    // let response = await NetworkApi.requestGet('/api/support/list', { ...content.filter})

    // if (response.resultCode == 200) {
    //     changeContent(c => {
    //         return {
    //             ...c,
    //             loading: false,
    //             result: JSON.parse(response.result)
    //         }
    //     })
    // } else {
    //     message.error(response.message || 'system error')
    // }
  }, [content]);

  // content.result.sort((a, b) => {
  //     if (a['status_date'] > b['status_date']) {
  //       return -1;
  //     }
  //     if (a['status_date'] < b['status_date']) {
  //       return 1;
  //     }
  //     return 0;
  //   });

  console.log("content = ", content);
  const planInfo = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "Төлөвлөсөн огноо",
      dataIndex: "date",
    },
    {
      title: "Төлөвлөсөн ажилтан",
      dataIndex: "register",
    },
    {
      title: "Арга хэмжээний төрөл",
      dataIndex: "name",
    },
    {
      title: "Арга хэмжээ",
      dataIndex: "category",
    },
    {
      title: "Хаяг",
      dataIndex: "sub_category",
    },
    {
      title: "Төлөвлөгөөнд орсон тариаланчид",
      dataIndex: "product",
    },
    {
      title: "Төлөвлөсөн талбайн хэмжээ /га/",
      dataIndex: "max",
    },
    {
      title: "Гүйцэтгэл тариаланчаар",
      dataIndex: "amount",
      render: (value: number) =>
        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "Гүйцэтгэл талбайн хэмжээгээр /га/",
      dataIndex: "price",
      render: (value: number) =>
        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "Гүйцэтгэх нэгж нэр",
      dataIndex: "totalPrice",
      render: (value: number) =>
        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
  ];

  return (
    <>
      <BreadMe title={["УХ, агрономи нэгж төлөвлөгөөт ажил", "Жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              rowKey={"id"}
              columns={planInfo}
              dataSource={content.result}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

export default PlannedPlantList;
