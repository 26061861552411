import { useCallback, useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Select,
  Space,
  Form,
  Modal,
  DatePicker,
  Divider,
  message,
  InputNumber,
  Tooltip,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import {
  SearchOutlined,
  DownloadOutlined,
  PlusSquareOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import * as querystring from "querystring";
import BreadMe from "../lib/Breadme";
import useChemicalTypeList from "../../hooks/useChemicalTypeList";
import useChemicalWarehouse from "../../hooks/useChemicalWarehouse";
import NetworkApi from "../../network/networkApi";
import UserContext from "../../providers/UserContext";
import moment from "moment";
import config from "../../config";
import { useForm } from "antd/lib/form/Form";
interface ContentData {
  result: any[];
  count: number;
  page: number;
  loading: boolean;
}
interface ModalState {
  visible: boolean;
  edit?: any;
}
const WareHouse = () => {
  const [visible, setVisible] = useState<ModalState>({
    visible: false,
  });
  const [filter, changeFilter] = useState<any>({});
  const [content, updateContent] = useState<ContentData>({
    result: [],
    count: 0,
    page: 0,
    loading: false,
  });
  const [typeList] = useChemicalTypeList();
  const loadContent = useCallback(async () => {
    updateContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/chemical/sale/list", {
      ...filter,
      page: content.page,
    });

    updateContent({
      loading: false,
      result: response.result?.rows || [],
      count: response.result?.total || 0,
      page: response.result?.page || 1,
    });
  }, [content, filter]);
  const decline = useCallback(async (id: any) => {
    let response = await NetworkApi.requestGet(
      `/api/chemical/sale/delete/${id}`
    );
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Системд алдаа гарлаа");
    }
  }, []);
  useEffect(() => {
    loadContent();
  }, [content.page, filter]);
  const elementInfo = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
    },
    {
      title: "Бодис, бордооны төрөл",
      render: (value: any) => value.chemical?.type?.name,
    },
    {
      title: "Бодис, бордооны нэр",
      render: (value: any) => value.chemical?.technicalName,
    },
    {
      title: "Хэмжээ/кг",
      render: (value: any) => `${value.amount}${value.chemical?.unit?.name}`,
    },
    {
      title: "Огноо",
      dataIndex: "saleDate",
    },
    {
      title: "Зарцуулалтын төрөл",
      render: (value: any) =>
        value.type == "sale" ? "Худалдан борлуулах" : "Устгал",
    },
    {
      title: "Борлуулагч",
      render: (value: any) => `${value.seller?.name}-${value.seller?.rd}`,
    },
    {
      title: "Хүлээн авагч",
      render: (value: any) => `${value.buyerRd} ${value.buyer?.name || ""}`,
    },
    {
      title: "Төлөв",
      render: (value: any) =>
        value.status == "active" ? (
          "Баталгаажсан"
        ) : value.status == "reject" ? (
          "Цуцлагдсан"
        ) : user.role.cd == "HEAD_OF_OFFICE" ||
          user.role.cd == "SPECIALIST" ||
          user.role.cd == "ADMIN" ||
          user.role.cd == "CUSTOMER" ? (
          <Space>
            <Button
              onClick={() => setVisible({ visible: true, edit: value.id })}
              type={"primary"}
              size={"small"}
              ghost
            >
              <EditOutlined />
            </Button>
            <Tooltip title="Устгах">
              <Popconfirm
                placement="topLeft"
                title={"Та устгахдаа итгэлтэй байна уу?"}
                onConfirm={() => {
                  decline(value.id);
                }}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button
                  type={"primary"}
                  danger
                  size={"small"}
                  icon={<DeleteOutlined />}
                ></Button>
              </Popconfirm>
            </Tooltip>
          </Space>
        ) : (
          <></>
        ),
    },
  ];

  const data: any[] = [];

  const columns = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "Бодис, бордооны нэр",
      dataIndex: "element",
      key: "element",
    },
    {
      title: "Огноо",
      dataIndex: "date",
      key: "date",
      ellipsis: true,
    },
    {
      title: "Хэмжээ",
      dataIndex: "size",
      key: "size",
      ellipsis: true,
    },
    {
      title: "Зарцуулалтын төрөл",
      dataIndex: "type",
      key: "type",
      ellipsis: true,
    },
    {
      title: "Хаанаас",
      dataIndex: "from",
      key: "from",
      ellipsis: true,
    },
    {
      title: "Баримтын акт",
      dataIndex: "documents",
      key: "documents",
      ellipsis: true,
      render: (value: any) => (
        <Space size={10}>
          <Link to={"#"}>{value}</Link>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };
  const { user } = useContext(UserContext);
  return (
    <>
      <BreadMe title={["Зарцуулсан бодис, бордоо, савны жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <h4>Бодис, бордооны бүртгэл</h4>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 15, marginBottom: 15 }}
          justify={"space-between"}
        >
          <Col span={24}>
            <Space size={40}>
              <Form
                layout="inline"
                onFinish={(filter: any) => {
                  let saleDate;
                  if (filter.date?.length > 0) {
                    filter.startDate = filter.date[0].format("YYYY-MM-DD");
                    filter.endDate = filter.date[1].format("YYYY-MM-DD");
                  }
                  changeFilter({
                    ...filter,
                    saleDate,
                  });
                }}
              >
                <Form.Item name="chemicalTypeId">
                  <Select
                    allowClear
                    style={{ width: 180 }}
                    placeholder="Бодис, бордооны төрөл"
                  >
                    {typeList.result.map((t) => (
                      <Select.Option key={t.id} value={t.id}>
                        {t.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="chemicalName">
                  <Input placeholder="Бодис, бордооны нэр" />
                </Form.Item>
                <Form.Item name="date">
                  <DatePicker.RangePicker placeholder={["Эхлэх", "Дуусах"]} />
                </Form.Item>
                <Form.Item name="type">
                  <Select
                    allowClear
                    style={{ width: 200 }}
                    placeholder="Зарцуулалтын төрөл"
                  >
                    <Select.Option value="sale">Худалдаа</Select.Option>
                    <Select.Option value="care">Арчилгаа</Select.Option>
                    <Select.Option value="delete">Устгал</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="seller">
                  <Input placeholder="Борлуулагч нэр, РД" />
                </Form.Item>
                <Form.Item name="buyer">
                  <Input placeholder="Хүлээн авагч нэр, РД" />
                </Form.Item>
                <Form.Item name="status">
                  <Select allowClear style={{ width: 160 }} placeholder="Төлөв">
                    <Select.Option value="pending">Хүлээгдэж буй</Select.Option>
                    <Select.Option value="active">Баталгаажсан</Select.Option>
                    <Select.Option value="rejected">Цуцлагдсан</Select.Option>
                  </Select>
                </Form.Item>
                <Button
                  htmlType="submit"
                  value="sdarara"
                  name="ss"
                  type={"primary"}
                >
                  <SearchOutlined />
                </Button>
              </Form>
            </Space>
          </Col>
        </Row>
        <Row justify={"end"} gutter={24}>
          <Col>
            <Button
              target="blank"
              href={`${config.get(
                "apiUrl"
              )}/api/chemical/sale/list/download?${querystring.stringify(
                filter
              )}`}
              type={"primary"}
            >
              <DownloadOutlined />
              Татах
            </Button>
          </Col>

          {user?.organizations?.length > 0 &&
          user.organizations[0].license?.length > 0 ? (
            <Col>
              <Button
                onClick={() => setVisible({ visible: true })}
                type={"primary"}
              >
                <PlusSquareOutlined />
                Бодис, бордоо зарцуулах
              </Button>
            </Col>
          ) : null}
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              pagination={{
                current: content.page,
                total: content.count,
              }}
              onChange={(p) => {
                updateContent({
                  ...content,
                  page: p.current || 1,
                });
              }}
              loading={content.loading}
              columns={elementInfo}
              dataSource={content.result}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
        <Divider plain />
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify="end">
              <Button type={"primary"}>
                <PlusSquareOutlined />
                Сав баглаа устгах
              </Button>
            </Row>
          </Col>
          <Col span={24}>
            <h4>Сав баглаа</h4>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={data}
              onChange={handleParam}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <ChemicalSpend
        visible={visible.visible}
        edit={visible.edit}
        onHide={() => {
          setVisible({ visible: false });
          loadContent();
        }}
      />
    </>
  );
};
interface Props {
  visible: boolean;
  onHide: any;
  edit?: any;
}
const ChemicalSpend = ({ visible, onHide, edit }: Props) => {
  const [form] = useForm();
  const [selectedChemicalType, changeSelectedChemicalType] = useState<any>("");
  const [wareHouseList, _loadLicense, licenseFilter] = useChemicalWarehouse();
  const [editState, changeEdit] = useState<any>({});

  useEffect(() => {
    if (visible) {
      form.resetFields();
      licenseFilter({
        status: "active",
      });
      if (edit) {
        loadChemicalSale(edit);
      } else {
        changeEdit({});
      }
    }
  }, [visible, edit]);
  const loadChemicalSale = useCallback(async (id: any) => {
    let response = await NetworkApi.requestGet(
      `/api/warehouse/chemical/sale/detail/${id}`
    );
    if (response.resultCode == 200) {
      form.setFieldsValue({
        ...response.result,
        saleDate: moment(response.result.saleDate),
      });
      changeEdit(response.result);
      changeRemaining(
        (response.result?.warehouse?.remaining ?? 0) +
          (response.result?.amount ?? 0) ?? undefined
      );
    } else {
      onHide();
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
    console.log(response);
  }, []);
  const [remaining, changeRemaining] = useState<number | undefined>();

  useEffect(() => {
    if (!visible) {
      changeRemaining(undefined);
      form.resetFields();
      licenseFilter({
        status: "active",
      });
    }
  }, [visible]);

  const onFinish = useCallback(
    async (value: any) => {
      if (value.importDate) {
        value.saleDate = value.saleDate.format("YYYY-MM-DD");
      }
      if (value.buyerId) {
        value.buyerId = value.buyerId.value;
      }
      if (edit) {
        value.id = edit;
      }
      let response = await NetworkApi.requestPost(
        "/api/warehouse/chemical/sale",
        value
      );
      if (response.resultCode == 200) {
        onHide();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [edit]
  );

  useEffect(() => {
    console.log({ remaining });
  }, [remaining]);

  return (
    <Modal
      onCancel={onHide}
      visible={visible}
      title={"Бодис, бордоо зарцуулалт"}
      footer={[
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Хадгалах
        </Button>,
        <Button type="default" onClick={onHide}>
          Хаах
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item name="type" label="Зарцуулалтын төрөл">
          <Select>
            <Select.Option value="sale">Худалдаа</Select.Option>
            <Select.Option value="delete">Устгал</Select.Option>
          </Select>
        </Form.Item>
        {editState.id ? (
          <>
            <Form.Item
              name="warehouseId"
              hidden
              initialValue={editState.warehouse?.id}
            >
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="warehouses"
              label="Агуулах"
              initialValue={editState.warehouse?.chemical?.technicalName}
            >
              <Input disabled />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            name="warehouseId"
            label="Агуулах"
            rules={[
              {
                required: true,
                message: "Агуулах сонгоно уу!",
              },
            ]}
          >
            <Select
              onSelect={(v) => {
                const license = wareHouseList.result.find(
                  (item) => item.id == v
                );
                changeSelectedChemicalType(license?.chemical?.type);
                console.log("warehouse select", license?.remaining);
                changeRemaining(license?.remaining ?? undefined);
              }}
            >
              <Select.Option value="">Агуулах</Select.Option>
              {wareHouseList.result.map((t) => (
                <Select.Option value={t.id}>
                  {t.chemical?.technicalName}-{t.remaining}
                  {t.chemical?.unit?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="saleDate"
          label="Борлуулсан огноо"
          rules={[
            {
              required: true,
              message: "Борлуулсан огноо оруулна уу!",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="buyerRd"
          label="Худалдан авагчийн регистр"
          rules={[
            {
              required: true,
              message: "Худалдан авагчийн регистр оруулна уу!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="amount"
          label="Бодис, бордооны хэмжээ"
          rules={[
            {
              required: true,
              message: "Бодис, бордооны хэмжээ оруулна уу!",
            },
            () => ({
              validator(_, value) {
                if (
                  typeof value === "number" &&
                  typeof remaining === "number" &&
                  value > remaining
                ) {
                  return Promise.reject(
                    new Error("Зарцуулах хэмжээ үлдэгдлээс хэтэрсэн байна!")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            parser={(value) => value?.replace(",", ".") || ""}
            addonAfter="кг"
          />
        </Form.Item>
        {selectedChemicalType?.hasParcel && (
          <Form.Item name="parcel" label="Сав баглааны хэмжээ(ш)">
            <InputNumber parser={(value) => value?.replace(",", ".") || ""} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
export default WareHouse;
