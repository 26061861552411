import { message, Select, Spin } from "antd";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import NetworkApi from "../../network/networkApi";

interface SelectProps {
  only: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (value: any) => void;
}

const UserSelectWithSearch: React.FC<SelectProps> = ({
  only,
  placeholder,
  value,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState<any>();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = async (search?: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }
      let response = await NetworkApi.requestGet("/api/user/search/list", {
        search,
        only,
      });
      if (response.resultCode === 200) {
        const newOptions = response.result?.rows.map((item: any) => ({
          label: `${item.lastName?.substring(0, 1)}.${item.firstName} (${
            item.rd
          })`,
          value: item.id,
        }));
        setOptions(newOptions);
        setFetching(false);
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
        setFetching(false);
      }
    };

    return debounce(loadOptions);
  }, [800]);

  useEffect(() => {
    debounceFetcher();
  }, []);

  return (
    <Select
      value={selectedValue || value}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
      showSearch
      onSelect={(newValue: any) => {
        setSelectedValue(newValue);
        if (onChange) {
          onChange(newValue);
        }
      }}
      onClear={() => {
        setSelectedValue(undefined);
        if (onChange) {
          onChange(undefined);
        }
      }}
      style={{ minWidth: 150 }}
      placeholder={placeholder}
      allowClear
    />
  );
};

export default UserSelectWithSearch;
