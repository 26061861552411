import { useEffect, useState } from "react";
import { Col, Layout, Row, Tabs } from "antd";
import BreadMe from "../lib/Breadme";
import { useParams } from "react-router-dom";
import useUserDetail from "../../hooks/useUserDetail";
import FarmerFarmlandList from "./atoms/FarmerFarmlandList";
import FarmerLicenseList from "./atoms/FarmerLicenseList";
import FarmerWarehousePlant from "./atoms/FarmerWarehousePlant";
import FarmerWarehouseChemical from "./atoms/FarmerWarehouseChemical";
import FarmerIncentiveList from "./atoms/FarmerIncentiveList";
import FarmerSuppportList from "./atoms/FarmerSupportList";

const FarmerDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [activeTab, changeActiveTab] = useState("farmland");
  const [farmer, loadFarmer] = useUserDetail();
  const [fData, setFData] = useState<{
    type: string;
    name: string;
    confirmStatus: string;
    rd: string;
    phone: string;
    address: string;
    ownerId: string;
  }>({
    type: "",
    name: "",
    confirmStatus: "",
    rd: "",
    phone: "",
    address: "",
    ownerId: "",
  });

  useEffect(() => {
    if (id) {
      loadFarmer(+id);
    }
  }, [id]);

  const getConfirmStatus = (status: string): string => {
    switch (status) {
      case "yes":
        return "Баталгаажсан";
      case "no":
        return "Цуцалсан";
      default:
        return "Баталгаажуулаагүй";
    }
  };

  const updateFData = () => {
    if (
      !farmer ||
      farmer.resultCode !== 200 ||
      (farmer.result?.organizations ?? []).length === 0
    ) {
      setFData({
        type: "",
        name: "",
        confirmStatus: "",
        rd: "",
        phone: "",
        address: "",
        ownerId: "",
      });
      return;
    }
    const organization = farmer.result.organizations[0];
    const type = organization.type === "farmer" ? "Иргэн" : "Хуулийн этгээд";
    let name = "";
    let rd = "";
    if (organization.type === "farmer") {
      name = `${farmer.result.lastName.substr(0, 2)}.${
        farmer.result.firstName
      }`;
      rd = farmer.result.rd;
    } else {
      name = farmer.result.firstName;
      rd = organization.rd;
    }

    setFData({
      type,
      name,
      rd,
      confirmStatus: getConfirmStatus(farmer.result.confirm),
      phone: farmer.result?.phone ?? "",
      address: farmer.result?.sum?.fullName ?? "",
      ownerId: organization.id ?? "",
    });
  };

  useEffect(() => {
    updateFData();
  }, [farmer.result]);

  return (
    <>
      <BreadMe title={["Тариаланчийн дэлгэрэнгүй"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row justify="center" align="top" gutter={16} className="farmer">
          <Col span={8}>
            <div className="profile-card">
              <Row>
                <Col span={24} className="profile-name-outer">
                  <div className="profile-name">{fData.name}</div>
                </Col>
                <Col span={24} className="profile-list-item-outer">
                  <div className="profile-list-item">
                    <div className="label">
                      <span>Утасны дугаар</span>
                    </div>
                    <div className="value">{fData.phone}</div>
                  </div>
                </Col>
                <Col span={24} className="profile-list-item-outer">
                  <div className="profile-list-item">
                    <div className="label">
                      <span>Харьяалал</span>
                    </div>
                    <div className="value">{fData.address}</div>
                  </div>
                </Col>
                <Col span={24} className="profile-list-item-outer">
                  <div className="profile-list-item">
                    <div className="label">
                      <span>Регистрийн дугаар</span>
                    </div>
                    <div className="value">{fData.rd}</div>
                  </div>
                </Col>
                <Col span={24} className="profile-list-item-outer">
                  <div className="profile-list-item">
                    <div className="label">
                      <span>Төрөл</span>
                    </div>
                    <div className="value">{fData.type}</div>
                  </div>
                </Col>
                <Col span={24} className="profile-list-item-outer">
                  <div className="profile-list-item">
                    <div className="label">
                      <span>Баталгаажсан төлөв</span>
                    </div>
                    <div className="value">{fData.confirmStatus}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={16}>
            <div className="info-card">
              <Row>
                <Col span={24}>
                  <Tabs onChange={(key) => changeActiveTab(key)} type="card">
                    <Tabs.TabPane tab="Талбай" key="farmland">
                      {activeTab === "farmland" && (
                        <FarmerFarmlandList rd={fData.rd} />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ГҮГ" key="license">
                      {activeTab === "license" && (
                        <FarmerLicenseList rd={fData.rd} />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Бүтээгдэхүүн" key="plant">
                      {activeTab === "plant" && (
                        <FarmerWarehousePlant ownerId={fData.ownerId + ""} />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Бодис, бордоо" key="chemical">
                      {activeTab === "chemical" && (
                        <FarmerWarehouseChemical
                          organizationId={fData.ownerId + ""}
                        />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Урамшуулал" key="incentive">
                      {activeTab === "incentive" && (
                        <FarmerIncentiveList rd={fData.rd} />
                      )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Дэмжлэг" key="support">
                      {activeTab === "support" && (
                        <FarmerSuppportList rd={fData.rd} />
                      )}
                    </Tabs.TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export default FarmerDetail;
