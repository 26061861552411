import { message } from "antd"
import { useCallback, useEffect, useState } from "react"
import NetworkApi from "../network/networkApi"
interface DetailData{
    loading:boolean
    resultCode:number
    farmland:any
    usageList:any
    ownerHistory: any
}
const useFarmlandDetail = (detailId?:any): [DetailData, (id:any) => void, any]=>{
    const [id,changeId] = useState(detailId)
    const [content, changeContent] = useState<DetailData>({
        loading:false,
        resultCode:200,
        farmland:{},
        usageList:[],
        ownerHistory: []
    })
    useEffect(()=>{
        if(id){
            loadContent(id)
        }
    },[id])
    const loadContent = useCallback(async (id:any)=>{
        changeContent({
            ...content,
            loading:true
        })
        let response = await NetworkApi.requestGet(`/api/farmland/detail/${id}`)
        if(response.resultCode==200){
            changeContent({
                loading:false,
                resultCode:response.resultCode,
                farmland:response.result.farmland,
                usageList:response.result.usageList,
                ownerHistory:response.result.ownerHistory
            })
        }else{
            changeContent({
                ...content,
                loading:false,
                resultCode:response.resultCode
            })
            message.error(response.message||'system error')
        }
    },[])
    return [content,loadContent,changeId]
}
export default useFarmlandDetail
