import { useEffect, useState } from "react";
import { Table } from "antd";
import useFarmerPlantWarehouse from "../../../hooks/useFarmerPlantWarehouse";

const FarmerWarehousePlant = ({ ownerId }: { ownerId: string }) => {
  const [plant, , loadContent] = useFarmerPlantWarehouse();
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);

  useEffect(() => {
    if (ownerId?.length > 0) {
      loadContent({
        ...plant,
        ownerId,
        page: 1,
        pageSize: 10,
      });
    }
  }, [ownerId]);

  const columnsT = [
    {
      title: "№",
      render: (a: any, b: any, index: any) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Таримлын төрөл",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.plant.type.name}` : "-"),
    },
    {
      title: "Таримал",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.plant.name}` : "-"),
    },
    {
      title: "Таримлын сорт",
      dataIndex: "sort",
      render: (sort: any) => (sort ? `${sort.name}` : "-"),
    },
    {
      title: "Хаанаас",
      render: (itm: any) => {
        if (itm.type == "harvest") {
          return `НТД-${itm.farmlandPlant?.usage?.farmland?.posNo}`;
        }
        if (itm.type == "import") {
          return itm.country?.name;
        }
        if (itm.type == "natural") {
          return `${itm.aimag?.name}-${itm.sum?.name}`;
        }
      },
    },
    {
      title: "Төрөл",
      render: (itm: any) => {
        if (itm.type == "harvest") {
          return "Таримал";
        }
        if (itm.type == "import") {
          return "Импорт";
        }
        if (itm.type == "natural") {
          return "Байгалийн";
        }
      },
    },
    {
      title: "Хэмжээ",
      render: (itm: any) =>
        `${itm.amount} ${itm.sort?.unit ? itm.sort.unit.name : ""}`,
    },
    {
      title: "Үлдэгдэл",
      render: (itm: any) =>
        `${itm.remaining} ${itm.sort?.unit ? itm.sort.unit.name : ""}`,
    },
    {
      title: "Огноо",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
    <>
      <Table
        scroll={{ x: true }}
        style={{ width: "100%" }}
        pagination={{
          current: plant.page,
          pageSize: plant.pageSize,
          total: plant.count,
          onChange(current, pageSize) {
            setPage(current);
            setPageSize(pageSize);
          },
        }}
        onChange={(p) => {
          loadContent({
            ...plant,
            page: p.current || 1,
            pageSize: p.pageSize || 10,
          });
        }}
        loading={plant.loading}
        columns={columnsT}
        dataSource={plant.result}
      />
    </>
  );
};

export default FarmerWarehousePlant;
