import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Switch,
} from "antd";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import NetworkApi from "../../network/networkApi";
import UserContext from "../../providers/UserContext";
import AddressSelect from "../lib/AddressSelect";
import BreadMe from "../lib/Breadme";
const Option = Select.Option;
const FarmLandAdd = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const [hasId, setHasId] = useState(true);
  const [farmlandData, setFarmlandData] = useState<any>({});
  const [defaultAddress, setDefaultAddress] = useState<any>({
    aimagId: null,
    sumId: null,
    bagId: null,
  });
  const [disabledSearchButton, setDisabledSearchButton] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [findingFarmland, setFindingFarmland] = useState(false);
  const [posNo, setPosNo] = useState<string | undefined>();
  const [regnum, setRegNum] = useState<string | undefined>();
  const [hasInitialDate, setHasInitialDate] = useState<boolean>(false);
  const handleSubmit = useCallback(
    async (data: any) => {
      if (!submitLoading) {
        setSubmitLoading(true);
        data.initialDate = data.initialDate.format("YYYY-MM-DD");
        if (hasId) {
          data = {
            ...data,
            posNo: farmlandData.posNo,
            ownerId: farmlandData.regnum,
          };
        }
        let result = await NetworkApi.requestPost("/api/farmland/create", data);
        if (result.resultCode == 200) history.push("/area/list");
        else {
          message.error(result.message || "Алдаа гарлаа, дахин оролдоно уу!");
        }
      }
      setSubmitLoading(false);
    },
    [history, submitLoading, hasId, farmlandData]
  );
  useEffect(() => {
    if (form && user.role.cd == "VILLAGE_AGRONOMIST") {
      form.setFieldsValue({
        aimagId: user.aimagId,
        sumId: user.sumId,
      });
    }
  }, [user, form]);
  const [currentState, changeState] = useState("");
  const findFarmlandByPosNo = useCallback(async () => {
    setFindingFarmland(true);
    const response = await NetworkApi.requestPost("/api/farmland/find", {
      posNo,
      regnum: regnum,
    });
    if (response.resultCode === 200) {
      setFarmlandData(response.result);
      setHasInitialDate(response.result.initialDate ? true : false);
      form.setFieldsValue({
        area: response.result?.area,
        aimagId: response.result?.aimag?.id,
        sumId: response.result?.sum?.id,
        bagId: response.result?.bag?.id,
        position: response.result?.position,
        initialDate: response.result.initialDate
          ? moment(response.result.initialDate)
          : undefined,
        type: response.result?.type,
        useType: response.result?.useType,
      });
      setDefaultAddress({
        aimagId: response.result?.aimag?.id,
        sumId: response.result?.sum?.id,
        bagId: response.result?.bag?.id,
      });
    } else {
      message.error(response?.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
    setFindingFarmland(false);
  }, [posNo, regnum, form]);
  useEffect(() => {
    if (hasId && `${posNo ?? ""}`.length > 0 && `${regnum ?? ""}`.length > 0) {
      setDisabledSearchButton(false);
    } else {
      setDisabledSearchButton(true);
    }
  }, [hasId, posNo, regnum]);
  return (
    <>
      <BreadMe title={["Тариалангийн талбай", "Тариан талбайн бүртгэх"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={20}>
          <Col span={16} push={4}>
            <Form
              onFinish={(data) => handleSubmit(data)}
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
              name="basic"
              form={form}
            >
              <Form.Item label="Нэгж талбарын дугаартай эсэх">
                <Switch
                  checkedChildren="дугаартай"
                  unCheckedChildren="дугааргүй"
                  checked={hasId}
                  onChange={(e) => {
                    setDefaultAddress({});
                    setFarmlandData({});
                    form.resetFields();
                    setHasId(e);
                  }}
                />
              </Form.Item>
              {hasId ? (
                <>
                  <Form.Item
                    label="Нэгж талбарын дугаар"
                    name="posNo"
                    rules={[
                      {
                        required: true,
                        message: "Нэгж талбарын дугаар оруулна уу!",
                      },
                    ]}
                  >
                    <Input onChange={(e) => setPosNo(e.target.value)} />
                  </Form.Item>
                  <Form.Item
                    label="Эзэмшигчийн регистрийн дугаар"
                    name="regnum"
                    rules={[
                      {
                        required: true,
                        message: "Эзэмшигчийн регистрийн дугаар оруулна уу!",
                      },
                    ]}
                  >
                    <Input onChange={(e) => setRegNum(e.target.value)} />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Row justify="end">
                      <Button
                        disabled={disabledSearchButton}
                        type="primary"
                        loading={findingFarmland}
                        onClick={findFarmlandByPosNo}
                      >
                        Хайх
                      </Button>
                    </Row>
                  </Form.Item>
                </>
              ) : null}
              <AddressSelect
                defaultAimag={
                  user.role.cd == "VILLAGE_AGRONOMIST"
                    ? user.aimagId
                    : defaultAddress.aimagId
                }
                defaultSum={
                  user.role.cd == "VILLAGE_AGRONOMIST"
                    ? user.sumId
                    : defaultAddress.sumId
                }
                defaultBag={defaultAddress.bagId}
                rule={true}
                enableSelect={true}
              />
              <Form.Item
                label="Талбайн хэмжээ/га"
                name="area"
                rules={[
                  { required: true, message: "Талбайн хэмжээ оруулна уу!" },
                ]}
              >
                <InputNumber
                  disabled={hasId}
                  style={{ width: "100%" }}
                  parser={(value) => value?.replace(",", ".") || ""}
                />
              </Form.Item>
              <Form.Item
                label="Ашиглагдаж эхэлсэн огноо"
                name="initialDate"
                rules={[{ required: true, message: "Огноо оруулна уу!" }]}
              >
                <DatePicker placeholder={"Огноо"} disabled={hasId && hasInitialDate} />
              </Form.Item>
              <Form.Item
                label="Төрөл"
                name="useType"
                rules={[{ required: true, message: "Төрөл оруулна уу!" }]}
              >
                <Input disabled={hasId} />
              </Form.Item>
              <Form.Item
                label="Эрхийн төрөл өмчийн хэлбэр"
                name="type"
                initialValue="state"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Select
                  disabled={hasId}
                  onChange={(s: string) => {
                    changeState(s);
                  }}
                >
                  <Option value="private">Хувийн</Option>
                  <Option value="state">Төрийн</Option>
                </Select>
              </Form.Item>
              {currentState == "private" ? (
                <Form.Item name="ownerId" label="Эзэмшигч">
                  <Input disabled={hasId} />
                </Form.Item>
              ) : null}
              <Form.Item
                label="Талбайн байршил"
                name="position"
                rules={[{ message: "Талбайн байршил сонгоно уу!" }]}
              >
                <Input disabled={hasId} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  loading={submitLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export default FarmLandAdd;
