import { Button, Form, Input, message, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useState } from "react";
import NetworkApi from "../../network/networkApi";
import { useParams } from "react-router-dom";

type Props = {
  visible: boolean;
  onSuccess: any;
  onHide: any;
};
export const FarmLandOwnerChange = ({ visible, onSuccess, onHide }: Props) => {
  const { id } = useParams<{
    id?: any;
  }>();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = useCallback(async (values: any) => {
    setIsLoading(true);
    let response = await NetworkApi.requestPost(
      `/api/farmland/change-owner/${id}`,
      values
    );
    if (response.resultCode === 200) {
      onSuccess();
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
    setIsLoading(false);
  }, []);
  return (
    <Modal
      onCancel={onHide}
      visible={visible}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={isLoading}
        >
          Хадгалах
        </Button>,
        <Button key="submit" type="default" onClick={onHide}>
          Хаах
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Эзэмшигчийн регистрийн дугаар"
          name="rd"
          rules={[
            {
              required: true,
              message: "Эзэмшигчийн регистрийн дугаар оруулна уу!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
