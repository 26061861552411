import { useEffect, useState } from "react";
import AddressItemSelect from "./AddressItemSelect";

const AddressSelect = (props: any) => {
  const [address, changeAddress] = useState({
    aimag: props.defaultAimag || "",
    sum: props.defaultSum || "",
    bag: props.defaultBag || "",
  });
  useEffect(() => {
    changeAddress((a) => {
      return {
        ...a,
        aimag: props.defaultAimag ?? props.initValues?.aimag,
      };
    });
  }, [props.defaultAimag, props.initValues]);
  useEffect(() => {
    changeAddress((a) => {
      return {
        ...a,
        sum: props.defaultSum ?? props.initValues?.sum,
      };
    });
  }, [props.defaultSum, props.initValues]);
  useEffect(() => {
    changeAddress((a) => {
      return {
        ...a,
        bag: props.defaultBag ?? props.initValues?.bag,
      };
    });
  }, [props.defaultBag, props.initValues]);

  return (
    <>
      <AddressItemSelect
        disabled={props.defaultAimag && props.enableSelect != true ? true : false}
        subtitle="Аймаг сонгох"
        name="aimagId"
        {...props}
        value={address.aimag}
        title={props.aimagTitle || "Аймаг, хот"}
        parent={1}
        onChange={(aimag) => {
          changeAddress({
            aimag,
            sum: "",
            bag: "",
          });
        }}
      />
      <AddressItemSelect
        disabled={props.defaultSum && props.enableSelect != true ? true : false}
        subtitle="Сум сонгох"
        {...props}
        name="sumId"
        value={address.sum}
        title={props.aimagTitle || "Сум, дүүрэг"}
        parent={address.aimag}
        onChange={(sum) => {
          changeAddress({
            ...address,
            sum,
            bag: "",
          });
        }}
      />
      {props.ignoreBag != true && (
        <AddressItemSelect
          disabled={props.defaultBag && props.enableSelect != true ? true : false}
          subtitle="Баг сонгох"
          {...props}
          name="bagId"
          value={address.bag}
          title={props.aimagTitle || "Баг, хороо"}
          parent={address.sum}
          onChange={(bag) => {
            changeAddress({
              ...address,
              bag,
            });
          }}
        />
      )}
    </>
  );
};
export default AddressSelect;
