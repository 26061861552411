import { Layout, Modal, Space, Table, Tag } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import NetworkApi from '../../network/networkApi';
import BreadMe from '../lib/Breadme'
const columns = [
    {
        title: '№',
        render: (t: any, ind: any, index: any) => index + 1,
        ellipsis: true,
    },
    {
        title: 'Таримлын нэр',
        render: (t: any) => t.plant.name,
        ellipsis: true,
    },
    {
        title: 'Тариалсан хугацаа',
        render: (value: any) => `${value.startDate || ''}-${value.endDate || ''}`,
        ellipsis: true,
    },
    {
        title: 'Хураасан хугацаа',
        render: (value: any) => `${value.harvestStartDate || ''}-${value.harvestEndDate || ''}`,
        ellipsis: true,
    },
    {
        title: 'Хэмжээ',
        render: (value: any) => `${value.harvest || ''}${value.plant.unit?.name || ''}`,
    },
    {
        title: 'Хураасан талбай',
        dataIndex: 'area',
        render: (value: any) => `${value || ''} га`,
        ellipsis: true,
    },
    {
        title: 'Тариалсан талбай',
        dataIndex: 'area',
        render: (value: any) => `${value || ''} га`,
        ellipsis: true,
    },

];
interface Params {
    id?: any
}
interface ContentData {
    result: any[],
    loading: boolean,
    resultCode:number
}
const CultivationPlan = () => {
    let {id} = useParams<Params>();
    useEffect(()=>{
        if(id){
            loadContent(id)
        }
    },[id])
    const [content,setContent] = useState<ContentData>({
        result:[],
        loading:true,
        resultCode:200
    })
    const loadContent = useCallback(async (yer:any)=>{
        setContent(c=>{
            return{
                ...c,
                loading:true
            }
        })
        let response = await NetworkApi.requestGet(`/api/statistic/plan/detail/${yer}`)
        setContent({
            loading:false,
            resultCode:response.resultCode,
            result:response.result||[]
        })
    },[])
    const plantTypes = useMemo(()=>{
        if(content.result.length>0){
            return content.result[0].plantTypes
        }
        return []
    },[content.result])
    return (
        <div>
            <BreadMe title={['Тайлан', `Тариалалтын төлөвлөгөө-${id}`]} />
            <Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
                <Table pagination={false} dataSource={content.result} bordered>                    
                <Table.Column title="Аймгийн нэр" render={(data: any,index:any) => {
                        return data.aimag?.name
                    }} />
                    
                    {plantTypes.map((plant:any)=>{
                        return(
                            <Table.Column key={plant.plantType.id} width={150} title={plant.plantType?.name} render={(data: any) => {
                                let p = data.plantTypes.find((e:any)=>e.plantType.id==plant.plantType.id)
                                return p?.area
                            }} />
                        )
                    })}
                </Table>
            </Layout.Content>
        </div>
    )
}
interface Props{
    visible:boolean
    onHide:any
    edit:any
}
const PlanEdit = ({visible,onHide,edit}:Props)=>{
    return(
        <Modal visible={visible} onCancel={onHide}>

        </Modal>
    )
}
export default CultivationPlan