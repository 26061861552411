import { FileTextOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
  Timeline,
} from "antd";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  INCENTIVE_STATUS,
  INCENTIVE_STATUS_LIST,
  INCENTIVE_STATUS_REF,
  INCENTIVE_TYPE_REF,
} from "../../../enums/incentive.enum";
import UserContext from "../../../providers/UserContext";
import useIncentivePlantSort from "../../../hooks/useIncentivePlantSort";
import NetworkApi from "../../../network/networkApi";
import { formatNumberWithCommaAndPoint } from "../../../helpers/number.helper";
import { USER_ROLE } from "../../../enums/user.enum";
import useIncentiveDetail from "../../../hooks/useIncentiveDetail";

const IncentiveStatus = ({ status }: { status: string }) => {
  if (status === INCENTIVE_STATUS.PAID)
    return (
      <span style={{ color: "green" }}>{INCENTIVE_STATUS_REF[status]}</span>
    );
  if (status === INCENTIVE_STATUS.INVALID_ACCOUNT)
    return <span style={{ color: "red" }}>{INCENTIVE_STATUS_REF[status]}</span>;
  return <span>{INCENTIVE_STATUS_REF[status]}</span>;
};

const FarmerIncentiveList = ({ rd }: { rd: string }) => {
  const { user } = useContext(UserContext);
  const [sorts] = useIncentivePlantSort();
  const [content, changeContent] = useState({
    loading: false,
    page: 1,
    count: 0,
    filter: {},
    result: [],
  });
  const [detailModal, changeDetailModal] = useState<{
    id?: number;
    visible: boolean;
  }>({ visible: false });

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/incentive/list", {
      ...content.filter,
      userRd: rd,
      page: content.page,
    });
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content, rd]);

  useEffect(() => {
    loadContent();
  }, [content.filter, content.page, rd]);

  const changeFilter = (filter: any) => {
    changeContent({ ...content, filter });
  };

  const getColumns = () => {
    const columns = [
      {
        title: "№",
        dataIndex: "id",
        render: (v: any, r: any, index: number) => `${index + 1}`,
      },
      {
        title: "Хүсэлт илгээсэн огноо",
        dataIndex: "sentDate",
        render: (value: any) =>
          value ? moment(value).format("YYYY-MM-DD") : "",
      },
      {
        title: "Урамшуулалд хамрагдах сар",
        dataIndex: "targetDate",
        render: (value: any) => moment(value).format("YYYY-MM"),
      },
      {
        title: "Овог нэр",
        render: (item: any) => {
          if (item.organization.type === "organization") {
            return item.organization.name;
          }
          return `${item.user.lastName} ${item.user.firstName}`;
        },
      },
      {
        title: "РД",
        render: (item: any) => {
          if (item.organization.type === "organization") {
            return item.organization.rd;
          }
          return `${item.user.rd}`;
        },
      },
      {
        title: "Аймаг",
        dataIndex: "user",
        render: (value: any) => `${value.aimag.name}`,
      },
      {
        title: "Сум",
        dataIndex: "user",
        render: (value: any) => `${value.sum.name}`,
      },
      {
        title: "Бүтээгдэхүүний төрөл",
        dataIndex: "type",
        render: (value: any) => INCENTIVE_TYPE_REF[value],
      },
      {
        title: "И-баримтаар баталгаажсан бүтээгдэхүүний хэмжээ (кг)",
        dataIndex: "totalEbarimtAmount",
        render: (value: any) => `${formatNumberWithCommaAndPoint(value)} кг`,
      },
      {
        title: "ГҮГ-ээр баталгаажсан бүтээгдэхүүний хэмжээ (кг)",
        dataIndex: "totalAmount",
        render: (value: any) => `${formatNumberWithCommaAndPoint(value)} кг`,
      },
      {
        title: "Урамшуулал бодогдсон дүн (төг)",
        dataIndex: "issuedAmount",
      },
      {
        title: "Олгосон огноо",
        dataIndex: "issuedDate",
      },
      {
        title: "Төлөв",
        dataIndex: "status",
        render: (value: any) => <IncentiveStatus status={value} />,
      },
      {
        title: "Үйлдэл",
        dataIndex: "id",
        width: "100px",
        render: (value: any) => (
          <Row gutter={[16, 16]}>
            <Col>
              <Button
                type={"primary"}
                size={"small"}
                ghost
                icon={<FileTextOutlined />}
                onClick={() => changeDetailModal({ visible: true, id: value })}
              ></Button>
            </Col>
          </Row>
        ),
      },
    ];

    if (
      [USER_ROLE.PROVINCE_AGRONOMIST, USER_ROLE.VILLAGE_AGRONOMIST].includes(
        user.role?.cd
      )
    ) {
      columns.splice(8, 3);
    }
    return columns;
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Form
            layout="inline"
            onFinish={(filter: any) => {
              if (filter.targetDate) {
                filter.targetDate = filter.targetDate.format("YYYY-MM-DD");
              }
              changeFilter(filter);
            }}
          >
            <Row gutter={[0, 16]} style={{ width: "100%" }}>
              <Col flex="none">
                <Form.Item name="targetDate">
                  <DatePicker picker="month" placeholder="Он сар" />
                </Form.Item>
              </Col>
              <Col flex="none">
                <Form.Item name="posNo">
                  <Input placeholder={"НТДугаар"} />
                </Form.Item>
              </Col>
              <Col flex="none">
                <Form.Item name="userRd">
                  <Input disabled placeholder={`${rd}`} />
                </Form.Item>
              </Col>
              <Col flex="200px">
                <Form.Item name="sortId">
                  <Select placeholder="Бүтээгдэхүүн" allowClear>
                    {sorts.result.map((p: any) => (
                      <Select.Option key={`ps-${p.id}`} value={p.id}>
                        {p.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="200px">
                <Form.Item name="status">
                  <Select placeholder="Төлөв" allowClear>
                    {INCENTIVE_STATUS_LIST.map((p: any) => (
                      <Select.Option key={`st-${p.id}`} value={p.id}>
                        {p.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex="none">
                <Button
                  htmlType="submit"
                  type={"primary"}
                  style={{ marginRight: 16 }}
                >
                  <SearchOutlined />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row style={{ marginTop: 15, marginBottom: 15 }}>
        <Col span={24}>
          <Descriptions title="" style={{ marginTop: 15 }}>
            <Descriptions.Item label="Нийт">{content.count}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Table
            scroll={{ x: true }}
            style={{ width: "100%" }}
            className="plant-table"
            pagination={{
              current: content.page,
              total: content.count,
            }}
            onChange={(p) => {
              changeContent({
                ...content,
                page: p.current || 1,
              });
            }}
            loading={content.loading}
            columns={getColumns()}
            dataSource={content.result ?? []}
            rowKey={"id"}
          />
        </Col>
      </Row>
      {detailModal.visible && (
        <IncentiveDetailModal
          id={detailModal.id}
          visible={detailModal.visible}
          onCancel={() => changeDetailModal({ visible: false })}
        />
      )}
    </>
  );
};

export default FarmerIncentiveList;

const IncentiveDetailModal = ({
  visible,
  id,
  onCancel,
}: {
  visible: boolean;
  id?: any;
  onCancel: () => void;
}) => {
  const [content, , changeId] = useIncentiveDetail();
  useEffect(() => {
    if (id) {
      changeId(id);
    }
  }, [id, changeId]);

  return (
    <Modal
      width={1000}
      visible={visible}
      title="Урамшууллын хүсэлт дэлгэрэнгүй"
      onCancel={onCancel}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Descriptions column={2}>
            <Descriptions.Item label="Хүсэлт гаргасан">
              {content.result?.organization?.type === "organization" &&
                `${content.result.organization.name} (${content.result.organization.rd})`}
              {content.result?.organization?.type === "farmer" &&
                `${content.result?.user?.lastName ?? ""} ${
                  content.result?.user?.firstName ?? ""
                } (${content.result?.user?.rd ?? ""})`}
            </Descriptions.Item>
            <Descriptions.Item label="Хүсэлт илгээсэн огноо">
              {`${
                content.result?.sentDate
                  ? moment(content.result.sentDate).format(
                      "YYYY-MM-DD hh:mm:ss"
                    )
                  : ""
              }`}
            </Descriptions.Item>
            <Descriptions.Item label="Урамшууллын бүтээгдэхүүн">
              {`${
                content.result?.type && INCENTIVE_TYPE_REF[content.result.type]
              } ${`${formatNumberWithCommaAndPoint(
                Math.min(
                  content.result?.totalAmount,
                  content.result.totalEbarimtAmount
                ) / 1000
              )} тн`}`}
            </Descriptions.Item>
            <Descriptions.Item label="Хаяг">
              {`${content.result?.user?.aimag?.name ?? ""}, ${
                content.result?.user?.sum?.name ?? ""
              }`}
            </Descriptions.Item>
            <Descriptions.Item label="Банк">
              {content.result?.bankName ?? ""}
            </Descriptions.Item>
            <Descriptions.Item label="Геодизээр баталгаажсан">
              {`${`${content.result?.totalArea ?? 0} га`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}`}
            </Descriptions.Item>
            <Descriptions.Item label="Дансны нэр">
              {content.result?.camName}
            </Descriptions.Item>
            <Descriptions.Item label="Төлөв">
              {content.result?.status
                ? INCENTIVE_STATUS_REF[content.result.status]
                : ""}
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">Төлвийн түүх</Divider>
          <Timeline mode="left">
            {(content.result?.incentiveLogs ?? []).map((item: any) => (
              <Timeline.Item
                label={`${
                  content.result?.sentDate
                    ? moment(item.updatedAt).format("YYYY-MM-DD hh:mm:ss")
                    : ""
                }`}
              >
                {item.message}
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
      </Row>
    </Modal>
  );
};
