import React, { useCallback, useEffect, useState } from 'react'
import NetworkApi from '../network/networkApi'
interface ContentData {
    result: any[],
    loading: boolean
}
const useFarmlandServiceList = (page?: number): [ContentData, () => void, any] => {
    const [filter, changeFilter] = useState<any>(null)
    const [content, changeContent] = useState<ContentData>({
        loading: false,
        result: []
    })
    useEffect(() => {
        if(filter)
            loadContent()
    }, [filter])
    const loadContent = useCallback(async () => {
        let response = await NetworkApi.requestGet('/api/farmland/service/list', filter)
        if (response.resultCode == 200)
            changeContent({
                loading: false,
                result: response.result || []
            })
    }, [filter])

    return [content, loadContent, changeFilter]
}
export default useFarmlandServiceList