import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any[];
  loading: boolean;
  count: 0;
  page: 1;
  pageSize: 10;
}
const useFarmerPlantWarehouse = (): [ContentData, () => void, any] => {
  const [filter, changeFilter] = useState<any>({});
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
    count: 0,
    page: 1,
    pageSize: 10,
  });
  useEffect(() => {
    if (filter?.organizationId?.length > 0) {
      loadContent();
    }
  }, [filter]);
  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      "/api/admin/warehouse/chemical/list",
      filter
    );
    changeContent({
      loading: false,
      count: response.result.count,
      page: response.result.page,
      pageSize: response.result.pageSize,
      result: response.result?.rows || [],
    });
  }, [filter]);

  return [content, loadContent, changeFilter];
};
export default useFarmerPlantWarehouse;
