import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Avatar, Button, Col, Form, FormInstance, Input, Layout, message, Modal, Row, Select, Switch } from 'antd'
import { AntDesignOutlined } from '@ant-design/icons'
import AddressItemSelect from "../lib/AddressItemSelect";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import UserContext from "../../providers/UserContext";

const { Option } = Select;

const Profile = () => {
    const [address, changeAddress] = useState({ aimag: '', sum: '' });
    const { user } = useContext(UserContext);
    const formRef = useRef<any>();

    useEffect(() => {
        console.log(user);
        changeAddress({ aimag: user.aimagId, sum: user.sumId });
        formRef.current.setFieldsValue(
            user
        );
    }, [user]);
    const submitUser = useCallback(async (data: any) => {
        let response = await NetworkApi.requestPost('/api/user/profile/update', data)
        if (response.resultCode == 200) {
            message.success('Амжилттай засагдлаа')
        } else {
            message.error(response.message || 'system error')
        }
    }, [])
    return (
        <>
            <BreadMe title={['Хувийн мэдээлэл']} />
            <Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
                <Form ref={formRef} layout="vertical" onFinish={submitUser}>
                    <Row>
                        <Col span={6}>
                            <Avatar
                                size={{ xs: 24, sm: 32, md: 40, lg: 80, xl: 120, xxl: 160 }}
                                icon={<AntDesignOutlined />}
                            />
                        </Col>
                        <Col span={18}>
                            <Row gutter={15}>
                                <Col span={12}>
                                    <Form.Item name="lastName" label={"Овог"}>
                                        <Input placeholder={"Овог"} disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="firstName" label={"Нэр"}>
                                        <Input placeholder={"Нэр"} disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="rd" label={"Регистр"}>
                                        <Input placeholder={"XX00000000"} disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="phone" label={"Утас"}>
                                        <Input placeholder={"Утасны дугаар"} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item name="email" label={"И-мэйл"}>
                                <Input placeholder={"и-мэйл хаяг"} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <AddressItemSelect value={address.aimag} parent={1} title="Хамаарах / Аймаг" name="aimagId"
                                onChange={(aimag) => changeAddress({ aimag, sum: '' })} />
                        </Col>
                        <Col span={8}>
                            <AddressItemSelect value={address.sum} parent={address.aimag} title="Хамаарах / Сум"
                                name="sumId" onChange={(sum) => changeAddress({ ...address, sum })} />
                        </Col>
                        <Col span={8}>
                            <Form.Item name="name" label={"Нэвтрэх нэр"}>
                                <Input placeholder={"нэвтрэх нэр"} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="password" label={"Нууц үг"} >
                                <Input.Password placeholder={"нууц үг"} type={'password'} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="confirmPassword" label={"Нууц үг давтах"} >
                                <Input.Password placeholder={"нууц үг давтах"} type={'password'} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button htmlType="submit" type="primary">
                                Хадгалах
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Layout.Content>
        </>
    )
};
export default Profile
