import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import UserContext from "../../providers/UserContext";
import { USER_ROLE } from "../../enums/user.enum";

const Option = Select.Option;

const LicenseAdd = () => {
  const [form] = Form.useForm();
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (
      user.role?.cd == "ADMIN" ||
      user?.role?.cd == "VILLAGE_AGRONOMIST"
      // user.role?.cd == "PROVINCE_AGRONOMIST"
    ) {
      return true;
    }
    return false;
  }, [user]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/certificate/list", {
      ...content.filter,
      page: content.page,
    });
    if (response.resultCode == 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);
  const deleteCertificate = useCallback(async (id: any) => {
    let response = await NetworkApi.requestGet(`/api/certificate/delete/${id}`);
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Системд алдаа гарлаа");
    }
  }, []);

  const licenseInfo = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "ГҮГ дугаар",
      dataIndex: "id",
      render: (value: any) => `ГҮГ-${value}`,
    },
    {
      title: "Тариаланч",
      render: (value: any) =>
        `${value.sizes[0]?.warehouse?.owner?.name}-${value.sizes[0]?.warehouse?.owner?.rd}`,
    },
    {
      title: "Хаанаас",
      render: (value: any) =>
        `${value.sizes[0]?.warehouse?.aimag?.name}-${value.sizes[0]?.warehouse?.sum?.name}`,
    },
    {
      title: "Хүлээн авагчийн нэр",
      dataIndex: "receiverName",
    },
    {
      title: "Хүлээн авагчийн хаяг",
      render: (value: any) =>
        `${value.receiverAimag?.name}-${value.receiverSum?.name}`,
    },
    {
      title: "Хүчинтэй хугацаа",
      render: (value: any) => `${value.startDate}-${value.endDate}`,
    },
    {
      title: "Зориулалт",
      dataIndex: "purpose",
      render: (value: any) =>
        value == "sale" ? "Худалдан борлуулах" : "Үрэнд",
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: string) => (
        <>
          {
            {
              pending: <Tag color={"#ff9900"}>Хүлээгдэж буй</Tag>,
              draft: <Tag color={"#ff9900"}>Ноорог</Tag>,
              active: <Tag color={"#93c47d"}>Зөвшөөрсөн</Tag>,
              inactive: <Tag color="default">Идэвхигүй</Tag>,
              rejected: <Tag color={"#cc0000"}>Татгалзсан</Tag>,
              reviewed: <Tag color={"#93c47d"}>Хянасан</Tag>,
              detained: <Tag color={"#d04040"}>Саатуулсан</Tag>,
            }[value]
          }
        </>
      ),
    },
    {
      title: "Үйлдэл",
      render: (value: any) => (
        <Space size={10}>
          {["draft", "pending"].includes(value.status) &&
            (canEdit ||
              (user?.role.cd === USER_ROLE.CUSTOMER &&
                user.id === value.createdUserId)) && (
              <Button
                type={"primary"}
                size={"small"}
                ghost
                icon={<EditOutlined />}
              >
                <Link to={`/origin/edit/${value.id}`}>Засах</Link>
              </Button>
            )}
          {["draft", "pending"].includes(value.status) &&
            (canEdit ||
              (user?.role.cd === USER_ROLE.CUSTOMER &&
                user.id === value.createdUserId)) && (
              <Popconfirm
                title="Устгахдаа итгэйлтэй байна уу"
                onConfirm={() => deleteCertificate(value.id)}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button
                  type={"primary"}
                  size={"small"}
                  danger
                  ghost
                  icon={<DeleteOutlined />}
                >
                  Устгах
                </Button>
              </Popconfirm>
            )}
          <Link to={"/origin/detail/" + value.id}>
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
            >
              Дэлгэрэнгүй
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
  const [aimagList, setAimagList] = useState<any[]>([]);
  const [fromSumList, setFromSumList] = useState<any[]>([]);
  const [toSumList, setToSumList] = useState<any[]>([]);
  const loadAimagList = useCallback(async () => {
    const response = await NetworkApi.requestGet(`/api/address/1`);
    setAimagList(response.result || []);
  }, []);
  useEffect(() => {
    loadAimagList();
  }, [loadAimagList]);
  const loadSumList = useCallback(async (parent, cb) => {
    if (parent) {
      const response = await NetworkApi.requestGet(`/api/address/${parent}`);
      cb(response.result || []);
    } else {
      cb([]);
    }
  }, []);
  return (
    <>
      <BreadMe title={["Гэрчилгээ", "Гэрчилгээний жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Form
          style={{ width: "100%" }}
          onFinish={(data) => {
            let v = data;
            if (data.acceptedAt) {
              v.acceptedAt = data.acceptedAt.format("YYYY/MM/DD");
            }
            if (data.date?.length > 0) {
              v.startDate = data.date[0].format("YYYY/MM/DD");
              v.endDate = data.date[1].format("YYYY/MM/DD");
            }
            changeContent({ ...content, filter: data, page: 1 });
          }}
          name="filter"
          form={form}
        >
          <Row>
            <Col span={24}>
              <Row gutter={24}>
                <Col span={7}>
                  <Form.Item name="id">
                    <Input placeholder={"Гэрчилгээний дугаар"} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="status">
                    <Select allowClear placeholder="Төлөв">
                      <Option value="pending">Хүлээгдэж буй</Option>
                      <Option value="draft">Ноорог</Option>
                      <Option value="active">Зөвшөөрсөн</Option>
                      <Option value="rejected">Татгалзсан</Option>
                      <Option value="reviewed">Хянасан</Option>
                      <Option value="detained">Саатуулсан</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="purpose">
                    <Select allowClear placeholder="Зориулалт">
                      <Option value="sale">Худалдаа</Option>
                      <Option value="seed">Үрэнд</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="rd">
                    <Input placeholder={"Тариаланчийн регистр"} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="acceptedAt">
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Баталсан огноо"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={7}>
                  <Form.Item name="date">
                    <DatePicker.RangePicker
                      style={{ width: "100%" }}
                      placeholder={["Хүчинтэй хугацаа эхлэх", "Дуусах"]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="fromAimag">
                    <Select
                      allowClear
                      placeholder="Хаанаас аймаг"
                      onChange={(value) => {
                        form.setFieldsValue({ fromSum: undefined });
                        loadSumList(value, setFromSumList);
                      }}
                    >
                      {aimagList.map((aimag: any) => {
                        return (
                          <Select.Option key={aimag.id} value={aimag.id}>
                            {aimag.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="fromSum">
                    <Select allowClear placeholder="Хаанаас сум">
                      {fromSumList.map((aimag: any) => {
                        return (
                          <Select.Option key={aimag.id} value={aimag.id}>
                            {aimag.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="receiverAimagId">
                    <Select
                      allowClear
                      placeholder="Хаашаа аймаг"
                      onChange={(value) => {
                        form.setFieldsValue({ receiverSumId: undefined });
                        loadSumList(value, setToSumList);
                      }}
                    >
                      {aimagList.map((aimag: any) => {
                        return (
                          <Select.Option key={aimag.id} value={aimag.id}>
                            {aimag.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="receiverSumId">
                    <Select allowClear placeholder="Хаашаа сум">
                      {toSumList.map((aimag: any) => {
                        return (
                          <Select.Option key={aimag.id} value={aimag.id}>
                            {aimag.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={1}>
                  <Form.Item>
                    <Button type={"primary"} htmlType={"submit"}>
                      <SearchOutlined />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Descriptions title="" style={{ marginTop: 15 }}>
          <Descriptions.Item label="Нийт">{content.count}</Descriptions.Item>
        </Descriptions>
        <Row>
          <Col span={24}>
            <Table
              pagination={{
                current: content.page,
                total: content.count,
              }}
              onChange={(p) => {
                changeContent({
                  ...content,
                  page: p.current || 1,
                });
              }}
              rowKey={"id"}
              columns={licenseInfo}
              dataSource={content.result}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export default LicenseAdd;
