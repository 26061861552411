import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import BreadMe from "../lib/Breadme";
import UserContext from "../../providers/UserContext";
import { USER_ROLE } from "../../enums/user.enum";
import {
  EQUIPMENT_RENT_STATUS_LIST,
  EQUIPMENT_RENT_STATUS_REF,
  EQUIPMENT_USAGE,
  EQUIPMENT_USAGE_REF,
} from "../../enums/equipment.enum";
import { useParams, useHistory } from "react-router-dom";
import config from "../../config";
import UserSelectWithSearch from "../lib/UserSelectWithSearch";
import EquipmentAddEdit from "./components/EquipmentAddEdit";
import moment from "moment";

interface Params {
  id?: any;
}

const EquipmentDetail = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [equipmentContent, changeEquipmentContent] = useState<{
    loading: boolean;
    result: any;
  }>({
    loading: true,
    result: {},
  });
  useEffect(() => {
    loadEquipmentContent();
  }, []);
  const loadEquipmentContent = useCallback(async () => {
    changeEquipmentContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet(`/api/equipment/detail/${id}`);
    if (response.resultCode === 200) {
      changeEquipmentContent((c) => {
        return {
          ...c,
          loading: false,
          result: response.result || {},
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [equipmentContent]);
  const canEdit = useMemo(() => {
    if (
      [USER_ROLE.ADMIN, USER_ROLE.VILLAGE_AGRONOMIST].includes(
        user?.role?.cd
      ) &&
      !equipmentContent.result?.deleteFlg
    ) {
      return true;
    }
    return false;
  }, [user, equipmentContent]);
  const [renterContent, changeRenterContent] = useState({
    loading: true,
    result: [],
    count: 0,
    filter: {},
    page: 1,
  });
  useEffect(() => {
    loadRenterContent();
  }, [renterContent.filter, renterContent.page]);
  const loadRenterContent = useCallback(async () => {
    changeRenterContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet(
      `/api/equipment/renting/list/${id}`,
      {
        ...renterContent.filter,
        page: renterContent.page,
      }
    );
    if (response.resultCode === 200) {
      changeRenterContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [renterContent]);
  const deleteEquipment = useCallback(async () => {
    let response = await NetworkApi.requestPost(`/api/equipment/delete/${id}`);
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      history.push("/equipment/list");
    } else {
      message.error(response.message || "Устгахад алдаа гарлаа.");
    }
  }, []);
  const renderImage = () => {
    if (!equipmentContent.result.image) {
      return;
    }
    const equipmentImage = JSON.parse(equipmentContent.result.image);
    return (
      <Image
        width={164}
        src={`${config.get("apiUrl")}/tmp/${equipmentImage.name}`}
      />
    );
  };
  const [equipmentEdit, changeEquipmentEdit] = useState<{
    visible: boolean;
    edit?: any;
  }>({
    visible: false,
  });
  const setEquipmentEdit = (visible?: boolean, edit?: any) => {
    changeEquipmentEdit({
      visible: visible === undefined ? false : visible,
      edit,
    });
  };
  const [renterEdit, changeRenterEdit] = useState<{
    visible: boolean;
    edit?: any;
  }>({
    visible: false,
  });
  const setRenterEdit = (visible?: boolean, edit?: any) => {
    changeRenterEdit({
      visible: visible === undefined ? false : visible,
      edit,
    });
  };
  const deleteEquipmentRenting = useCallback(async (item: any) => {
    let response = await NetworkApi.requestPost(
      `/api/equipment/renting/delete/${item.id}`
    );
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadRenterContent();
    } else {
      message.error(response.message || "Устгахад алдаа гарлаа.");
    }
  }, []);

  const columns = [
    {
      title: "№",
      dataIndex: "id",
    },
    {
      title: "Түрээслэгч",
      dataIndex: "renter",
      render: (value: any) =>
        `${value.lastName?.substring(0, 1)}.${value.firstName} (${value.rd})`,
    },
    {
      title: "Тоо хэмжээ",
      dataIndex: "quantity",
    },
    {
      title: "Эхэлсэн огноо",
      dataIndex: "startDate",
    },
    {
      title: "Дуусах огноо",
      dataIndex: "endDate",
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      render: (value: any) => `${EQUIPMENT_RENT_STATUS_REF[value]}`,
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          {canEdit && (
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<EditOutlined />}
              onClick={() => setRenterEdit(true, item)}
            />
          )}
          {canEdit && (
            <Popconfirm
              title="Устгахдаа итгэйлтэй байна уу"
              onConfirm={() => deleteEquipmentRenting(item)}
              okText="Тийм"
              cancelText="Үгүй"
            >
              <Button
                onClick={() => {}}
                type={"primary"}
                size={"small"}
                danger
                ghost
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <BreadMe title={["Тоног төхөөрөмж", "Тоног төхөөрөмжийн дэлгэрэнгүй"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Descriptions title="Тоног төхөөрөмжийн мэдээлэл">
            <Descriptions.Item span={3}>
              {equipmentContent.result.image && renderImage()}
            </Descriptions.Item>
            <Descriptions.Item label="Төрөл">
              {equipmentContent.result.type?.name || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Дэд төрөл">
              {equipmentContent.result.subType?.name || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Марк">
              {equipmentContent.result.mark || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Ашиглалт">
              {equipmentContent.result.usage &&
                EQUIPMENT_USAGE_REF[equipmentContent.result.usage]}
            </Descriptions.Item>
            <Descriptions.Item label="Тоо хэмжээ ">
              {equipmentContent.result.quantity || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Үйлдвэрлэсэн улс">
              {equipmentContent.result.country?.name || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Эзэмшигч">
              {`${equipmentContent.result.owner?.lastName.substring(0, 1)}.${
                equipmentContent.result.owner?.firstName
              } (${equipmentContent.result.owner?.rd})`}
            </Descriptions.Item>
            <Descriptions.Item label="Үйлдвэрлэсэн огноо">
              {equipmentContent.result.manufacturedDate || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Улсын дугаар">
              {equipmentContent.result.countryNumber || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Сериал дугаар">
              {equipmentContent.result.serialNumber || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Үзүүлэлт хүчин чадал">
              {equipmentContent.result.performance || ""}
            </Descriptions.Item>
            <Descriptions.Item label="Тайлбар">
              {equipmentContent.result.desciption || ""}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"} gutter={24}>
              {!equipmentContent.loading && canEdit && (
                <Col>
                  <Button
                    onClick={() =>
                      setEquipmentEdit(true, equipmentContent.result)
                    }
                    type={"primary"}
                    icon={<EditOutlined />}
                  >
                    Засах
                  </Button>
                </Col>
              )}
              {!equipmentContent.loading && canEdit && (
                <Col>
                  <Popconfirm
                    title="Устгахдаа итгэйлтэй байна уу"
                    onConfirm={() => deleteEquipment()}
                    okText="Тийм"
                    cancelText="Үгүй"
                  >
                    <Button
                      onClick={() => {}}
                      type={"primary"}
                      danger
                      icon={<DeleteOutlined />}
                    >
                      Устгах
                    </Button>
                  </Popconfirm>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"}>
              {!equipmentContent.loading &&
                canEdit &&
                equipmentContent.result.usage !== EQUIPMENT_USAGE.DOWN && (
                  <Button
                    onClick={() => setRenterEdit(true)}
                    type={"primary"}
                    icon={<PlusSquareOutlined />}
                  >
                    Түрээслэгч нэмэх
                  </Button>
                )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              pagination={{
                current: renterContent.page,
                total: renterContent.count,
              }}
              onChange={(p) => {
                changeRenterContent({
                  ...renterContent,
                  page: p.current || 1,
                });
              }}
              rowKey={"id"}
              columns={columns}
              dataSource={renterContent.result}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <EquipmentAddEdit
        visible={equipmentEdit.visible}
        edit={equipmentEdit.edit}
        onHide={() => setEquipmentEdit(false)}
        onSuccess={() => {
          setEquipmentEdit(false);
          loadEquipmentContent();
        }}
      />
      {renterEdit.visible && (
        <RenterAddEdit
          equipmentId={id}
          visible={renterEdit.visible}
          edit={renterEdit.edit}
          onHide={() => setRenterEdit(false)}
          onSuccess={() => {
            setRenterEdit(false);
            loadRenterContent();
          }}
        />
      )}
    </>
  );
};
export default EquipmentDetail;

interface Props {
  equipmentId: any;
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const RenterAddEdit = ({
  equipmentId,
  edit,
  visible,
  onHide,
  onSuccess,
}: Props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (visible) {
      if (edit) {
        form.setFieldsValue({
          ...edit,
          renter: edit.renter.rd,
          startDate: moment(edit.startDate),
          endDate: moment(edit.endDate),
        });
      } else {
        form.resetFields();
      }
    }
  }, [edit, visible]);
  const saveContent = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      const response = await NetworkApi.requestPost(
        "/api/equipment/renting/update",
        {
          ...data,
          equipmentId,
        }
      );
      console.log("create: ", response);
      if (response.resultCode === 200) {
        onSuccess();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [edit]
  );

  return (
    <Modal
      visible={visible}
      title={edit ? "Түрээслэгч засах" : "Түрээслэгч нэмэх"}
      onCancel={onHide}
      footer={null}
    >
      <Form
        form={form}
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        wrapperCol={{ flex: 1 }}
        colon={false}
        layout="horizontal"
        className="form-with-label-wrap"
        onFinish={saveContent}
      >
        <Form.Item
          name="renterId"
          label="Түрээслэгч : "
          rules={[{ required: true, message: "Түрээслэгч сонгоно уу" }]}
        >
          <UserSelectWithSearch
            placeholder="Түрээслэгч сонгоно уу"
            only={false}
          />
        </Form.Item>
        <Form.Item
          name="quantity"
          label="Тоо хэмжээ : "
          rules={[{ required: true, message: "Тоо хэмжээ оруулна уу" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="startDate"
          label="Эхлэх огноо : "
          rules={[
            { required: true, message: "Эхлэх огноо сонгоно уу" },
            () => ({
              validator(rule, startDate) {
                const endDate = form.getFieldValue("endDate");
                if (
                  startDate &&
                  endDate &&
                  startDate.format("x") > endDate.format("x")
                ) {
                  return Promise.reject(
                    "Эхлэх огноо дуусах огнооноос их байна"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            disabledDate={(current) =>
              current &&
              form.getFieldValue("endDate") &&
              current > form.getFieldValue("endDate")
            }
          />
        </Form.Item>
        <Form.Item
          name="endDate"
          label="Дуусах огноо : "
          rules={[
            {
              required: true,
              message: "Дуусах огноо сонгоно уу",
            },
            () => ({
              validator(rule, endDate) {
                const startDate = form.getFieldValue("startDate");
                if (
                  startDate &&
                  endDate &&
                  startDate.format("x") > endDate.format("x")
                ) {
                  return Promise.reject(
                    "Дуусах огноо эхлэх огнооноос бага байна"
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            disabledDate={(current) =>
              current &&
              form.getFieldValue("startDate") &&
              current < form.getFieldValue("startDate")
            }
          />
        </Form.Item>
        <Form.Item
          name="status"
          label="Төлөв : "
          rules={[{ required: true, message: "Төлөв сонгоно уу" }]}
        >
          <Select placeholder="Төлөв сонгоно уу">
            {EQUIPMENT_RENT_STATUS_LIST.map((type) => {
              return (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Divider />
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
