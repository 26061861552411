import React, { useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Breadcrumb,
  Layout,
  Select,
  Space,
  Checkbox,
  Form,
  Modal,
  Popconfirm,
} from "antd";
import { Link } from "react-router-dom";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";

const FarmType = () => {
  const [addEdit, setAddEdit] = useState({
    visible: false,
    edit: null,
  });
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setTimeout(() => {
      setVisible(false);
    }, 500);
  };
  const [visibleDelete, setDelete] = useState(false);
  const deleteModal = () => {
    setDelete(true);
  };
  const deleteOk = () => {
    setTimeout(() => {
      setDelete(false);
    }, 500);
  };
  const elementData = [
    {
      key: "1",
      name: "Улсын",
      description: "улсын өмчийн",
    },
    {
      key: "2",
      name: "Эзэмшил",
      description: "хувийн эзэмшилийн талбай",
    },
    {
      key: "3",
      name: "Түрээс",
      description: "Бусдийн талбайг түрээсээр ашиглах",
    },
  ];

  const elementInfo = [
    {
      title: "Талбайн төрөл",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Үйлдэл",
      dataIndex: "key",
      key: "key",
      render: () => (
        <Space size={10}>
          <Button
            type={"primary"}
            size={"small"}
            ghost
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна."
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button
              type={"primary"}
              size={"small"}
              danger
              ghost
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Талбайн төрөл"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"}>
              <Button
                onClick={showModal}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Талбайн төрөл нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              columns={elementInfo}
              dataSource={elementData}
              onChange={handleParam}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
        <FarmTypeEdit
          edit={addEdit.edit}
          onHide={() => setAddEdit({ visible: false, edit: null })}
          onSuccess={() => console.log("hi")}
          visible={addEdit.visible}
        />
      </Layout.Content>
    </>
  );
};

interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const FarmTypeEdit = ({ edit, visible, onHide, onSuccess }: Props) => {
  return (
    <Modal
      width={595}
      centered
      visible={visible}
      footer={null}
      title={"Талбайн төрөл нэмэх"}
    >
      <Form labelCol={{ span: 8 }}>
        <Form.Item label="Төрөл">
          <Input placeholder={"Талбайн төрлийн нэр"} />
        </Form.Item>
        <Form.Item label="Тайлбар">
          <Input placeholder={"Талбайн төрлийн тайлбар"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FarmType;
