import React, { useCallback, useEffect, useState } from 'react'
import NetworkApi from '../network/networkApi'
interface ContentData {
    result: any[],
    page?: number,
    total?: number,
    loading: boolean
}
const useFarmlandList = (page?: number): [ContentData, () => void, any, any] => {
    const [filter, changeFilter] = useState<any>(null)
    const [content, changeContent] = useState<ContentData>({
        loading: false,
        result: []
    })
    useEffect(() => {
        loadContent()            
    }, [filter])
    const loadContent = useCallback(async () => {
        let response = await NetworkApi.requestGet('/api/farmland/list', filter)
        if (response.resultCode == 200)
            changeContent({
                loading: false,
                page: response.result?.page,
                total: response.result?.count,
                result: response.result.rows || []
            })
    }, [filter])

    return [content, loadContent, changeFilter, filter]
}
export default useFarmlandList