import { useCallback, useEffect, useState } from "react";
import { Col, Row, Select, message } from "antd";
import NetworkApi from "../network/networkApi";

import useChemicalTypeList from "../hooks/useChemicalTypeList";
import { Pie, Bar } from "@ant-design/plots";

const chartOuterStyle = {
  margin: 10,
  paddingTop: 10,
  paddingBottom: 10,
  background: "#fff",
};
const chartTitleStyle = {
  textAlign: "center" as "center",
  fontSize: 14,
  fontWeight: 600,
  paddingTop: 10,
};

interface pieChartDataRow {
  id: string;
  label: string;
  value: number;
  count: number;
}

interface ChemicalRequestContent {
  loading: boolean;
  result: pieChartDataRow[];
}

const useChemicalRequests = (): [ChemicalRequestContent, () => void] => {
  const [content, changeContent] = useState<ChemicalRequestContent>({
    loading: false,
    result: [],
  });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      "/api/dashboard/chemical/request"
    );
    if (response.resultCode === 200) {
      changeContent({
        loading: false,
        result: response.result.chemicalTypes || [],
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  return [content, loadContent];
};

interface ChemicalImportContent {
  loading: boolean;
  result: pieChartDataRow[];
}

const useChemicalImport = (): [ChemicalImportContent, () => void] => {
  const [content, changeContent] = useState<ChemicalImportContent>({
    loading: false,
    result: [],
  });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      "/api/dashboard/chemical/license"
    );
    if (response.resultCode === 200) {
      changeContent({
        loading: false,
        result: response.result?.organizations || [],
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  return [content, loadContent];
};

interface ChemicalApprovedContent {
  loading: boolean;
  result: pieChartDataRow[];
}

const useChemicalApproved = (): [ChemicalApprovedContent, () => void] => {
  const [content, changeContent] = useState<ChemicalApprovedContent>({
    loading: false,
    result: [],
  });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(
      "/api/dashboard/chemical/request?type=approved"
    );
    if (response.resultCode === 200) {
      changeContent({
        loading: false,
        result: response.result?.chemicalTypes || [],
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  return [content, loadContent];
};

interface ChemicalByTypeContent {
  loading: boolean;
  result: any[];
}

const useChemicalByType = (): [
  ChemicalByTypeContent,
  (typeId: number) => void
] => {
  const [content, changeContent] = useState<ChemicalByTypeContent>({
    loading: false,
    result: [],
  });

  const loadContent = useCallback(async (typeId: number) => {
    let response = await NetworkApi.requestGet("/api/dashboard/chemical/type", {
      id: typeId,
    });
    if (response.resultCode === 200) {
      changeContent({
        loading: false,
        result: response.result.chemicals || [],
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, []);

  return [content, loadContent];
};

const DashboardChemical = () => {
  const [chemicalTypeList] = useChemicalTypeList();
  const [chemicalRequests] = useChemicalRequests();
  const [chemicalImport] = useChemicalImport();
  const [chemicalApproved] = useChemicalApproved();
  const [chemicalByType, loadChemicalByType] = useChemicalByType();

  const [chemicalType, setChemicalType] = useState<{
    id: number;
    name: string;
  }>();

  useEffect(() => {
    if (!chemicalType && chemicalTypeList.result.length > 0) {
      setChemicalType(chemicalTypeList.result[0]);
    }
  }, [chemicalType, chemicalTypeList]);

  useEffect(() => {
    if (chemicalType) {
      loadChemicalByType(chemicalType.id);
    }
  }, [chemicalType, loadChemicalByType]);

  return (
    <>
      <Row style={{ padding: 20, paddingTop: 0 }}>
        <Col span={10}>
          <Col span={24}>
            <div style={chartOuterStyle}>
              <div style={chartTitleStyle}>
                <span>Бодис, бордооны хүсэлтээр</span>
              </div>
              <div
                style={{
                  width: "100%",
                  height: 280,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Pie
                  appendPadding={10}
                  angleField="amount"
                  colorField="typeName"
                  radius={0.9}
                  label={{
                    type: "outer",
                    content: "{percentage}",
                  }}
                  interactions={[
                    {
                      type: "element-active",
                    },
                  ]}
                  data={chemicalRequests.result ?? []}
                />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div style={chartOuterStyle}>
              <div style={chartTitleStyle}>
                <span>Бодис, бордооны зөвшөөрлөөр</span>
              </div>
              <div
                style={{
                  width: "100%",
                  height: 280,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Pie
                  appendPadding={10}
                  angleField="amount"
                  colorField="typeName"
                  radius={0.9}
                  label={{
                    type: "outer",
                    content: "{percentage}",
                  }}
                  interactions={[
                    {
                      type: "element-active",
                    },
                  ]}
                  data={chemicalApproved.result ?? []}
                />
              </div>
            </div>
          </Col>
        </Col>
        <Col span={14}>
          <div style={chartOuterStyle}>
            <div style={chartTitleStyle}>
              <span>Бодис, бордооны импортлогчид</span>
            </div>
            <div
              style={{
                width: "100%",
                height: 620,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Pie
                appendPadding={10}
                angleField="amount"
                colorField="organizationName"
                radius={0.9}
                label={{
                  type: "outer",
                  content: "{percentage}",
                }}
                interactions={[
                  {
                    type: "element-active",
                  },
                ]}
                data={chemicalImport.result ?? []}
              />
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div style={chartOuterStyle}>
            <div style={chartTitleStyle}>
              {chemicalType && <span>{chemicalType.name} бодис, бордооны төрлөөр</span>}
            </div>
            <Row
              justify="end"
              align="middle"
              style={{ paddingRight: 20, marginBottom: 20 }}
            >
              {chemicalTypeList.result?.length > 0 && (
                <Select
                  defaultValue={chemicalTypeList.result[0].id}
                  style={{ width: 200 }}
                  onChange={(value: number) => {
                    const filteredItem = chemicalTypeList.result.filter(
                      (item) => item.id === value
                    );
                    setChemicalType(filteredItem[0]);
                  }}
                >
                  {chemicalTypeList.result.map((type) => {
                    return (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Row>
            <div
              style={{
                width: "100%",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <Bar
                data={chemicalByType.result}
                xField="amount"
                yField="chemicalName"
                seriesField="chemicalName"
                legend={false}
                maxBarWidth={16}
                height={1000}
                barBackground={{
                  style: {
                    fill: "rgba(0,0,0,0.1)",
                  },
                }}
                color="#1890ff"
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DashboardChemical;
