import React, { useCallback, useEffect, useState } from 'react'
import NetworkApi from '../network/networkApi'
interface ContentData {
    result: any[],
    loading: boolean
}
const useRoleList = (ignore:any): [ContentData, () => void] => {
    const [content, changeContent] = useState<ContentData>({
        loading: false,
        result: []
    })
    const loadContent = useCallback(async () => {
        let response = await NetworkApi.requestGet('/api/role/list',{ignore})        
        changeContent({
            loading: false,
            result: response.result || []
        })
    }, [ignore])
    useEffect(() => {
        loadContent()
    }, [])
    return [content, loadContent]
}
export default useRoleList