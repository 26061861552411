import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Select,
  Space,
  Form,
  Modal,
  message,
  Popconfirm,
  Switch,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import useChemicalTypeList from "../../hooks/useChemicalTypeList";
import useChemicalPurposeList from "../../hooks/useChemicalPurposeList";
import NetworkApi from "../../network/networkApi";
import useChemical from "../../hooks/useChemical";
import useUnit from "../../hooks/useUnit";

const { Option } = Select;
const { TextArea } = Input;

interface AddEditModalData {
  visible: boolean;
  id?: number;
}

const ChemicalElement = () => {
  const [addEdit, setAddEdit] = useState<AddEditModalData>({
    visible: false,
    id: undefined,
  });
  const [chemicalTypeList] = useChemicalTypeList();
  const [content, loadContent, changeFilter, filter] = useChemical();
  const showModal = (id: number) => {
    setAddEdit({ visible: true, id: id });
  };

  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost("/api/chemical/update", {
      ...data,
      status: "D",
    });
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Засвар хийхэд алдаа гарлаа.");
    }
  }, []);

  const elementInfo = [
    {
      title: "Бодис, бордооны төрөл",
      dataIndex: "type",
      key: "type",
      render: (type: any) => {
        return type?.name;
      },
    },
    {
      title: "Худалдааны нэр",
      dataIndex: "technicalName",
    },
    {
      title: "Үйлчлэх бодис, бордооны нэр",
      dataIndex: "name",
    },
    {
      title: "Үйлчлэх бодис, бордооны хувь хэмжээ",
      render: (itm: any) => {
        return `${itm.chemicalSize} ${itm.measure}`;
      },
    },
    {
      title: "Томъёо",
      dataIndex: "chemicalFormula",
    },
    {
      title: "Олон улсын бүртгэлийн дугаар /CAS/",
      dataIndex: "registrationNumber",
    },
    {
      title: "Зориулалт",
      dataIndex: "purpose",
      render: (purpose: any) => {
        return `${purpose?.name}`;
      },
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Button
            onClick={() => showModal(item.id)}
            type={"primary"}
            size={"small"}
            ghost
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна уу"
            onConfirm={() => deleteItem(item)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button
              onClick={() => {}}
              type={"primary"}
              size={"small"}
              danger
              ghost
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const filterChange = (value: any) => {
    changeFilter((prevValues: any) => {
      return { ...prevValues, page: 1, typeId: value };
    });
  };

  const filterName = (t: any) => {
    changeFilter((prevValues: any) => {
      return { ...prevValues, page: 1, name: t.target.value };
    });
  };

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Бодис, бордоо"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"space-between"}>
              <Space size={20}>
                <Form.Item>
                  <Select
                    defaultValue={0}
                    style={{ width: 200 }}
                    onChange={filterChange}
                  >
                    <Option value={0}>Бодис, бордооны төрөл</Option>
                    {chemicalTypeList.result.map((type) => {
                      return (
                        <Option key={type.id} value={type.id}>
                          {type.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Нэр">
                  <Input value={filter.name} onChange={filterName} />
                </Form.Item>
                <Form.Item label="Төлөв" initialValue="A">
                  <Select
                    style={{ width: 200 }}
                    defaultValue="A"
                    onChange={(value) => {
                      changeFilter((prevValues: any) => {
                        return { ...prevValues, page: 1, status: value };
                      });
                    }}
                  >
                    <Option value={"A"}>Идэвхтэй</Option>
                    <Option value={"D"}>Устгасан</Option>
                  </Select>
                </Form.Item>
              </Space>
              <Button
                type={"primary"}
                icon={<PlusSquareOutlined />}
                onClick={() => {
                  setAddEdit({ visible: true, id: undefined });
                }}
              >
                Бодис, бордоо нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              rowKey={"id"}
              columns={elementInfo}
              dataSource={content.result}
              onChange={handleParam}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <ChemicalAddEdit
        visible={addEdit.visible}
        id={addEdit.id}
        hide={() => setAddEdit({ visible: false, id: undefined })}
        onSuccess={() => {
          loadContent();
          setAddEdit({ visible: false, id: undefined });
        }}
      />
    </>
  );
};

interface ModalProps {
  visible: boolean;
  hide: () => void;
  onSuccess: () => void;
  id?: number;
}

const ChemicalAddEdit = ({ visible, onSuccess, id }: ModalProps) => {
  const [typeList] = useChemicalTypeList();
  const [purposeList] = useChemicalPurposeList();
  const [unitList] = useUnit();
  const formRef = useRef<any>();
  useEffect(() => {
    if (visible) {
      if (id) {
        loadEdit(id);
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [id, visible]);
  const loadEdit = useCallback(async (id: any) => {
    formRef.current?.resetFields();
    let response = await NetworkApi.requestGet(`/api/chemical/detail/${id}`);
    if (response.resultCode === 200) {
      formRef.current?.setFieldsValue(response.result);
    } else {
      message.error(response.message || "Системийн алдаа. API ирсэнгүй");
    }
  }, []);
  const saveContent = useCallback(
    async (data: any) => {
      if (id) {
        data = { ...data, id };
      }
      let response = await NetworkApi.requestPost("/api/chemical/update", data);
      if (response.resultCode === 200) {
        onSuccess();
      } else {
        message.error(response.message || "Системийн алдаа. UPDATE хийсэнгүй");
      }
    },
    [id]
  );
  return (
    <Modal
      title={id ? "Бодис, бордоо засах" : "Бодис, бордоо нэмэх"}
      width={595}
      centered
      visible={visible}
      onCancel={onSuccess}
      footer={null}
    >
      <Form onFinish={saveContent} ref={formRef} labelCol={{ span: 10 }}>
        <Form.Item name="typeId" label="Бодис, бордооны төрөл сонгох">
          <Select style={{ width: "100%" }}>
            <Option value={""}>Бодис, бордооны төрөл</Option>
            {typeList.result.map((type) => (
              <Option value={type.id} key={type.id}>
                {type.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="technicalName" label="Худалдааны болон техникийн нэр">
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Үйлчлэх бодис, бордооны нэр">
          <Input />
        </Form.Item>
        <Form.Item name="purposeId" label="Зориулалт">
          <Select style={{ width: "100%" }}>
            <Option value="">Зориулалт сонго</Option>
            {purposeList.result.map((purpose: any) => (
              <Option value={purpose.id} key={purpose.id}>
                {purpose.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Үйлчлэх бодис, бордооны хувь, хэмжээ">
          <Input.Group compact>
            <Form.Item name="chemicalSize" noStyle>
              <Input
                style={{ width: "75%" }}
                placeholder={"Үйлчлэх бодис, бордооны хувь, хэмжээ"}
              />
            </Form.Item>
            <Form.Item name="measure" noStyle>
              <Select style={{ width: "25%" }}>
                <Option value={"%"}>%</Option>
                <Option value={"г/кг"}>г/кг</Option>
                <Option value={"г/л"}>г/л</Option>
                <Option value={"л"}>л</Option>
                <Option value={"килограмм"}>кг</Option>
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item name="chemicalFormula" label="Томъёо">
          <Input />
        </Form.Item>
        <Form.Item name="registrationNumber" label="ОУ бүртгэлийн дугаар /CAS/">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Тайлбар">
          <TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            placeholder={"Тайлбар бичнэ үү!"}
          />
        </Form.Item>
        <Form.Item name="measureId" label="Хэмжих нэгж">
          <Select style={{ width: "50%" }}>
            <Select.Option value="">Сонго</Select.Option>
            {unitList.result.map((unit) => (
              <Select.Option value={unit.id}>{unit.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item initialValue="A" name="status" label="Төлөв">
          <Select style={{ width: "50%" }}>
            <Select.Option value="A">Идэвхтэй</Select.Option>
            <Select.Option value="D">Устгагдсан</Select.Option>
          </Select>
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default ChemicalElement;
