import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import Menu from "./lib/Menu";
import Dashboard from "./Dashboard";
import DashboardFarmLand from "./DashboardFarmLand";
import DashboardChemical from "./DashboardChemical";
import FarmLandAdd from "./farmland/FarmLandAdd";
import OrgLicense from "./license/OrgLicense";
import Chemical from "./license/Chemical";
import ChemicalOrders from "./license/ChemicalOrders";
import FarmlandList from "./farmland/FarmlandList";
import FarmLandService from "./farmland/FarmLandService";
import FarmLandDetail from "./farmland/FarmLandDetail";
import FarmLandGrow from "./farmland/FarmLandGrow";
import Farmers from "./users/Farmers";
import WareHouse from "./warehouse/WareHouse";
import Usage from "./warehouse/Usage";
import LicenseAdd from "./license/LicenseAdd";
import LicenseList from "./license/LicenseList";
import LicenseDetail from "./license/LicenseDetail";
import UserList from "./users/UserList";
import ElementType from "./config/ElementType";
import ChemicalElement from "./config/Element";
import FarmLandType from "./config/FarmLandType";
import PlantType from "./config/FarmLandPlantType";
import FarmService from "./config/PlantService";
import FarmType from "./config/FarmType";
import PlantList from "./config/PlantList";
import PlantSort from "./config/PlantSort";
import PlanList from "./plan/PlanList";
import PlanAdd from "./plan/PlanAdd";
import PlanEdit from "./plan/PlanEdit";
import PlanDetail from "./plan/PlanDetail";
import PlannedPlantList from "./plan/PlannedPlantList";
import SupportAdd from "./support/SupportAdd";
import SupportList from "./support/SupportList";
import IncentiveList from "./incentive/IncentiveList";
import IncentiveAdd from "./incentive/IncentiveAdd";
import IncentiveEdit from "./incentive/IncentiveEdit";
import FarmUnit from "./config/FarmUnit";
import UserMenu from "./lib/Header";
import Permission from "./users/Permission";
import Profile from "./users/Profile";
import FarmlandUsageList from "./farmland/usage/FarmlandUsageList";
import CultivationReport from "./report/CultivationReport";
import HarvestReport from "./report/HarvestReport";
import AgroCultivation from "./report/AgroCultivation";
import ActiveFarmlandUsageList from "./farmland/usage/ActiveFarmlandUsageList";
import CultivationPlan from "./report/CultivationPlan";
import CultivationPlanList from "./report/CultivationPlanList";
import MapPage from "./map/mappage";
import EquipmentType from "./config/EquipmentType";
import EquipmentSubType from "./config/EquipmentSubType";
import EquipmentCountry from "./config/EquipmentCountry";
import EquipmentList from "./equipment/EquipmentList";
import EquipmentDetail from "./equipment/EquipmentDetail";
import SellerParcelList from "./parcel/SellerParcelList";
import CellarTypes from "./config/CellarTypes";
import WareHouseList from "./warehouse/WarehouseList";
import { WarehouseAdd, WarehouseEdit } from "./warehouse/WarehouseAdd";
import FarmerDetail from "./users/FarmerDetail";
import FarmlandListV2 from "./farmland/usage/FarmlandListV2";
import SupportOthersListT from "./support-others/SupportOthersListT";
import SupportOthersListF from "./support-others/SupportOthersListF";
import SupportOthersAdd from "./support-others/SupportOthersAdd";
import SupportOthersEdit from "./support-others/SupportOthersEdit";
import ElementTypeFile from "./config/ElementTypeFile";
import ChemicalFOrders from "./license/ChemicalFOrders";

const AdminApp = () => {
  return (
    <BrowserRouter>
      <Layout
        style={{ minHeight: "100vh", maxWidth: "100vw", overflowX: "hidden" }}
      >
        <Menu />
        <Layout>
          <UserMenu />
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route
              exact
              path="/dashboard/farmland"
              component={DashboardFarmLand}
            />
            <Route
              exact
              path="/dashboard/chemical"
              component={DashboardChemical}
            />
            <Route exact path="/license/farm" component={FarmlandUsageList} />
            <Route exact path="/license/organization" component={OrgLicense} />
            <Route exact path="/license/chemical" component={Chemical} />
            <Route
              exact
              path="/license/chemical/orders"
              component={ChemicalOrders}
            />
            <Route
              exact
              path="/license/chemical/fertilizer-orders"
              component={ChemicalFOrders}
            />
            <Route exact path="/license/chemical/usage" component={Usage} />
            <Route exact path="/area/add" component={FarmLandAdd} />
            <Route exact path="/area/list" component={FarmlandList} />
            <Route exact path="/area/detail/:id" component={FarmLandDetail} />
            <Route exact path="/area/service/:id" component={FarmLandService} />
            <Route exact path="/area/farm/:id" component={FarmLandGrow} />
            <Route exact path="/farmers" component={Farmers} />
            <Route exact path="/farmers/:id" component={FarmerDetail} />
            <Route exact path="/warehouse" component={WareHouse} />
            <Route exact path="/wm/warehouse" component={WareHouseList} />
            <Route exact path="/wm/warehouse/add" component={WarehouseAdd} />
            <Route
              exact
              path="/wm/warehouse/edit/:id"
              component={WarehouseEdit}
            />
            <Route exact path="/origin/add" component={LicenseAdd} />
            <Route exact path="/origin/list" component={LicenseList} />
            <Route exact path="/origin/edit/:id" component={LicenseAdd} />
            <Route exact path="/origin/detail/:id" component={LicenseDetail} />
            <Route exact path="/origin/check/:id" component={OrgLicense} />
            <Route exact path="/user/list" component={UserList} />
            <Route exact path="/chemical/type" component={ElementType} />
            <Route exact path="/chemical/file" component={ElementTypeFile} />
            <Route
              exact
              path="/chemical/component"
              component={ChemicalElement}
            />
            <Route exact path="/service/type" component={FarmLandType} />
            <Route exact path="/service/list" component={FarmService} />
            <Route exact path="/plant/type" component={PlantType} />
            <Route exact path="/plant/list" component={PlantList} />
            <Route exact path="/plant/sort/list" component={PlantSort} />
            <Route exact path="/plan/list" component={PlanList} />
            <Route exact path="/plan/add" component={PlanAdd} />
            <Route exact path="/plan/edit/:id" component={PlanEdit} />
            <Route exact path="/plan/detail/:id" component={PlanDetail} />
            <Route exact path="/planned/list" component={PlannedPlantList} />
            <Route exact path="/support/add" component={SupportAdd} />
            <Route exact path="/support/list" component={SupportList} />
            <Route
              exact
              path="/support-others/add"
              component={SupportOthersAdd}
            />
            <Route
              exact
              path="/support-others/edit/:id"
              component={SupportOthersEdit}
            />
            <Route
              exact
              path="/support-others/list/technic"
              component={SupportOthersListT}
            />
            <Route
              exact
              path="/support-others/list/fertilizer"
              component={SupportOthersListF}
            />
            <Route exact path="/incentive" component={IncentiveList} />
            <Route exact path="/incentive/request" component={IncentiveAdd} />
            <Route
              exact
              path="/incentive/request/:id"
              component={IncentiveEdit}
            />
            <Route exact path="/mappage" component={MapPage} />
            <Route exact path="/area/type" component={FarmType} />
            <Route exact path="/unit" component={FarmUnit} />
            <Route exact path="/user/permission" component={Permission} />
            <Route exact path="/user/profile" component={Profile} />
            <Route
              exact
              path="/report/cultivation"
              component={CultivationReport}
            />
            <Route exact path="/report/harvest" component={HarvestReport} />
            <Route
              exact
              path="/report/agroCultivation"
              component={AgroCultivation}
            />
            <Route exact path="/report/plan" component={CultivationPlanList} />
            <Route exact path="/report/plan/:id" component={CultivationPlan} />
            <Route exact path="/farmland/list" component={FarmlandListV2} />
            <Route
              exact
              path="/farmland/:id/usage/list"
              component={ActiveFarmlandUsageList}
            />
            <Route exact path="/equipment/type" component={EquipmentType} />
            <Route
              exact
              path="/equipment/subtype"
              component={EquipmentSubType}
            />
            <Route
              exact
              path="/equipment/country"
              component={EquipmentCountry}
            />
            <Route exact path="/equipment/list" component={EquipmentList} />
            <Route
              exact
              path="/equipment/detail/:id"
              component={EquipmentDetail}
            />
            <Route exact path="/parcel" component={SellerParcelList} />
            <Route exact path="/cellar-type" component={CellarTypes} />
          </Switch>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};
export default AdminApp;
