import { useContext, useMemo, useState } from "react";
import { Row, Col, Button, Table, Layout, Space, Popconfirm } from "antd";
import { Link, useParams } from "react-router-dom";
import {
  FileTextOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import useFarmlandDetail from "../../hooks/useFarmlandDetail";
import UserContext from "../../providers/UserContext";
import { FarmLandOwnerChange } from "./FarmLandOwnerChange";

interface Params {
  id?: any;
}

const FARMLAND_USAGE_TYPE: any = { owner: "Эзэмшил", rent: "Түрээс" };

const FarmLandDetail = () => {
  let { id } = useParams<Params>();
  const [openChangeOwner, setOpenChangeOwner] = useState<boolean>(false);
  const [content, loadContent] = useFarmlandDetail(id);
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (user.role?.cd == "ADMIN" || user?.role?.cd == "VILLAGE_AGRONOMIST") {
      return true;
    }
    return false;
  }, [user]);

  const canChangeOwner = useMemo(() => {
    if (user.role?.cd == "ADMIN") {
      return true;
    }
    return false;
  }, [user]);

  const usageListInfo = [
    {
      title: "Ашиглаж буй тариаланч",
      dataIndex: "organization",
      render: (value: any) => `${value?.name ?? ""} (${value?.rd ?? ""})`,
    },
    {
      title: "Эрхийн төрөл",
      dataIndex: "type",
      render: (value: string) => FARMLAND_USAGE_TYPE[value],
    },
    {
      title: "Ашиглаж эхэлсэн",
      dataIndex: "startDate",
    },
    {
      title: "Ашиглаж дуусах",
      dataIndex: "endDate",
    },
    {
      title: "Ашиглаж буй талбай",
      dataIndex: "area",
    },
    {
      title: "Таримлын мэдээлэл",
      dataIndex: "id",
      render: (index: any) => (
        <Link to={"/area/farm/" + index}>
          <Button
            type={"primary"}
            size={"small"}
            ghost
            icon={<FileTextOutlined />}
            title={"Таримлын дэлгэрэнгүй"}
          >
            Дэлгэрэнгүй
          </Button>
        </Link>
      ),
    },
    {
      title: "Талбайн арчилгаа",
      dataIndex: "id",
      render: (index: any) => (
        <Link to={"/area/service/" + index}>
          <Button
            type={"primary"}
            size={"small"}
            ghost
            icon={<FileTextOutlined />}
            title={"Арчилгааны дэлгэрэнгүй"}
          >
            Дэлгэрэнгүй
          </Button>
        </Link>
      ),
    },
    ...(canEdit
      ? [
          {
            title: "Үйлдэл",
            dataIndex: "id",
            render: (index: any) => (
              <Space size={10}>
                <Button
                  type={"primary"}
                  size={"small"}
                  ghost
                  icon={<EditOutlined />}
                  title={"Засах"}
                />
                <Popconfirm
                  title="Устгахдаа итгэйлтэй байна уу"
                  okText="Тийм"
                  cancelText="Үгүй"
                >
                  <Button
                    type={"primary"}
                    size={"small"}
                    danger
                    ghost
                    icon={<DeleteOutlined />}
                    title={"Устгах"}
                  />
                </Popconfirm>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const ownerHistory = [
    {
      title: "Эзэмшигч",
      dataIndex: "organization",
      render: (value: any) => `${value?.name ?? ""} (${value?.rd ?? ""})`,
    },
    {
      title: "Эхэлсэн",
      dataIndex: "startDate",
    },
    {
      title: "Дууссан",
      dataIndex: "endDate",
    },
  ];

  return (
    <>
      <BreadMe
        title={[
          "Тариалангийн талбай",
          "Тариалангийн талбайн дугаар",
          `T-${content.farmland?.posNo}`,
        ]}
      />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Хаяг
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Талбайн хэмжээ
          </Col>
          <Col
            span={4}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Эзэмшигчийн РД
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Ашиглаж эхэлсэн
          </Col>

          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
              fontWeight: "bold",
            }}
          >
            Байршил
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {`${content.farmland?.aimag?.name ?? "-"}, ${
              content.farmland?.sum?.name ?? "-"
            }, ${content.farmland?.bag?.name ?? "-"}`}
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {`${content.farmland.area ?? "-"} га`}
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {content.farmland.ownerId}
          </Col>
          <Col
            span={5}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {content.farmland.initialDate}
          </Col>
          <Col
            span={4}
            style={{
              padding: 16,
              color: "rgba(0, 0, 0, 0.85)",
              background: "#fafafa",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            {`${content.farmland?.position ?? "-"}`}
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <h4>Хариуцагч:</h4>
          </Col>
          <Col span={24}>
            <Table
              rowKey={"id"}
              columns={usageListInfo}
              dataSource={content.usageList}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row style={{ marginTop: 15, marginBottom: 15 }}>
              <Col span={12}>
                <h4>Эзэмшигчийн түүх:</h4>
              </Col>
              <Col span={12}>
                <Row justify="end" align="middle">
                  {canChangeOwner && (
                    <Button
                      type="primary"
                      icon={<PlusSquareOutlined />}
                      onClick={() => setOpenChangeOwner(true)}
                    >
                      Эзэмшигч нэмэх
                    </Button>
                  )}
                  {openChangeOwner && (
                    <FarmLandOwnerChange
                      visible={openChangeOwner}
                      onSuccess={() => {
                        loadContent(id);
                        setOpenChangeOwner(false);
                      }}
                      onHide={() => setOpenChangeOwner(false)}
                    />
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              rowKey={"id"}
              columns={ownerHistory}
              dataSource={content.ownerHistory}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export default FarmLandDetail;
