import { AntDesignOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Switch,
  Select,
} from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import useUserDetail from "../../hooks/useUserDetail";
import NetworkApi from "../../network/networkApi";
import AddressSelect from "../lib/AddressSelect";
import UserContext from "../../providers/UserContext";
interface Props {
  visible: boolean;
  id: any;
  onHide: any;
}
const FarmerEdit = ({ visible, id, onHide }: Props) => {
  const [userDetail, loadUser] = useUserDetail();
  const { user } = useContext(UserContext);
  const formRef = useRef<any>();
  const [hasPassword, setHasPassword] = useState(false);
  const [address, addressChanged] = useState({
    defaultAimag: "",
    defaultSum: "",
    defaultBag: "",
  });
  useEffect(() => {
    if (visible) {
      loadUser(id);
    }
  }, [visible, id]);
  useEffect(() => {
    if (!userDetail.loading && userDetail.resultCode == 200) {
      formRef.current?.resetFields();
      setHasPassword(false);
      if (userDetail.result?.organizations?.[0]?.type == "organization") {
        formRef.current?.setFieldsValue({
          ...userDetail.result,
          organizationRd: userDetail.result?.organizations?.[0]?.rd,
          organizationName: userDetail.result?.organizations?.[0]?.name,
          aid: userDetail.result?.organizations?.[0]?.aid,
          confirm: userDetail.result?.confirm,
        });
      } else {
        formRef.current?.setFieldsValue({
          ...userDetail.result,
          aid: userDetail.result?.organizations?.[0]?.aid,
          confirm: userDetail.result?.confirm,
        });
      }
      addressChanged({
        defaultAimag: userDetail.result.aimagId,
        defaultSum: userDetail.result.sumId,
        defaultBag: userDetail.result.bagId,
      });
    }
  }, [userDetail]);
  const handleSubmit = useCallback(
    async (data: any) => {
      let url =
        userDetail.result?.organizations?.[0]?.type == "organization"
          ? "/api/organization/update"
          : "/api/farmer/update";
      let respose = await NetworkApi.requestPost(url, { ...data, id });
      if (respose.resultCode == 200) {
        message.success("Амжилттай хадгалагдлаа");
        onHide();
      } else {
        message.error(respose.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [id, userDetail]
  );

  return (
    <Modal footer={[]} width={800} visible={visible} onCancel={onHide}>
      <Form ref={formRef} layout="vertical" onFinish={handleSubmit}>
        <Row>
          <Col span={6}>
            <Avatar
              size={{ xs: 24, sm: 32, md: 40, lg: 80, xl: 120, xxl: 160 }}
              icon={<AntDesignOutlined />}
            />
          </Col>
          <Col span={18}>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item name="name" label={"Нэвтрэх нэр"}>
                  <Input placeholder={"нэвтрэх нэр"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="rd" label={"Регистр"}>
                  <Input placeholder={"XX00000000"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="lastName" label={"Овог"}>
                  <Input placeholder={"Овог"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="firstName" label={"Нэр"}>
                  <Input placeholder={"Нэр"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="phone" label={"Утас"}>
                  <Input placeholder={"Утасны дугаар"} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label={"И-мэйл"}>
                  <Input placeholder={"и-мэйл хаяг"} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <fieldset>
          <legend style={{ width: "auto", paddingLeft: 10, paddingRight: 10 }}>
            {userDetail.result?.organizations?.[0]?.type == "farmer"
              ? "Иргэн"
              : "Хуулийн этгээд"}
          </legend>
          <Row gutter={15}>
            {userDetail.result?.organizations?.[0]?.type == "organization" ? (
              <>
                <Col span={12}>
                  <Form.Item
                    name="organizationName"
                    label={"Хуулийн этгээдийн нэр"}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="organizationRd"
                    label={"Хуулийн этгээдийн регистр"}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="aid"
                    label={"Анхан шатны үйлдвэрлэл эрхлэгчийн дугаар"}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <Col span={24}>
                <Form.Item name="aid" label={"Тариаланчийн код"}>
                  <Input />
                </Form.Item>
              </Col>
            )}
            <Col span={4}>
              <Form.Item
                label="Нууц үг солих"
                name="changePassword"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  checked={hasPassword}
                  onChange={(e) => setHasPassword(e)}
                ></Switch>
              </Form.Item>
            </Col>
            {hasPassword ? (
              <>
                <Col span={10}>
                  <Form.Item name="password" label={"Нууц үг"}>
                    <Input.Password placeholder={"нууц үг"} type={"password"} />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item name="confirmPassword" label={"Нууц үг давтах"}>
                    <Input.Password
                      placeholder={"нууц үг давтах"}
                      type={"password"}
                    />
                  </Form.Item>
                </Col>
              </>
            ) : null}
            <Col span={24}>
              {user.role.cd == "ADMIN" ? (
                <AddressSelect
                  defaultAimag={address.defaultAimag}
                  defaultBag={address.defaultBag}
                  defaultSum={address.defaultSum}
                  enableSelect={true}
                />
              ) : (
                <AddressSelect
                  defaultAimag={address.defaultAimag}
                  defaultBag={address.defaultBag}
                  defaultSum={address.defaultSum}
                />
              )}
            </Col>
            <Col span={24}>
              <Form.Item label="Баталгаажуулсан эсэх" name="confirm">
                <Select>
                  <Select.Option value="no">Цуцалсан</Select.Option>
                  <Select.Option value="yes">Баталгаажсан</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button htmlType="submit" type="primary">
                Хадгалах
              </Button>
              <Button key="cancel" type="default" onClick={onHide}>
                Болих
              </Button>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </Modal>
  );
};
export default FarmerEdit;
