import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Select,
} from "antd";
import {
  SearchOutlined,
  FileTextOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import NetworkApi from "../../network/networkApi";
import { Link } from "react-router-dom";
import BreadMe from "../lib/Breadme";
import AddressSelect from "../lib/AddressSelect";
import UserContext from "../../providers/UserContext";
import FarmlandEdit from "./FarmlandEdit";
import * as querystring from "querystring";
import config from "../../config";
interface ImportState {
  visible: boolean;
  loading: boolean;
  result: any[];
  step: number;
  selectedRowKeys: any[];
  regnum: string;
}
const FarmlandList = () => {
  const { user } = useContext(UserContext);
  const canEdit = useMemo(() => {
    if (user.role?.cd == "ADMIN" || user?.role?.cd == "VILLAGE_AGRONOMIST") {
      return true;
    }
    return false;
  }, [user]);
  const [content, changeContent] = useState({
    loading: false,
    page: 1,
    pageSize: 10,
    area: 0,
    count: 0,
    result: [],
  });
  const [filter, changeFilter] = useState<any>({});
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/farmland/list", filter);
    if (response.resultCode == 200) {
      changeContent({
        loading: false,
        page: response.result.page,
        pageSize: response.result.pageSize,
        count: response.result.count,
        area: response.result.area,
        result: response.result.rows || [],
      });
    } else {
      message.error(response.message || "system error");
    }
  }, [filter]);
  const deleteItem = useCallback(async (item: any) => {
    let response = await NetworkApi.requestGet(`/api/farmland/delete/${item}`);
    if (response.resultCode == 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "system error");
    }
  }, []);
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(10);
  // const changeFilter = (filter: any) => {
  //     changeContent({ ...content, filter });
  // };

  const { defaultAimag, defaultSum } = useMemo(() => {
    let defaultAimag = "";
    let defaultSum = "";
    if (
      user.role?.cd == "PROVINCE_AGRONOMIST" ||
      user.role?.cd == "VILLAGE_AGRONOMIST"
    ) {
      defaultAimag = user.aimagId;
    }
    if (user.role?.cd == "VILLAGE_AGRONOMIST") {
      defaultSum = user.sumId;
    }
    return { defaultAimag, defaultSum };
  }, [user]);
  const downloadFile = useCallback(() => {
    NetworkApi.requestGet("/api/farmland/list/download", filter);
  }, [content]);

  const [showEdit, setShowEdit] = useState<any>({ visible: false, id: null });
  const columns = [
    {
      title: "№",
      width: 60,
      ellipsis: true,
      render: (a: any, b: any, index: any) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "НТДугаар",
      dataIndex: "posNo",
      ellipsis: true,
    },
    {
      title: "Талбайн хэмжээ",
      dataIndex: "area",
      key: "area",
      ellipsis: true,
    },
    {
      title: "Байршил",
      render: (data: any) => {
        return `${data?.aimag?.name}-${data?.sum?.name}`;
      },
      ellipsis: true,
    },
    {
      title: "Ашиглаж эхэлсэн огноо",
      dataIndex: "initialDate",
      ellipsis: true,
    },
    {
      title: "Эзэмшигч",
      render: (item: any) => {
        return item.type == "state"
          ? "Улсын"
          : item.owner
          ? item.owner?.name + "-" + item.ownerId
          : item.ownerId || "";
      },
      ellipsis: true,
    },
    {
      title: "Төлөв",
      dataIndex: "xyrStatus",
      render: (status: any) => {
        if (status === "created") {
          return <span>Систем</span>;
        }
        if (status === "approved") {
          return <span style={{ color: "green" }}>Баталгаажсан</span>;
        }
        if (status === "agro") {
          return <span style={{ color: "green" }}>Агро баталгаажуулсан</span>;
        }
        if (status === "rejected") {
          return <span style={{ color: "red" }}>Мэдээлэл зөрүүтэй</span>;
        }
      },
    },
    {
      title: "Дэлгэрэнгүй",
      render: (value: any) => (
        <Space size={10}>
          <Link to={"/area/detail/" + value.id}>
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
            >
              Дэлгэрэнгүй
            </Button>
          </Link>
          {canEdit ? (
            <>
              {(value.xypStatus !== "approved" ||
                (value.xypStatus === "approved" &&
                  user.role?.cd == "ADMIN")) && (
                <Button
                  onClick={() => setShowEdit({ visible: true, id: value.id })}
                  type={"primary"}
                  size={"small"}
                  ghost
                  icon={<EditOutlined />}
                ></Button>
              )}
              <Popconfirm
                placement="top"
                title={"Та устгахдаа итгэлтэй байна уу"}
                onConfirm={() => deleteItem(value.id)}
                okText="Тийм"
                cancelText="Үгүй"
              >
                <Button
                  danger
                  type={"primary"}
                  size={"small"}
                  ghost
                  icon={<DeleteOutlined color="red" />}
                />
              </Popconfirm>
            </>
          ) : null}
        </Space>
      ),
    },
  ];
  const [importXyr, setImportXyr] = useState<ImportState>({
    visible: false,
    loading: false,
    result: [],
    step: 1,
    selectedRowKeys: [],
    regnum: "",
  });
  const importColumns = [
    {
      title: "НТД",
      dataIndex: "parcelId",
    },
    {
      title: "Төрөл",
      dataIndex: "landuseType",
    },
    {
      title: "Хэмжээ",
      render: (data: any) => `${data.areaM2 / 10000}га`,
    },
    {
      title: "Эзэмшигч",
      render: (data: any) => `${data.lastName} ${data.firstName}`,
    },
    {
      title: "Байршил",
      render: (data: any) =>
        `${data.aimagCityName} ${data.soumDistrictName} ${data.bagKhorooName}`,
    },
  ];
  const importFarmlandList = useCallback(async () => {
    if (importXyr.step == 1) {
      setImportXyr((e) => {
        return {
          ...e,
          loading: true,
        };
      });
      let response = await NetworkApi.requestPost("/api/farmland/import", {
        regnum: importXyr.regnum,
      });
      if (response.resultCode == 200) {
        setImportXyr({
          ...importXyr,
          loading: false,
          step: 2,
          selectedRowKeys: [],
          result: response.result,
        });
        console.log(response.result);
      } else {
        setImportXyr((e) => {
          return {
            ...e,
            loading: false,
          };
        });
        message.error(response.message || "system error");
      }
    } else {
      if (importXyr.selectedRowKeys.length == 0) {
        message.error("Газар сонгогдоогүй байна");
        return;
      }
      setImportXyr((e) => {
        return {
          ...e,
          loading: true,
        };
      });
      let response = await NetworkApi.requestPost(
        "/api/farmland/import/submit",
        { regnum: importXyr.regnum, lands: importXyr.selectedRowKeys }
      );
      if (response.resultCode == 200) {
        setImportXyr({
          ...importXyr,
          loading: false,
          step: 2,
          selectedRowKeys: [],
          result: response.result,
        });
      } else {
        setImportXyr((e) => {
          return {
            ...e,
            loading: false,
          };
        });
        message.error(response.message || "system error");
      }
    }
  }, [importXyr]);

  const [applyingFarmland, setApplyingFarmland] = useState<boolean>(false);

  const applyFarmlandByPosNo = useCallback(async () => {
    setApplyingFarmland(true);
    let response = await NetworkApi.requestPost(`/api/farmland/list/approve`);
    if (response.resultCode == 200) {
      Modal.success({
        title: "Амжилттай",
        content: (
          <div>
            <p>Баталгаажсан талбайн хэмжээ: {response.result?.area} га</p>
            <p>Баталгаажсан талбайн тоо: {response.result?.approveCount}</p>
          </div>
        ),
        okText: "За",
      });
      loadContent();
    } else {
      Modal.error({
        title: "Алдаа",
        content: (
          <div>
            <p>{response.message || "Холбогдоход алдаа гарлаа"}</p>
          </div>
        ),
        okText: "За",
      });
    }
    setApplyingFarmland(false);
  }, []);

  return (
    <>
      <BreadMe title={["Тариалангийн талбай", "Тариан талбайн жагсаалт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={[16, 16]} justify={"space-between"}>
          <Col span={28}>
            <Space size={40}>
              <Form layout="inline" onFinish={changeFilter}>
                <AddressSelect
                  defaultAimag={defaultAimag}
                  defaultSum={defaultSum}
                  hideLabel={true}
                />
                <Form.Item name="xyrStatus">
                  <Select
                    style={{ width: 201 }}
                    placeholder="Баталгаажсан эсэх"
                    allowClear
                  >
                    <Select.Option value="approved">
                      Геодезиор баталгаажсан
                    </Select.Option>
                    <Select.Option value="agro">
                      Агро баталгаажуулсан
                    </Select.Option>
                    <Select.Option value="created">
                      Системээр нэмсэн
                    </Select.Option>
                    <Select.Option value="rejected">
                      Мэдээлэл зөрүүтэй
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="posNo">
                  <Input placeholder={"НТДугаар"} />
                </Form.Item>
                <Button htmlType="submit" type={"primary"}>
                  <SearchOutlined />
                </Button>
              </Form>
            </Space>
          </Col>
          <Col span={6}>
            <Row justify={"end"} gutter={[16, 16]}>
              {user.role.cd == "ADMIN" && (
                <Col>
                  <Button
                    loading={applyingFarmland}
                    onClick={applyFarmlandByPosNo}
                    icon={<CheckSquareOutlined />}
                    type={"primary"}
                  >
                    НТД баталгаажуулах
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type={"primary"}
                  onClick={() => {
                    setImportXyr({
                      visible: true,
                      loading: false,
                      result: [],
                      selectedRowKeys: [],
                      step: 1,
                      regnum: "",
                    });
                  }}
                >
                  Хураас татах
                </Button>
              </Col>
              {user?.role.cd == "ADMIN" && (
                <Col>
                  <Button
                    target="blank"
                    href={`${config.get(
                      "apiUrl"
                    )}/api/farmland/list/download?${querystring.stringify(
                      filter
                    )}`}
                    type={"primary"}
                  >
                    <DownloadOutlined />
                    Татах
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Descriptions title="" style={{ marginTop: 15 }}>
          <Descriptions.Item label="Нийт">{content.count}</Descriptions.Item>
          <Descriptions.Item label="Нийт талбай">
            {content.area?.toLocaleString()} га
          </Descriptions.Item>
        </Descriptions>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              loading={content.loading}
              columns={columns}
              dataSource={content.result}
              rowKey={"id"}
              onChange={(p) => {
                changeFilter({
                  ...filter,
                  page: p.current || 1,
                  pageSize: p.pageSize || 10,
                });
              }}
              pagination={{
                current: content.page,
                pageSize: content.pageSize,
                total: content.count,
                onChange(current, pageSize) {
                  setPage(current);
                  setPageSize(pageSize);
                },
              }}
            />
          </Col>
        </Row>
        <FarmlandEdit
          visible={showEdit.visible}
          id={showEdit.id}
          onHide={() => {
            loadContent();
            setShowEdit({ visible: false, id: null });
          }}
        />
        <Modal
          width={800}
          confirmLoading={importXyr.loading}
          onOk={() => importFarmlandList()}
          title="Газрын системээс татах"
          visible={importXyr.visible}
          onCancel={() => setImportXyr({ ...importXyr, visible: false })}
        >
          {importXyr.step == 1 ? (
            <div style={{ padding: 15 }}>
              <Form>
                <Form.Item label="Регистрийн дугаар">
                  <Input
                    onChange={(e) =>
                      setImportXyr({ ...importXyr, regnum: e.target.value })
                    }
                    value={importXyr.regnum}
                  />
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div>
              <Table
                scroll={{ x: true }}
                rowKey={"parcelId"}
                rowSelection={{
                  selectedRowKeys: importXyr.selectedRowKeys,
                  onChange: (selectedRowKeys) => {
                    setImportXyr({
                      ...importXyr,
                      selectedRowKeys,
                    });
                  },
                }}
                columns={importColumns}
                dataSource={importXyr.result}
              />
            </div>
          )}
        </Modal>
      </Layout.Content>
    </>
  );
};

export default FarmlandList;
