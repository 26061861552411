export enum USER_ROLE {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  PROVINCE_AGRONOMIST = "PROVINCE_AGRONOMIST",
  VILLAGE_AGRONOMIST = "VILLAGE_AGRONOMIST",
  SPECIALIST = "SPECIALIST",
  HEAD_OF_OFFICE = "HEAD_OF_OFFICE",
  PROVINCE_INSPECTOR = "PROVINCE_INSPECTOR",
  INSPECTOR = "INSPECTOR",
  WAREHOUSE_SPECIALIST = "WAREHOUSE_SPECIALIST",
}
