import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Row,
  Select,
  Typography,
  Card,
  Image,
  Popconfirm,
  Upload,
} from "antd";

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import Dragger from "antd/lib/upload/Dragger";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import NetworkApi from "../../network/networkApi";
import useProducts from "../../hooks/useProducts";
import UserContext from "../../providers/UserContext";
import AddressSelect from "../lib/AddressSelect";
import SupportProducts from "../support/SupportProducts";
import ProductSelect from "../support/ProductSelect";
import BreadMe from "../lib/Breadme";
import config from "../../config";
import { formatNumberWithComma } from "../../helpers/number.helper";

const Option = Select.Option;
const { Text } = Typography;

export const mockUploadAction = (value: any) => {
  setTimeout(() => {
    value.onSuccess({ status: "success" });
  }, 600);
};

interface Props {
  onSuccess: () => void;
}

const SupportAdd = ({ onSuccess }: Props) => {
  const useFarmlandUsageList = (page?: number): [any, () => void, any] => {
    const [filter, changeFilter] = useState<any>({
      pageSize: 100,
      status: "active",
    });
    const [content, changeContent] = useState<any>({
      loading: false,
      count: 0,
      result: [],
    });
    useEffect(() => {
      loadContent();
    }, [filter]);
    const loadContent = useCallback(async () => {
      let response = await NetworkApi.requestGet(
        "/api/farmland/usage/list",
        filter
      );
      if (response.resultCode == 200)
        changeContent({
          loading: false,
          count: response.result?.count,
          result: response.result?.rows,
        });
    }, [filter]);
    return [content, loadContent, changeFilter];
  };
  const host = config.get("apiUrl");
  const history = useHistory();
  const formRef = useRef<any>();
  const [productList] = useProducts();
  const [farmLandList] = useFarmlandUsageList();

  const { user } = useContext(UserContext);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [category, changeCategory] = useState("");
  const [sub_category, changeSubCategory] = useState("");
  const [productId, changeProduct] = useState("");
  const [amount, calcPrice] = useState("");

  const [files, changeFileList] = useState<any[]>([]);
  const handleSubmit = useCallback(
    async (data: any) => {
      if (!submitLoading) {
        setSubmitLoading(true);

        let upload: any[] = [];
        for (var i = 0; i < 10; i++) {
          if (data.hasOwnProperty("upload_" + `${i}`)) {
            upload = [...upload, data["upload_" + `${i}`]["file"]["0"]];
            data = { ...data, file: upload };
          } else break;
        }

        let org_type = "farmer";
        if (user.organizations.length > 0) {
          org_type = user.organizations[0].type;
        }

        let aimag = "";
        if (user.aimag) {
          aimag = user.aimag.name;
        }

        let soum = "";
        if (user.sum) {
          soum = user.sum.name;
        }

        data = {
          ...data,
          name: user.firstName,
          register: user.rd,
          type: org_type,
          lastName: user.lastName,
          aimag,
          soum,
          phone: user.phone || "none",
          email: user.email || "none",
        };

        if (data.file && data.file.length > 0) {
          handleUploadFile(data);
        }
      }
    },
    [history, submitLoading]
  );

  useEffect(() => {
    if (formRef.current && user.role.cd == "VILLAGE_AGRONOMIST") {
      formRef.current.setFieldsValue({
        aimagId: user.aimagId,
        sumId: user.sumId,
      });
    }
  }, [user, formRef]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e.fileList;
  };

  const prodObj = useMemo(() => {
    if (productId) {
      return productList.products.find((e) => e.id == productId);
    }
    return {
      price: 0,
      measure: "",
      currency: "",
      support_time: 0,
      amount: 0,
      attachTypes: [],
    };
  }, [productId, productList]);

  const priceObj = useMemo(() => {
    if (amount) {
      return {
        total: Number(amount) * prodObj.price,
        advance:
          ((Number(amount) * prodObj.price) / 100) * prodObj.deposit_percent,
      };
    }
    return {
      total: 0,
      advance: 0,
    };
  }, [amount]);

  const handleUploadFile = async (data: any) => {
    let attach: any[] = [];

    for (var i = 0; i < data.file.length; i++) {
      const file: File = data.file[i].originFileObj;
      let response = await NetworkApi.requestUploadFile(file);

      if (response.resultCode === 200) {
        message.success(`${response.result.name} файл амжилттай хууллаа.`);

        attach = [
          {
            name: response.result.name,
            queue: i,
            extension: response.result.mimetype,
          },
          ...attach,
        ];

        data = {
          ...data,
          attaches: attach,
        };
      } else {
        message.error(`Файл хуулхад алдаа гарлаа.`);
        setSubmitLoading(false);
      }
    }

    let result = await NetworkApi.requestPost("/api/support/add", data);
    if (result.resultCode == 200) history.push("/support/list");
    else {
      message.error(result.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  };

  return (
    <>
      <BreadMe title={["", "Дэмжлэгийн сангийн хүсэлт бүртгэх"]} />

      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={20}>
          <Col span={16} push={4}>
            <Form
              onFinish={(data) => handleSubmit(data)}
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
              name="basic"
              ref={formRef}
            >
              <Form.Item label="Регистр" name="register">
                <Input disabled defaultValue={user.rd} />
              </Form.Item>

              <Form.Item label="Нэр" name="name">
                <Input disabled defaultValue={user.firstName} />
              </Form.Item>

              <Form.Item
                label="Талбайн дугаар"
                name="farmland_id"
                rules={[
                  { required: true, message: "Талбайн дугаар оруулна уу" },
                ]}
              >
                <Select
                  style={{ width: "100%", minWidth: 150 }}
                  showSearch
                  placeholder="Талбайн дугаар"
                >
                  {farmLandList.result.map((f: any) => {
                    return (
                      <Option key={f.farmland.id} value={f.farmland.id}>
                        НТД-{f.farmland.posNo}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="category"
                rules={[{ required: true, message: "Ангилал сонгоно уу!" }]}
                label="Ангилал"
              >
                <Select
                  style={{ width: "100%", minWidth: 150 }}
                  showSearch
                  placeholder="Ангилал сонгох"
                  optionFilterProp="children"
                  onChange={(e: any) => changeCategory(e)}
                >
                  {productList.categories.map((item) => {
                    return (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="sub_category"
                rules={[
                  {
                    required: true,
                    message: "Бүтээгдэхүүний төрөл сонгоно уу!",
                  },
                ]}
                label="Бүтээгдэхүүний төрөл"
              >
                <Select
                  style={{ width: "100%", minWidth: 150 }}
                  showSearch
                  placeholder="Бүтээгдэхүүний төрөл сонгох"
                  optionFilterProp="children"
                  onChange={(e: any) => changeSubCategory(e)}
                >
                  {productList.sub_categories.map((item) => {
                    if (item.category_id == category) {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    }
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="product_id"
                rules={[
                  { required: true, message: "Бүтээгдэхүүн сонгоно уу!" },
                ]}
                label="Бүтээгдэхүүн"
              >
                <Select
                  style={{ width: "100%", minWidth: 150 }}
                  showSearch
                  placeholder="Бүтээгдэхүүн сонгох"
                  optionFilterProp="children"
                  onChange={(e: any) => changeProduct(e)}
                >
                  {productList.products.map((item) => {
                    if (item.sub_category_id == sub_category) {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name} ({item.measure})
                        </Option>
                      );
                    }
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Хүсэж буй хэмжээ"
                name="amount"
                rules={[{ required: true, message: "хэмжээ оруулна уу!" }]}
              >
                <Input
                  type="number"
                  defaultValue={0}
                  min={0}
                  max={1000000000}
                  style={{ width: "180px" }}
                  placeholder="Дэмжлэг хүсэж буй хэмжээ"
                  // formatter={value => }
                  addonAfter={`${prodObj.measure}`}
                  onChange={(t) => calcPrice(t.target.value)}
                />
              </Form.Item>

              <Form.Item label="Нэгж үнэ" name="price">
                <Text>
                  {formatNumberWithComma(prodObj.price)}
                  {prodObj.currency}
                </Text>
              </Form.Item>

              <Form.Item label="Урьдчилгаа төлбөр" name="advance">
                <Text>
                  {formatNumberWithComma(priceObj.advance)} (
                  {prodObj.deposit_percent}%)
                </Text>
              </Form.Item>

              <Form.Item label="Нийт төлбөр" name="totalPrice">
                <Text>{formatNumberWithComma(priceObj.total)}</Text>
              </Form.Item>

              <Form.Item label="Дэмжлэгийн хугацаа" name="supportTime">
                <Text>{prodObj.support_time} сар</Text>
              </Form.Item>

              <Row style={{ marginBottom: 15 }}>
                <Col span={24}>
                  <fieldset style={{ border: "1px solid gray", padding: 10 }}>
                    <legend
                      style={{
                        width: "auto",
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      Бүрдүүлэх материалууд:
                    </legend>
                    {prodObj.attachTypes.map((rs: any, index: number) => {
                      return (
                        <Form.Item
                          name={["upload_" + index, "file"]}
                          label={rs.name}
                          className="form-long-label"
                          valuePropName={"fileList" + `${index}`}
                          getValueFromEvent={normFile}
                          rules={[
                            { required: true, message: "Файл хавсаргана уу" },
                          ]}
                        >
                          <Upload
                            accept=".png, .jpeg, .pdf"
                            name="upload"
                            customRequest={mockUploadAction}
                            maxCount={1}
                          >
                            <Button icon={<UploadOutlined />}>
                              Файл сонгох
                            </Button>
                          </Upload>
                        </Form.Item>
                      );
                    })}
                  </fieldset>
                </Col>
              </Row>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  loading={submitLoading}
                  type="primary"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export default SupportAdd;
