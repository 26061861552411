import EventEmitter from "events";


let config:any = {};

let configList:any = {
    emitter:new EventEmitter(),
    url:{
        home:'/',
    },
    apiUrl: 'https://plant.mofa.gov.mn',
    sfsFileUrl: 'https://support.mofa.gov.mn/FileCenter/RequestPath/',    
};

config.config = function (data:any) {
    configList = {
        ...configList,
        ...data
    };
};
config.get = function (option:string) {
    return configList[option];
};

export default config
