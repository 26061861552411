import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Space,
  Form,
  Modal,
  message,
  Popconfirm,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";

import useUnit from "../../hooks/useUnit";
import NetworkApi from "../../network/networkApi";

const FarmUnit = () => {
  const [edit, setVisible] = useState({
    visible: false,
    edit: null,
  });
  const [content, loadContent] = useUnit();
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost("/api/unit/update", {
      ...data,
      status: "D",
    });
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "system error");
    }
  }, []);
  const elementInfo = [
    {
      title: "Нэгжийн нэр",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Үйлдэл",
      render: (value: any) => (
        <Space size={10}>
          <Button
            onClick={() => setVisible({ visible: true, edit: value })}
            type={"primary"}
            size={"small"}
            ghost
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна уу"
            onConfirm={() => deleteItem(value)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button type={"primary"} size={"small"} danger ghost>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Хэмжих нэгж"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"end"}>
              <Button
                type={"primary"}
                icon={<PlusSquareOutlined />}
                onClick={() => setVisible({ visible: true, edit: null })}
              >
                Хэмжих нэгж нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              rowKey="id"
              columns={elementInfo}
              dataSource={content.result}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <UnitAddEdit
        visible={edit.visible}
        edit={edit.edit}
        onHide={() => setVisible({ visible: false, edit: null })}
        onSuccess={() => {
          loadContent();
          setVisible({ visible: false, edit: null });
        }}
      />
    </>
  );
};
interface AddEditProps {
  visible: boolean;
  onHide: any;
  onSuccess: any;
  edit?: any;
}
const UnitAddEdit = ({ visible, onHide, onSuccess, edit }: AddEditProps) => {
  const formRef = useRef<any>();
  useEffect(() => {
    if (visible) {
      if (edit) {
        formRef.current?.setFieldsValue(edit);
      } else {
        formRef.current?.resetFields();
      }
    }
  }, [visible, edit]);
  const onFinish = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      let response = await NetworkApi.requestPost("/api/unit/update", data);
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "system error");
      }
    },
    [edit]
  );
  return (
    <Modal
      width={595}
      centered
      visible={visible}
      onCancel={onHide}
      title={"Хэмжих нэгж нэмэх"}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => formRef.current?.submit()}
          disabled={!(edit?.loading || true)}
        >
          Хадгалах
        </Button>,
        <Button key="cancel" type="default" onClick={onHide}>
          Хаах
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Form
            onFinish={onFinish}
            ref={formRef}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item name="name" label="Нэгжийн нэр">
              <Input placeholder={"Нэгжийн нэр"} />
            </Form.Item>
            <Form.Item name="description" label="Тайлбар">
              <Input placeholder={"Нэгжийн тайлбар"} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
export default FarmUnit;
