import React from 'react';
import {Breadcrumb} from "antd";

interface Props{
    title?:string[]
}

const BreadMe =({title=['Эхлэл']}:Props)=> {
    return <Breadcrumb style={{ margin: 15 }}>
        {
            title.map((i)=>
                <Breadcrumb.Item key={i}>{i}</Breadcrumb.Item>
            )
        }
    </Breadcrumb>
};
export default BreadMe;
