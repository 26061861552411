import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import NetworkApi from "../network/networkApi";

interface ContentData {
  users: any[];
  orgs: any[];
  count: number;
  page: number;
  loading: boolean;
}
const useUserList = (page?: number): [ContentData, () => void, any, any] => {
  const [filter, changeFilter] = useState<any>({ page: page });
  const [content, changeContent] = useState<ContentData>({
    loading: true,
    count: 0,
    page: 1,
    users: [],
    orgs: [],
  });
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    changeContent({
      loading: true,
      count: 0,
      page: 1,
      users: [],
      orgs: [],
    });
    let response = await NetworkApi.requestGet("/api/user/list", filter);
    if (response.resultCode == 200) {
      changeContent({
        loading: false,
        count: response.result.count,
        page: response.result.page,
        users: response.result?.users || [],
        orgs: response.result?.orgs || [],
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [filter]);

  return [content, loadContent, changeFilter, filter];
};
export default useUserList;
