import { useState, useEffect, useCallback, useContext } from "react";
import {
  Col,
  Form,
  Layout,
  Row,
  Table,
  Divider,
  Space,
  Button,
  message,
  Timeline,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";

import UserContext from "../../providers/UserContext";
import { usePlanApprove } from "../../hooks/usePlanApprove";
import { usePlanDecline } from "../../hooks/usePlanDecline";

import { PLAN_STATUS, PLAN_STATUS_TEXT } from "../../enums/plan.enum";
import { USER_ROLE } from "../../enums/user.enum";
import { formatArea } from "../../helpers/number.helper";

interface Params {
  id?: any;
}

interface PlanDetailType {
  id: number;
  plant: {
    id: number;
    name: string;
    type: {
      id: number;
      name: string;
    };
  };
  status: keyof typeof PLAN_STATUS_TEXT;
  year: string;
  user: {
    id: number;
    lastName: string;
    firstName: string;
  };
  farmlands: any[];
  histories: any[];
  createdAt: string;
}

const PlanDetail = () => {
  const history = useHistory();
  const { id } = useParams<Params>();

  const { user } = useContext(UserContext);
  const [loadingAP, approvePlan] = usePlanApprove();
  const [loadingAD, declinePlan] = usePlanDecline();

  const [content, changeContent] = useState<{
    loading: boolean;
    result: PlanDetailType;
  }>({
    loading: false,
    result: {
      id: 0,
      plant: { id: 0, name: "", type: { id: 0, name: "" } },
      status: PLAN_STATUS.PENDING,
      year: "",
      user: {
        id: 0,
        lastName: "",
        firstName: "",
      },
      farmlands: [],
      histories: [],
      createdAt: "",
    },
  });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet(`/api/plan/detail/${id}`);

    if (response.resultCode === 200) {
      changeContent({
        loading: false,
        result: response.result || { farmlands: [] },
      });
    } else {
      message.error(response.message || "system error");
    }
  }, [id]);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  const planFarmLandColumns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Нэгж талбайн дугаар",
      dataIndex: "posNo",
    },
    {
      title: "Талбайн нийт хэмжээ",
      dataIndex: "area",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Эзэмшигчийн нэр",
      dataIndex: "ownerName",
    },
    {
      title: "Регистр",
      dataIndex: "ownerRd",
    },
    {
      title: "Эзэмшлийн хэлбэр",
      dataIndex: "type",
      render: (type: any) => (type == "private" ? "Эзэмшил" : "Өмчлөл"),
    },
    {
      title: "Ашиглаж буй хэмжээ",
      dataIndex: "usedArea",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Төлөвлөгдсөн талбай",
      dataIndex: "plannedArea",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Тариалах талбай",
      dataIndex: "planArea",
      editable: true,
      render: (value: any) => {
        switch (typeof value) {
          case "string":
            return `${formatArea(parseFloat(value))}`;
          case "number":
            return `${formatArea(value)}`;
          default:
            return `0.00 га`;
        }
      },
    },
  ];

  const getHistoryTypeName = (type: string) => {
    switch (type) {
      case "created":
        return "үүсгэсэн";
      case "approved":
        return "баталгаажуулсан";
      case "declined":
        return "цуцалсан";
      case "edited":
        return "засвар оруулсан";
      default:
        return "";
    }
  };

  return (
    <>
      <BreadMe title={["Тариалалтын төлөвлөгөө", "Нэмэх"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Form
              labelCol={{ xs: 24, sm: 24, md: 24, lg: 10, xl: 10 }}
              wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 14, xl: 14 }}
            >
              <Row justify="space-around" gutter={16}>
                <Divider
                  orientation="left"
                  plain
                  style={{ margin: 0, lineHeight: 0 }}
                >
                  Тариалалтын төрлийн мэдээлэл
                </Divider>
              </Row>
              {content.result && content.result?.id && (
                <Row
                  justify="space-around"
                  gutter={16}
                  style={{
                    padding: 24,
                    border: "1px solid #f0f0f0",
                    borderTop: "none",
                  }}
                >
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item label="Таримлын төрөл">
                      {content.result?.plant.type.name}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item label="Таримлын нэр">
                      {content.result?.plant.name}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="year" label="Төлөвлөгөөт он">
                      {content.result?.year}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="status" label="Төлөв">
                      {content.result?.status &&
                        PLAN_STATUS_TEXT[content.result?.status]}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="createdUser" label="Үүсгэсэн огноо">
                      {content.result?.user &&
                        `${content.result?.user.lastName.charAt(0)}.${
                          content.result?.user.firstName
                        }`}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item name="createdAt" label="Үүсгэсэн огноо">
                      {content.result?.createdAt &&
                        moment(content.result.createdAt).format(
                          "YYYY/MM/DD HH:mm"
                        )}
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row gutter={16}>
              <Divider
                orientation="left"
                plain
                style={{ margin: 0, lineHeight: 0 }}
              >
                Төлөвлөгдсөн талбай, тариаланч
              </Divider>
            </Row>
            <Row
              gutter={16}
              style={{
                padding: 24,
                border: "1px solid #f0f0f0",
                borderTop: "none",
              }}
            >
              <Col span={24}>
                <Row
                  justify="end"
                  gutter={16}
                  style={{ marginTop: 15, marginBottom: 15 }}
                />
              </Col>
              <Col span={24}>
                <Table
                  scroll={{ x: true }}
                  columns={planFarmLandColumns}
                  dataSource={content.result.farmlands}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row gutter={16}>
              <Divider
                orientation="left"
                plain
                style={{ margin: 0, lineHeight: 0 }}
              >
                Төлөвлөгөөний түүх
              </Divider>
            </Row>
            <Row
              gutter={16}
              style={{
                padding: 24,
                border: "1px solid #f0f0f0",
                borderTop: "none",
              }}
            >
              <Col span={24}>
                <Row
                  justify="end"
                  gutter={16}
                  style={{ marginTop: 15, marginBottom: 15 }}
                />
              </Col>
              <Col span={24}>
                <Timeline mode="left">
                  {(content.result?.histories ?? []).map((item: any) => (
                    <Timeline.Item
                      label={`${
                        item?.createdAt
                          ? moment(item.updatedAt).format("YYYY-MM-DD hh:mm:ss")
                          : ""
                      }`}
                    >
                      {`${
                        item.user.lastName && item.user.firstName
                          ? `${item.user.lastName.substr(0, 2)}.${
                              item.user.firstName
                            }`
                          : ""
                      } `}
                      <strong>{getHistoryTypeName(item.type)}</strong>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 15, marginBottom: 15 }}
          justify="end"
          align="middle"
        >
          <Space>
            {content.result?.status &&
              content.result.status === PLAN_STATUS.PENDING &&
              user.role.cd === USER_ROLE.PROVINCE_AGRONOMIST && (
                <>
                  <Button
                    type="primary"
                    size="small"
                    loading={loadingAP}
                    onClick={() => approvePlan(id, loadContent)}
                  >
                    Баталгаажуулах
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    danger
                    loading={loadingAD}
                    onClick={() => declinePlan(id, loadContent)}
                  >
                    Цуцлах
                  </Button>
                </>
              )}
            {content.result?.status &&
              content.result.user &&
              content.result.status === PLAN_STATUS.PENDING &&
              user.role.cd === USER_ROLE.VILLAGE_AGRONOMIST &&
              user.id === content.result.user.id && (
                <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  onClick={() => history.push(`/plan/edit/${id}`)}
                >
                  Засах
                </Button>
              )}
          </Space>
        </Row>
      </Layout.Content>
    </>
  );
};
export default PlanDetail;
