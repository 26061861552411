import fetchWithTimeout from './fetchWithTimeout'
import axios from 'axios'
import * as querystring from 'querystring'
import config from '../config'

let headers: any = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}
const apiUrl = config.get('apiUrl')
class NetworkApi {
  static parseUrl = (url: string): string => {
    return `${url.indexOf('http') == 0 ? '' : apiUrl}${url}`
  }
  static requestGet = async (url: string, params?: any) => {
    console.log(url)
    let header = {
      ...headers,
    }
    try {
      if (params) {
        url += '?' + querystring.stringify(params)
      }
      let response: any = await fetchWithTimeout(NetworkApi.parseUrl(url), {
        headers: {
          ...header,
        },
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        crossDomain: true,
        withCredentials: true,
      })
      if (response.status == 401) {
        config.get('emitter').emit('auth-error')
      }
      if (response.status === 200) {
        let result = await response.json();
        return { resultCode: response.status, result: (result || {}).result || {} }
      } else {
        let result = await response.json();
        return { resultCode: response.status, message: await result.message }
      }
    } catch (e) {
      console.log(e)
      return { resultCode: 500, message: 'system error' }
    }
  }
  static requestDelete = async (url: string, params?: any) => {
    let header = {
      ...headers,
    }
    try {
      if (params) {
        url += '?' + querystring.stringify(params)
      }
      let response: any = await fetchWithTimeout(NetworkApi.parseUrl(url), {
        headers: {
          ...header,
        },
        method: 'DELETE',
        mode: 'cors',
        credentials: 'include',
        crossDomain: true,
        withCredentials: true,
      })
      if (response.status == 401) {
        config.get('emitter').emit('auth-error')
      }
      if (response.status === 200) {
        let result = await response.json();
        return { resultCode: response.status, result: (result || {}).result || {} }
      } else {
        let result = await response.json();
        return { resultCode: response.status, message: await result.message }
      }
    } catch (e) {
      return { resultCode: 500, message: 'system error' }
    }
  }
  static requestPost = async (url: string, body?: any, params?: any) => {
    let header = {
      ...headers,
    }
    try {
      let response: any = await fetchWithTimeout(NetworkApi.parseUrl(url), {
        headers: {
          ...header,
        },
        method: 'POST',
        body: JSON.stringify(body),
        mode: 'cors',
        credentials: 'include',
        withCredentials: true,
      })
      if (response.status == 401) {
        config.get('emitter').emit('auth-error')
      }
      if (response.status === 200) {
        let result = await response.json();
        return { resultCode: response.status, result: (result || {}).result || {} }
      } else {
        let result = await response.json();
        return { resultCode: response.status, message: await result.message }
      }
    } catch (e) {
      return { resultCode: 500, message: 'system error' }
    }
  }
  static requestUploadFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file, file.name);
      const options: any = {
        url: NetworkApi.parseUrl('/api/file/upoad'),
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryie6DlKv9QNK5Ycqq',
        },
        data: formData,
      };
      const response = await axios(options);

      if (response.status === 401) {
        config.get('emitter').emit('auth-error')
      }
      if (response.status === 200) {
        return { resultCode: response.status, result: (response.data || {}).result || {} }
      } else {
        return { resultCode: response.status, message: await response.data.message }
      }
    } catch (e) {
      return { resultCode: 500, message: 'system error' }
    }
  }
  static requestPut = async (url: string, body?: any, params?: any) => {
    let header = {
      ...headers,
    }
    try {
      let response: any = await fetchWithTimeout(NetworkApi.parseUrl(url), {
        headers: {
          ...header,
        },
        method: 'PUT',
        body: JSON.stringify(body),
        mode: 'cors',
        credentials: 'include',
        withCredentials: true,
      })
      if (response.status == 401) {
        config.get('emitter').emit('auth-error')
      }
      if (response.status === 200) {
        let result = await response.json();
        return { resultCode: response.status, result: (result || {}).result || {} }
      } else {
        let result = await response.json();
        return { resultCode: response.status, message: await result.message }
      }
    } catch (e) {
      return { resultCode: 500, message: 'system error' }
    }
  }
}

export default NetworkApi