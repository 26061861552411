import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import config from "../../config";
import { formatNumberWithComma } from "../../helpers/number.helper";
import useCellarType from "../../hooks/useCellarType";
import useWarehouseDetail from "../../hooks/useWarehouseDetail";
import NetworkApi from "../../network/networkApi";
import AddressSelect from "../lib/AddressSelect";
import BreadMe from "../lib/Breadme";

interface CellarProps {
  visible: boolean;
  edit?: boolean;
  editValue?: any;
  onSubmit: (values: any) => void;
  onHide: () => void;
}

const CellarForm = ({
  visible,
  edit,
  editValue,
  onHide,
  onSubmit,
}: CellarProps) => {
  const [form] = Form.useForm();
  const [cellarTypes] = useCellarType();

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        ...editValue,
        cellarTypeId: `${editValue.cellarTypeId}`,
      });
    }
  }, [form, edit, editValue]);

  const onFinish = async (values: any) => {
    const cellarType = await cellarTypes.result.find(
      (item) => item.id === +values.cellarTypeId
    );
    onSubmit({
      ...values,
      cellarType,
    });
  };

  return (
    <Modal
      visible={visible}
      title={edit ? "Зоорь засах" : "Зоорь нэмэх"}
      onCancel={onHide}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Зоорийн хийцийн төрөл"
          name="cellarTypeId"
          rules={[
            {
              required: true,
              message: "Зоорийн хийцийн төрөл сонгоно уу!",
            },
          ]}
        >
          <Select>
            {(cellarTypes.result ?? []).map((item: any) => (
              <Select.Option key={`ct-${item.id}`} value={`${item.id}`}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Тоо"
          name="quantity"
          rules={[{ required: true, message: "Тоо оруулна уу!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Багтаамж (тн)"
          name="storage"
          rules={[{ required: true, message: "Багтаамж оруулна уу!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Боломжит багтаамж (тн)"
          name="availableStorage"
          rules={[
            { required: true, message: "Багтаамж оруулна уу!" },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const storage = getFieldValue("storage");
                if ((storage ?? 0) < (value ?? 0)) {
                  return Promise.reject(
                    "Боломжит багтаамжийн утга багтаамжаас хэтэрсэн байна"
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Хадгалах
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

type FormProps = {
  fieldsValue?: any;
  loading: boolean;
  onSubmit?: (values: any) => void;
};

const WarehouseForm = ({ fieldsValue, loading, onSubmit }: FormProps) => {
  const [form] = Form.useForm();
  const [organizationRd, setOrganizationRd] = useState("");
  const [orgLoading, setOrgLoading] = useState(false);
  const [ownerType, setOwnerType] = useState("1");
  const [cellarForm, setCellarForm] = useState<{
    visible: boolean;
    edit: boolean;
    editIndex?: number;
    editValue?: any;
  }>({
    visible: false,
    edit: false,
  });
  const [cellars, setCellars] = useState<any[]>([]);

  useEffect(() => {
    if (fieldsValue) {
      form.setFieldsValue(fieldsValue);
      if (fieldsValue?.cellars) setCellars(fieldsValue.cellars);
      if (fieldsValue?.ownerType) setOwnerType(fieldsValue.ownerType);
    }
  }, [form, fieldsValue]);

  const handleCellars = (value: any) => {
    if (cellarForm.edit && typeof cellarForm.editIndex === "number") {
      let cs: any[] = [...cellars];
      cs[cellarForm.editIndex] = value;
      setCellars(cs);
    } else {
      setCellars([...cellars, value]);
    }
    setCellarForm({
      visible: false,
      edit: false,
      editIndex: undefined,
      editValue: undefined,
    });
  };

  const checkOrganization = useCallback(async () => {
    if (organizationRd) {
      setOrgLoading(true);
      let response = await NetworkApi.requestGet(
        `/api/warehouse/organization/check/${organizationRd}`
      );
      if (response.resultCode == 200) {
        form.setFieldsValue({ owner: response.result.name });
      } else {
        message.error({
          message: response.message || "Алдаа гарлаа, дахин оролдоно уу!",
        });
      }
      setOrgLoading(false);
    } else {
      message.error({ message: "Регистэрийн дугаар оруулна уу" });
    }
  }, [organizationRd]);

  const onFinish = (values: any) => {
    if (onSubmit) {
      if (ownerType === "2") {
        values["rd"] = organizationRd;
      }
      onSubmit({
        ...values,
        cellars,
      });
    }
  };

  return (
    <Form
      onFinish={onFinish}
      wrapperCol={{ span: 16 }}
      labelCol={{ span: 8 }}
      name="basic"
      form={form}
    >
      <AddressSelect
        initValues={{
          aimag: fieldsValue?.aimagId,
          sum: fieldsValue?.sumId,
          bag: fieldsValue?.bagId,
        }}
        rule={true}
      />
      <Form.Item
        label="Хаяг"
        name="address"
        rules={[
          {
            required: true,
            message: "Хаяг оруулна уу!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Эзэмшигч төрөл"
        name="ownerType"
        rules={[{ required: true, message: "Эзэмшигч төрөл сонгоно уу!" }]}
      >
        <Select onChange={(value: string) => setOwnerType(value)}>
          <Select.Option value="1">Иргэн</Select.Option>
          <Select.Option value="2">Хуулийн этгээд</Select.Option>
        </Select>
      </Form.Item>
      {ownerType === "2" && (
        <Form.Item label="Хуулийн этгээдийн РД">
          <Row>
            <Col>
              <Input onChange={(e) => setOrganizationRd(e.target.value)} />
            </Col>
            <Col>
              <Button
                type="primary"
                loading={orgLoading}
                onClick={checkOrganization}
              >
                Хайх
              </Button>
            </Col>
          </Row>
        </Form.Item>
      )}
      <Form.Item
        label="Эзэмшигч"
        name="owner"
        rules={[{ required: true, message: "Эзэмшигч оруулна уу!" }]}
      >
        <Input disabled={ownerType === "2"} />
      </Form.Item>
      <Form.Item
        label="Утас"
        name="phone"
        rules={[{ required: true, message: "Утас оруулна уу!" }]}
      >
        <Input />
      </Form.Item>
      <Divider />
      <Row justify="end">
        <Button
          type="primary"
          onClick={() =>
            setCellarForm({
              visible: true,
              edit: false,
              editIndex: undefined,
              editValue: undefined,
            })
          }
        >
          Нэмэх
        </Button>
      </Row>
      {cellarForm.visible && (
        <CellarForm
          visible={cellarForm.visible}
          edit={cellarForm.edit}
          editValue={cellarForm.editValue}
          onSubmit={handleCellars}
          onHide={() =>
            setCellarForm({
              visible: false,
              edit: false,
              editIndex: undefined,
              editValue: undefined,
            })
          }
        />
      )}
      <Row style={{ marginTop: 16, marginBottom: 16 }}>
        <Col span={24}>
          <Table
            scroll={{ x: true }}
            rowKey="id"
            columns={[
              {
                title: "Зоорийн хийц",
                dataIndex: "cellarType",
                render: (value: any) => `${value.name}`,
              },
              {
                title: "Тоо",
                dataIndex: "quantity",
              },
              {
                title: "Багтаамж (тн)",
                dataIndex: "storage",
                render: (value: number | null) =>
                  formatNumberWithComma(value ?? 0),
              },
              {
                title: "Боломжит багтаамж (тн)",
                dataIndex: "availableStorage",
                render: (value: number | null) =>
                  formatNumberWithComma(value ?? 0),
              },
              {
                title: "",
                render: (v: any, r: any, indx: number) => (
                  <Row gutter={16}>
                    <Col>
                      <Button
                        onClick={() =>
                          setCellarForm({
                            visible: true,
                            edit: true,
                            editIndex: indx,
                            editValue: r,
                          })
                        }
                        type={"primary"}
                        size={"small"}
                        ghost
                        icon={<EditOutlined />}
                      />
                    </Col>
                    <Col>
                      <Button
                        danger
                        type={"primary"}
                        size={"small"}
                        ghost
                        icon={<DeleteOutlined color="red" />}
                        onClick={() => {
                          let cs = [...cellars].filter(
                            (item, index) => index !== indx
                          );
                          setCellars(cs);
                        }}
                      />
                    </Col>
                  </Row>
                ),
              },
            ]}
            dataSource={cellars ?? []}
          />
        </Col>
      </Row>
      <Divider />
      <Form.Item
        label="Зураг"
        name="images"
        valuePropName="fileList"
        getValueFromEvent={(e: any) => {
          return e.fileList;
        }}
        rules={[{ required: true, message: "Зураг оруулна уу" }]}
      >
        <Upload
          name="file"
          listType="picture-card"
          accept="image/*"
          action={`${config.get("apiUrl")}/api/file/upoad`}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item label="Нэмэлт танилцуулга" name="note">
        <Input.TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button loading={loading} type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form.Item>
    </Form>
  );
};

const WarehouseEdit = () => {
  const { id }: any = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [content, , changeId] = useWarehouseDetail();
  useEffect(() => {
    if (id) {
      changeId(id);
    }
  }, [id]);
  const [fieldsValue, setFieldsValue] = useState({});
  useEffect(() => {
    if (content.resultCode === 200) {
      const images = (content.result?.warehouse?.images ?? []).map(
        (item: any, index: number) => ({
          uid: index,
          name: `image-${index}.jpg`,
          status: "done",
          url: `${config.get("apiUrl")}/tmp/${item}`,
        })
      );
      setFieldsValue({
        ...content.result.warehouse,
        cellars: content.result.cellars,
        images,
      });
    }
  }, [content]);
  const handleSubmit = useCallback(
    async (data: any) => {
      if (!loading) {
        setLoading(true);
        data["id"] = content.result.warehouse.id;
        if (data.images.length > 0) {
          let images = data.images.map(
            (item: any) =>
              item.url?.split("/").at(-1) ?? item.response.result.name
          );
          data.images = images;
        }
        let result = await NetworkApi.requestPost(
          "/api/warehouse/update",
          data
        );
        if (result.resultCode == 200) history.push("/wm/warehouse");
        else {
          message.error(result.message || "Алдаа гарлаа, дахин оролдоно уу!");
        }
      }
      setLoading(false);
    },
    [content, history, loading]
  );

  return (
    <>
      <BreadMe title={["Агуулах", "Агуулах зоорь бүртгэх"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={20}>
          <Col span={16} push={4}>
            <WarehouseForm
              fieldsValue={fieldsValue}
              loading={loading}
              onSubmit={handleSubmit}
            />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};

const WarehouseAdd = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(
    async (data: any) => {
      if (!loading) {
        setLoading(true);
        if (data.images.length > 0) {
          let images = data.images.map(
            (item: any) => item.response.result.name
          );
          data.images = images;
        }
        let result = await NetworkApi.requestPost(
          "/api/warehouse/update",
          data
        );
        if (result.resultCode == 200) history.push("/wm/warehouse");
        else {
          message.error(result.message || "Алдаа гарлаа, дахин оролдоно уу!");
        }
      }
      setLoading(false);
    },
    [history, loading]
  );

  return (
    <>
      <BreadMe title={["Агуулах", "Агуулах зоорь бүртгэх"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row gutter={20}>
          <Col span={16} push={4}>
            <WarehouseForm loading={loading} onSubmit={handleSubmit} />
          </Col>
        </Row>
      </Layout.Content>
    </>
  );
};
export { WarehouseAdd, WarehouseEdit };
