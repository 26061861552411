import { useCallback, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  Modal,
} from "antd";
import NetworkApi from "../../network/networkApi";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const forgotPassword = useCallback(async (values) => {
    setLoading(true);
    let result = await NetworkApi.requestPost(
      "/api/auth/password/reset",
      values
    );
    if (result.resultCode === 200) {
      setLoading(false);
      Modal.success({
        // icon: null,
        content: (
          <Row>
            <Typography.Title level={5}>Амжилттай!</Typography.Title>
            <Typography.Paragraph style={{ fontSize: 12 }}>
              Таны бүртгэлтэй и-мэйл хаяг руу шинэ нууц үг илгээгдсэн. Та и-мэйл
              хаягаа шалгана уу. ( и-мэйлийн inbox, spam, junk хэсгийг шалгана
              уу)
            </Typography.Paragraph>
          </Row>
        ),
        okText: "Хаах",
        onCancel: () => {
          history.push("/");
        },
        onOk: () => {
          history.push("/");
        },
      });
    } else {
      setLoading(false);
      Modal.error({
        // icon: null,
        content: (
          <Row>
            <Typography.Title level={5}>Алдаа!</Typography.Title>
            <Typography.Paragraph style={{ fontSize: 12 }}>
              Таны регистрийн дугаар, и-мэйл хаяг тохирохгүй байна! Та
              plant@mofa.gov.mn хаягт өөрийн овог, нэр, регистр, утас, и-мэйл
              хаягаа илгээн мэдээллээ шинэчилнэ үү.
            </Typography.Paragraph>
          </Row>
        ),
        okText: "Хаах",
      });
    }
  }, []);
  const onFinish = (values: any) => {
    forgotPassword(values);
  };
  return (
    <Layout>
      <Row align="middle" style={{ minHeight: "100vh" }}>
        <Col span={6} push={9}>
          <Row justify={"center"}>
            <img
              src="https://mofa.gov.mn/exp/images/uploads/logo_mofa.svg"
              alt="Хүнс Хөдөө Аж Ахуйн Хөнгөн Үйлдвэрийн Яам"
              style={{ marginBottom: 15 }}
            />
            <Card style={{ minWidth: 314 }}>
              <Form
                name="password_form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Typography.Title level={5} style={{ textAlign: "center" }}>
                  Нууц үг сэргээх
                </Typography.Title>
                <Typography.Paragraph style={{ fontSize: 11 }}>
                  Шинэ нууц үгийг таны и-мэйл хаяг руу илгээх болно.
                </Typography.Paragraph>
                <Form.Item
                  name="rd"
                  rules={[
                    {
                      required: true,
                      message: "Регистрийн дугаар оруулна уу!",
                    },
                  ]}
                >
                  <Input size={"large"} placeholder={"Регистрийн дугаар"} />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "И-мэйл хаяг оруулна уу!" },
                  ]}
                >
                  <Input
                    size={"large"}
                    type="email"
                    placeholder={"И-мэйл хаяг"}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify={"center"} align={"middle"}>
                    <Button loading={loading} type={"primary"} htmlType={"submit"}>
                      Илгээх
                    </Button>
                  </Row>
                </Form.Item>
                <Typography.Paragraph style={{ fontSize: 11 }}>
                  Хэрэв та бүртгэлтэй и-мэйл хаяг оруулаагүй болон санахгүй бол
                  plant@mofa.gov.mn хаягт өөрийн овог, нэр, регистр, утас,
                  и-мэйл хаягаа илгээн мэдээллээ шинэчилнэ үү.
                </Typography.Paragraph>
              </Form>
            </Card>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};
export default ForgotPassword;
