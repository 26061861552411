import { omit } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { USER_ROLE } from "../enums/user.enum";
import NetworkApi from "../network/networkApi";
interface ContentData {
  result: any[];
  loading: boolean;
}
const useWarehouseFarmerList = (
  page?: number
): [ContentData, () => void, any] => {
  const [filter, changeFilter] = useState<{
    page?: number;
    user?: any;
    rd?: string;
  }>({ page, user: undefined });
  const [content, changeContent] = useState<ContentData>({
    loading: false,
    result: [],
  });
  useEffect(() => {
    loadContent();
  }, [filter]);
  const loadContent = useCallback(async () => {
    if (
      filter.user &&
      ((filter.user?.role.cd === USER_ROLE.CUSTOMER && filter.rd) ||
        filter.user?.role.cd !== USER_ROLE.CUSTOMER)
    ) {
      changeContent((c) => {
        return {
          ...c,
          loading: true,
        };
      });
      let response = await NetworkApi.requestGet(
        "/api/warehouse/plant/organizations",
        { ...omit(filter, ["user"]) }
      );
      if (response.resultCode == 200)
        changeContent({
          loading: false,
          result: response.result.rows || [],
        });
    }
  }, [filter]);

  return [content, loadContent, changeFilter];
};
export default useWarehouseFarmerList;
