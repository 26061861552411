export enum PLAN_STATUS {
	PENDING = "Pending",
  	APPROVED = "Approved",
  	DECLINED = "Declined"
}

export enum PLAN_STATUS_TEXT {
  	Pending = "Хүлээгдэж буй",
  	Approved = "Баталгаажуулсан",
  	Declined = "Цуцалсан"
}
