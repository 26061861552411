import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Layout,
  Row,
  Select,
  Divider,
  DatePicker,
  message,
  Table,
  Modal,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import "moment/locale/mn";
import locale from "antd/es/date-picker/locale/mn_MN";

import BreadMe from "../lib/Breadme";
import usePlantType from "../../hooks/usePlantType";
import usePlant from "../../hooks/usePlant";
import NetworkApi from "../../network/networkApi";
import { AddPlanFarmLand } from "./components/AddPlanFramLand";
import { PlanFarmLandType } from "./types/PlanFarmLandType";
import { RequestFarmLandType } from "./types/RequestFarmLandType";
import { formatArea } from "../../helpers/number.helper";
import PlanAreaInput from "./components/PlanAreaInput";

const PlanAdd = () => {
  const history = useHistory();
  const [planForm] = Form.useForm();

  const [plantType] = usePlantType();
  const [plant, , changePlantFilter] = usePlant();
  const [showAddFarmLand, setShowAddFarmLand] = useState(false);
  const [year, changeYear] = useState();

  const [planFarmLands, setPlanFarmLands] = useState<{
    loading: boolean;
    data: PlanFarmLandType[];
  }>({ loading: false, data: [] });

  const loadFarmLandList = useCallback(
    async (farmlandIds: string[], onSuccess: (result: any[]) => void) => {
      let filter: any = { farmlands: farmlandIds };
      if (year) {
        filter["year"] = year;
      }
      let response = await NetworkApi.requestPost(
        "/api/plan/farmland/remained",
        filter
      );

      if (response.resultCode === 200) {
        onSuccess(response.result);
      } else {
        message.error(response.message || "system error");
      }
    },
    [year, planFarmLands]
  );

  const onChangePlanArea = (index: number, planArea: any) => {
    const list = [...planFarmLands.data];
    list[index].planArea = planArea;
    setPlanFarmLands({ ...planFarmLands, data: list });
  };

  const handleAddFarmLand = (farmlandIds: string[]) => {
    setPlanFarmLands({ ...planFarmLands, loading: true });
    setShowAddFarmLand(false);
    loadFarmLandList(farmlandIds, (result: any[]) => {
      const farmlands = [...planFarmLands.data, ...result];
      setPlanFarmLands({
        loading: false,
        data: farmlands,
      });
    });
  };

  const handleChangeYear = () => {
    const farmlandIds = planFarmLands.data.map((item) => `${item.id}`);
    loadFarmLandList(farmlandIds, (result: any[]) => {
      const farmlands = [...result];
      for (let i = 0; i < farmlands.length; i++) {
        const ofl = planFarmLands.data.find(
          (item) => item.id === farmlands[i].id
        );
        if (ofl) {
          let max = (farmlands[i].area || 0) - (farmlands[i].plannedArea || 0);
          const isOverflowMax = ofl.planArea > max;
          if (isOverflowMax) farmlands[i]["planArea"] = max;
          else farmlands[i]["planArea"] = ofl.planArea ?? 0;
        }
      }
      setPlanFarmLands({
        loading: false,
        data: farmlands,
      });
    });
  };

  useEffect(() => {
    if (year) {
      handleChangeYear();
    }
  }, [year]);

  const handleRemoveFarmLand = (id: number) => {
    const farmlands = planFarmLands.data.filter(
      (farmland) => farmland.id !== id
    );
    setPlanFarmLands({ ...planFarmLands, data: farmlands });
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      const farmlands: RequestFarmLandType[] = planFarmLands.data
        .filter((farmland) => farmland.planArea && farmland.planArea > 0)
        .map(({ id, planArea }) => ({
          id,
          planArea,
        }));

      let response = await NetworkApi.requestPost("/api/plan/create", {
        year: data.year.format("YYYY"),
        plantId: data.plantId,
        farmlands,
      });

      if (planFarmLands.data?.length === 0) {
        message.error("Тариалангийн газар сонгоно уу");
        return;
      } else if (farmlands.length === 0) {
        message.error("Тариалангийн газрын тариалах газар оруулна уу");
        return;
      }

      if (response.resultCode === 200) {
        history.push("/plan/list");
      } else {
        message.error(response.message || "system error");
      }
    },
    [history, planFarmLands.data]
  );

  const planFarmLandColumns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Нэгж талбайн дугаар",
      dataIndex: "posNo",
    },
    {
      title: "Талбайн нийт хэмжээ",
      dataIndex: "area",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Эзэмшигчийн нэр",
      dataIndex: "ownerName",
    },
    {
      title: "Регистр",
      dataIndex: "ownerRd",
    },
    {
      title: "Эзэмшлийн хэлбэр",
      dataIndex: "type",
      render: (type: any) => (type == "private" ? "Эзэмшил" : "Өмчлөл"),
    },
    {
      title: "Ашиглаж буй хэмжээ",
      dataIndex: "usedArea",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Төлөвлөгдсөн талбай",
      dataIndex: "plannedArea",
      render: (value: number) => `${formatArea(value)}`,
    },
    {
      title: "Тариалах талбай",
      dataIndex: "planArea",
      render: (value: number, record: any, index: number) => (
        <PlanAreaInput
          area={value ?? 0}
          max={(record.area || 0) - (record.plannedArea || 0)}
          onChange={(v: number) => onChangePlanArea(index, v)}
        />
      ),
    },
    {
      title: "Үйлдэл",
      dataIndex: "id",
      render: (id: number) => (
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveFarmLand(id)}
        />
      ),
    },
  ];

  return (
    <>
      <BreadMe title={["Тариалалтын төлөвлөгөө", "Нэмэх"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Form
              form={planForm}
              onFinish={handleSubmit}
              labelCol={{ xs: 24, sm: 24, md: 24, lg: 10, xl: 10 }}
              wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 14, xl: 14 }}
            >
              <Row justify="space-around" gutter={16}>
                <Divider
                  orientation="left"
                  plain
                  style={{ margin: 0, lineHeight: 0 }}
                >
                  Тариалалтын төрлийн мэдээлэл
                </Divider>
              </Row>
              <Row
                justify="space-around"
                gutter={16}
                style={{
                  padding: 24,
                  border: "1px solid #f0f0f0",
                  borderTop: "none",
                }}
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="plantTypeId"
                    label="Таримлын төрөл"
                    rules={[
                      { required: true, message: "Таримлын төрөл сонгоно уу" },
                    ]}
                  >
                    <Select
                      defaultValue=""
                      onChange={(v) => changePlantFilter({ type: v })}
                    >
                      <Select.Option value="">Таримлын төрөл</Select.Option>
                      {plantType.result.map((p: any) => (
                        <Select.Option value={p.id}>{p.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="plantId"
                    label="Таримлын нэр"
                    rules={[{ required: true, message: "Таримал сонгоно уу" }]}
                  >
                    <Select defaultValue="">
                      <Select.Option value={""}>Таримлын нэр</Select.Option>
                      {plant.result.map((p: any) => (
                        <Select.Option value={p.id}>{p.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="year"
                    label="Он"
                    rules={[{ required: true, message: "Он сонгоно уу" }]}
                  >
                    <DatePicker
                      picker="year"
                      locale={locale}
                      onChange={(date: any) =>
                        changeYear(date ? date.format("YYYY") : null)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row gutter={16}>
              <Divider
                orientation="left"
                plain
                style={{ margin: 0, lineHeight: 0 }}
              >
                Төлөвлөгдсөн талбай, тариаланч
              </Divider>
            </Row>
            <Row
              gutter={16}
              style={{
                padding: 24,
                border: "1px solid #f0f0f0",
                borderTop: "none",
              }}
            >
              <Col span={24}>
                <Row
                  justify="end"
                  gutter={16}
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      if (!year) {
                        Modal.confirm({
                          title: "Анхааруулга",
                          content:
                            "Төлөвлөгөөний он оруулсаны дараа нэмэх боломжтой",
                          okText: "Ойлголоо",
                          okCancel: false,
                        });
                        return;
                      }
                      setShowAddFarmLand(true);
                    }}
                  >
                    Нэмэх
                  </Button>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  scroll={{ x: true }}
                  columns={planFarmLandColumns}
                  dataSource={planFarmLands.data}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 15, marginBottom: 15 }}
          justify="end"
          align="middle"
        >
          <Button type="primary" onClick={() => planForm.submit()}>
            Хадгалах
          </Button>
        </Row>
        {showAddFarmLand && (
          <AddPlanFarmLand
            visible={showAddFarmLand}
            onCancel={() => setShowAddFarmLand(false)}
            onAddFarmLand={handleAddFarmLand}
            addedFarmLands={planFarmLands.data.map((farmland) => farmland.id)}
          />
        )}
      </Layout.Content>
    </>
  );
};

export default PlanAdd;
