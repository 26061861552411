import { useCallback, useEffect, useState } from "react";
import { message, Table, Tag } from "antd";
import NetworkApi from "../../../network/networkApi";
import { formatNumberWithComma } from "../../../helpers/number.helper";

const FarmerSuppportList = ({ rd }: { rd: string }) => {
  const [content, changeContent] = useState({
    loading: true,
    result: [],
    filter: {},
  });

  useEffect(() => {
    loadContent();
  }, [content.filter, rd]);

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });

    let response = await NetworkApi.requestGet("/api/admin/support/list", {
      ...content.filter,
      rd,
    });

    if (response.resultCode == 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          result: JSON.parse(response.result),
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content, rd]);

  content.result.sort((a, b) => {
    if (a["status_date"] > b["status_date"]) {
      return -1;
    }
    if (a["status_date"] < b["status_date"]) {
      return 1;
    }
    return 0;
  });

  const supportInfo = [
    {
      title: "№",
      dataIndex: "id",
      render: (v: any, r: any, index: number) => `${index + 1}`,
    },
    {
      title: "Огноо",
      dataIndex: "date",
    },
    {
      title: "Хүсэлт гаргагч Регистр №",
      dataIndex: "register",
    },
    {
      title: "Хуулийн этгээдийн нэр",
      dataIndex: "name",
    },
    {
      title: "Талбайн дугаар",
      dataIndex: "farmlandPosNo",
    },
    {
      title: "Ангилал",
      dataIndex: "category",
    },
    {
      title: "Бүтээгдэхүүний төрөл",
      dataIndex: "sub_category",
    },
    {
      title: "Бүтээгдэхүүний нэр",
      dataIndex: "product",
    },
    {
      title: "Боломжит дээд хэмжээ",
      dataIndex: "max",
    },
    {
      title: "Хүсэлтийн нэмжээ",
      dataIndex: "amount",
      render: (value: number) => formatNumberWithComma(value),
    },
    {
      title: "Нэгж үнэ",
      dataIndex: "price",
      render: (value: number) => formatNumberWithComma(value),
    },
    {
      title: "Нийт үнэ",
      dataIndex: "totalPrice",
      render: (value: number) => formatNumberWithComma(value),
    },
    {
      title: "ҮА явуулах аймар сум",
      dataIndex: "aimag",
    },
    {
      title: "Төлөв",
      dataIndex: "status_id",
      render: (value: number) => (
        <>
          {
            {
              1: <Tag color="cyan">Илгээгдсэн</Tag>,
              2: <Tag color="slateblue">Хүлээн авсан</Tag>,
              3: <Tag color="mediumslateblue">Хуваарилсан</Tag>,
              4: <Tag color="darkslateblue">Хянасан</Tag>,
              5: <Tag color="green">Баталсан</Tag>,
              6: <Tag color="red">Татгалзсан</Tag>,
              null: <Tag color="cyan">Илгээгдсэн</Tag>,
            }[value]
          }
        </>
      ),
    },
    {
      title: "Төлөв огноо",
      dataIndex: "status_date",
    },
  ];
  return (
    <>
      <Table
        scroll={{
          x: true,
        }}
        style={{ width: "100%" }}
        rowKey={"id"}
        loading={content.loading}
        columns={supportInfo}
        dataSource={content.result}
      />
    </>
  );
};

export default FarmerSuppportList;
