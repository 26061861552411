import React, {useState} from 'react'

import {Row, Col, Input, Button, Modal, Table, Card, Layout} from 'antd'
import License from '../lib/License';

const { TextArea } = Input;

const CertificateCheck = () => {
    const [visible, setVisible] = useState(false);
    const [id,changeId] = useState('')
    const showModal = () => {
        setVisible(true);
    };
    
    return (
        <Layout title="Ургамал, түүний  гаралтай хүнсний  түүхий эд,  бүтээгдэхүүний  гарал, үүсэл">
            <Row >
                <Col span={24}>
                    <Card>
                        <Row>
                            <Col span={6}>
                                <p style={{textAlign:'center', marginTop:'7px'}}>
                                    <h1>ГҮГ шалгах</h1>
                                </p>
                            </Col>
                            <Col span={12}>
                            <Input value={id} onChange={e=>changeId(e.target.value)} placeholder={'Гэрчилгээний дугаар оруулах'}/>
                            </Col>
                            <Col span={6}>
                                <Button type={"primary"} onClick={showModal}>Шалгах</Button><br/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Modal width={1240} centered visible={visible} onCancel={() => setVisible(false)}
                footer={[
                    <Button key="submit" type="default" onClick={() => setVisible(false)}>
                        Хаах
                       </Button>,
                ]}
            >
                <License isSmall={false} id={visible?id:''} key={"PrintLicense"} />
            </Modal>
            
        </Layout>
    )
};
export default CertificateCheck
