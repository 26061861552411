import { Button, Layout, List } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NetworkApi from '../../network/networkApi'
import BreadMe from '../lib/Breadme'
interface ContentData {
    result: any[],
    loading: boolean,
    resultCode: number
}
const CultivationPlanList = () => {
    const [content, setContent] = useState<ContentData>({
        result: [],
        loading: true,
        resultCode: 200
    })
    const loadContent = useCallback(async () => {
        setContent(c => {
            return {
                ...c,
                loading: true
            }
        })
        let response = await NetworkApi.requestGet('/api/statistic/plan/years')
        setContent({
            loading: false,
            resultCode: response.resultCode,
            result: response.result || []
        })
    }, [])
    useEffect(() => {
        loadContent()
    }, [])
    return (
        <div>
            <BreadMe title={['Тайлан', 'Тариалалтын төлөвлөгөө']} />
            <Layout.Content style={{ background: '#fff', padding: 24, margin: 0, minHeight: 280 }}>
                <List
                    header={<div ><Button type="primary">Төлөвлөгөө нэмэх</Button></div>}
                    bordered
                    dataSource={content.result}
                    renderItem={item => (
                        <List.Item>
                            <Link to={`/report/plan/${item.year}`}>{item.year} оны төлөвлөгөө</Link>
                        </List.Item>
                    )}
                />
            </Layout.Content>
        </div>
    )
}
export default CultivationPlanList