import { useState, useEffect, useCallback, useContext } from "react";
import { Modal, Row, Col, Form, Input, Button, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import NetworkApi from "../../../network/networkApi";
import UserContext from "../../../providers/UserContext";
import AddressItemSelect from "../../lib/AddressItemSelect";

const columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "Нэгж талбарын дугаар",
    dataIndex: "posNo",
  },
  {
    title: "Баг",
    dataIndex: "bag",
    render: (value: any) => `${value.name}`,
  },
  {
    title: "Хэмжээ",
    dataIndex: "area",
    render: (value: number) => `${value.toFixed(2)} га`,
  },
  {
    title: "Эзэмшигчийн нэр",
    render: (record: any) => {
      const noUsageData = record?.usage?.length === 0;
      const noOrganizationData = !record?.usage[0]?.organization;
      if (noUsageData || noOrganizationData) {
        return "";
      }
      let name = "";
      let parts = record?.usage[0]?.organization.name.split(" ");
      if (parts.length > 1) {
        name = `${parts[0].substr(0, 1)}.${parts[1]}`;
      } else {
        name = parts[0];
      }
      return `${name} (${record?.usage[0]?.organization.rd ?? ""})`;
    },
  },
];

const BagSelect = (props: any) => {
  const [bag, changeBag] = useState(props.defaultBag || "");

  useEffect(() => {
    changeBag(props.defaultBag);
  }, [props.defaultBag]);

  return (
    <>
      <AddressItemSelect
        subtitle="Баг сонгох"
        {...props}
        name="bagId"
        value={bag}
        parent={props.sum}
        onChange={(v: string) => {
          changeBag(v);
        }}
      />
    </>
  );
};

export const AddPlanFarmLand = ({
  visible,
  onCancel,
  onAddFarmLand,
  addedFarmLands,
}: {
  visible: boolean;
  onCancel: any;
  onAddFarmLand: any;
  addedFarmLands: number[];
}) => {
  const { user } = useContext(UserContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [paginationSelectedRowKeys, setPaginationSelectedRowKeys] =
    useState<any>({});
  const [filter, changeFilter] = useState<{
    page: number;
    posNo: string;
    bagId: string;
  }>({ page: 1, posNo: "", bagId: "" });
  const [content, changeContent] = useState({
    loading: false,
    result: [],
    total: 0,
    page: 1,
  });

  const loadContent = useCallback(async () => {
    let response = await NetworkApi.requestGet("/api/plan/farmland/list", {
      ...filter,
      farmlandIds: addedFarmLands,
    });
    changeContent({
      loading: false,
      result: response.result?.rows || [],
      total: response.result?.count || 0,
      page: response.result?.page || 1,
    });
  }, [filter, addedFarmLands]);

  useEffect(() => {
    if (visible) {
      loadContent();
    }
  }, [visible, filter, loadContent]);

  const handleSubmitSearch = (values: any) => {
    changeFilter({ ...filter, posNo: values.posNo, bagId: values.bagId });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={() => onAddFarmLand(selectedRowKeys)}
      title={"Ажилд хамрагдах тариаланч, талбай нэмэх"}
      okText="Нэмэх"
      cancelText="Хаах"
      width={920}
    >
      <Row justify="center" style={{ marginBottom: 25 }}>
        <Col span={22}>
          <Form layout="inline" onFinish={handleSubmitSearch}>
            <Form.Item name="posNo" label="Хайх">
              <Input placeholder="Хайх" />
            </Form.Item>
            <BagSelect sum={user?.sumId} />
            <Form.Item>
              <Button
                htmlType="submit"
                icon={<SearchOutlined />}
                type="primary"
              ></Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={22}>
          <Table
            scroll={{ x: true }}
            rowKey="id"
            style={{ width: "100%" }}
            loading={content.loading}
            columns={columns}
            dataSource={content.result}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys,
              onChange: (selectedRowKeys: React.Key[]) => {
                const d = paginationSelectedRowKeys;
                d[`${content.page}`] = selectedRowKeys;
                setPaginationSelectedRowKeys(d);
                setSelectedRowKeys(
                  [].concat(...Object.keys(d).map((k) => d[k]))
                );
              },
            }}
            pagination={{
              showSizeChanger: false,
              current: content.page,
              defaultPageSize: 10,
              total: content.total,
              onChange: (page: number) => changeFilter({ ...filter, page }),
            }}
          ></Table>
        </Col>
      </Row>
    </Modal>
  );
};
