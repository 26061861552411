import React, { useCallback, useEffect, useState } from 'react'
import NetworkApi from '../network/networkApi'
interface ContentData {
    result: any[],
    loading: boolean
}
const useChemical = (page?: number, typeId?:number, name?:string): [ContentData, () => void, any,any] => {
    const [filter, changeFilter] = useState({ page: page?page:0, typeId: typeId?typeId: null, name: name?name:''})
    const [content, changeContent] = useState<ContentData>({
        loading: false,
        result: []
    })
    useEffect(() => {
        loadContent()
    }, [filter])
    const loadContent = useCallback(async () => {
        let response = await NetworkApi.requestGet('/api/chemical/list', filter)
        changeContent({
            loading: false,
            result: response.result.rows || []
        })
    }, [filter])

    return [content, loadContent, changeFilter,filter]
}
export default useChemical
