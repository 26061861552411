export const GERMINATION_MAIN_TYPE = {
  TYPE_1: "1",
  TYPE_2: "2",
  TYPE_3: "3",
};

export const GERMINATION_MAIN_TYPE_REF = {
  [GERMINATION_MAIN_TYPE.TYPE_1]: "Сүүн болц",
  [GERMINATION_MAIN_TYPE.TYPE_2]: "Аарцан болц",
  [GERMINATION_MAIN_TYPE.TYPE_3]: "Бүрэн болц",
};
export const GERMINATION_MAIN_TYPE_LIST = [
  {
    id: GERMINATION_MAIN_TYPE.TYPE_1,
    name: "Сүүн болц",
  },
  {
    id: GERMINATION_MAIN_TYPE.TYPE_2,
    name: "Аарцан болц",
  },
  {
    id: GERMINATION_MAIN_TYPE.TYPE_3,
    name: "Бүрэн болц",
  },
];

export const GERMINATION_DEVELOPMENT_STAGE = {
  STAGE_1: "1",
  STAGE_2: "2",
  STAGE_3: "3",
  STAGE_4: "4",
  STAGE_5: "5",
};

export const GERMINATION_DEVELOPMENT_STAGE_REF = {
  [GERMINATION_DEVELOPMENT_STAGE.STAGE_1]: "Соёололт",
  [GERMINATION_DEVELOPMENT_STAGE.STAGE_2]: "Бутлалт",
  [GERMINATION_DEVELOPMENT_STAGE.STAGE_3]: "Гол хатгалт",
  [GERMINATION_DEVELOPMENT_STAGE.STAGE_4]: "Түрүүлэлт",
  [GERMINATION_DEVELOPMENT_STAGE.STAGE_5]: "Цэцэглэлт",
};
export const GERMINATION_DEVELOPMENT_STAGE_LIST = [
  {
    id: GERMINATION_DEVELOPMENT_STAGE.STAGE_1,
    name: "Соёололт",
  },
  {
    id: GERMINATION_DEVELOPMENT_STAGE.STAGE_2,
    name: "Бутлалт",
  },
  {
    id: GERMINATION_DEVELOPMENT_STAGE.STAGE_3,
    name: "Гол хатгалт",
  },
  {
    id: GERMINATION_DEVELOPMENT_STAGE.STAGE_4,
    name: "Түрүүлэлт",
  },
  {
    id: GERMINATION_DEVELOPMENT_STAGE.STAGE_5,
    name: "Цэцэглэлт",
  },
];

export const GERMINATION_QUALITY = {
  EXCELLENT: "4",
  GOOD: "3",
  FAIR: "2",
  POOR: "1",
};

export const GERMINATION_QUALITY_REF = {
  [GERMINATION_QUALITY.EXCELLENT]: "Онц",
  [GERMINATION_QUALITY.GOOD]: "Сайн",
  [GERMINATION_QUALITY.FAIR]: "Дунд",
  [GERMINATION_QUALITY.POOR]: "Муу",
};
export const GERMINATION_QUALITY_LIST = [
  {
    id: GERMINATION_QUALITY.EXCELLENT,
    name: "Онц",
  },
  {
    id: GERMINATION_QUALITY.GOOD,
    name: "Сайн",
  },
  {
    id: GERMINATION_QUALITY.FAIR,
    name: "Дунд",
  },
  {
    id: GERMINATION_QUALITY.POOR,
    name: "Муу",
  },
];
