import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  List,
  message,
  Modal,
  Row,
  Image,
  Input,
  Form,
  Table,
} from "antd";
import config from "../../../config";
import NetworkApi from "../../../network/networkApi";
import UserContext from "../../../providers/UserContext";
import { FilePdfOutlined } from "@ant-design/icons";
import moment from "moment";
import FarmlandUsageActivities from "./atoms/FarmlandUsageActivities";
interface DetailContent {
  usage: any;
  permissions: any[];
  activities: any[];
  loading: boolean;
}

interface DetailProps {
  visible: boolean;
  id: any;
  hide: () => void;
}

const FarmlandUsageDetail = ({ visible, id, hide }: DetailProps) => {
  const [content, changeContent] = useState<DetailContent>({
    usage: {},
    permissions: [],
    activities: [],
    loading: true,
  });
  const [showCancel, changeShowCancel] = useState(false);
  const { user } = useContext(UserContext);
  const [reason, setReason] = useState("");
  const certificates = useMemo<any[]>(() => {
    if (content.usage.certificate) {
      return JSON.parse(content.usage.certificate);
    }
    return [];
  }, [content]);
  const setConfirm = useCallback(
    async (status: "active" | "rejected", reason?: string) => {
      let response = await NetworkApi.requestPost(
        "/api/farmland/usage/confirm",
        {
          id: content.usage.id,
          status,
          reason,
        }
      );
      if (response.resultCode == 200) {
        changeContent((ct) => {
          console.log([response.result.permission, ...ct.permissions]);
          return {
            ...ct,
            usage: response.result.usage,
            permissions: [response.result.permission, ...ct.permissions],
            activities: response.result.activities,
          };
        });
      } else {
        message.error(response.message || "system error");
      }
    },
    [content]
  );
  useEffect(() => {
    if (visible && id) {
      loadContent(id);
    }
  }, [visible, id]);
  const loadContent = useCallback(async (id: any) => {
    let response = await NetworkApi.requestGet(
      `/api/farmland/usage/detail/${id}`
    );
    if (response.resultCode == 200) {
      changeContent({
        loading: false,
        usage: response.result.usage,
        permissions: response.result.permissions,
        activities: response.result.activities,
      });
    } else {
      message.error(response.message || "Системд алдаа гарлаа");
      changeContent({
        loading: false,
        usage: {},
        permissions: [],
        activities: [],
      });
    }
  }, []);
  const sumPermission = useMemo(() => {
    return content.permissions.find((p) => p.role == "sum");
  }, [content]);
  const mainSource = [
    {
      key: "2",
      name: "Талбайн хэмжээ",
      value: `${content.usage.farmland?.area} га`,
    },
    {
      key: "33",
      name: "Хүсэлт гаргагч",
      value: `${content.usage.organization?.name}-${content.usage.organization?.rd}`,
    },

    {
      key: "3",
      name: "Эзэмшигчийн нэр",
      value: content.usage.owner,
    },
    {
      key: "4",
      name: "Байршил",
      value:
        content.usage.farmland?.aimag?.name +
        " / " +
        content.usage.farmland?.sum?.name +
        " / " +
        content.usage.farmland?.bag?.name,
    },
    {
      key: "5",
      name: "Хүсэлт илгээсэн хугацаа",
      value: moment(content.usage.createdAt).format("YYYY-MM-DD"),
    },
    {
      key: "6",
      name: "Ашиглах төрөл",
      value: content.usage.type == "owner" ? "Эзэмшигч" : "Түрээс",
    },
    {
      key: "7",
      name: "Ашиглах талбайн хэмжээ",
      value: `${content.usage.area} га`,
    },
    {
      key: "8",
      name: "Ашиглаж эхлэх огноо",
      value: content.usage.startDate,
    },
    {
      key: "88",
      name: "Ашиглаж дуусах огноо",
      value: content.usage.endDate,
    },
    {
      key: "9",
      name: "Гэрчилгээ/Түрээслэх гэрээ",
      value: (
        <List
          grid={{ gutter: 10, column: 8 }}
          dataSource={certificates}
          renderItem={(item) => (
            <List.Item>
              {item.type == "application/pdf" ? (
                <Card bodyStyle={{ padding: 5, height: "100%" }}>
                  <div
                    onClick={() =>
                      window.open(`${config.get("apiUrl")}/tmp/${item.tmp}`)
                    }
                    style={{ height: "100%", textAlign: "center" }}
                  >
                    <FilePdfOutlined style={{ fontSize: 50 }} />
                    <p>{item.name || ""}</p>
                  </div>
                </Card>
              ) : (
                <Card bodyStyle={{ padding: 5 }}>
                  <Image
                    width="100%"
                    height="100%"
                    src={`${config.get("apiUrl")}/tmp/${item.tmp}`}
                  />
                </Card>
              )}
            </List.Item>
          )}
        />
      ),
    },
  ];

  const srcInfo = [
    {
      title: "НТДугаар",
      dataIndex: "name",
    },
    {
      title: `${content.usage.farmland?.posNo}`,
      dataIndex: "value",
    },
  ];

  const checkList = [
    {
      key: "1",
      name: "Аймаг",
      value: content.usage.farmland?.aimag.name,
    },
    {
      key: "2",
      name: "Сум",
      value: content.usage.farmland?.sum.name,
    },
    {
      key: "3",
      name: "Албан тушаал",
      value: "Сумын агрономич",
    },
    {
      key: "4",
      name: "Овог нэр",
      value: `${sumPermission?.user?.lastName || ""} ${
        sumPermission?.user?.firstName || ""
      }`,
    },
    {
      key: "5",
      name: "Төлөв",
      value:
        content.usage?.status != "pending"
          ? content.usage?.status == "active"
            ? "Зөвшөөрсөн"
            : "Татгалзсан"
          : "Хүлээгдэж буй",
    },
  ];

  return (
    <Modal
      width={1240}
      centered
      visible={visible}
      onCancel={hide}
      title={"Тариалангийн талбайн хүсэлтийн дэлгэрэнгүй"}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Table
            scroll={{ x: true }}
            dataSource={mainSource}
            rowKey={"key"}
            columns={srcInfo}
            size="small"
            pagination={false}
            bordered
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <fieldset
            style={{ border: "1px solid gray", padding: 10, height: 300 }}
          >
            <legend
              style={{ width: "auto", paddingLeft: 10, paddingRight: 10 }}
            >
              Зөвшөөрөл:
            </legend>
            <Row gutter={10}>
              <Col span={24}>
                <Table
                  scroll={{ x: true }}
                  dataSource={checkList}
                  rowKey={"key"}
                  columns={srcInfo}
                  showHeader={false}
                  size="small"
                  pagination={false}
                />
              </Col>
              {content.usage.status == "pending" &&
              (user.role.cd == "VILLAGE_AGRONOMIST" ||
                user.role.cd == "ADMIN") ? (
                <>
                  <Col>
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => setConfirm("active")}
                    >
                      Зөвшөөрөх
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      key="submit"
                      type="primary"
                      danger
                      onClick={() => changeShowCancel(true)}
                    >
                      Татгалзах
                    </Button>
                  </Col>
                </>
              ) : null}
            </Row>
          </fieldset>
        </Col>
      </Row>
      {content.activities.length > 0 && (
        <FarmlandUsageActivities activities={content.activities} />
      )}
      <Modal
        visible={showCancel}
        title="Татгалзах шалтгаан"
        okText="Илгээх"
        cancelText="болих"
        onOk={() => {
          if (reason) {
            setConfirm("rejected", reason);
            changeShowCancel(false);
          } else {
            message.error("татгалзах шалтгаан оруулна уу");
          }
        }}
        onCancel={() => changeShowCancel(false)}
      >
        <Form layout="vertical">
          <Form.Item name="reason" label="Татгалзах шалтгаан">
            <Input.TextArea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Modal>
  );
};
export default FarmlandUsageDetail;
