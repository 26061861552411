import React, { useCallback, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd'
import AdminApp from './components/AdminApp';
import NetworkApi from './network/networkApi';
import LoginApp from './components/login/LoginApp'
import UserContext from './providers/UserContext';
import config from './config';
import moment from 'moment'
import 'moment/locale/mn'
import mnMN from 'antd/lib/locale/mn_MN'
import { USER_ROLE } from './enums/user.enum';
const mn = {
  ...mnMN,
  Image: {
    preview: '',
  },
}
moment.locale('mn')
const App = () => {
  const [loading, changeLoading] = useState(true);
  let [user, changeUser] = useState<any>(null);
  useEffect(() => {
    loadUser()
    config.get('emitter').on('auth-error',()=>{      
      changeUser(null)
    })
  }, []);
  const loadUser = useCallback(async () => {
    let result = await NetworkApi.requestGet('/api/user/info');    
    if (result.resultCode == 200) {
      changeUser(result.result || null)
      if (result.result?.role?.cd === USER_ROLE.WAREHOUSE_SPECIALIST && !window.location.href.includes("/wm/warehouse")) {
        window.location.href = "/wm/warehouse";
      }
    } else {
      changeUser(null)
    }
    changeLoading(false)

  }, []);
  return (
    <div className="App">
      {
        loading ? <div /> : (
          <UserContext.Provider value={{ user: user }}>
            <ConfigProvider locale={mn} >
              {user ? <AdminApp /> : <LoginApp />}
            </ConfigProvider>
          </UserContext.Provider>
        )
      }
    </div>
  );
};

export default App;
