import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Table, Space, Tag, Button, Row, Col, Form, Select, Input } from "antd";

import NetworkApi from "../../../network/networkApi";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import AddressSelect from "../../lib/AddressSelect";
import UserContext from "../../../providers/UserContext";

const FarmerFarmlandUsageList = ({ rd }: { rd: string }) => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [content, changeContent] = useState({
    loading: false,
    result: [],
    page: 1,
    count: 0,
  });

  const [filter, changeFilter] = useState({
    status: "active",
    posNo: "",
    identifier: "",
    page: 1,
  });

  useEffect(() => {
    loadContent();
  }, [filter, rd]);

  const { defaultAimag, defaultSum } = useMemo(() => {
    let defaultAimag = "";
    let defaultSum = "";
    if (
      user.role?.cd == "PROVINCE_AGRONOMIST" ||
      user.role?.cd == "VILLAGE_AGRONOMIST"
    ) {
      defaultAimag = user.aimagId;
    }
    if (user.role?.cd == "VILLAGE_AGRONOMIST") {
      defaultSum = user.sumId;
    }
    return { defaultAimag, defaultSum };
  }, [user]);

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/farmland/usage/list", {
      ...filter,
      identifier: rd,
    });
    changeContent({
      loading: false,
      count: response.result?.count,
      page: response.result.page,
      result: response.result?.rows,
    });
  }, [filter, rd]);
  const columns = [
    {
      title: "НТДугаар",
      render: (value: any) => <>{value.farmland?.posNo}</>,
    },
    {
      title: "Талбайн хэмжээ",
      dataIndex: "area",
      render: (value: number) => <>{value} га</>,
    },
    {
      title: "Байршил",
      render: (itm: any) => (
        <>
          {itm.farmland?.sum?.name} - {itm.farmland?.bag?.name}
        </>
      ),
    },
    {
      title: "Ашиглаж эхэлсэн огноо",
      dataIndex: "startDate",
    },
    {
      title: "Ашиглаж дуусах огноо",
      dataIndex: "endDate",
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      render: (type: any) => (type == "rent" ? "Түрээс" : "Эзэмшил"),
    },
    {
      title: "Тариалалт",
      render: (itm: any) => (
        <Space size={10}>
          <Button
            type="link"
            onClick={() => history.push(`/area/detail/${itm.farmland.id}`)}
          >
            <Tag
              icon={<EyeOutlined />}
              style={{ fontWeight: "bolder", border: "none", borderRadius: 8 }}
            >
              Харах
            </Tag>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={28}>
          <Space size={40}>
            <Form layout="inline" onFinish={changeFilter}>
              <AddressSelect
                defaultAimag={defaultAimag}
                defaultSum={defaultSum}
                hideLabel={true}
              />
              <Form.Item name="type">
                <Select style={{ width: 201 }} placeholder="Төрөл" allowClear>
                  <Select.Option value="owner">Эзэмшил</Select.Option>
                  <Select.Option value="rent">Түрээс</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="posNo">
                <Input placeholder={"НТДугаар"} />
              </Form.Item>
              <Button htmlType="submit" type={"primary"}>
                <SearchOutlined />
              </Button>
            </Form>
          </Space>
        </Col>
      </Row>
      <Table
        scroll={{
          x: true,
        }}
        style={{ width: "100%" }}
        loading={content.loading}
        columns={columns}
        pagination={{
          current: filter.page,
          total: content.count,
        }}
        onChange={(p) => {
          changeFilter({
            ...filter,
            page: p.current || 1,
          });
        }}
        dataSource={content.result}
        rowKey={"id"}
      />
    </>
  );
};
export default FarmerFarmlandUsageList;
