import { CSSProperties, useContext, useState } from "react";
import { Menu, Layout, Divider } from "antd";

import {
  AppstoreOutlined,
  CopyOutlined,
  PieChartOutlined,
  ContainerOutlined,
  ApartmentOutlined,
  HomeOutlined,
  TeamOutlined,
  DropboxOutlined,
  UsergroupAddOutlined,
  SendOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import UserContext from "../../providers/UserContext";
import { USER_ROLE } from "../../enums/user.enum";

// ADMIN - MACS

// SPECIALIST - PLANT - Хими
// HEAD_OF_OFFICE - PLANT - Химийн бодис
// VILLAGE_AGRONOMIST - PLANT - Сумын ар
// CUSTOMER - PLANT - Тариаланч

const { Sider } = Layout;
const { SubMenu } = Menu;

const menuGroupTitle: CSSProperties = {
  padding: "10px 15px 15px 15px",
  letterSpacing: "0.05em",
  pointerEvents: "none",
  cursor: "default",
  fontSize: 12,
  textTransform: "uppercase",
  color: "#333",
  fontWeight: "bold",
};

const MainMenu = () => {
  const { user } = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Sider width={256} style={{ background: "white" }}>
      <Layout.Header style={{ padding: 0 }}>
        <img
          style={{ height: "100%", paddingLeft: 10, float: "left" }}
          src="https://mofa.gov.mn/_next/image?url=%2Fimages%2Flogo%2Fmain.svg&w=750&q=75"
        />
      </Layout.Header>
      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
      >
        {user?.role.cd === USER_ROLE.ADMIN && (
          <SubMenu
            key="dashboard"
            title={<span>Хянах самбар</span>}
            icon={<CopyOutlined />}
          >
            <Menu.Item key="dashboard-1">
              <Link to={"/"}>Ерөнхий</Link>
            </Menu.Item>
            {user?.role.cd === USER_ROLE.ADMIN && (
              <Menu.Item key="dashboard-2">
                <Link to={"/dashboard/farmland"}>Газар тариалан</Link>
              </Menu.Item>
            )}
            {user?.role.cd === USER_ROLE.ADMIN && (
              <Menu.Item key="dashboard-3">
                <Link to={"/dashboard/chemical"}>Бодис, бордоо</Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {USER_ROLE.WAREHOUSE_SPECIALIST !== user?.role?.cd && (
          <>
            {user?.role.cd !== USER_ROLE.ADMIN && (
              <Menu.Item key="1" icon={<HomeOutlined />}>
                <Link to={"/"}>Хянах самбар</Link>
              </Menu.Item>
            )}
            <Divider />
            {/* УГҮПБХС */}
            <div style={menuGroupTitle}>УГҮПБХС</div>
            <SubMenu
              key="sub1"
              title={<span>Зөвшөөрөл</span>}
              icon={<CopyOutlined />}
            >
              <Menu.Item key="2">
                <Link to={"/license/farm"}>Тариан талбайн зөвшөөрөл</Link>
              </Menu.Item>
              {(user.role.cd == "ADMIN" ||
                (user.role.cd == "CUSTOMER" &&
                  user.organizations.length > 0 &&
                  user.organizations[0].license.length > 0)) && (
                <Menu.Item key="3">
                  <Link to={"/license/organization"}>
                    Хуулийн этгээдийн зөвшөөрөл
                  </Link>
                </Menu.Item>
              )}
              {(user.role.cd == "HEAD_OF_OFFICE" ||
                user.role.cd == "SPECIALIST" ||
                user.role.cd == "ADMIN" ||
                user.role.cd == "PROVINCE_INSPECTOR" ||
                user.role.cd == "INSPECTOR" ||
                (user.role.cd == "CUSTOMER" &&
                  user.organizations.length > 0 &&
                  user.organizations[0].license.length > 0)) && (
                <SubMenu
                  key="license-chemical"
                  title={<span>Бодис, Бордоо</span>}
                >
                  <Menu.Item key="license-chemical-list">
                    <Link to={"/license/chemical"}>Жагсаалт</Link>
                  </Menu.Item>
                  <Menu.Item key="license-chemical-orders">
                    <Link to={"/license/chemical/orders"}>Тушаал</Link>
                  </Menu.Item>
                  <Menu.Item key="license-chemical-fertilizer-orders">
                    <Link to={"/license/chemical/fertilizer-orders"}>
                      Бордоо
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="9">
                    <Link to={"/license/chemical/usage"}>Зарцуулалт</Link>
                  </Menu.Item>
                </SubMenu>
              )}
            </SubMenu>
            {user?.role.cd == "CUSTOMER" ? (
              <SubMenu
                key="sub2"
                title={<span>Тариалангийн талбай</span>}
                icon={<AppstoreOutlined />}
              >
                <Menu.Item key="6">
                  <Link to={"/farmland/list"}>Жагсаалт</Link>
                </Menu.Item>
              </SubMenu>
            ) : (
              <SubMenu
                key="sub2"
                title={<span>Тариалангийн талбай</span>}
                icon={<AppstoreOutlined />}
              >
                {(user?.role.cd == "VILLAGE_AGRONOMIST" ||
                  user?.role.cd == "ADMIN") && (
                  <Menu.Item key="5">
                    <Link to={"/area/add"}>Бүртгэх</Link>
                  </Menu.Item>
                )}
                <Menu.Item key="6">
                  <Link to={"/area/list"}>Жагсаалт</Link>
                </Menu.Item>
              </SubMenu>
            )}
            {user?.role.cd != "CUSTOMER" && (
              <Menu.Item key="7" icon={<TeamOutlined />}>
                <Link to={"/farmers"}>Тариаланчид</Link>
              </Menu.Item>
            )}
            {/* {
                                user?.role.cd != 'CUSTOMER' && ( */}
            <SubMenu
              key="sub61"
              title={<span>Гэрчилгээ</span>}
              icon={<ContainerOutlined />}
            >
              {(user?.role.cd == "CUSTOMER" ||
                user?.role.cd == "VILLAGE_AGRONOMIST" ||
                user?.role.cd == "ADMIN") && (
                <Menu.Item key="10">
                  <Link to={"/origin/add"}>Бүртгэх</Link>
                </Menu.Item>
              )}
              <Menu.Item key="11">
                <Link to={"/origin/list"}>Жагсаалт</Link>
              </Menu.Item>
            </SubMenu>
            {/* )
                            } */}
            <Divider />
            {/* Газар тариалан */}
            <div style={menuGroupTitle}>Газар тариалан</div>
            {[
              USER_ROLE.ADMIN,
              USER_ROLE.PROVINCE_AGRONOMIST,
              USER_ROLE.VILLAGE_AGRONOMIST,
              USER_ROLE.CUSTOMER,
            ].includes(user?.role?.cd) && (
              <Menu.Item key="equipment" icon={<ToolOutlined />}>
                <Link to={"/equipment/list"}>Тоног төхөөрөмж</Link>
              </Menu.Item>
            )}
            {(user?.role.cd == "CUSTOMER" || user?.role?.cd == "ADMIN") && (
              <>
                <SubMenu
                  key="support1"
                  icon={<SendOutlined />}
                  title={<span>ХААК ТӨХХК хүсэлт</span>}
                >
                  <Menu.Item key="support11">
                    <Link to={"/support/add"}>Хүсэлт нэмэх</Link>
                  </Menu.Item>
                  <Menu.Item key="support12">
                    <Link to={"/support/list"}>Хүсэлтийн жагсаалт</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="support2"
                  icon={<SendOutlined />}
                  title={<span>ХХААХҮЯ-ны дэмжлэг</span>}
                >
                  <Menu.Item key="support21">
                    <Link to={"/support-others/add"}>Хүсэлт нэмэх</Link>
                  </Menu.Item>
                  <Menu.Item key="support22">
                    <Link to={"/support-others/list/technic"}>
                      Техникийн жагсаалт
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="support23">
                    <Link to={"/support-others/list/fertilizer"}>
                      Бордооны жагсаалт
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </>
            )}
            {[
              USER_ROLE.ADMIN,
              USER_ROLE.PROVINCE_AGRONOMIST,
              USER_ROLE.VILLAGE_AGRONOMIST,
              USER_ROLE.CUSTOMER,
            ].includes(user?.role?.cd) && (
              <SubMenu
                key="incentive"
                icon={<SendOutlined />}
                title={<span>Урамшууллын хүсэлт</span>}
              >
                {USER_ROLE.CUSTOMER === user?.role?.cd && (
                  <Menu.Item key="incentive-request">
                    <Link to={"/incentive/request"}>Хүсэлт илгээх</Link>
                  </Menu.Item>
                )}
                <Menu.Item key="incentive-list">
                  <Link to={"/incentive"}>Хүсэлтийн жагсаалт</Link>
                </Menu.Item>
              </SubMenu>
            )}
            {(user?.role.cd === USER_ROLE.VILLAGE_AGRONOMIST ||
              user?.role.cd === USER_ROLE.PROVINCE_AGRONOMIST ||
              user?.role.cd === USER_ROLE.SPECIALIST ||
              user?.role.cd === USER_ROLE.ADMIN) && (
              <SubMenu
                key="sub7"
                title={<span>Тариалалтын төлөвлөгөө</span>}
                icon={<TeamOutlined />}
              >
                {user?.role.cd === USER_ROLE.VILLAGE_AGRONOMIST && (
                  <Menu.Item key="10">
                    <Link to={"/plan/add"}>Бүртгэх</Link>
                  </Menu.Item>
                )}
                <Menu.Item key="plan">
                  <Link to={"/plan/list"}>Жагсаалт</Link>
                </Menu.Item>
                {user?.role.cd === USER_ROLE.VILLAGE_AGRONOMIST && (
                  <Menu.Item key="reportPlanAgronomist">
                    <Link to={"/report/agroCultivation"}>Тайлан</Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}
            {/* {(user?.role.cd == 'CUSTOMER' || user?.role.cd == 'ADMIN') && <Menu.Item key="planned" icon={<TeamOutlined />}><Link to={'/planned/list'}>УХ, агрономи нэгж төлөвлөгөөт ажил</Link></Menu.Item>} */}
            {user?.role.cd !== "CUSTOMER" &&
              user?.role.cd !== USER_ROLE.WAREHOUSE_SPECIALIST &&
              user?.role.cd !== USER_ROLE.INSPECTOR && (
                <SubMenu
                  key="report"
                  icon={<PieChartOutlined />}
                  title={<span>Тайлан</span>}
                >
                  <Menu.Item key="reportPlan">
                    <Link to={"/report/plan"}>Төлөвлөгөө</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="reportGermination"><Link to={'/report/germination'}>Соёололтийн мэдээ</Link></Menu.Item> */}
                  <Menu.Item key="reportCultivation">
                    <Link to={"/report/cultivation"}>Тариалалтын мэдээ</Link>
                  </Menu.Item>
                  <Menu.Item key="reportHarvest">
                    <Link to={"/report/harvest"}>Ургацын мэдээ</Link>
                  </Menu.Item>
                </SubMenu>
              )}
            <SubMenu
              key="wm-warehouse"
              icon={<DropboxOutlined />}
              title={<span>Зоорь, агуулах</span>}
            >
              <Menu.Item key="wm-warehouse-list">
                <Link to={"/wm/warehouse"}>Жагсаалт</Link>
              </Menu.Item>
            </SubMenu>
          </>
        )}
        {user?.role.cd == USER_ROLE.WAREHOUSE_SPECIALIST && (
          <SubMenu
            key="wm-warehouse"
            icon={<DropboxOutlined />}
            title={<span>Зоорь, агуулах</span>}
          >
            <Menu.Item key="wm-warehouse-add">
              <Link to={"/wm/warehouse/add"}>Бүртгэх</Link>
            </Menu.Item>
            <Menu.Item key="wm-warehouse-list">
              <Link to={"/wm/warehouse"}>Жагсаалт</Link>
            </Menu.Item>
          </SubMenu>
        )}
        <Divider />
        {/* Бусад */}
        {user?.role.cd == "CUSTOMER" && (
          <Menu.Item key="8" icon={<DropboxOutlined />}>
            <Link to={"/warehouse"}>Миний бүтээгдэхүүн</Link>
          </Menu.Item>
        )}
        {user && ["CUSTOMER", "SPECIALIST"].includes(user.role.cd) && (
          <Menu.Item key="parcel" icon={<DropboxOutlined />}>
            <Link to={"/parcel"}>Сав устгал</Link>
          </Menu.Item>
        )}
        {user?.role.cd == "ADMIN" && (
          <Menu.Item key="13" icon={<UsergroupAddOutlined />}>
            <Link to={"/user/list"}>Хэрэглэгчийн бүртгэл</Link>
          </Menu.Item>
        )}
        {user?.role.cd == "ADMIN" && (
          <SubMenu
            key="sstm-v"
            icon={<ApartmentOutlined />}
            title={<span>Системийн утгууд</span>}
          >
            <Menu.Item key="sstm-v-1-1">
              <Link to={"/chemical/file"}>Бодис, бордооны төрлийн файл</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-1">
              <Link to={"/chemical/type"}>Бодис, бордооны төрөл</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-2">
              <Link to={"/chemical/component"}>Бодис, бордоо</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-3">
              <Link to={"/service/type"}>Талбайн арчилгааны төрөл</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-4">
              <Link to={"/service/list"}>Талбайн арчилгаа</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-5">
              <Link to={"/plant/type"}>Таримлын төрөл</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-6">
              <Link to={"/plant/list"}>Таримал</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-7">
              <Link to={"/plant/sort/list"}>Таримлын сорт</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-8">
              <Link to={"/unit"}>Хэмжих нэгж</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-9">
              <Link to={"/equipment/type"}>Төхөөрөмжийн төрөл</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-10">
              <Link to={"/equipment/subtype"}>Төхөөрөмжийн дэд төрөл</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-11">
              <Link to={"/equipment/country"}>Үйлдвэрлэсэн улс</Link>
            </Menu.Item>
            <Menu.Item key="sstm-v-12">
              <Link to={"/cellar-type"}>Зоорийн хийцийн төрөл</Link>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    </Sider>
  );
};
export default MainMenu;
