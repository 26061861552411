import { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Layout,
  Space,
  Form,
  Modal,
  Popconfirm,
  message,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import BreadMe from "../lib/Breadme";
import NetworkApi from "../../network/networkApi";
import useCellarType from "../../hooks/useCellarType";
interface EditState {
  visible: boolean;
  edit?: any;
}
const CellarTypes = () => {
  const [content, loadContent, changeFilter] = useCellarType();
  const [edit, changeEdit] = useState<EditState>({
    visible: false,
  });
  const setEdit = (visible?: boolean, edit?: any) => {
    changeEdit({
      visible: visible == undefined ? false : visible,
      edit,
    });
  };
  const filterChange = (value: any) => {
    changeFilter((prevValues: any) => {
      return { ...prevValues, page: 1, type: value };
    });
  };
  const deleteItem = useCallback(async (data) => {
    let response = await NetworkApi.requestPost("/api/cellarType/update", {
      ...data,
      status: "D",
    });
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Устгахад алдаа гарлаа.");
    }
  }, []);

  const elementInfo = [
    {
      title: "Төрлийн нэр",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Үйлдэл",
      render: (item: any) => (
        <Space size={10}>
          <Button
            onClick={() => setEdit(true, item)}
            type={"primary"}
            size={"small"}
            ghost
            icon={<EditOutlined />}
          />
          <Popconfirm
            title="Устгахдаа итгэйлтэй байна уу"
            onConfirm={() => deleteItem(item)}
            okText="Тийм"
            cancelText="Үгүй"
          >
            <Button
              onClick={() => {}}
              type={"primary"}
              size={"small"}
              danger
              ghost
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleParam = (pagination: any, filters: any, sorter: any) => {
    console.log("Various parameters", pagination, filters, sorter);
  };

  return (
    <>
      <BreadMe title={["Системийн утгууд", "Зоорийн хийцийн төрөл"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Row justify={"space-between"}>
              <Button
                onClick={() => setEdit(true)}
                icon={<PlusSquareOutlined />}
                type={"primary"}
              >
                Төрөл нэмэх
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={elementInfo}
              dataSource={content.result}
              onChange={handleParam}
              rowKey={"id"}
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout.Content>
      <CellarTypeForm
        visible={edit.visible}
        edit={edit.edit}
        onHide={() => setEdit(false)}
        onSuccess={() => {
          setEdit(false);
          loadContent();
        }}
      />
    </>
  );
};
interface Props {
  edit?: any;
  visible: boolean;
  onHide: () => void;
  onSuccess: () => void;
}
const CellarTypeForm = ({ edit, visible, onHide, onSuccess }: Props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (visible) {
      if (edit) {
        form.setFieldsValue(edit);
      } else {
        form.resetFields();
      }
    }
  }, [visible, edit]);
  const onFinish = useCallback(
    async (data: any) => {
      if (edit) {
        data = { ...edit, ...data };
      }
      console.log(data);
      let response = await NetworkApi.requestPost(
        "/api/cellarType/update",
        data
      );
      if (response.resultCode == 200) {
        onSuccess();
      } else {
        message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
      }
    },
    [edit]
  );
  return (
    <Modal
      width={595}
      centered
      visible={visible}
      title={
        edit ? "Зоорийн хийцийн төрөл засах" : "Зоорийн хийцийн төрөл нэмэх"
      }
      onCancel={onHide}
      footer={null}
    >
      <Form form={form} labelCol={{ span: 6 }} onFinish={onFinish}>
        <Form.Item name="name" label="Төрлийн нэр">
          <Input />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
    </Modal>
  );
};
export default CellarTypes;
