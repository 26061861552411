import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Modal,
  Row,
  Spin,
  Timeline,
} from "antd";
import { SupportItemType } from "./SupportProductAdd";
import Table, { ColumnsType } from "antd/lib/table";
import { formatNumberWithComma } from "../../../helpers/number.helper";
import useSupportOthersDetail from "../../../hooks/useSupportOthersDetail";
import { useEffect } from "react";
import { SUPPORT_TYPE, SUPPORT_TYPE_REF } from "../../../enums/support.enum";
import moment from "moment";
import config from "../../../config";

interface Props {
  visible: boolean;
  id: number;
  onHide: () => void;
}

export default function SupportOthersDetail({ visible, id, onHide }: Props) {
  const [detail, loadDetail] = useSupportOthersDetail();

  useEffect(() => {
    loadDetail(id);
  }, [id]);

  const getItemsColumns = () => {
    const columns: ColumnsType<SupportItemType> = [
      {
        title: "№",
        render: (v, r, index: number) => `${index + 1}`,
      },
      {
        title: "Дилер нэр",
        dataIndex: "dealer",
      },
      {
        title: "Дилер регистр",
        dataIndex: "dealerRd",
      },
      {
        title: "Бүтээгдэхүүн",
        dataIndex: "prdProductName",
      },
      {
        title: "Ибаримт код",
        dataIndex: "productCode",
      },
      {
        title: "Үнийн дүн",
        render: (item: any) => {
          if (item.totalPrice) {
            return `${formatNumberWithComma(item.totalPrice ?? 0)} ₮`;
          }
          if (item.ebTotalAmount > 0) {
            return `${formatNumberWithComma(item.ebTotalAmount ?? 0)} ₮`;
          }
          return "0 ₮";
        },
      },
    ];

    if (detail?.result.type === SUPPORT_TYPE.TECHNIC) {
      columns.push({
        title: "Техникийн хувийн хэрэг",
        dataIndex: "contract",
        render: (contract: string) => {
          if (contract) {
            return (
              <a
                href={`${config.get("apiUrl")}/tmp/${
                  JSON.parse(contract).name
                }`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue" }}
              >
                {JSON.parse(contract).name}
              </a>
            );
          }
          return "-";
        },
      });
    }

    if (detail?.result.type === SUPPORT_TYPE.FERTILIZER) {
      columns.push({
        title: "Гэрээ",
        dataIndex: "contract",
        render: (contract: string) => {
          if (contract) {
            return (
              <a
                href={`${config.get("apiUrl")}/tmp/${
                  JSON.parse(contract).name
                }`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue" }}
              >
                {JSON.parse(contract).name}
              </a>
            );
          }
          return "-";
        },
      });
      columns.push({
        title: "Гаальд мэдүүлсэн файл",
        dataIndex: "custom",
        render: (custom: string) => {
          if (custom) {
            return (
              <a
                href={`${config.get("apiUrl")}/tmp/${JSON.parse(custom).name}`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue" }}
              >
                {JSON.parse(custom).name}
              </a>
            );
          }
          return "-";
        },
      });
      columns.push({
        title: "Бордоо хэрэглэсэн талбайн зураг явцын дүнгийн фото зураг",
        dataIndex: "landImage",
        render: (files: string) => {
          return JSON.parse(files).map((file: any, index: number) => (
            <Row key={`land-image-${index}`}>
              <Col span={24}>
                <a
                  href={`${config.get("apiUrl")}/tmp/${file.name}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "blue" }}
                >
                  {file.name}
                </a>
              </Col>
            </Row>
          ));
        },
      });
    }

    return columns;
  };

  const getLandsColumns = () => {
    const columns: ColumnsType<any> = [
      {
        title: "№",
        render: (v, r, indx: number) => `${indx + 1}`,
      },
      {
        title: "НТДугаар",
        dataIndex: "posNo",
      },
      {
        title: "Хэмжээ",
        dataIndex: "area",
        render: (value: any) => `${value} га`,
      },
    ];

    if (detail?.result.type === SUPPORT_TYPE.FERTILIZER) {
      columns.push({
        title:
          "Газрын агрохими, агрофизикийн лабораторийн шинжилгээний дүн (2019-2023)",
        dataIndex: "file",
        render: (value: any) => (
          <a
            href={`${config.get("apiUrl")}/tmp/${JSON.parse(value).name}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: "blue" }}
          >
            {JSON.parse(value).name}
          </a>
        ),
      });
    }

    return columns;
  };

  return (
    <Modal
      style={{ minWidth: 978 }}
      title={null}
      visible={visible}
      onCancel={onHide}
      footer={null}
      closable={false}
    >
      {detail.loading && <Spin size="large" />}
      {!detail.loading && !detail.result && "Мэдээлэл олдсонгүй"}
      {detail.result && (
        <>
          <Row>
            <Col span={24}>
              <Divider orientation="left">Дэлгэрэнгүй</Divider>
              <Descriptions column={2}>
                <Descriptions.Item span={2} label="Хүсэлтийн төрөл">
                  {SUPPORT_TYPE_REF[detail.result?.type]}
                </Descriptions.Item>
                <Descriptions.Item label="Хүсэлт гаргагчийн регистр">
                  {detail.result.user.rd}
                </Descriptions.Item>
                <Descriptions.Item label="Баталгаажсан тариаланч эсэх">
                  <Checkbox checked />
                </Descriptions.Item>
                <Descriptions.Item label="Хүсэлт гаргагчийн овог">
                  {detail.result.user.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="ГЗБГЗЗГ-аар баталгаажсан талбайн нийт хэмжээ">
                  {`${detail.result.totalArea} га`}
                </Descriptions.Item>
                <Descriptions.Item label="Хүсэлт гаргагчийн нэр">
                  {detail.result.user.firstName}
                </Descriptions.Item>
                <Descriptions.Item label="Тариалалт бүртгэсэн эсэх">
                  <Checkbox checked />
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider orientation="left">Хүсэлтийн газрууд</Divider>
              <Table
                scroll={{ x: true }}
                columns={getLandsColumns()}
                dataSource={detail.result?.supportLands ?? []}
                pagination={false}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider orientation="left">Хүсэлтийн бүтээгдэхүүнүүд</Divider>
              <Table
                scroll={{ x: true }}
                columns={getItemsColumns()}
                dataSource={detail.result?.supportItems ?? []}
                pagination={false}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Divider orientation="left">Төлвийн түүх</Divider>
              <Timeline mode="left">
                {(detail.result?.supportLogs ?? []).map((item: any) => (
                  <Timeline.Item
                    label={`${
                      item?.updatedAt
                        ? moment(item.updatedAt).format("YYYY-MM-DD hh:mm:ss")
                        : ""
                    }`}
                  >
                    {item.message}
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
          </Row>
        </>
      )}
      <Row style={{ marginTop: 16, marginBottom: 16 }} justify="end">
        <Button type="primary" onClick={() => onHide()}>
          Хаах
        </Button>
      </Row>
    </Modal>
  );
}
