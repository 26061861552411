import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileTextOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Timeline,
} from "antd";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import config from "../../config";
import {
  INCENTIVE_STATUS,
  INCENTIVE_STATUS_LIST,
  INCENTIVE_STATUS_REF,
  INCENTIVE_TYPE_REF,
} from "../../enums/incentive.enum";
import { USER_ROLE } from "../../enums/user.enum";
import NetworkApi from "../../network/networkApi";
import UserContext from "../../providers/UserContext";
import BreadMe from "../lib/Breadme";
import * as querystring from "querystring";
import useIncentivePlantSort from "../../hooks/useIncentivePlantSort";
import useIncentiveDetail from "../../hooks/useIncentiveDetail";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { formatNumberWithCommaAndPoint } from "../../helpers/number.helper";

const IncentiveStatus = ({ status }: { status: string }) => {
  if (status === INCENTIVE_STATUS.PAID)
    return (
      <span style={{ color: "green" }}>{INCENTIVE_STATUS_REF[status]}</span>
    );
  if (
    [INCENTIVE_STATUS.INVALID_ACCOUNT, INCENTIVE_STATUS.DELETED].includes(
      status
    )
  )
    return <span style={{ color: "red" }}>{INCENTIVE_STATUS_REF[status]}</span>;
  return <span>{INCENTIVE_STATUS_REF[status]}</span>;
};

const IncentiveList = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [sorts, , changeSortsFilter] = useIncentivePlantSort();
  const [content, changeContent] = useState({
    loading: false,
    page: 1,
    count: 0,
    filter: {},
    result: [],
  });
  const [detailModal, changeDetailModal] = useState<{
    id?: number;
    visible: boolean;
  }>({ visible: false });
  const [accountModal, changeAccountModal] = useState<{
    id?: number;
    visible: boolean;
  }>({ visible: false });

  const loadContent = useCallback(async () => {
    changeContent((c) => {
      return {
        ...c,
        loading: true,
      };
    });
    let response = await NetworkApi.requestGet("/api/incentive/list", {
      ...content.filter,
      page: content.page,
    });
    if (response.resultCode === 200) {
      changeContent((c) => {
        return {
          ...c,
          loading: false,
          page: response.result.page,
          count: response.result.count,
          result: response.result.rows || [],
        };
      });
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
  }, [content]);

  useEffect(() => {
    loadContent();
  }, [content.filter, content.page]);

  const changeFilter = (filter: any) => {
    changeContent({ ...content, filter });
  };

  const deleteItem = useCallback(async (recordId) => {
    let response = await NetworkApi.requestPost(
      `/api/incentive/delete/${recordId}`
    );
    if (response.resultCode === 200) {
      message.success("Амжилттай устгагдлаа");
      loadContent();
    } else {
      message.error(response.message || "Устгахад алдаа гарлаа.");
    }
  }, []);

  const deleteConfirm = (id: string) => {
    Modal.confirm({
      content: `Устгахдаа итгэлтэй байна уу?`,
      okText: "Тийм",
      cancelText: "Үгүй",
      icon: <DeleteOutlined style={{ color: "red" }} />,
      onOk: () => deleteItem(id),
    });
  };

  const deleteWarning = (id: string) => {
    Modal.warning({
      title: `Анхааруулга`,
      content: `Хэрвээ буцаасан хүсэлтийг тоо, дүнг засах бол засах үйлдлийг хийнэ
      үү. Буцаасан хүсэлтийг устгасанаар та энэ сард дахин хүсэлт илгээх
      боломжгүй болохыг анхаарна уу!!!`,
      okText: "Ойлголоо",
      onOk: () => deleteConfirm(id),
    });
  };

  const getColumns = () => {
    const columns = [
      {
        title: "№",
        dataIndex: "id",
        render: (v: any, r: any, index: number) => `${index + 1}`,
      },
      {
        title: "Хүсэлт илгээсэн огноо",
        dataIndex: "sentDate",
        render: (value: any) =>
          value ? moment(value).format("YYYY-MM-DD") : "",
      },
      {
        title: "Урамшуулалд хамрагдах сар",
        dataIndex: "targetDate",
        render: (value: any) => moment(value).format("YYYY-MM"),
      },
      {
        title: "Овог нэр",
        render: (item: any) => {
          if (item.organization.type === "organization") {
            return item.organization.name;
          }
          return `${item.user.lastName} ${item.user.firstName}`;
        },
      },
      {
        title: "РД",
        render: (item: any) => {
          if (item.organization.type === "organization") {
            return item.organization.rd;
          }
          return `${item.user.rd}`;
        },
      },
      {
        title: "Аймаг",
        dataIndex: "user",
        render: (value: any) => `${value.aimag.name}`,
      },
      {
        title: "Сум",
        dataIndex: "user",
        render: (value: any) => `${value.sum.name}`,
      },
      {
        title: "Бүтээгдэхүүний төрөл",
        dataIndex: "type",
        render: (value: any) => INCENTIVE_TYPE_REF[value],
      },
      {
        title: "И-баримтаар баталгаажсан бүтээгдэхүүний хэмжээ (кг)",
        dataIndex: "totalEbarimtAmount",
        render: (value: any) => `${formatNumberWithCommaAndPoint(value)} кг`,
      },
      {
        title: "ГҮГ-ээр баталгаажсан бүтээгдэхүүний хэмжээ (кг)",
        dataIndex: "totalAmount",
        render: (value: any) => `${formatNumberWithCommaAndPoint(value)} кг`,
      },
      {
        title: "Урамшуулал бодогдсон дүн (төг)",
        dataIndex: "issuedAmount",
      },
      {
        title: "Олгосон огноо",
        dataIndex: "issuedDate",
      },
      {
        title: "Төлөв",
        dataIndex: "status",
        render: (value: any) => <IncentiveStatus status={value} />,
      },
      {
        title: "Үйлдэл",
        dataIndex: "id",
        width: "100px",
        render: (value: any, record: any) => (
          <Space size={16}>
            <Button
              type={"primary"}
              size={"small"}
              ghost
              icon={<FileTextOutlined />}
              onClick={() => changeDetailModal({ visible: true, id: value })}
            />
            {record.status === INCENTIVE_STATUS.INVALID_ACCOUNT && (
              <Button
                type={"primary"}
                size={"small"}
                ghost
                icon={<EditOutlined />}
                onClick={() => changeAccountModal({ visible: true, id: value })}
              />
            )}
            {[
              INCENTIVE_STATUS.DRAFT,
              INCENTIVE_STATUS.REQUESTED,
              INCENTIVE_STATUS.RETURNED,
            ].includes(record.status) && (
              <Button
                type={"primary"}
                size={"small"}
                ghost
                icon={<EditOutlined />}
                onClick={() => history.push(`/incentive/request/${value}`)}
              />
            )}
            {/*{[INCENTIVE_STATUS.DRAFT, INCENTIVE_STATUS.REJECTED].includes(*/}
            {/*  record.status*/}
            {/*) && (*/}
            {/*  <Button*/}
            {/*    onClick={() => deleteWarning(record.id)}*/}
            {/*    type={"primary"}*/}
            {/*    size={"small"}*/}
            {/*    danger*/}
            {/*    ghost*/}
            {/*    icon={<DeleteOutlined />}*/}
            {/*  />*/}
            {/*)}*/}
          </Space>
        ),
      },
    ];

    if (
      [USER_ROLE.PROVINCE_AGRONOMIST, USER_ROLE.VILLAGE_AGRONOMIST].includes(
        user.role?.cd
      )
    ) {
      columns.splice(8, 3);
    }
    return columns;
  };

  return (
    <>
      <BreadMe title={["Урамшууллын хүсэлт"]} />
      <Layout.Content
        style={{ background: "#fff", padding: 24, margin: 0, minHeight: 280 }}
      >
        <Row>
          <Col span={24}>
            <Form
              layout="inline"
              onFinish={(filter: any) => {
                if (filter.targetDate) {
                  filter.targetDate = filter.targetDate.format("YYYY-MM-DD");
                }
                changeFilter(filter);
              }}
            >
              <Row gutter={[0, 16]} style={{ width: "100%" }}>
                <Col flex="none">
                  <Form.Item name="targetDate">
                    <DatePicker picker="month" placeholder="Он сар" />
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Form.Item name="posNo">
                    <Input placeholder={"НТДугаар"} />
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Form.Item name="userRd">
                    <Input placeholder={"Регистр"} />
                  </Form.Item>
                </Col>
                <Col flex="200px">
                  <Form.Item name="sortId">
                    <Select placeholder="Бүтээгдэхүүн" allowClear>
                      {sorts.result.map((p: any) => (
                        <Select.Option key={`ps-${p.id}`} value={p.id}>
                          {p.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="200px">
                  <Form.Item name="status">
                    <Select placeholder="Төлөв" allowClear>
                      {INCENTIVE_STATUS_LIST.map((p: any) => (
                        <Select.Option key={`st-${p.id}`} value={p.id}>
                          {p.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex="none">
                  <Button
                    htmlType="submit"
                    type={"primary"}
                    style={{ marginRight: 16 }}
                  >
                    <SearchOutlined />
                  </Button>
                </Col>
                {USER_ROLE.ADMIN === user.role?.cd && (
                  <Col flex="none">
                    <Button
                      target="blank"
                      href={`${config.get(
                        "apiUrl"
                      )}/api/incentive/list/download?${querystring.stringify(
                        content.filter
                      )}`}
                      type={"primary"}
                    >
                      <DownloadOutlined />
                      Татах
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={24}>
            <Descriptions title="" style={{ marginTop: 15 }}>
              <Descriptions.Item label="Нийт">
                {content.count}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              style={{ width: "100%" }}
              pagination={{
                current: content.page,
                total: content.count,
              }}
              onChange={(p) => {
                changeContent({
                  ...content,
                  page: p.current || 1,
                });
              }}
              loading={content.loading}
              columns={getColumns()}
              dataSource={content.result ?? []}
              rowKey={"id"}
            />
          </Col>
        </Row>
        {detailModal.visible && (
          <IncentiveDetailModal
            id={detailModal.id}
            visible={detailModal.visible}
            onCancel={() => changeDetailModal({ visible: false })}
          />
        )}
        {accountModal.visible && (
          <IncentiveAccountModal
            id={accountModal.id}
            visible={accountModal.visible}
            onCancel={() => changeAccountModal({ visible: false })}
            onSuccess={() => {
              message.success("Дансны мэдээлэл амжилттай шинэчлэгдлээ");
              changeAccountModal({ visible: false });
              loadContent();
            }}
          />
        )}
      </Layout.Content>
    </>
  );
};

export default IncentiveList;

const IncentiveDetailModal = ({
  visible,
  id,
  onCancel,
}: {
  visible: boolean;
  id?: any;
  onCancel: () => void;
}) => {
  const [content, , changeId] = useIncentiveDetail();
  useEffect(() => {
    if (id) {
      changeId(id);
    }
  }, [id, changeId]);

  return (
    <Modal
      width={1000}
      visible={visible}
      title="Урамшууллын хүсэлт дэлгэрэнгүй"
      onCancel={onCancel}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Descriptions column={2}>
            <Descriptions.Item label="Хүсэлт гаргасан">
              {content.result?.organization?.type === "organization" &&
                `${content.result.organization.name} (${content.result.organization.rd})`}
              {content.result?.organization?.type === "farmer" &&
                `${content.result?.user?.lastName ?? ""} ${
                  content.result?.user?.firstName ?? ""
                } (${content.result?.user?.rd ?? ""})`}
            </Descriptions.Item>
            <Descriptions.Item label="Хүсэлт илгээсэн огноо">
              {`${
                content.result?.sentDate
                  ? moment(content.result.sentDate).format(
                      "YYYY-MM-DD hh:mm:ss"
                    )
                  : ""
              }`}
            </Descriptions.Item>
            <Descriptions.Item label="Урамшууллын бүтээгдэхүүн">
              {`${
                content.result?.type && INCENTIVE_TYPE_REF[content.result.type]
              } ${`${formatNumberWithCommaAndPoint(
                Math.min(
                  content.result?.totalAmount,
                  content.result.totalEbarimtAmount
                ) / 1000
              )} тн`}`}
            </Descriptions.Item>
            <Descriptions.Item label="Хаяг">
              {`${content.result?.user?.aimag?.name ?? ""}, ${
                content.result?.user?.sum?.name ?? ""
              }`}
            </Descriptions.Item>
            <Descriptions.Item label="Банк">
              {content.result?.accountNumber ?? ""}{" "}
              {content.result?.bankName ?? ""}
            </Descriptions.Item>
            <Descriptions.Item label="Геодизээр баталгаажсан">
              {`${`${content.result?.totalArea ?? 0} га`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}`}
            </Descriptions.Item>
            <Descriptions.Item label="Дансны нэр">
              {content.result?.camName}
            </Descriptions.Item>
            <Descriptions.Item label="Төлөв">
              {content.result?.status
                ? INCENTIVE_STATUS_REF[content.result.status]
                : ""}
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">Төлвийн түүх</Divider>
          <Timeline mode="left">
            {(content.result?.incentiveLogs ?? []).map((item: any) => (
              <Timeline.Item
                label={`${
                  content.result?.sentDate
                    ? moment(item.updatedAt).format("YYYY-MM-DD hh:mm:ss")
                    : ""
                }`}
              >
                {item.message}
              </Timeline.Item>
            ))}
          </Timeline>
        </Col>
      </Row>
    </Modal>
  );
};

const IncentiveAccountModal = ({
  visible,
  id,
  onCancel,
  onSuccess,
}: {
  visible: boolean;
  id?: any;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const [form] = Form.useForm();

  const [content, , changeId] = useIncentiveDetail();
  const { user } = useContext(UserContext);
  const [bankList, changeBankList] = useState<any[]>([]);
  const loadBankList = useCallback(async () => {
    changeLoadingBank(true);
    const response = await NetworkApi.requestGet("/api/bank/list");
    if (response.resultCode === 200) {
      changeBankList(response.result ?? []);
    } else {
      message.error(response.message || "Алдаа гарлаа, дахин оролдоно уу!");
    }
    changeLoadingBank(false);
  }, []);
  useEffect(() => {
    loadBankList();
  }, []);
  useEffect(() => {
    if (id) {
      changeId(id);
    }
  }, [id, changeId]);

  useEffect(() => {
    if (content) {
      form.setFieldsValue({
        bankId: content.result?.bankId,
        accountNumber: content.result?.accountNumber,
      });
      changeAccountNumber(content.result?.accountNumber);
      const bank = bankList.find(
        (item) => `${item.code}` === `${content.result.bankId}`
      );
      changeSelectedBank(bank);
    }
  }, [content, bankList]);

  const [loadingBank, changeLoadingBank] = useState<boolean>(false);

  const [submit, changeSubmit] = useState<{
    loading: boolean;
  }>({
    loading: false,
  });
  const [selectedBank, changeSelectedBank] = useState<any | undefined>();

  const [camName, changeCamName] = useState<any | undefined>();
  const onFinish = useCallback(
    async (data: any) => {
      if (!submit.loading) {
        changeSubmit({
          loading: true,
        });
        data.id = id;
        data.bankId = selectedBank.code;
        data.bankName = selectedBank.name;
        data.bankBic = selectedBank.bic;
        data.camName = camName;
        let result = await NetworkApi.requestPost(
          "/api/bank/account/update",
          data
        );
        if (result.resultCode == 200) onSuccess();
        else {
          message.error(result.message || "Алдаа гарлаа, дахин оролдоно уу!");
        }
      }
      changeSubmit({
        loading: false,
      });
    },
    [id, submit, selectedBank, content, camName]
  );
  const [loadingCamName, changeLoadingCamName] = useState<boolean>(false);
  const [accountNumber, changeAccountNumber] = useState<string | undefined>();
  const checkCamName = useCallback(async () => {
    changeLoadingCamName(true);
    if (selectedBank != undefined && selectedBank != null) {
      let result = await NetworkApi.requestPost("/api/camname/check", {
        bankBic: selectedBank.bic,
        accountNumber: accountNumber,
        accountName: user.lastName + " " + user.firstName,
      });
      if (result.resultCode == 200) {
        changeCamName(result.result.camname);
      } else {
        changeCamName(null);
        Modal.confirm({
          title: "Анхааруулга",
          content: result.message || "Алдаа гарлаа",
          okText: "Ойлголоо",
          okCancel: false,
        });
      }
    } else {
      changeCamName(null);
      Modal.confirm({
        title: "Анхааруулга",
        content: "Банк сонгоно уу!",
        okText: "Ойлголоо",
        okCancel: false,
      });
    }
    changeLoadingCamName(false);
  }, [selectedBank, accountNumber]);
  return (
    <Modal
      visible={visible}
      title="Дансны мэдээлэл засах"
      onCancel={onCancel}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Банк сонгох"
              name="bankId"
              rules={[
                {
                  required: true,
                  message: "Банк сонгоно уу",
                },
              ]}
            >
              <Select
                loading={loadingBank}
                onChange={(v) => {
                  if (v) {
                    const bank = bankList.find(
                      (item) => `${item.code}` === `${v}`
                    );
                    changeSelectedBank(bank);
                  } else {
                    changeSelectedBank(undefined);
                  }
                }}
              >
                {bankList.map((t: any) => (
                  <Select.Option key={`bank-${t.code}`} value={t.code}>
                    {t.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Хүсэлт гаргагчийн нэр дээрх дансны дугаар"
              name="accountNumber"
              rules={[
                {
                  required: true,
                  message:
                    "Хүсэлт гаргагчийн нэр дээрх дансны дугаар оруулна уу",
                },
                {
                  validator: (_rule, value) => {
                    if (isEmpty(value)) Promise.resolve();
                    if (!/^[0-9]*$/.test(value))
                      return Promise.reject(new Error("Зөвхөн тоо оруулна уу"));
                    if (!selectedBank) return Promise.resolve();
                    if (9 > value.length)
                      return Promise.reject(
                        new Error(`Дансны дугаарын урт багадаа 9 байх ёстой`)
                      );
                    if (12 < value.length)
                      return Promise.reject(
                        new Error(`Дансны дугаарын урт ихдээ 12 байх ёстой`)
                      );
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Хүсэлт гаргагчийн нэр дээрх дансны дугаар оруулна уу"
                onChange={(e) => {
                  changeAccountNumber(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 14, offset: 8 }}>
              <Row justify="start">
                <Button
                  loading={loadingCamName}
                  type="primary"
                  style={{ borderRadius: 10 }}
                  onClick={() => checkCamName()}
                >
                  Шалгах
                </Button>
              </Row>
            </Form.Item>
            <Form.Item
              label="Хүлээн авах дансны мэдээлэл"
              style={{ marginBottom: 10 }}
            >
              {`${selectedBank?.name ?? ""} - ${accountNumber ?? ""} - ${
                camName ?? ""
              }`}
            </Form.Item>
            <Row justify="end" gutter={16}>
              <Col span={12}>
                <Button
                  style={{ width: "100%" }}
                  disabled={camName == undefined || camName == null}
                  loading={submit.loading}
                  type="primary"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  style={{ width: "100%" }}
                  type="default"
                  onClick={onCancel}
                >
                  Цуцлах
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
